import { stringify as safeStableStringify } from 'safe-stable-stringify'
import { IDBModDoc, IDBObject } from './DBTypes'
import { ElectronAPI } from './ElectronAPI'
import { VideoCacheRecord } from './VideoCacheRecord'
import { ListDocsArgs, RetrieveDocArgs, RetrieveDocResponse, StoreDocArgs, StoreDocResponse } from './lanStorage/docs'

const log = require('debug')('sltt:SlttAppStorage')

type WindowInSlttApp = Window & typeof globalThis & { electron: ElectronAPI }
const windowInSlttApp = window as WindowInSlttApp

export const hasSharedLANStorage = () => {
    const hasElectronContext = !!windowInSlttApp.electron
    const hasDisabledLANStorage = localStorage.disableLANStorage === 'true'
    if (hasElectronContext) {
        log('hasSharedLANStorage', { hasElectronContext, 'localStorage.disableLANStorage': localStorage.disableLANStorage })
    }
    return hasElectronContext && !hasDisabledLANStorage
}

const VIDEO_CACHE_API_STORE_BLOB = 'storeVideoBlob'
const VIDEO_CACHE_API_TRY_RETRIEVE_BLOB = 'tryRetrieveVideoBlob'
const VIDEO_CACHE_RECORDS_API_STORE_VCR = 'storeVideoCacheRecord'
const VIDEO_CACHE_RECORDS_API_LIST_VCRS = 'listVideoCacheRecords'
const VIDEO_CACHE_RECORDS_API_RETRIEVE_VCR = 'retrieveVideoCacheRecord'
export const DOCS_API_STORE_DOC = 'storeDoc'
export const DOCS_API_LIST_DOCS = 'listDocs'
export const DOCS_API_RETRIEVE_DOC = 'retrieveDoc'

export const storeVideoBlob = async (blob: Blob, blobId: string, logContext: string) => {
    if (!hasSharedLANStorage()) return

    const arrayBuffer = await blob.arrayBuffer()
    log(`${logContext} ${VIDEO_CACHE_API_STORE_BLOB} to windowInSlttApp...`, blobId, blob.type, blob, arrayBuffer)
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(VIDEO_CACHE_API_STORE_BLOB, { blobId, arrayBuffer })
    log(`${logContext} ${VIDEO_CACHE_API_STORE_BLOB} response`, response)
}

export const tryRetrieveVideoBlob = async (blobId: string, logContext: string) => {
    if (!hasSharedLANStorage()) return null

    const arrayBuffer = await windowInSlttApp.electron.ipcRenderer.invoke(VIDEO_CACHE_API_TRY_RETRIEVE_BLOB, { blobId })
    log(`${logContext} ${VIDEO_CACHE_API_TRY_RETRIEVE_BLOB} from windowInSlttApp`, blobId, arrayBuffer)
    return arrayBuffer ? new Blob([arrayBuffer]) : null
}

export const storeVideoCacheRecord = async (vcr: VideoCacheRecord, logContext: string) => {
    if (!hasSharedLANStorage()) return

    const serializableVcr = makeObjSerializable(vcr)
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(VIDEO_CACHE_RECORDS_API_STORE_VCR, { videoCacheRecord: serializableVcr })
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_STORE_VCR} response`, response)
    return response
}

/**
 * Lists all video cache records in the storage.
 * 
 * This function queries the local storage to retrieve a filename list of all cached video records.
 * 
 * @project The project name to list video cache records for. Pass empty string for all projects
 * 
 * @returns {Promise<string[]>} A promise that resolves with an array of video record filenames.
 */
export const listVideoCacheRecords = async (project: string, logContext: string): Promise<string[]> => {
    if (!hasSharedLANStorage()) return []

    const response = await windowInSlttApp.electron.ipcRenderer.invoke(VIDEO_CACHE_RECORDS_API_LIST_VCRS, { project })
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_LIST_VCRS} response`, response)
    return response
}

export const retrieveVideoCacheRecord = async (filename: string, logContext: string) => {
    if (!hasSharedLANStorage()) return null

    const response = await windowInSlttApp.electron.ipcRenderer.invoke(VIDEO_CACHE_RECORDS_API_RETRIEVE_VCR, { filename })
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_RETRIEVE_VCR} response`, response)
    return response
}
/**
 * 
 * @param remoteSeq Number.NaN (default) if not from remote 
 * @param logContext 
 * @returns 
 */
export const storeDoc = async ({ project, doc, remoteSeq = Number.NaN }: StoreDocArgs<IDBObject>, logContext: string): Promise<StoreDocResponse | null> => {
    if (!hasSharedLANStorage()) return null

    // log(`${logContext} ${DOCS_API_STORE_DOC} to windowInSlttApp...`, { seq: seqStr, doc })
    const serializableDoc = makeObjSerializable(doc)
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_STORE_DOC, { project, doc: serializableDoc, remoteSeq } as StoreDocArgs<IDBObject>)
    // log(`${logContext} ${DOCS_API_STORE_DOC} response`, response)
    return response as StoreDocResponse
}

export const listDocs = async ({ project, isFromRemote }: ListDocsArgs, logContext: string): Promise<string[]> => {
    if (!hasSharedLANStorage()) return []

    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_LIST_DOCS, { project, isFromRemote } as ListDocsArgs)
    log(`${logContext} ${DOCS_API_LIST_DOCS} docs (${response.length}) response`, response)
    return response
}

export const retrieveDoc = async ({ project, filename, isFromRemote }: RetrieveDocArgs, logContext: string):
    Promise<RetrieveDocResponse<IDBModDoc> | null> => {
    if (!hasSharedLANStorage()) return null

    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_RETRIEVE_DOC, { project, filename, isFromRemote } as RetrieveDocArgs)
    // const seq = filename.split('__')[0]
    // log(`${logContext} ${DOCS_API_RETRIEVE_DOC} response`, { seq, response })
    return response as RetrieveDocResponse<IDBModDoc>
}

const makeObjSerializable = <T>(obj: T): T => {
    const stringified = safeStableStringify(obj)
    return stringified && JSON.parse(stringified)
}
