import _ from "underscore"
import { PassageNote } from "../../models3/ProjectModels"
import { fmt } from "../utils/Fmt"

const log = require('debug')('sltt:NoteSelector')

 
/* Map noteType to/from shape and color.
 * Code was moved here from NoteDialog because it was causing a circular dependency.
*/

export class NoteSelector {
    shapeIndex = -1 // shape index in markerShapes, -1 if no shape restriction
    colorIndex = -1 // color index in project.noteColors, -1 if no color restriction
    noteType = '0'

    static maxColors = 8

    /*
     * Currently supports a maximum of 3 shapes and 8 colors.
     * OK to append (new shapes/colors) to this table but do not delete or change existing entries
     * because that will create data migration issues for the note.type field
     */
    static shapeColorMap = [
        // original 3 shapes, 3 colors
        new NoteSelector(0, 0, '0'), 
        new NoteSelector(0, 1, '1'), 
        new NoteSelector(0, 2, '2'),
        new NoteSelector(1, 0, '3'),
        new NoteSelector(1, 1, '4'),
        new NoteSelector(1, 2, '5'),
        new NoteSelector(2, 0, '6'),
        new NoteSelector(2, 1, '7'),
        new NoteSelector(2, 2, '8'),

        // added 5 more colors
        new NoteSelector(0, 3, '9'),
        new NoteSelector(0, 4, '10'),
        new NoteSelector(0, 5, '11'),
        new NoteSelector(0, 6, '12'),
        new NoteSelector(0, 7, '13'),
        new NoteSelector(1, 3, '14'),
        new NoteSelector(1, 4, '15'),
        new NoteSelector(1, 5, '16'),
        new NoteSelector(1, 6, '17'),
        new NoteSelector(1, 7, '18'),
        new NoteSelector(2, 3, '19'),
        new NoteSelector(2, 4, '20'),
        new NoteSelector(2, 5, '21'),
        new NoteSelector(2, 6, '22'),
        new NoteSelector(2, 7, '23'),
    ]

    constructor(shapeIndex?: number, colorIndex?: number, noteType?: string) {
        if (shapeIndex !== undefined) this.shapeIndex = shapeIndex
        if (colorIndex !== undefined) this.colorIndex = colorIndex
        if (noteType !== undefined) this.noteType = noteType
    }

    // Return all the note selectors with a colorIndex in the noteColors array
    // and which the corresponding color has not been deleted.
    // Order by shapeIndex then colorIndex.
    static validNoteSelectors(noteColors: string[]) {
        function validColor(colorIndex: number) {
            return colorIndex < noteColors.length && noteColors[colorIndex] !== PassageNote.DeletedColor
        }

        let valids = NoteSelector.shapeColorMap.filter(ns => validColor(ns.colorIndex))
        valids = _.sortBy(valids, ns => 1000 * ns.shapeIndex + ns.colorIndex)
        //log('validNoteSelectors', fmt({validCount: valids.length, noteColors}))
        return valids
    }

    static noteSelector(noteType: string) {
        let index = parseInt(noteType)
        index = isNaN(index) || index >= NoteSelector.shapeColorMap.length ? 0 : index
        return NoteSelector.shapeColorMap[index]
    }

    static getType(shapeIndex: number, colorIndex: number) {
        let noteSelector = NoteSelector.shapeColorMap.find(
            ns => ns.shapeIndex === shapeIndex && ns.colorIndex === colorIndex)

        if (!noteSelector) {
            throw new Error(`NoteSelector.getType: invalid shapeIndex ${shapeIndex} or colorIndex ${colorIndex}`)
        }

        return noteSelector.noteType
    }

    get active() {
        return this.shapeIndex !== -1 || this.colorIndex !== -1
    }

    // true iff this selector mathches note.type
    matches(noteType: string) {
        let noteSelector = NoteSelector.noteSelector(noteType)
        if (this.colorIndex !== -1 && this.colorIndex !== noteSelector.colorIndex) return false
        if (this.shapeIndex !== -1 && this.shapeIndex !== noteSelector.shapeIndex) return false
        return true
    }
}
