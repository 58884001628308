// Provides a button to send debugging info to the developers

import React, { FC, useContext } from 'react'
import { t } from 'ttag'
import { VideoCache } from '../../models3/VideoCache'
import { RootContext } from '../app/RootContext'
import { Root } from '../../models3/Root'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { displayInfo, logError, logInfo, setRollbarDebugInfo } from '../utils/Errors'
import { rollbar } from '../..'
import { IDBObject } from '../../models3/DBTypes'
import { MAXTOSYNC } from '../../models3/_LevelupDB'

const log = require('debug')('sltt:SendDebugInfo') 

// return description of videos that need to be uploaded
async function getNeedsUploaded(rt: Root) {
    if (!rt) return '*failed*' // should never happen

    function uploadMessage(vcr: VideoCacheRecord) {
        const isNote = vcr._id.split('/').length > 3 ? '' : '(note) '

        const parts = vcr._id.split('/')
        const passage = rt.project.findPassage(parts.slice(1, 3).join('/'))
        const passageName = passage ? passage.displayName(rt) : vcr._id

        return `${passageName} ${isNote ? '(note) ' : ''}(${vcr.uploadCount})`
    }

    let vcrs = await VideoCache.getAllVcrs()
    vcrs = vcrs.filter(vcr => vcr.needsUploaded)

    let trailer = ''
    if (vcrs.length > 10) {
        trailer = ` ... ${vcrs.length - 10} videos not shown`
        vcrs = vcrs.slice(-10)
    }

    return vcrs.map(uploadMessage).join('; ') + trailer
}

const failedGetNeedsSyncedResponse: { header: string, unsyncedDocs: IDBObject[] } = { header: '*failed*', unsyncedDocs: [] }

// Return first 10 docs and up to last 5 docs that need to be synced
async function getNeedsSynced(rt: Root) {
    if (!rt) return JSON.stringify(failedGetNeedsSyncedResponse) // should never happen

    const idb = rt.project.db
    let docs = await idb.get(idb.localSeqBase, idb.localSeqLast) // TODO: lan docs

    let header = `${docs.length} unsynced docs. total size: ${JSON.stringify(docs).length} bytes`
    if (docs.length > (MAXTOSYNC + 5)) {
        // show first 10 docs, since those are most likely to be the problem
        const first10docs = docs.slice(0, MAXTOSYNC)
        // add last (up to 5) docs that don't overlap with the first 10
        const remainingDocs = docs.slice(MAXTOSYNC, docs.length)
        const lastDocs = remainingDocs.slice(-5)
        docs = first10docs.concat(lastDocs)
        header = header + ` Truncating: including first ${MAXTOSYNC} docs and last ${lastDocs.length} docs (${remainingDocs.length - lastDocs.length} docs not shown)...:`
    }
    return JSON.stringify({ header, unsyncedDocs: docs })
}

// Send debugging info to the developers including info about videos needing to be uploaded and docs needing to be synced
export async function sendDebugInfoViaRollbar(rt: Root, message: string, isError: boolean) {
    let needsSynced = '*failed*'
    try {
        needsSynced = await getNeedsSynced(rt)
    }
    catch (error) {
    }

    let needsUploaded = '*failed*'
    try {
        needsUploaded = await getNeedsUploaded(rt)
    }
    catch (error) {
    }

    rollbar.configure({ payload: { custom: {needsSynced, needsUploaded} } })

    if (isError) {
        logError(message)
    } else {
        logInfo(message)
    }

    // Clear needsSynced and needsUploaded since we only set them when the user explicitly asks for debugging info
    // and they are not set when automatically reporting an error.
    rollbar.configure({ payload: { custom: { needsSynced: null, needsUploaded: null } } })
}

interface ISendDebugInfo {
}

const SendDebugInfo: FC<ISendDebugInfo> = () => {
    let rt = useContext(RootContext)

    return (
        <div>
            <button type="button"
                className={"btn btn-default"}
                onClick={async () => { 
                    await sendDebugInfoViaRollbar(rt!, 'Debug info sent to developers', false)
                    displayInfo(t`Debug info sent to developers`)
                }}>{t`Send debug info to developers`}</button>
        </div> 
    )
}

export default SendDebugInfo
