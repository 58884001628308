import React, { FunctionComponent, useState, useRef } from 'react'
import { t } from 'ttag'
import { AppRoot } from '../../models3/AppRoot'

import { useOnClickOutside } from '../utils/Hooks'
import { EditableRichText } from '../utils/RichTextEditor'

const log = require('debug')('sltt:NoteTextEditor')


interface INoteTextEditor {
    initialText: string,
    rows?: number,
    onSave: (text: string) => void,
    onCancel: () => void,
    useRichText?: boolean,
}

// Show text editor with save/cancel buttons. 
// Autosave changes if user clicks elsewhere.

export const NoteTextEditor: FunctionComponent<INoteTextEditor> = 
        ({ initialText, rows, onSave, onCancel, useRichText }) => 
{
    let [value, setValue] = useState(initialText)
    const ref = useRef()

    useOnClickOutside(ref, () => {
        log('useOnClickOutside')
        if (value !== initialText) {
            onSave(value)
        } else {
            onCancel()
        }
    })

    let prefix = 'note-text-editor'

    if (AppRoot.featureFlag('rtf')) {
        return (
            <EditableRichText
                savedText={initialText}
                save={text => onSave(text)}
                cancel={() => onCancel()}
                editorOpen={true}
                setEditorOpen={value => { log('!!!', value) }}
                prefix={prefix}
            />
        )
    }

    return (
        <div className="note-text-editor" ref={ref as any}>
            <div className="note-text-editor-editor">
                <textarea
                    name="story"
                    autoFocus
                    rows={rows ? rows : 10}
                    cols={rows === 1 ? 100 : 60}
                    value={value}
                    onChange={e => setValue(e.target.value)} />
            </div>
            <div className="note-text-editor-buttons">
                <button type="button"
                    className="btn btn-default btn-primary pull-right note-text-editor-cancel"
                    onClick={onCancel}>{/* translator: important */ t`Cancel`}</button>
                <button type="button"
                    className="btn btn-primary pull-right note-text-editor-ok"
                    onClick={() => onSave(value)}>{t`OK`}</button>
            </div>
        </div>
    )
}

