import React, { FC, useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { t } from 'ttag'

import * as serviceWorker from '../../serviceWorker'

// Customize build process like this?
// https://karannagupta.com/using-custom-workbox-service-workers-with-create-react-app/
// Eventual this: https://github.com/facebook/create-react-app/issues/5673

// NOTE: In order to use service worker currently you must
//     yarn build      -- build production version of code,
//                     -- autocreates loader based on template
//     serve -s public
const ServiceWorkerWrapper: FC = () => {
    const [updateAvailable, setUpdateAvailable] = useState(false)
    const [worker, setWorker] = useState<ServiceWorker | null>(null)

    const updateToLatest = () => {
        worker?.postMessage({ type: 'SKIP_WAITING' })
        setUpdateAvailable(false)
        window.location.reload()
    }

    const onUpdateAvailable = (registration: ServiceWorkerRegistration) => {
        setUpdateAvailable(true)
        setWorker(registration.waiting)
    }

    useEffect(() => {
        // Use service worker
        try {
            serviceWorker.register({ onUpdate: onUpdateAvailable })
        } catch (error) {
            const msg = 'serviceWorker register error: '
            console.error(msg, error)
            serviceWorker.unregister()
            console.log('serviceWorker unregistered')
            throw new Error(msg + String(error))
        }

            /* --- OR --- */

        // Do NOT use service worker
        // serviceWorker.unregister()
    }, [])

    if (updateAvailable) {
        return (
            <Alert>
                <span className="hide-on-alert-overlap">{t`An update is available.`}</span>
                <button onClick={updateToLatest}>{t`Update`}</button>
            </Alert>
        )
    }

    return null
}

export default ServiceWorkerWrapper
