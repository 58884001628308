// This component plays a PassageVideo by knowing about the main video
// and its patch videos and switching between them as the absolute time
// advances.
//
// This component should not know anything about what environment it is in
// so it should NOT contain any references to Root.
//
// The routine play(), stop(), setCurrentTime() are directly invoked by
// the parent in order to initiate those operations.

import React, { Component, FC } from 'react'
import {observable, set} from 'mobx'
import {observer} from 'mobx-react'

import { PassageVideo, Passage, PassageSegment } from '../../models3/ProjectModels'
import { ViewableVideoCollection, ViewableVideo } from './ViewableVideoCollection'
import './Video.css'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import VideoPlayerCore from './VideoPlayerCore'
import { FullScreenButton, FullScreenOffButton } from '../utils/Buttons'
import { PositionSetter, VideoTimeline } from './VideoTimeline'
import VideoTimelinePlayButtons from './VideoTimelinePlayButtons'

const log = require('debug')('sltt:VideoPlayer2') 

const ALittleBit = 0.05

interface IFullScreenToggleButton {
    fullScreen: boolean,
    setFullScreen: (fullScreen: boolean) => void,
}

const FullScreenToggleButton: FC<IFullScreenToggleButton> = observer(({ fullScreen, setFullScreen}) => {
    if (fullScreen) {
        return (
            <FullScreenOffButton enabled={true} onClick={() => setFullScreen(false)} />
        )
    }

    return (
        <FullScreenButton enabled={true} onClick={() => setFullScreen(true)} />
    )
})

interface IVideoPlayer {
    passage: Passage,
    video: PassageVideo,
        // This may be the base video or a VisiblePassageVideo built to contain only
        // the patches up to a certain point in time.
    vvc: ViewableVideoCollection,
        // It is the responsibility of the parent component to setup() this
        // and initiate a download()

    initialTime?: number,
    playbackRate: number,
    autoPlay?: boolean,
    disablePlay?: boolean, // ignore requests to play
    className?: string,
    onEnded?: () => void,
    onTick?: (currentTime: number) => void,
    onPlayingStatus?: (playing: boolean) => void,
    onSegmentChange?: (videoId: string, segmentIndex: number) => void,
    onCanPlayThrough?: (video: PassageVideo) => void,
    disableOnClick?: boolean, // ignore clicks

    // Inform parent that user has requested a play or a stop.
    play: (startTime?: number, endingTime?: number) => void,
    stop: (hitEndingTime?: boolean) => void,

    setVideoWidth?: (width: number) => void, // inform parent of change of video width
    muted?: boolean,
}

@observer
export default class VideoPlayer extends Component<IVideoPlayer> {
    // PassageVideo/segmgmentIndex corresponding to the segment that is currently playing
    @observable segmentIndex = 0
    @observable actualSegment: PassageSegment | null = null
        // Currently selected segment.
        // "actual" means that if this segment is a patch, we store the segment for the patch
        // video rather than the segment from the base video.
    
    @observable playing = false

    @observable fullScreen = false

    /* Normally we want to notify our parent when the video changes status between
     * playing and not playing so that it can adjust its controls to reflect this.
     * However sometimes this player has to temporarily stop one video and start another
     * video to switch from one patch to another. When we are doing that we don't
     * want our parent to update since the change is only temporary and should not
     * affect the UI.
     */
    disableOnPlayingStatus = false
    
    currentTime = 0
    endingTime?: number = undefined
    ended = false

    videoInitialized = ''

    vpc: VideoPlayerCore | null = null
        // VideoPlayerCore component manages all the videos in the PassageVideo and plays
        // them as directed by the VideoPlayer2.

    setters: PositionSetter[] = [] 

    constructor(props: IVideoPlayer) {
        super(props)        
        log('constructor')

        let { autoPlay, initialTime } = this.props
        
        let currentTime = initialTime || 0
        this.setCurrentTime(currentTime)
        this.setupSetters()
        autoPlay && this.doInitialPlay().catch(displayError)
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keydown)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keydown)
    }

    setupSetters() {
        this.setters = [
            // set currentTime
            new PositionSetter(
                '3',
                () => 0,
                () => this.props.video.computedDuration,
                (value: number) => {
                    if (!this.playing) {
                        this.props.onTick && this.props.onTick(value)
                        this.setCurrentTime(value)
                    }
                }
            ),
        ]
    }

    setFullScreen = (fullScreen: boolean) => { 
        this.fullScreen = fullScreen 
    }

    doInitialPlay = async () => {
        let { video, vvc, play } = this.props
        
        let endingTime = video.computedDuration
        log('doInitialPlay', fmt({video, endingTime}))

        this.currentTime = 0

        await vvc.waitUntilDownloaded()
        this.setCurrentTime(0)

        // Call parent to start this video (and maybe other windows) playing
        play(0, endingTime)
    }

    render() {
        const { vvc, className, children, playbackRate, setVideoWidth, muted, video } = this.props
        const { fullScreen } = this
        //log('render', fmt({actualSegment: this.actualSegment}))

        const _className = `video-player${className ? ' ' + className : ''}` + (fullScreen ? '-full-screen' : '')

        return (
            <div className={_className}>
                <VideoPlayerCore
                    className={`video-player-video-video`}
                    vvc={vvc}
                    playbackRate={playbackRate}
                    ref={vpc => {this.vpc = vpc}}
                    onStop={this.onStop}
                    onClick={this.onClick}
                    onTick={this.onTick}
                    onPlayingStatus={this.onPlayingStatus}
                    setVideoWidth={setVideoWidth}
                    onCanPlayThrough={this.onCanPlayThroughHandler}
                    muted={muted}>
                        {children}
                </VideoPlayerCore>
                {fullScreen && 
                    <div className='video-player-video-full-screen-timeline'>
                        <VideoTimelinePlayButtons
                            isPlaying={this.playing}
                            playAll={() => { 
                                let startTime = this.currentTime
                                this.props.play(startTime) 
                            }}
                            pause={() => this.stop()}
                        />
                        <VideoTimeline
                            setters={this.setters}
                            domainStartPosition={0}
                            domainEndPosition={video.computedDuration}
                            adjustTime={time => {
                                this.setters[0].setValue(time)
                            }}
                            enabled={true}
                            allowAdjustingPositions={true}
                    />
                </div>}
                <FullScreenToggleButton 
                    fullScreen={this.fullScreen} setFullScreen={this.setFullScreen} />
            </div>
        )
    }

    keydown = (e: KeyboardEvent) => {
        if (e.code === 'Escape' && this.fullScreen) {
            e.preventDefault()
            this.fullScreen = false
            return
        }

        /**
        * On Windows the meta key is the windows key.
        * On Mac, the meta key is the command key.
        */
        if (e.code === 'KeyF' && (e.metaKey || e.ctrlKey) && e.shiftKey && !this.fullScreen) {
            e.preventDefault()
            this.fullScreen = true
            return
        }
    }

    /* This is the central function of this component. 
     * When a video is playing this called on every tick of the clock.
     * It must respond to segment boundary transitions and change which video
     * is playing when it hits a patch segment.
     * 
     * Rembmer that in SLTT a 'position' is a time offset relative to one specific
     * video. A 'time' is an absolute time offset withing the entire collection of
     * videos for a specific draft of a passage.
     */
    onTick = (position: number /* position in this video in seconds */) => {
        let { onTick, video } = this.props
        let { endingTime, actualSegment, segmentIndex } = this
        if (actualSegment === null) {
            log('###onTick aborted, no segment')
            return
        }

        let time = actualSegment.positionToTime(position)
        let segmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

        //log('!!!onTick', fmt({ segmentIndex, position, time, segmentEndTime, endingTime}))

        if (endingTime !== undefined && almostDone(time, endingTime)) {
            log('at specified ending time, stop', fmt({position, time, endingTime}))
            this.stop()
            return
        }

        if (almostDone(time, segmentEndTime) && this.segmentHasGapAtEnd(segmentIndex)) {
            this.playVideoForNextSegment()
            return
        }

        this.currentTime = time
        this.setters[0].setValue(time)
        onTick && onTick(time)

        let _segmentIndex = video.timeToSegmentIndex(time)
        if (_segmentIndex !== segmentIndex) {
            this.selectSegment(_segmentIndex)
        }
    }

    /* Determine if it is necessary to at end of segment to stop playing
     * the current video and go to a differnt video or a different time.
     */
    segmentHasGapAtEnd = (segmentIndex: number) => {
        let { video, passage } = this.props

        if (segmentIndex+1 >= video.segments.length) return false

        let seg1 = video.segments[segmentIndex]
        segmentIndex++
        let seg2 = video.segments[segmentIndex]
        while (seg2?.ignoreWhenPlayingVideo) {
            segmentIndex++
            seg2 = video.segments[segmentIndex]
        }
        if (!seg1 || !seg2) return true

        let video1 = seg1.patchVideo(passage) || video
        let video2 = seg2.patchVideo(passage) || video

        if ((video1 !== video2) || !closeEnough(seg1.endPosition, seg2.position)) {
            log('segmentHasGapAtEnd', fmt({seg1, seg2}))
            return true
        }

        return false
    }
    
    playVideoForNextSegment() {
        let { passage, video } = this.props
        let { segments } = video
        let segmentIndex = this.segmentIndex + 1
        let segment: PassageSegment | undefined = undefined

        while (segmentIndex < segments.length) {
            segment = segments[segmentIndex]
            if (!segment?.actualSegment(passage).ignoreWhenPlayingVideo) break
            segmentIndex++
        }

        log('playVideoForNextSegment', fmt({
            segment: segment ? segment : '### NO segment',
            time: segment?.time,
            length: segments.length,
            segmentIndex,
        }))

        if (segmentIndex >= segments.length || !segment) {
            log('### no segment!')
            this.stop()
            return
        }

        this.disableOnPlayingStatus = true // don't update toolbar play status while switching videos

        this.setCurrentTime(segment.time, true)

        if (this.vpcNotSet('playNextSegment')) return
        this.vpc!.play()
            .then(() => {
                this.disableOnPlayingStatus = false
            })
            .catch(err => {
                this.disableOnPlayingStatus = false
                displayError(err)
            })
    }

    onPlayingStatus = (playing: boolean) => {
        let { onPlayingStatus } = this.props
        let { disableOnPlayingStatus } = this

        this.playing = playing
        !disableOnPlayingStatus && onPlayingStatus && onPlayingStatus(playing)
    }

    onStop = () => {
        let { onPlayingStatus, onEnded } = this.props
        let { disableOnPlayingStatus } = this
        
        this.ended && setTimeout(() => onEnded && onEnded(), 500)
        !disableOnPlayingStatus && onPlayingStatus && onPlayingStatus(false)

        this.playing = false
    }

    /**
     * When user clicks video requesting that we start or stop it, we just
     * inform our parent. They are responsible for invoking this.play() or this.stop().
     * I think that is because things like detached windows and draft players need
     * to be informed about these actions.
     */
    onClick = async () => {
        let { playing, currentTime, endingTime } = this
        let { disablePlay } = this.props
        
        if (playing) {
            log('onClick stop')
            this.props.stop()  // inform parent of stop request
        }
        else {
            if (disablePlay) return
            log('onClick play', fmt({currentTime, endingTime}))

            this.props.play(currentTime, endingTime) // inform parent of play request
        }
    }

    // Note that _video here might be either the main video or a patch
    onCanPlayThroughHandler = (_video: PassageVideo) => {
        let { video, onCanPlayThrough, initialTime } = this.props
        let { videoInitialized } = this

        //log('onCanPlayThrough', fmt({ _video, duration, video, videoInitialized}))

        /**
         * Videos made with the webcam can take a few ms before the image stabalizes.
         * When the main video is first loaded, seek a short time into the video to avoid
         * showing the image from the video before the camera has stabalized.
         */
        if (_video._id === video._id && videoInitialized !== video._id) {
            log('onCanPlayThrough initialize', fmt({_video}))

            let time = initialTime || 0.05
            this.setCurrentTime(time)
            this.videoInitialized = video._id
        }

        onCanPlayThrough && onCanPlayThrough(_video)
    }

    // --------------------------
    // ---- Called by parent ----
    // --------------------------

    // startTime = null, means play from current position
    // endTime = null, means play through until end
    // Called externally.
    public async play(startTime?: number, endingTime?: number) {
        let { disablePlay, video } = this.props
        let { playing, currentTime } = this
        // console.clear()
        log('play', fmt({currentTime, startTime, endingTime, playing}))

        // If play from beginning and first segment is hidden, skip it.
        if (startTime === 0 && video.segments[0].ignoreWhenPlayingVideo && video.segments.length > 1) {
            startTime = video.segments[1].time
        }

        // Once playing has started we don't want to do any additional initialization
        // because it will disrupt the play.
        this.videoInitialized = video._id

        if (this.vpcNotSet('play')) return

        if (disablePlay) {
            log('play [disablePlay=true]')
            return
        }

        this.disableOnPlayingStatus = false
        this.ended = false

        this.endingTime = endingTime ?? video.computedDuration
        log('play', fmt({
            video: JSON.stringify(video.dbgSegments(), null, 4),
            startTime, 
            endingTime: this.endingTime, 
            currentTime: this.currentTime, 
            playing}))

        if (startTime === undefined) { 
            // If user did not request a specific start time, default to currentTime
            startTime = currentTime

            // If very close to end, go back to start
            if (startTime + .1 >= this.endingTime) {
                startTime = 0
            } 
        } 

        // Cause VideoPlayerCore to display video for this time.
        // It might be the main video or a patch. 
        // It is stopped.
        this.setCurrentTime(startTime, true)

        // I could not make this fix the error associated with playing "too soon", not sure why
        // let _delay = 250
        // let retryLimit = 5
        // for (let retry = 0; retry <= retryLimit; ++retry) {
        //     try {
        //         log('!!!play', fmt({retry}))
        //         await this.vpc!.play()
        //     } catch (error) {
        //         log("!!!play() failed, retrying", error)
        //         await delay(_delay)
        //         _delay = 2*_delay

        //         if (retry > retryLimit) {
        //             throw `Failed to play() after 5 retries`
        //         }
        //     }
        // }

        await this.vpc!.play()
    }

    public stop()
    {
        if (this.vpcNotSet('stop')) return

        log(`stop`)
        this.ended = true
        this.vpc!.stop()
    }

    // May be invoked internally or externally.
    // Stops video from playing.
    // Sets the currently active video as well as the current time in that video
    skipTinySegments(segmentIndex: number) {
        let { segments } = this.props.video

        while (segmentIndex+1 < segments.length) {
            if (segments[segmentIndex+1].time - segments[segmentIndex].time >= 3 * ALittleBit) break

            segmentIndex = segmentIndex + 1
            log('skipTinySegment')
        }

        return segmentIndex
    }

    public setCurrentTime(time: number, _skipTinySegments?: boolean) {
        let { video, passage } = this.props
        let { playing } = this

        log('setCurrentTime (and stop)', fmt({time, playing}))
        if (this.vpcNotSet('setCurrentTime')) return

        playing && this.vpc!.stop()

        let _segmentIndex = video.timeToSegmentIndex(time)
        if (_skipTinySegments) {
            let _segmentIndex2 = this.skipTinySegments(_segmentIndex)
            if (_segmentIndex2 !== _segmentIndex) {
                time = video.segments[_segmentIndex2].time
                _segmentIndex = _segmentIndex2
            }
        }

        // set this.actualSegment, this.segmentIndex, call onSegmentChange
        this.selectSegment(_segmentIndex)

        this.currentTime = time

        this.setters[0].setValue(time)
        
        let actualVideo = video.segments[_segmentIndex].patchVideo(passage) || video
        
        // Ask VideoPlayerCore to select the visible video and set the position.
        // Video stopped at this position.
        let { actualSegment } = this
        this.vpc!.setVideo(actualVideo, actualSegment!.timeToPosition(time))
    }

    // --- local utility functions

    vpcNotSet(message?: string) {
        if (this.vpc) return false

        log('###checkVpc failed', message)
        return true
    }

    selectSegment(segmentIndex: number) {
        let { passage, video, onSegmentChange } = this.props

        let segment = video.segments[segmentIndex]

        if (!segment) {
            log('### selectSegment failed', fmt({video, segmentIndex}))
            segmentIndex = 0
            segment = video.segments[0]
        }

        let actualSegment = segment.actualSegment(passage)
        log('selectSegment', fmt({segmentIndex, actualSegment, position: actualSegment.position}))

        this.actualSegment = actualSegment
        this.segmentIndex = segmentIndex
        onSegmentChange && onSegmentChange(video._id, segmentIndex)
    }

}

const closeEnough = (a: number, b: number) => Math.abs(a - b) < ALittleBit
const almostDone = (a: number, b: number) => a + ALittleBit >= b
