import React, { FC } from 'react'
import { observable } from 'mobx'
import { observer, /* inject */ } from 'mobx-react'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import SegmentSelector from './SegmentSelector'
import SegmentToolbar from './SegmentToolbar'
import SegmentGlosses from './SegmentGlosses'
import _ from 'underscore'
import './Segments.css'
import '../translation/Translation.css'
import VideoDropTarget from '../passages/VideoDropTarget'
import { PassageSegment, PassageVideo, Passage, PassageSegmentLabel } from '../../models3/ProjectModels'
import { displayError, systemError } from '../utils/Errors'
import OldSegmentViewer from './OldSegmentViewer'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import SegmentLabelsEditor from './SegmentLabelsEditor'
import { defaultLabels } from './SegmentLabelsPosition'
import SegmentReferencesEditor from './SegmentReferencesEditor'
import { fmt } from '../utils/Fmt'
import { VideoSketchEditor } from '../video/VideoSketcher'

// Display the currently selected passageSegment and allow editing it.


const log = require('debug')('sltt:SegmentsEditor')

interface ISegmentsEditor {
    rt: Root,
    editingSegmentPosition: EditingSegmentPosition, 
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void,
}

class SegmentsEditor extends React.Component<ISegmentsEditor> {
    @observable videoBeingRecorded: PassageVideo | null = null
    @observable currentVersion: PassageVideo | null = null
    @observable currentVersionSegment: PassageSegment | null = null
    @observable oldSegmentViewerOpen = false
    @observable editingReferences = false
    @observable editingGlosses = false
    savedPassageSegment: PassageSegment | null = null

    constructor(props: ISegmentsEditor) {
        super(props)
        this.stopRecording = this.stopRecording.bind(this)
        this.chooseVersionToPlay = this.chooseVersionToPlay.bind(this)
        this.openOldSegmentViewer = this.openOldSegmentViewer.bind(this)
        this.closeOldSegmentViewer = this.closeOldSegmentViewer.bind(this)
        this.record = this.record.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.isMostRecentVersion = this.isMostRecentVersion.bind(this)

        let { passageSegment, passage, passageVideo } = this.props.rt
        if (passageSegment && passage && passageVideo) {
            if (passageSegment.videoPatchHistory.length > 0) {
                let last = passageSegment.videoPatchHistory.length - 1
                let mostRecent = passageSegment.videoPatchHistory[last]
                let currentVersion = this.videoInHistory(mostRecent, passage)
                if (currentVersion) {
                    this.currentVersion = currentVersion
                    this.currentVersionSegment = currentVersion.segments[0]
                }
            } else {
                this.currentVersion = passageVideo
                this.currentVersionSegment = passageSegment
            }
        }
    }
    
    componentWillUnmount() {
        let { rt } = this.props
        rt.setEditingSegment(false)
    }

    videoInHistory = (id: string, passage: Passage) => passage.videos.find(v => v._id === id)

    render() {
        let { rt, editingSegmentPosition, setEditingSegmentPosition } = this.props
        let { passage, passageVideo, passageSegment, editingSegment, editingSegmentLabels,
            segmentLabelsDraft,
            tourSelector /* so that children re-render */ } = rt
        const editingVideoSketch = rt.videoSketchData.editingCurrentSegment(rt.passage, rt.passageSegment)

        // access passage._rev to force render whenever any passage info received
        // eslint-disable-next-line
        passage && passage._rev

        if (!passage || !passageVideo || !passageSegment) return null

        // Access to force re-renders on change
        passageSegment._id
        passageSegment.videoPatchHistory.length

        let segments = passageVideo.visibleSegments(passage)
        
        let currentSegment = passageSegment.actualSegment(passage)
        if (!currentSegment) return null

        let idx = _.findIndex(segments, ps => ps._id === currentSegment!._id)
        if (idx === -1) return null

        let { record, stopRecording, chooseVersionToPlay, closeOldSegmentViewer,
            currentVersion, currentVersionSegment, play, pause, oldSegmentViewerOpen,
            setEditingGlosses, startEditingLabels, cancelEditingLabels,
            updateLabels, editingGlosses, setEditingReferences,
            editingReferences } = this

        const modalEditing = editingReferences || editingSegmentLabels || editingVideoSketch
        let defined = (e: PassageVideo | undefined) => e !== undefined
        let oldVersionOptions = passageSegment.videoPatchHistory.map(item => this.videoInHistory(item, passage!))
                                                    .filter(defined)
                                                    .reverse() as PassageVideo[]
        oldVersionOptions.push(passageVideo)

        let message = <div>{t`Drop video here to patch current segment.`}</div>
        let dropTargetView = <DropTargetViewLarge message={message} />

        return (
            <VideoDropTarget 
                    rt={rt} 
                    passage={passage} 
                    videoIsPatch={true} 
                    segment={passageSegment}
                    ondone={() => { }} 
                    dropTargetView={dropTargetView}>
                <div className="passage-passage-segments-editor">
                    <div className="segment-selector">
                        <div className="segment-selector-first-group">
                        </div>
                        <div className="segment-selector-middle-group">
                            <SegmentSelector segment={currentSegment} rt={rt} editing={rt.segmentTabEditingInProgress} />
                        </div>
                        <div className="segment-selector-last-group">
                            <SegmentTimestamp {...{rt, passage, passageSegment, currentSegment}} />
                        </div>
                    </div>
                    <SegmentToolbar
                        rt={rt}
                        record={record}
                        segment={currentSegment}
                        stopRecording={stopRecording}
                        oldVersionOptions={oldVersionOptions}
                        chooseVersionToPlay={chooseVersionToPlay}
                        convertReferences={() => {
                            passage && passageVideo?.convertReferences(passage)
                            this.props.rt.setDbsRefs(this.props.rt.portion, this.props.rt.passageSegment)
                        }}
                        play={play}
                        pause={pause}
                        editingSegmentPosition={editingSegmentPosition}
                        setEditingSegmentPosition={setEditingSegmentPosition}
                        startEditingLabels={startEditingLabels}
                        setEditingReferences={setEditingReferences}
                    />
                    {oldSegmentViewerOpen && currentVersion && currentVersionSegment && (
                        <OldSegmentViewer
                            key={currentVersion._id}        // Component will be recreated when this changes
                            rt={rt}
                            originalSegment={passageSegment}
                            originalVideo={passageVideo}
                            video={currentVersion}
                            segment={currentVersionSegment}
                            onDone={closeOldSegmentViewer}
                        />
                    )}
                    <div className='flex-column-display'>
                        {editingReferences && (
                            <SegmentReferencesEditor 
                                rt={rt}
                                segment={currentSegment.actualSegment(passage)}
                                onClose={() => { 
                                    setEditingReferences(false)
                                } } />
                        )}
                        {editingSegmentLabels && (
                            <SegmentLabelsEditor 
                                rt={rt}
                                labels={segmentLabelsDraft}
                                updateLabels={updateLabels}
                                onClose={cancelEditingLabels} />
                        )}
                        {editingVideoSketch && (
                            <VideoSketchEditor data={rt.videoSketchData} />
                        )}
                    </div>
                    {!modalEditing && <SegmentGlosses
                        editing={editingGlosses}
                        setEditing={setEditingGlosses}
                        segment={currentSegment}
                        tourSelector={tourSelector}
                        rt={rt} />}
                </div>
            </VideoDropTarget>
        )
    }

    setEditingReferences = (editing: boolean) => {
        this.editingReferences = editing
        this.setEditing()
    }

    setEditingGlosses = (editing: boolean) => {
        this.editingGlosses = editing
        this.setEditing()
    }

    updateLabels = (labels: PassageSegmentLabel[]) => {
        let { rt } = this.props
        rt.segmentLabelsDraft = labels
    }

    startEditingLabels = () => {
        const { rt } = this.props
        const { passageSegment, passage } = rt
        if (!passage || !passageSegment) return
        
        const actualSegment = passageSegment.actualSegment(passage)
        if (!actualSegment) return
        
        const labels = actualSegment.labels || []

        // If labels do not exist yet, use default labels
        const initialLabels = labels.map(l => new PassageSegmentLabel(l.x, l.y, l.xText, l.yText, l.text))
        for (let i = labels.length; i < 4; i++) {
            initialLabels.push(defaultLabels[i])
        }

        rt.startEditingSegmentLabels(actualSegment, initialLabels)
        this.setEditing()
    }

    cancelEditingLabels = () => {
        let { rt } = this.props
        rt.resetSegmentLabelsDraftChanges()
        this.setEditing()
    }

    setEditing = () => {
        let { editingReferences, editingGlosses } = this
        let { editingSegmentPosition } = this.props

        let { rt } = this.props
        let { editingSegmentLabels } = rt
        const editingVideoSketch = rt.videoSketchData.editingCurrentSegment(rt.passage, rt.passageSegment)

        let editingSegment = (
            editingGlosses || editingSegmentLabels || editingVideoSketch || editingReferences ||
            (editingSegmentPosition !== EditingSegmentPosition.None))
        log(`setEditing ${editingSegment}`)
        rt.setEditingSegment(editingSegment)
    }

    chooseVersionToPlay(id: string) {
        let { rt } = this.props
        let { passage, passageVideo, passageSegment } = rt
        if (!passage || !passageVideo || !passageSegment) {
            return
        }

        let video = this.videoInHistory(id, passage)
        if (!video) {
            return
        }

        let isMainVideo = video._id === passageVideo._id
        let segment = isMainVideo ? passageSegment : video.segments[0]

        if (this.isMostRecentVersion(video)) {
            this.closeOldSegmentViewer()
            this.play()
        } else {
            this.openOldSegmentViewer()
        }

        this.currentVersion = video
        this.currentVersionSegment = segment
    }

    play() {
        let { rt } = this.props
        let { passageVideo, passage, passageSegment } = rt
        if (!passageVideo || !passage || !passageSegment) return

        let segment = passageSegment.actualSegment(passage)
        if (!segment) {
            return
        }

        rt.play(segment.time, segment.time + segment.duration, segment.time)
    }

    pause() {
        let { rt } = this.props
        rt.stop()
    }

    isMostRecentVersion(currentVersion: PassageVideo) {
        let { rt } = this.props
        let { passageVideo, passage, passageSegment } = rt
        if (!passageVideo || !passage || !passageSegment) {
            return true
        }

        let { videoPatchHistory } = passageSegment
        if (videoPatchHistory.length > 0) {
            let last = videoPatchHistory.length - 1
            let mostRecent = videoPatchHistory[last]
            if (currentVersion._id !== mostRecent) {
                return false
            }
        }
        return true
    }

    openOldSegmentViewer() {
        this.oldSegmentViewerOpen = true
    }

    closeOldSegmentViewer() {
        this.oldSegmentViewerOpen = false
    }

    /**
     * Record a patch for this segment (or selection)
     */
    record() {
        let { recording } = this.props.rt

        log('!!!record', fmt({recording}))
        if (recording) {
            log(`record ignored`)
            return    // ignore if already recording
        }

        let { rt } = this.props
        let { name, portion, passage, iAmTranslator } = rt
        if (!portion || !passage || !iAmTranslator) return

        this.videoBeingRecorded = passage.createVideo(name)
        rt.record(name, this.videoBeingRecorded, this.onRecordingDone.bind(this))
    }

    async onRecordingDone(err: any, blobsCount?: number, url?: string, duration?: number) {
        log('!!!(patch) onRecordingDone', fmt({err, blobsCount, url, duration}))

        let { rt, setEditingSegmentPosition } = this.props
        if (err) {
            displayError(err)
            rt.recording = false
            return
        }

        let { passage, passageVideo, passageSegment, selectionStartTime, selectionEndTime } = rt
        let video = this.videoBeingRecorded
        if (!passage || !passageVideo || !passageSegment || !video) return

        video.url = `${url}-${blobsCount}`
        video.duration = duration!

        let patchableSelectionPresent = rt.patchableSelectionPresent()

        try {
            let segment = passageSegment
            if (patchableSelectionPresent) {
                let { segment: _segment } = await passageVideo.createSelectionSegment(passage, selectionStartTime, selectionEndTime) 
                segment = _segment
            }
            
            await rt.passage!.addPatchVideo(passageVideo, video, segment)

            rt.setPassageVideo(passageVideo)
            rt.setPassageSegment(segment)
            let actualSegment = segment.actualSegment(rt.passage!)
            rt.resetCurrentTime(actualSegment.time + 0.01)
            
            this.videoBeingRecorded = null
            rt.recording = false

            // Edit begining and ending position of patch
            setEditingSegmentPosition(EditingSegmentPosition.Both)
        } catch (error) {
            this.videoBeingRecorded = null
            rt.recording = false
            systemError(error)
        }
    }

    stopRecording() {
        this.props.rt.stop()
    }
}

interface ISegmentTimestamp {
    rt: Root,
    passage: Passage,
    passageSegment: PassageSegment,
    currentSegment: PassageSegment,
}

const SegmentTimestamp: FC<ISegmentTimestamp> = ({ rt, passage, passageSegment, currentSegment }) => {
    if (!passageSegment.isPatched) return null
    
    let videoId = passageSegment.videoPatchHistory.slice(-1)[0]
    if (!videoId) return null // should never happen
    
    let video = passage?.findVideo(videoId)
    if (!video) return null // should never happen

    let date = new Date(video.creationDate)
    return (
        <div className="segment-selector-patch-time"
                data-toggle="tooltip"
                title={t`Patch creation date`}>
            {rt.dateFormatter.format(date)}
        </div>)
}

export default observer(SegmentsEditor)
