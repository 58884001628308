import React, { FC, useContext } from 'react'
import { t } from 'ttag'
import { confirmAlert } from 'react-confirm-alert'

import { RootContext } from '../app/RootContext'
import { AppRoot } from '../../models3/AppRoot'
import { observer } from 'mobx-react'
import { sendDebugInfoViaRollbar } from '../translation/SendDebugInfo'

interface IRefetchLocalDatabase {
    appRoot: AppRoot,
}

const RefetchLocalDatabase: FC<IRefetchLocalDatabase> = observer(({ appRoot }) => {
    let rt = useContext(RootContext)

    const refetchMessage = t`Refetch local database` + '...'

    const confirmDelete = () => {
        const onConfirm = async () => {
            sendDebugInfoViaRollbar(rt!, 'Refetching local database', true)

            if (rt) {
                await rt.project.db.deleteDB()
            }
            await appRoot.reinitializeProjects()
        }

        confirmAlert({
            title: refetchMessage,
            message: t`This will delete the local database for this project and download a new copy from the remote server.`,
            confirmLabel: t`Accept`,
            cancelLabel: t`Cancel`,
            onConfirm,
            onCancel: () => {},
        })
    }

    return (
        <button
            type="button"
            className="btn btn-default"
            onClick={confirmDelete}
        >
            {refetchMessage}
        </button>
    )
})

export default RefetchLocalDatabase