import React, { FC, useEffect, useState } from 'react'
import {observer} from 'mobx-react'
import { t } from 'ttag'
import { delay } from 'q'

import { displayError } from '../utils/Errors'
import { PassageGloss } from '../../models3/ProjectModels'
import { observable } from 'mobx'
import { PlayButton, PreviousSegmentButton, NextSegmentButton, PaneCloseButton } from '../utils/Buttons'
import { TextSearchParameters } from '../utils/TextSearchParameters'
import { glossTextSearch, setPortionPassageVideoById } from '../utils/TextSearch'
import { fmt } from '../utils/Fmt'
import { CheckedItem } from '../../models3/RootBase'
import { getSearchableItemIds } from '../utils/TextSearchItemsSelector'
import { IRoot } from '../../models3/Root'

const log = require('debug')('sltt:GlossTextSearch')

// test searching for SARAH is sasl

interface IGlossSearchHeader {
    rt: IRoot,
    itemNumber: number, 
    itemCount: number,
    play: () => void,
    goToPreviousItem: () => void,
    goToNextItem: () => void,
    setSearchIsOpen: (searchIsOpen: boolean) => void,
}

const GlossSearchHeader: FC<IGlossSearchHeader> = 
    ({ rt, play, itemNumber, itemCount, goToPreviousItem, goToNextItem, setSearchIsOpen }) => 
{
    return (
        <div className="gloss-search-navigator">
            <div className="gloss-search-navigator-left">
                <PlayButton
                    tooltip={t`Play sign. Shortcut: <Enter>`}
                    className="gloss-play-button"
                    enabled={true}
                    selectionPresent={false}
                    onClick={() => play()} />
            </div>
            <div className="gloss-search-navigator-center">
                <PreviousSegmentButton
                    enabled={itemNumber > 0}
                    onClick={() => goToPreviousItem()}
                    tooltip={t`Go to previous gloss. Shortcut: <Shift><Tab>.`} />
                <span className="gloss-search-navigator-text">
                    {`${itemCount > 0 ? itemNumber + 1 : 0} / ${itemCount}`}
                </span>
                <NextSegmentButton
                    enabled={itemNumber < itemCount-1}
                    onClick={() => goToNextItem()}
                    tooltip={/* translator: important */ t`Go to next gloss. Shortcut: <Tab>.`} />
            </div>
            <div className="gloss-search-navigator-right">
                <PaneCloseButton
                    enabled={true}
                    onClick={() => setSearchIsOpen(false)}
                    tooltip={/* translator: important */ t`Close text search`}
                    className='' />
            </div> 
        </div>
    )
}

export async function selectGlossById(rt: IRoot, _id: string) {
    let gloss: PassageGloss | undefined
    let duration = 0

    let { video, passage } = await setPortionPassageVideoById(rt, _id)
    if (!video || !passage) return { gloss, video, duration }

    let glosses = video.getVisibleGlosses(passage)
    gloss = glosses.find(_gloss => _gloss._id === _id)

    if (gloss) {
        duration = gloss.duration(passage, video)
    }

    return { gloss, video, duration }
}

interface IGlossTextSearch {
    rt: IRoot,
}

export const GlossTextSearch: FC<IGlossTextSearch> = observer(({rt}) => 
{
    // See VideoMain.tsx:keydown for keyboard handling related to this function
    
    let { gtsp } = rt
    let { searchIsOpen, glossIds, itemNumber, searchText, searchChecked } = gtsp
    let glossId = glossIds[itemNumber] || ''

    function setSearchText(_searchText: string) {
        gtsp.itemNumber = -1
        gtsp.glossIds = []
        gtsp.searchText = _searchText
        if (_searchText.length < 2) return

        let ids = glossTextSearch(rt, _searchText, searchChecked)
        gtsp.glossIds = ids
    }

    function setSearchChecked(_searchChecked: CheckedItem[]) {
        let ids = glossTextSearch(rt, searchText, _searchChecked)
        gtsp.glossIds = ids
        gtsp.itemNumber = gtsp.glossIds.length ? 0 : -1
        gtsp.searchChecked = _searchChecked
    }

    if (gtsp.searchChecked.length === 0) {
        gtsp.searchChecked = getSearchableItemIds(rt)
    }

    return (
        <div className="gloss-search">
            <div className="gloss-search-header">
                <GlossSearchHeader {...{
                    rt, itemNumber, itemCount: glossIds.length,
                    play: () => { 
                        if (gtsp.itemNumber < 0) { gtsp.itemNumber = 0 }
                        gtsp.playGloss(rt).catch(displayError) 
                    },
                    goToPreviousItem: () => { gtsp.previousItem(rt).catch(displayError) },
                    goToNextItem: () => { gtsp.nextItem(rt).catch(displayError) },
                    setSearchIsOpen: (searchIsOpen: boolean) => gtsp.searchIsOpen = searchIsOpen,
                }} />
                
            </div>
            <div className="gloss-search-parameters">
                <TextSearchParameters {...{
                    rt,
                    searchIsOpen, 
                    setSearchIsOpen: (_searchIsOpen) => gtsp.searchIsOpen = _searchIsOpen,
                    searchText, 
                    setSearchText,
                    searchChecked, 
                    setSearchChecked,
                }} />
            </div>
        </div>
    )
})
