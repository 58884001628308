import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

import './Video.css'
import VideoPlayer from './VideoPlayer'
import { displayError } from '../utils/Errors'
import { PassageVideo, Passage } from '../../models3/ProjectModels'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import InputSlider from 'react-input-slider'
import { fmt } from '../utils/Fmt'

const log = require('debug')('sltt:RangeVideoPlayer') 

/**
* Play a video within startTime/endTime range.
* This module should never access root, all events are communicated to parent.
* Also contains a rate control for the video.
*
* RangeVideoPlayer
*    VideoPlayer - handle changes at semgnet/patch boundaries
*       CoreVideoPlayer - On request select/play main video or patch. Manage time poller.
*/


interface IRangeVideoPlayer {
    passage: Passage,
    video: PassageVideo,
    currentVideos: ViewableVideoCollection,
    startTime: number,
    endTime: number,
    playbackRate: number,
    setPlaybackRate: (rate: number) => void,
    className?: string,
    onTick?: (currentTime: number) => void,
    onEnded?: () => void,
    onPlayingStatus?: (isPlaying: boolean) => void,
}

@observer
class RangeVideoPlayer extends Component<IRangeVideoPlayer> {
    videoPlayer: VideoPlayer | null = null
    currentTime = 0
    autoPlayed = false

    // Because a video can be patched, we need to not start playing until the main video and all its patches
    // have been loaded. We keep a list of the _ids that have not signaled canPlayThrough yet
    waitingFor: string[]

    constructor(props: IRangeVideoPlayer) {
        super(props)

        let { video, startTime, endTime, currentVideos } = props
        this.waitingFor = currentVideos.viewableVideos.map(vv => vv.video._id)
        log('constructor', fmt({ video, startTime, endTime, waitingFor: this.waitingFor }))
        
        this.playRange = this.playRange.bind(this)
        this.stop = this.stop.bind(this)
        this.onRateChange = this.onRateChange.bind(this)
    }

    render() {
        let { passage, video, currentVideos, onTick, onEnded, onPlayingStatus, className,
            playbackRate, startTime, endTime } = this.props

        if (!video || !passage) return null

        log(`render ${video._id}`)
        let tooltip = `Speed = ${playbackRate.toFixed(1)}`

        return (
            <div className={className}>
                <div className='video-player-container'>
                    <VideoPlayer
                        ref={videoPlayer => this.videoPlayer = videoPlayer}
                        onCanPlayThrough={(_video) => {
                            if (this.autoPlayed) return
                            this.waitingFor = this.waitingFor.filter(_id => _id !== _video._id)
                            // log('onCanPlayThrough', fmt({_video, waitingFor: this.waitingFor}))

                            // Can't start playing until all the videos are ready to play
                            if (this.waitingFor.length > 0) return
                            
                            // log('autoPlay', fmt({ startTime, endTime }))
                            this.autoPlayed = true
                            this.videoPlayer?.play(startTime, endTime)
                                .catch(displayError)
                        }}
                        passage={passage}
                        video={video}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onTick={(currentTime: number) => { 
                            this.currentTime = currentTime
                            onTick && onTick(currentTime) 
                        }}
                        onEnded={onEnded}
                        onPlayingStatus={(isPlaying: boolean) => onPlayingStatus && onPlayingStatus(isPlaying)}
                        play={this.playRange}
                        stop={this.stop}
                    />
                </div>
                <div className='video-player-controls'>
                    <div className="u-slider u-slider-y video-player-slider">
                        <InputSlider
                            className="slider video-rate-input-slider"
                            slidertooltip={tooltip}
                            axis="y"
                            y={2.0 - playbackRate}
                            ymax={2}
                            ystep={0.1}
                            onChange={this.onRateChange}
                        />
                    </div>
                </div>
            </div>
        )
    }

    onRateChange = (pos: any /* {x:, y: } */) => {
        console.log(`rateChange ${pos.y}`)
        let { setPlaybackRate } = this.props
        let playbackRate = 2.0 - pos.y
        setPlaybackRate(playbackRate)
    }

    public playRange = async (startTime?: number, endTime?: number) => {
        //console.clear()
        log('playRange', startTime, endTime)

        let { videoPlayer } = this
        if (videoPlayer) {
            await videoPlayer.play(startTime, endTime)
        }
    }

    public stop = () => {
        log('stop')
        let { videoPlayer } = this
        let { onEnded } = this.props

        videoPlayer && videoPlayer.stop()
        onEnded && setTimeout(() => onEnded!(), 800)
    }

    // Returns true if video control was sufficiently setup to set time
    public setCurrentTime = (newTime: number) => {
        log('setCurrentTime', fmt({newTime}))
        
        let { videoPlayer } = this
        if (!videoPlayer) return false

        return videoPlayer.setCurrentTime(newTime)
    }
}

export default RangeVideoPlayer