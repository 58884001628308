import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { FC } from 'react'

const log = require('debug')('sltt:LocalStorageSetter') 
 
export const LocalStorageSetter: FC = () => {
    const flags = useFlags()
    
    // stringifiedJSON helps avoid setting the same values more than once
    const stringifiedJSON = JSON.stringify(flags.localStorage)

    useEffect(() => {
        if (!flags.localStorage) {
            return
        }
        log('flags.localStorage', flags.localStorage)
        try {
            for (let [key, value] of Object.entries(flags.localStorage)) {
                const sValue = `${value}` // localStorage values get stored as strings
                log(`localStorage.setItem("${key}", "${sValue}")`)
                localStorage.setItem(key, sValue)
                const result = localStorage.getItem(key)
                const truthyOrFalsy = Boolean(result) ? 'TRUTHY' : 'FALSY'
                log(`localStorage.getItem("${key}") => "${result}"`, `(where "${sValue}" is ${truthyOrFalsy})`)
            }            
        } catch (error) {
            console.error(error)
        }
    }, [stringifiedJSON])

    return (<div className="localStorageSetter"></div>)
}