import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import 'mobx-react-lite/batchingForReactDom'
import './index.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import { createBrowserHistory } from 'history'
import { Auth0Provider } from "@auth0/auth0-react"
import Rollbar from 'rollbar'
import { Provider } from '@rollbar/react'

import SLTool from './components/app/SLTool'
import ServiceWorkerWrapper from './components/app/ServiceWorkerWrapper'

import { version } from './components/app/NavigationBar'
import { ErrorBoundary } from './components/utils/Errors'
import { RollbarSingleton } from './components/utils/RollbarSingleton'
import { isPrgWebAppClient, isSlttAppClient, auth0ClientId, getRedirectUri } from './components/utils/Auth0Utils'

// Rollbar documentation https://docs.rollbar.com/docs/react

function getHostAndEnvironment() {
    let host = 'unknown'
    let environment = 'unknown'
    if (isPrgWebAppClient) {
        host = window.location.host
        if (host === 'sltt-bible.net' || host === 'sltt.bible') {
            environment = 'production'
        } else if (host.startsWith('localhost')) {
            environment = 'localhost'
        } else if (host.startsWith('dev.')) {
            environment = 'dev'
        } else if (host.startsWith('tst.')) {
            environment = 'tst'
        } else if (host.endsWith('vercel.app')) {
            environment = 'vercel'
        } else {
            environment = process.env.NODE_ENV
        }
    } else if (isSlttAppClient) {
        host = 'sltt-app'
        environment = `${process.env.REACT_APP_ELECTRON_ENV}.sltt-app`
    }
    console.log(`getHostAndEnvironment host: ${host}, environment: ${environment}`)
    return { host, environment }
}

const { host, environment } = getHostAndEnvironment() 

const rollbarConfig = {
    accessToken: 'fdf79309269b4d3ca4993add8c5dec76',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment,
        host,
        // context: 'rollbar/test'
        client: {
            javascript: {
                code_version: version,
                // source_map_enabled: true,
                // guess_uncaught_frames: true
            }
        }
    },
}

export const rollbar = new Rollbar(rollbarConfig)
RollbarSingleton.rollbarInstance = rollbar

export function redirectToHome() {
    let { pathname } = window.location
    let secondSlashIndex = pathname.slice(1).indexOf('/') + 2
    let pathnameWithHash = pathname.slice(0, secondSlashIndex).concat('#/index.html')
    let history = createBrowserHistory()
    history.replace(pathnameWithHash)
}

function TopLevelContainer() {
    return (
        <div className="app-container">
            <Provider instance={rollbar}>
                <ErrorBoundary>
                    {isPrgWebAppClient && <ServiceWorkerWrapper />}
                    <HashRouter>
                        <SLTool />
                    </HashRouter>
                </ErrorBoundary>
            </Provider>
        </div>
    )
}

// This function is for electron app to redirect to the correct page after login
// const onRedirectCallback = (appState) => {
//     const returnTo = (appState == null ? void 0 : appState.returnTo) || '#/callback' + window.location.search
//     console.log(`onRedirectCallback appState: ${JSON.stringify(appState || {}, null, 2)}`)
//     console.log(`onRedirectCallback returnTo: ${returnTo}`)
//     console.log(`onRedirectCallback window.location: ${JSON.stringify(window.location, null, 2)}`)
//     const history = createBrowserHistory()
//     history.replace(returnTo)
//     // history.push((appState == null ? void 0 : appState.returnTo) || window.location.pathname)
// }

/**
 * In order to use the Auth0 ability to login via Google and/or a user password we must wrap our stack
 * so that it can redirect the login process to the Auth0 website.
 */
function TopLevelContainerWithAuth0() {
    return (
        <Auth0Provider
                domain="sltt.auth0.com"
                clientId={auth0ClientId}
                redirectUri={getRedirectUri()}
                // onRedirectCallback={onRedirectCallback}
        >
            {TopLevelContainer()}
        </Auth0Provider>
    )
}


ReactDOM.render(
    TopLevelContainerWithAuth0(), 
    document.getElementById('root'))
