import React, { FunctionComponent } from 'react'

// const log = require('debug')('sltt:Optional') 

interface IOptional {
    show: boolean,
}

export const Optional: FunctionComponent<IOptional> = ({show, children}) => {
    return show ? (<> {children} </>) : null
}
