import React, { FC, useState, useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import { MultilingualString, Passage, Portion } from '../../models3/ProjectModels'
import { RootContext } from '../app/RootContext'
import { useOnClickOutside } from '../utils/Hooks'
import AutowidthInput from '../utils/AutowidthInput'
import { getByIso639dash1 } from '../utils/Languages'
import _ from 'underscore'
import { PortionsListTRLContext, getTitlesMessage } from '../utils/TRL'
import { ToggleBoxEditorButtons } from './ToggleBoxEditorButtons'
import { log } from './PortionView'
import './TitlesEditor.css'

interface ITitlesByLanguage {
    [title: string]: string
}
interface ITitlesByLanguageByPassageId {
    [passageId: string]: ITitlesByLanguage
}
interface ITitlesEditor {
    portion: Portion
    toggleTitles: () => void
}
export const TitlesEditor: FC<ITitlesEditor> = observer(
    ({ portion, toggleTitles }) => {
        const trlContext = useContext(PortionsListTRLContext)
        const { titleLanguage, portionsEditorRef } = trlContext
        const decoratedTitles: MultilingualString[] = useMemo(() => {
            if (!titleLanguage)
                return []
            return getDecoratedTitles(portion.titles, titleLanguage)
        }, [portion.titles, titleLanguage])
        const passagesIndexedById = useMemo(
            () => _.indexBy(portion.passages, '_id'), [portion.passages]
        )
        const [portionTitleUpdates, setPortionTitles] = useState<ITitlesByLanguage>({})
        const [passagesTitleUpdates, setPassagesTitles] = useState<ITitlesByLanguageByPassageId>({})
        useOnClickOutside(portionsEditorRef, async () => {
            log('useOnClickOutside')
            if (Object.keys(portionTitleUpdates).length !== 0 ||
                Object.keys(passagesTitleUpdates).length !== 0) {
                await confirmChanges()
            }
            else {
                toggleTitles()
            }
        })
        const confirmChanges = async (closeAfterConfirm: boolean = true) => {
            for (const [language, text] of Object.entries(portionTitleUpdates)) {
                await portion.setTitle(language, text)
            }
            for (const [passageId, titleUpdates] of Object.entries(passagesTitleUpdates)) {
                for (const [language, text] of Object.entries(titleUpdates)) {
                    await passagesIndexedById[passageId].setTitle(language, text)
                }
            }
            if (closeAfterConfirm)
                toggleTitles()
        }

        const cancelChanges = () => {
            toggleTitles()
        }

        const updatePortionTitles = (title: MultilingualString) => {
            setPortionTitles({
                ...portionTitleUpdates,
                [title.language]: title.text
            })
        }

        const updatePassageTitles = (passageId: string, title: MultilingualString) => {
            const passageTitlesToUpdate = passagesTitleUpdates[passageId] || {}
            const updatedTitles = {
                ...passageTitlesToUpdate,
                [title.language]: title.text
            }
            setPassagesTitles({
                ...passagesTitleUpdates,
                [passageId]: updatedTitles
            })
        }

        const portionInputPlaceholder = `Portion Title`
        const passageInputPlaceholder = `Passage Title`
        const titlesIssueMessage = getTitlesMessage(trlContext, portion)
        return <div>
            <div className="titles-editor">
                {titlesIssueMessage && <div className="trl-issue-message">{titlesIssueMessage}</div>}
                <div className="portion-titles-editor">
                    {decoratedTitles.map(title => <MultilingualTitlesEditor
                        key={`portion-${portion._id}-titles-editor-${title.language}`}
                        language={title.language}
                        value={getUpdatedTitleOrOriginalTitle(
                            portionTitleUpdates[title.language], title.text
                        )}
                        placeholder={portionInputPlaceholder}
                        readOnly={title.language !== titleLanguage}
                        onChange={updatePortionTitles} />
                    )}
                </div>
                <div className="passages-titles-editor">
                    {portion.finalPassages.map(passage => <PassageTitlesEditor
                        key={`portion-${portion._id}-passage-${passage._id}-titles-editor`}
                        passage={passage}
                        titleLanguage={titleLanguage}
                        passageInputPlaceholder={passageInputPlaceholder}
                        passageTitles={passagesTitleUpdates[passage._id]}
                        onChange={updatePassageTitles} />
                    )}
                </div>
            </div>
            {<ToggleBoxEditorButtons {...{ confirmChanges, cancelChanges }} />}
        </div>
    })
function getUpdatedTitleOrOriginalTitle(updatedTitle: string | undefined, originalTitle: string) {
    // Allow for user to delete existing string so that updatedTitle can be Empty.String
    return updatedTitle ?? originalTitle
}
interface IPassagesTitlesEditor {
    passage: Passage
    titleLanguage: string
    passageInputPlaceholder: string
    passageTitles: ITitlesByLanguage
    onChange: (passageId: string, title: MultilingualString) => void
}
const PassageTitlesEditor: FC<IPassagesTitlesEditor> = observer(
    ({ passage, titleLanguage, passageInputPlaceholder, passageTitles, onChange }) => {
        const decoratedTitles = useMemo(() => {
            if (!titleLanguage)
                return []
            return getDecoratedTitles(passage.titles, titleLanguage)
        }, [passage.titles, titleLanguage])
        return (
            <div>
                <div title={`Passage name`} className="passage-name">{passage.name}</div>
                {decoratedTitles.map(title => <MultilingualTitlesEditor
                    key={`passage-${passage._id}-titles-editor-${title.language}`}
                    language={title.language}
                    value={getUpdatedTitleOrOriginalTitle(
                        passageTitles && passageTitles[title.language], title.text
                    )}
                    placeholder={passageInputPlaceholder}
                    readOnly={title.language !== titleLanguage}
                    onChange={title => onChange(passage._id, title)} />
                )}
            </div>
        )
    })
function getDecoratedTitles(titles: MultilingualString[], editLanguage: string): MultilingualString[] {
    const iFoundTitle = titles.findIndex(t => t.language === editLanguage)
    if (iFoundTitle !== -1)
        return titles // editable title already in list

    // add new editable title to end of list
    const decoratedTitle = { language: editLanguage, text: '' }
    return [...titles, decoratedTitle]
}
interface IMultilingualTitlesEditor {
    language: string
    value: string
    placeholder: string
    readOnly?: boolean
    onChange: (title: MultilingualString) => void
}
const MultilingualTitlesEditor: FC<IMultilingualTitlesEditor> = observer(
    ({ language, value, placeholder, readOnly, onChange }) => {
        const langName = getByIso639dash1(language).local
        const placeholderWithLanguageName = `(${langName}) ${placeholder}`
        const rt = useContext(RootContext)
        if (!rt) {
            return <div />
        }
        const inputClass = rt.project.getPrimaryInputLanguageOrDefault() === language ? 'primary-input' : 'secondary-input'
        return (
            <div className="multilingual-titles-editor" title={placeholderWithLanguageName}>
                <div className="language-code">{language}</div>
                <AutowidthInput
                    className={`multilingual-title-editor ${inputClass}`}
                    disabled={readOnly}
                    maxLength={124} /* DBLMetata schema (for short/abbr name elements) has max length of 255 */
                    placeholderIsMinWidth={true}
                    placeholder={placeholderWithLanguageName}
                    value={value}
                    onChange={(e) => onChange({ language, text: e.target.value })} />
            </div>
        )
    })
