import { delay } from 'q'

import { PassageGloss, PassageNote, PassageNoteItem, PassageVideo } from "../../models3/ProjectModels"
import { IRoot } from '../../models3/Root'
import { CheckedItem } from "../../models3/RootBase"
import { fmt } from "./Fmt"
import { getCheckedVideos } from "./TextSearchItemsSelector"

const log = require('debug')('sltt:TextSearch')

export function getCheckedNotes(rt: IRoot, checked: CheckedItem[]) {
    let videos = getCheckedVideos(rt, checked)
    let notes: PassageNote[] = []
    let { project, iAmConsultant } = rt

    for (let video of videos) {
        let passage = project.findPassage(video._id)
        if (!passage) continue

        let _notes = video.getVisibleNotes(passage, iAmConsultant)
        for (let note of _notes) {
            notes.push(note) // Not using concat because recreating array each time is slow
        }
    }

    return notes
}

export function noteTextSearch(
        rt: IRoot,
        searchText: string,
        checked: CheckedItem[], // checked array can contain: portion ids, passage ids, video ids
        includeResolvedNotes?: boolean)
{
    let notes = getCheckedNotes(rt, checked)
    let _ids: string[] = []

    searchText = searchText.toLowerCase()

    function includes(text1: string, text2: string) {
        return text1.toLowerCase().includes(text2)
    }

    for (let note of notes) {
        if (note.resolved && includeResolvedNotes !== true) continue

        if (includes(note.description, searchText)) {
            _ids.push(note._id)
        }

        for (let item of note.items) {
            if (includes(item.text, searchText)) {
                _ids.push(item._id)
            }
        }
    }

    return _ids
}

export function getCheckedGlosses(rt: IRoot, checked: CheckedItem[]) {
    let videos = getCheckedVideos(rt, checked)
    let glosses: PassageGloss[] = []
    let { project } = rt

    for (let video of videos) {
        let passage = project.findPassage(video._id)
        if (!passage) continue

        let _glosses = video.getVisibleGlosses(passage)
        for (let gloss of _glosses) {
            glosses.push(gloss) // Not using concat because recreating array each time is slow
        }
    }

    return glosses
}

export function glossTextSearch(
    rt: IRoot,
    searchText: string,
    checked: CheckedItem[]) // checked array can contain: portion ids, passage ids, video ids
{
    let glosses = getCheckedGlosses(rt, checked)
    let _ids: string[] = []

    if (searchText === '') return []

    searchText = searchText.toLowerCase()

    function includes(text1: string, text2: string) {
        return text1.toLowerCase().includes(text2)
    }

    for (let gloss of glosses) {
        if (includes(gloss.text, searchText)) {
            _ids.push(gloss._id)
        }
    }

    return _ids
}

// interface INoteTextSearchResult {
//     note: PassageNote | undefined,
//     noteItem: PassageNoteItem | undefined,
// }

export function noteTextSearchResult(rt: IRoot, _id: string) 
{
    let note: PassageNote | undefined = undefined
    let noteItem: PassageNoteItem | undefined = undefined

    let { project } = rt
    let passage = project.findPassage(_id)
    if (!passage) {
        log('### no passage')
        return { note, noteItem, passage }
    }

    note = passage.findNote(_id)
    if (!note) {
        log('### no note')
        return { note, noteItem, passage }
    }
    
    noteItem = note.items.find(_noteItem => _id.startsWith(_noteItem._id))
    
    return { note, noteItem, passage }
}

export async function setPortionPassageVideoById(rt: IRoot, _id: string) {
    let { passage, portion, passageVideo, project } = rt
    log('selectById', fmt({
        _id,
        portion: portion?._id,
        passage: passage?._id,
        passageVideo: passageVideo?._id,
        previousVideoId: rt.gtsp.previousVideoId,
    }))

    let _portion = project.findPortion(_id)
    !_portion && log('### no portion')
    if (_portion && _portion._id !== portion?._id) {
        log('setPortion', fmt({ _portion }))
        await rt.setPortion(_portion)
    }

    let _passage = project.findPassage(_id)
    !_passage && log('### no passage')
    if (_passage && _passage._id !== passage?._id) {
        log('setPassage', fmt({ _passage }))
        await rt.setPassage(_passage)
    }

    let _video = _passage?.findVideo(_id)
    !_video && log('### no video')
    if (_video) {
        if (_video.isPatch) {
            if (_passage) {
                _video = _video.baseVideo(_passage) ?? undefined
            } else {
                _video = undefined // if we didnt find passage, we cant find baseVideo
            }
        }

        // If we found a video it is different than current video, set it as current video
        if (_video && _video._id !== passageVideo?._id) {
            log('setPassageVideo', fmt({ _video }))
            await rt.setPassageVideo(_video, true)
        }
    }

    if (_video && _video._id !== rt.gtsp.previousVideoId) {
        // HACKY, how could we tell if video has completed loading?
        await delay(2000) // give video a chance to load
        rt.gtsp.previousVideoId = _video._id
    }

    return { portion: _portion, passage: _passage, video: _video }
}