import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { extendObservable } from 'mobx'
import { observer, /* inject */ } from 'mobx-react'
import _ from 'underscore'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import { PreviousSegmentButton, NextSegmentButton } from '../utils/Buttons'
import { PassageSegment } from '../../models3/ProjectModels'

const log = require('debug')('sltt:SegmentSelector') 

interface ISegmentSelector {
    rt: Root,
    segment: PassageSegment,
    editing: boolean,
}

class SegmentSelector extends Component<ISegmentSelector> {

    render() {
        let { segment, rt, editing } = this.props
        let { passageVideo, passage } = rt

        if (!segment || !passage || !passageVideo) return null

        let segments = passageVideo.visibleSegments(passage)
        if(!segments.length) return null

        //segments.forEach(seg => console.log(`${seg.position.toFixed(2)}`))

        let idx = segments.findIndex(s => s._id === segment._id)
        if (idx === -1) return null

        let gotoSegment = function (seg: PassageSegment) {
            rt.resetCurrentTime(seg.time)
        }

        let enableLeft, enableRight
        // Don't allow changing the currently selected segment if we are in the middle
        // of an edit
        if (!editing) {
            enableLeft = idx > 0
            enableRight = idx < segments.length - 1
        }

        let segmentNumber = idx + 1
        return (
            <div className="segment-selector">
                <PreviousSegmentButton
                    enabled={ enableLeft }
                    tooltip={/* translator: important */ t`Go to previous segment.`}
                    onClick={ () => gotoSegment(segments[idx-1]) } />
                <span className="segment-selector-text">
                    {/* translator: important */ t`${segmentNumber} of ${segments.length}`}
                </span>
                <NextSegmentButton
                    enabled={enableRight}
                    tooltip={/* translator: important */ t`Go to next segment.`}
                    onClick={() => gotoSegment(segments[idx + 1])} />
            </div>
        )
    }

}

export default observer(SegmentSelector)
