import React, { FC, useState, useEffect, useRef } from 'react'

import { t } from 'ttag'

import { RefRange } from '../../scrRefs/RefRange'
import { SearchBox } from './SearchBox'
import { displayableBookNames } from '../../scrRefs/bookNames'
import { GotoReferenceIcon } from './Icons'
import "./ReferenceInput.css"
import { Project } from '../../models3/ProjectModels'
import { observer } from 'mobx-react'

const log = require('debug')('sltt:ReferenceInput')

interface IGotoReferenceButton {
    refs: RefRange[],
    setRefs: (_refs: RefRange[]) => void,
    tooltip: string,
}

const GotoReferenceButton: FC<IGotoReferenceButton> = ({ refs, setRefs, tooltip }) => {
    let enabled = refs.length > 0
    return (
        <button className='wraparound-button' 
                onClick={e => enabled && setRefs(refs)}>
            <GotoReferenceIcon className="reference-input-goto-reference-icon" tooltip={tooltip} />
        </button>
    )
}

/**
 * Allow user to enter a scirpture reference.
 * If defaultRefenceId present, save any reference entered as a default reference
 * for this parameter of this project.
 * If rt.dbsRefs has been set with references from the current segment or portion,
 * use this as a default.
 * 
 * This control uses 'displayableReferences'. These references can be entered using
 * the project book name, the UI language, or English.
 * They are displayed in the project language.
 */

interface IReferenceInput {
    refs: RefRange[],
    setRefs: (_refs: RefRange[]) => void,
    refInput: IRefInput,
    defaultReferenceId?: string,
    onEnter?: () => void,
    onEscape?: () => void,
    allowBookNameOnly?: boolean, 
    errored: boolean,
    setErrored: (value: boolean) => void,
    autoFocus?: boolean,
        // Allow user to enter a reference like 'Genesis', return a range containing
        // all the chapters in the book.
        // When false (or missing) no reference is returned until the user enters
        // a (at least) book and chatper number
    includeGotoReferenceButton?: boolean,
    referenceRequired?: boolean,
}

interface IRefInput {
    displayableReferences: (references: RefRange[] | null | undefined) => string,
    dbsRefs: RefRange[],
    parseReferences: (references: string) => RefRange[],
    project: Project,
    setDefault: (tag: string, value: string | null) => void,
    getDefault: (tag: string) => string | null,
}

export const ReferenceInput: FC<IReferenceInput> = observer((
        { refInput, refs, setRefs, defaultReferenceId, allowBookNameOnly, onEnter, onEscape, 
        errored, setErrored, autoFocus, includeGotoReferenceButton, referenceRequired }) => {
    let [references, setReferences] = useState(refInput.displayableReferences(refs))

    const searchRef = useRef<any>(null)

    const _setRefs = (_refs: RefRange[]) => {
        if (defaultReferenceId) {
            log('setDefaultReference', JSON.stringify(_refs))
            refInput.setDefault(defaultReferenceId, JSON.stringify(_refs))
        }
        // refs = _refs ???
        // log('!!!_setRefs', JSON.stringify(_refs))
        setRefs(_refs)
    }
    
    useEffect(() => {
        if (refs.length > 0) return

        // No references passed try to get a default reference from localStorage
        // or from the current rt.dbsRefs
        let defaultRefernce = (defaultReferenceId && refInput.getDefault(defaultReferenceId)) ?? ''
        log('defaultRerence', defaultRefernce)

        if (defaultRefernce) {
            let _refs = JSON.parse(defaultRefernce).map((r: any) => new RefRange(r.startRef, r.endRef))
            log('parseDefault', _refs)
            if (_refs.length) {
                _setRefs(_refs)
                setReferences(refInput.displayableReferences(_refs))
                return
            }      
        }
        
        if (defaultReferenceId && refInput.dbsRefs.length) {
            log('!!!dbsRefs')
            setReferences(refInput.displayableReferences(refInput.dbsRefs))
            _setRefs(refInput.dbsRefs)
        }

    }, [])
    

    let bookNames = displayableBookNames(refInput.project)

    let tooltip = refInput.displayableReferences(refInput.dbsRefs)

    return (
        <div className="reference-input">
            { includeGotoReferenceButton && 
                <GotoReferenceButton
                    refs={refInput.dbsRefs} 
                    setRefs={(_refs: RefRange[]) =>{
                        _setRefs(_refs)
                        setReferences(refInput.displayableReferences(_refs))
                        searchRef.current?.focus()
                    }}
                    tooltip={tooltip} /> }
            <div className={`reference-input-searchbox ${errored || (referenceRequired && refs.length === 0) ? 
                    'reference-input-error' : ''}`}>
                <SearchBox
                    searchParameter={references}
                    options={bookNames}
                    onEnter={() => { !errored && onEnter && onEnter() }}
                    onEscape={() => { onEscape && onEscape() }}
                    ref={searchRef}
                    searchParameterChanged={(newReferences: string) => {
                        setReferences(newReferences)
                        try {
                            let _refs = refInput.parseReferences(newReferences.trim())
                            log('setRefs', refInput.displayableReferences(_refs))
                            setErrored(false)
                            
                            // If some references have only a book name, i.e. no chapter number,
                            // ignore the reference until they enter a chapter number (unless allBookNameOnly is true)
                            if (_refs.some(ref => ref.isBBBOnly())) {
                                if (!allowBookNameOnly) {
                                    setErrored(true)
                                    return
                                }
                                // expand book name reference(s) to have first and last chapter number
                                _refs = _refs.map(ref => ref.fullBook()) 
                            }
                            
                            _setRefs(_refs)
                        } catch (error) {
                            setErrored(true)
                        }
                    }}
                    tooltip={t`Show book name suggestions`}
                    autoFocus={autoFocus}
                />
            </div>
        </div>
    )
})
