import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { SortableContainer } from 'react-sortable-hoc'
import { t } from 'ttag'

import PortionListItem from './PortionView'
import PortionAdder from './PortionAdder'
import './Portion.css'
import { systemError, ErrorBoundary } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { Portion } from '../../models3/ProjectModels';
import LoadingMessage from '../utils/InitializationMessage'
import { InputLanguageSelector } from '../utils/InputLanguageSelector'
import { getByIso639dash1 } from '../utils/Languages'
import { PortionsListTRLContextComponent, getProjectTRLIssue } from '../utils/TRL'

const log = require('debug')('sltt:PortionsEditor')

interface ISortableContainer {
    rt: Root,
    portions: Portion[],
}

const SortableList = SortableContainer( (props: ISortableContainer) => {
    return (
        <div className="portions-list">
            { 
                props.portions.map((portion, index) => (
                    <ErrorBoundary
                        key={portion._id}
                        fallbackUI={(
                            <div
                                className='portion-portion portion-error'
                                title={t`Something went wrong while displaying this portion`}
                            >
                                <b>{`${portion.name}???`}</b>
                            </div>
                        )}
                    >
                        <PortionListItem 
                            disabled={!props.rt.iAmInterpreter}
                            index={index} 
                            rt={props.rt}
                            portion={portion} />
                    </ErrorBoundary>
                ))
            }
        </div>
    )
})

interface IPortionsEditor {
    rt: Root,
}

const PortionsEditor: FC<IPortionsEditor> = observer(({ rt }) => {
    let { project, initialized, loadingMessage, useMobileLayout, isTRLProject } = rt
    const { inputLanguagePrimary } = { ...project } 
    const [titleLanguageCode, setTitleLanguageCode] = useState('')
    useEffect(() => {
        // inputLanguagePrimary may not have a value until after component is loaded
        const primaryInputLanguage = project.getPrimaryInputLanguageOrDefault()
        if (titleLanguageCode !== primaryInputLanguage) {
            setTitleLanguageCode(primaryInputLanguage)
        }
    }, [inputLanguagePrimary])
    
    useEffect(() => {
        // refresh the status for which portions have been published
        rt.loadTRLResources().catch(console.error)
    }, [])

    const projectTrlIssue = getProjectTRLIssue(rt)
    const portionsEditorRef = useRef()

    if (!project) return null

    let { portions } = project

    /* eslint-disable no-unused-expressions */
    portions.length // necessary to force re-render, dont know why
    
    let projectName = project.displayName

    if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

    const chooseTitleLanguage = t`Choose Title Language`;
    return (
        <div ref={portionsEditorRef as any} className='portions-editor'>
            <div className='portions-editor-header'>
                <h3>{/* translator: important */ t`Portions (${projectName})`}</h3>
                {isTRLProject && <div className="title-input-language-selector">
                    <i className="fas fa-fw fa-font" title={chooseTitleLanguage}></i>
                    <InputLanguageSelector
                        primaryInputLanguage={project.getPrimaryInputLanguageOrDefault()}
                        currentValue={titleLanguageCode}
                        selectorTooltip={chooseTitleLanguage}
                        getOptionTooltip={getTooltip}
                        chooseOption={setTitleLanguageCode}
                        disabled={false}
                    />
                </div>}
            </div>
            <PortionsListTRLContextComponent
                titleLanguage={titleLanguageCode}
                isTRLProject={isTRLProject}
                projectTrlIssue={projectTrlIssue}
                portionsEditorRef={portionsEditorRef}
            >
                <SortableList 
                    portions={portions}
                    rt={rt}
                    useDragHandle={true}
                    onSortEnd={onSortEnd} />
            </PortionsListTRLContextComponent>
            {!useMobileLayout && <PortionAdder rt={rt} />}
        </div>
    )

    function onSortEnd(result: any) {
        let oldIndex: number = result.oldIndex
        let newIndex: number = result.newIndex
        
        let { project } = rt

        project.movePortion(project.portions[oldIndex]._id, newIndex)
            .catch(systemError)
    }

    function shouldCancelStart(e: any) {
            // Cancel sorting if ...
            let tagName = e.target.tagName.toLowerCase()
            if (tagName === 'i') {
                    return true; // Return true to cancel sorting
            }
    }
})

function getTooltip(
    targetCode: string,
    primaryInputLanguage: string,
) {
    const primaryInputLanguageName = getByIso639dash1(primaryInputLanguage).local
    return (targetCode !== primaryInputLanguage) ?
        t`This language is not the primary input language (${primaryInputLanguageName})` : ''
}

export default PortionsEditor