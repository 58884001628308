// Display location of notes in this chunk.
// Allow user to click them -> 'noteclicked' event

import React, { Component, FunctionComponent, FC } from 'react'
import { observable } from 'mobx'
import {observer} from 'mobx-react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { PassageNote, PassageVideo, Passage, Project } from '../../models3/ProjectModels'
import './Note.css'
import '../utils/Buttons.css'
import { UnresolvedNoteMarker, CircleNoteMarker, SquareNoteMarker, TriangleNoteMarker } from '../utils/Buttons'
import { NoteSelector } from './NoteSelector'

const log = require('debug')('sltt:NoteBar')

interface INoteBar {
    noteBarDisplay: INoteBarDisplay,
    w: number,
    h?: number,
}

interface INoteBarDisplay {
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    canViewConsultantOnlyFeatures: boolean,
    setNote: (note: PassageNote) => void,
    project: Project,
    mostRecentNoteIdViewed: string,
    noteSelector: NoteSelector,
}

class NoteBar extends Component<INoteBar>  {

    @observable summary = ''
    // In Passage notes must be in ascending order by position in order to render correctly

    render() {
        let { w, h, noteBarDisplay } = this.props
        let { passage, passageVideo, canViewConsultantOnlyFeatures, project, 
            mostRecentNoteIdViewed, noteSelector } = noteBarDisplay
        
        if (!passage  || !passageVideo) return null
        let { _rev } = passage!  // force update when passage changes

        // This will have the side effect of (re)setting segment times if necessary
        let notes = passageVideo.getDrawableNotes(passage, w, canViewConsultantOnlyFeatures)

        if (noteSelector.active) {
            notes = notes.filter(note => noteSelector.matches(note.type) && !note.resolved)
        }

        let height = h || 30

        let className = "note-bar"
        if (noteSelector.active) className += ' note-selector-active'

        return (
            <div className={className} data-id='note-bar' style={{border: '1px solid var(--border-color)', height: height, position: 'relative'}}>
                <NoteBarMarkerList
                    enabled={!passage?.videoBeingCompressed}
                    project={project}
                    notes={notes}
                    noteBarHeight={height}
                    openNote={this.openNote.bind(this)} 
                    pixelsPerSecond={w / passageVideo.computedDuration}
                    mostRecentNoteIdViewed={mostRecentNoteIdViewed}
                />
                <p>{this.summary}</p>
            </div>
        )
    }

    openNote(note: PassageNote) {
        let { setNote } = this.props.noteBarDisplay
        log('open note', note)
        setNote(note)
    }
}

interface INoteBarMarkerList {
    enabled: boolean,
    project: Project,
    notes: PassageNote[],
    noteBarHeight: number,
    openNote: (note: PassageNote) => void,
    pixelsPerSecond: number,
    mostRecentNoteIdViewed: string,
}

let NoteBarMarkerList: FunctionComponent<INoteBarMarkerList> = observer((props) => {
    let { notes, noteBarHeight, openNote, project, enabled, mostRecentNoteIdViewed } = props

    return (
        <div>
            {notes.map((note, index) => (
                <NoteMarker key={note._id} {...{ note, index, noteBarHeight, openNote, project, enabled, mostRecentNoteIdViewed }} />
            ))}
        </div>
    )
})

interface INoteMarker {
    enabled: boolean,
    project: Project,
    note: PassageNote,
    index: number,
    noteBarHeight: number,
    openNote: (note: PassageNote) => void,
    mostRecentNoteIdViewed: string,
}

const markerShapes: JSX.Element[] = [
    <CircleNoteMarker className='note-bar-marker' enabled={true} />,
    <SquareNoteMarker className='note-bar-marker' enabled={true} />,
    <TriangleNoteMarker className='note-bar-marker' enabled={true} />,
]

const NoteMarker: FC<INoteMarker> = observer(({ project, note, noteBarHeight, 
            index, openNote, enabled, mostRecentNoteIdViewed }) => {
    let { type, resolved, onTop, consultantOnly, inIgnoredSegment, description, width: markerWidth } = note

    let noteSelector = NoteSelector.noteSelector(type)
    noteSelector.colorIndex = Math.min(noteSelector.colorIndex, project.noteColors.length-1)

    let fontSize = `${markerWidth / 2.666}px`
    let left = note.x
    let top = (noteBarHeight - markerWidth) / 2 + note.y - 2
    let width = markerWidth
    let height = markerWidth

    let color = ''
    if (!enabled) {
        color = 'grey'
    } else if (!onTop || inIgnoredSegment) {
        color = '#000000'    // Black
    } else if (consultantOnly) {
        color = 'purple'
    } else if (resolved) {
    } else {
        color = project.noteColors[noteSelector.colorIndex]
    }

    let shape: JSX.Element = markerShapes[noteSelector.shapeIndex]
    if (resolved) {
        shape = (<UnresolvedNoteMarker className='note-bar-marker' />)
    }

    // The fiddly expression for 'MostRecentNoteDecorator left' below is trying to ensure
    // that the square we draw around the different shapes looks centered.
    let marker =  (
        <div className='clickable'
                data-id={`note-${index}`}
                style={{ position: 'absolute', width, height, left, top, fontSize }}
                onClick={() => enabled && openNote(note)}>
            <span style={{ color, position: 'relative' }}> {shape} </span>
            {note._id === mostRecentNoteIdViewed && (
                <MostRecentNoteDecorator top={-1.5} width={width + 4} height={height + 5} 
                    left={resolved ? -1 : -2} />
            )}
        </div>
    )

    if (description) {
        return (
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip id='note-bar-tooltip'>
                    {description.split('\n').map((para, i) => {
                        return (<div key={i}> {para} </div>)
                    })}
                </Tooltip>
            }>
                {marker}
            </OverlayTrigger>
        )
    }

    return marker
})

type MostRecentNoteDecoratorProps = {
    width: number,
    height: number,
    top: number,
    left: number,
}

function MostRecentNoteDecorator({ width, height, top, left }: MostRecentNoteDecoratorProps) {
    return (
        <div style={{ width, height, top, border: '2px dotted gray', position: 'absolute', left, zIndex: 1 }} />
    )
}

export default observer(NoteBar)
