import { observable } from 'mobx'

import { Passage, ProjectTask, ProjectPlan, Portion } from '../../models3/ProjectModels'
import { Root } from '../../models3/Root';

export class DragAndDropData {
    @observable columns: ColumnData[];

    constructor(columns: ColumnData[]) {
        this.columns = columns;
    }

    findColumn(id: string) {
        return this.columns.find(column => column.id === id)
    }
}

export interface ColumnData {
    items: PassageData[];
    id: string;
    task: ProjectTask;
}

export interface PassageData {
    passage: Passage,
    portion: Portion,
    showLongName: boolean;
}

export function createDataModel(rt: Root, passages: Passage[], projectPlan: ProjectPlan, showLongName: boolean): DragAndDropData {
    let columns = createColumns(projectPlan)
    let updatedColumns = placePassagesInColumns(rt, columns, passages, showLongName)
    return new DragAndDropData(updatedColumns)
}

const createColumns = (projectPlan: ProjectPlan) => {
    let columns: ColumnData[] = []
    projectPlan.stages.forEach(stage => (
        stage.tasks.forEach(task => {
            let column: ColumnData = {
                items: [],
                id: task._id,
                task: task,
            }
            columns.push(column)
        })
    ))
    return columns
}

const placePassagesInColumns = (rt: Root, columns: ColumnData[], passages: Passage[], showLongName: boolean) => {
    let updatedColumns = columns
    // Prevent crash when there are no visible tasks
    if (updatedColumns.length === 0) {
        return updatedColumns
    }

    passages.forEach(passage => {
        let portion = rt.project.findPortion(passage._id)
        if (!portion) { 
            throw Error('Could not find portion for: ' + passage._id)
        }
        if (!passage.task) {
            updatedColumns[0].items.push({
                passage,
                portion,
                showLongName
            })
        } else {
            let index = updatedColumns.findIndex(c => c.task.id === passage.task)
            updatedColumns[index >= 0 ? index : 0].items.push({
                passage,
                portion,
                showLongName
            })
        }
    })

    return updatedColumns
}
