import _ from 'underscore'

import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { getVideoDuration } from '../../models3/VideoDuration'
import { fmt } from '../utils/Fmt'

const log = require('debug')('sltt:VideoUploader') 
const intest = (localStorage.getItem('intest') === 'true')

export class VideoUploader {
    // @observable public blobs: Array<Blob> = []
    // @observable public blobsSent = 0
    // private pushingBlob = false

    public recordAudio = false
    
    private blobs: Blob[] = []

    constructor(
        public projectName: string, 
        public path: string,
        public onRecordingDone: (err: any, blobsCount?: number, url?: string, duration?: number) => void) 
    {
        log('constructor', fmt({projectName, path}))
        console.assert(path.startsWith(projectName))

        if (projectName.startsWith('aud')) {
            this.recordAudio = true
        }
    }

    async pushVideoBlob(blob: Blob, lastBlob: boolean) {
        log('pushVideoBlob', fmt({size: blob.size, lastBlob}))

        this.blobs.push(blob)

        if (lastBlob) {
            await this.finishUpload()
        }
    }

    // Recording and uploading complete.
    private async finishUpload() {
        let { path, blobs } = this
        let _id = this.path + '-' + blobs.length
        log('finishUpload', fmt({_id}))
        let vcr = new VideoCacheRecord({_id})

        for (let blob of blobs) {
            await vcr.addBlob(blob)
        }
        await vcr.saveToDB()

        let blob = new Blob(blobs, { type: 'video/webm' })
        let duration = intest ? 1.5 : await getVideoDuration(blob)
        log('finishUpload', fmt({ duration }))

        // This will add the resulting item to the model
        this.onRecordingDone(null /* no error */, blobs.length, path, duration)
    }
}

export default VideoUploader
