import React, { Component, SFC } from 'react'
import { observer /* inject */ } from 'mobx-react'
import { observable } from 'mobx'
import { t } from 'ttag'

import _ from 'underscore'

import { Root } from '../../models3/Root'
import { displayError, systemError } from '../utils/Errors'
import { PassageSegment } from '../../models3/ProjectModels'
import { RefRange } from '../../scrRefs/RefRange'
import { displayableBookNames } from '../../scrRefs/bookNames'
import { ReferenceInput } from '../utils/ReferenceInput'
import { s } from '../utils/Fmt'

// Add explanatory text
// Add book chooser
// Box around editing div

const log = require('debug')('sltt:SegmentReferencesEditor')

interface ISegmentReferencesEditor {
    rt: Root,
    segment: PassageSegment,
    onClose: () => void,
}

class SegmentReferencesEditor extends Component<ISegmentReferencesEditor>  {
    @observable refRanges: RefRange[] = []
    @observable errorMessage = ''
    @observable errored = false
    selectionStart = 0
    selectionEnd = 1

    input: HTMLInputElement | null = null

    constructor(props: ISegmentReferencesEditor) {
        super(props)
        let { rt, segment } = this.props

        this.refRanges = segment.references
    }
    
    render() {
        let { refRanges, errorMessage, errored } = this
        let { rt, onClose } = this.props
        let { project } = rt

        let bookNames = displayableBookNames(project)

        return (
            <div className="segment-references-editor">
                <div className="segment-dialog-heading">{t`Edit Segment References:` + ' '}</div> 
                {/* <div className="segment-references-explanation">
                    <div>Examples: Matthew 5; Mark 3.1-5,7; Luke 1-3; John 1.1-2.3</div>
                    <div>Use the drop down at right to add a book name. Book names are English only for now.
                        We plan to add other languages soon.</div>
                </div> */}
                <div className="segment-references-input">
                    <div className="segment-references-input-info">
                        {(refRanges.length > 0) && <span>{t`<Enter> to save. `}</span>}
                        <span>{t`<Esc> to cancel.`}</span>
                    </div>

                    <ReferenceInput
                        refInput={rt}
                        refs={refRanges}
                        setRefs={refRanges => this.refRanges = refRanges }
                        onEnter={this.save.bind(this)}
                        onEscape={onClose}
                        errored={errored}
                        setErrored={value => {
                            if (value) {
                                this.refRanges = []
                            }
                            this.errored = value
                        }}
                        />
                </div>
            </div>
        )
    }
        
    save() {
        let { refRanges } = this
        log('save', refRanges)

        let { segment, rt } = this.props
        let { bookNames } = rt.project
        
        segment.setReferences(refRanges)
               .then(() => {
                   try {
                        let { portion } = rt
                        if (portion) {
                            rt.setDbsRefs(portion, segment)
                        }
                   } catch (err) {
                       log('Invalid references', err)
                   }

                   // Force anything that depends on passageSegment to render
                   let passageSegment = rt.passageSegment
                   rt.passageSegment = null
                   rt.passageSegment = passageSegment

                   this.props.onClose()
               })
               .catch((err: any) => {
                   this.props.onClose()
                   displayError(err)
                })
    }
}

export default observer(SegmentReferencesEditor)
