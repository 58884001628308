import { transliterate as hebrewTransliterate } from 'hebrew-transliteration'
import { Language } from './../components/utils/Languages'
// Lemma information from Marble dictionaries.

import _ from 'underscore'

import routePrefix from '../components/app/routePrefix'
import { getOrigin, retriableFetch } from '../models3/API'
import { Root } from '../models3/Root'
import { fmt, s } from '../components/utils/Fmt';
import { RefRange, RefRangeMap } from './RefRange'

const log = require('debug')('sltt:Lemmas') 

// Language specific definition for a LexMeaning
export class LexDefinition {
    public languageCode: string // en, sp, fr
    public definitionShort: string
    public glosses: string

    /*
        {
            "languageCode": "en",
            "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
            "glosses": "threshing floor of Atad"
        },
     */
    constructor (_lexDefinition: any) {
        this.languageCode = _lexDefinition.languageCode || ''
        this.definitionShort = _lexDefinition.definitionShort || ''
        this.glosses = _lexDefinition.glosses || ''
    }
}

export interface DefinitionLink {
    text: string,
    link: string, // e.g SDBH:<lemma>, '' if no link
}

// Information for a meaning (sense) of a Greek/Hebrew word
export class LexMeaning {
    public id: string   // e.g. "sdbh000002001001000"
    public lexicalLink: string    // e.g. "SDBH:<Hebrew>:000001"
    public definitions: LexDefinition[]
    public references: string[] // bbbcccvvv, where this meaning is used
    public lemmaString: string  // e.g. σάββατον (from MarbleLemma)
    public senseNumber: number // 1..
    public isHebrew: boolean

    private static readonly SENSE_INDEX_START = 13
    private static readonly SENSE_INDEX_END = 15

    /* _meaning
        {
            "definitions": [
                {
                    "languageCode": "en",
                    "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
                    "glosses": "threshing floor of Atad"
                },
                ...
            ],
            "references": "001050010|001050011"
        }
     */
    constructor(prefix: string, lemmaString: string, _lexMeaning: any, isHebrew: boolean) {
        this.id = prefix + _lexMeaning.id
        this.lemmaString = lemmaString
        
        let senseNumberString = this.id.slice(LexMeaning.SENSE_INDEX_START, LexMeaning.SENSE_INDEX_END + 1)
        let senseNumber = parseInt(senseNumberString)
        if (isNaN(senseNumber)) {
            senseNumber = 0
        }
        this.senseNumber = senseNumber
        let lexicalLinkSense = '000' + (senseNumber-1).toString().padStart(3, '0')
        
        const TAG_INDEX_END = 3
        let tag = this.id.slice(0, TAG_INDEX_END + 1).toUpperCase()
        this.lexicalLink = tag + ':' + lemmaString + ':' + lexicalLinkSense

        this.definitions = _lexMeaning.definitions.map((d: any) => new LexDefinition(d))
        this.references = (_lexMeaning.references && _lexMeaning.references.split('|')) || []

        this.isHebrew = isHebrew
    }

    dbg() {
        let { id, lexicalLink, definitions, references, lemmaString, senseNumber, isHebrew } = this
        return { id, lexicalLink, definitions, references, lemmaString, senseNumber, isHebrew }
    }

    senseName(transliterate: boolean) {
        let { lemmaString, senseNumber, isHebrew } = this
        if (transliterate) {
            return `${transliterateString(lemmaString, isHebrew) }-${senseNumber}`
        }

        return `${lemmaString}-${senseNumber}`
    }

    getDefinition(uiLanguage: string) {
        if (uiLanguage === 'es') uiLanguage = 'sp'

        let definition = this.definitions.find(def => def.languageCode === uiLanguage)
        
        if (!definition) {
            definition = this.definitions.find(def => def.languageCode === 'en')
        }

        if (!definition && this.definitions.length > 0) {
            definition = this.definitions[0]
        }

        return definition
    }

    glosses(uiLanguage: string) {
        let definition = this.getDefinition(uiLanguage)
        return definition?.glosses ?? ''
    }

    definitionShort(uiLanguage: string): DefinitionLink[] {
        let definition = this.getDefinition(uiLanguage)

        let parts = definition?.definitionShort.split(/(\{L:.*?\})/) ?? []

        let definitionParts = parts.map((part, i) => {
            if (i % 2 === 0) {
                return {text: part, link: ''}
            }

            part = part.slice(3, -2)  // strip off '{L:' and '>}'
            let [ text, link ] = part.split('<')
            return { text, link }
        })

        return definitionParts
    }

    /** Get which meaning this is in relation to the lemma. Starts at 1.
     * @throws if cannot get number
     */
    getMeaningNumber() {
        let meaningNumber = parseInt(this.id.slice(LexMeaning.SENSE_INDEX_START, LexMeaning.SENSE_INDEX_END + 1))
        if (isNaN(meaningNumber) || !isFinite(meaningNumber)) {
            throw new Error()
        }
        return meaningNumber
    }

    static getMeaningNumberFromLexicalLink(lexicalLink: string) {
        let parts = lexicalLink.split(':')
        let termIndex = -1
        if (parts.length >= 3) {
            let _termIndex = parts[2]
            termIndex = parseInt(_termIndex)
            if (isNaN(termIndex) || !isFinite(termIndex)) {
                termIndex = -1
            }
        }
        return termIndex + 1
    }
}

let ignoredLemmas = new Set(`διά ἀλλά γάρ γίνομαι δέ δύναμαι ἑαυτοῦ ἐγώ εἰ εἰμί εἰς ἐκεῖνος ἐν ἤ ἵνα καί μή ὁ ὅς ὅτι οὐ οὖν οὗτος πᾶς ποιέω πολύς πρός σύ τίς τὶς ὡς אֹו אֶחָד אַחַר אַיִן אֶל אַל אֵלֶּה אִם אָנֹכִי אַרְבַּע אֲשֶׁר אֵת אַתָּה אַתֶּם בּ בַּיִן גַּם דָּבָר דִּי הוּא הִיא היה הלך הֵם זֶה זֹה חָמֵשׁ כֹּה כִּי כֵּן לֹא מְאֹד מֵאָה מָה מִי סָבִיב עַד עֹוד עַל עִם עֶשֶׂר עָשָׂר רַב שֶׁבַע שָׁלֹשׁ שָׁם שְׁנַיִם שֵׁשׁ תָּוֶךְ תַּחַת
`.split(' '))

export class MarbleLemma {
    public lemmaString: string   // e.g. "Ἀαρών", "אָטָד"
    public id: string      // e.g. "sdbh000002"
    public meanings: LexMeaning[] = []

    /* _lemma
        {
            "lexMeanings": [
                {
                    "id": "000309002001000",
                    "definitions": [
                        {
                            "languageCode": "en",
                            "definitionShort": "= {L:threshing-floor<SDBH:גֹּרֶן>}, ◄ near river {L:Jordan<SDBH:יַרְדֵּן>}...",
                            "glosses": "threshing floor of Atad"
                        },
                        ...
                    ],
                    "references": "001050010|001050011"
                }
            ],
            "id": "000309002000000",
            "lemma": "אָטָד"
        }
     */
    constructor (prefix: string /*sdbg, sdbh*/, _lemma: any) {
        this.lemmaString = _lemma.lemma
        this.id = prefix + _lemma.id.slice(0,6)
    }

    addMeanings(prefix: string, _lemma: any) {
        let isHebrew = this.id.startsWith('sdbh')

        for (let _meaning of _lemma.lexMeanings) {
            if (ignoredLemmas.has(this.lemmaString)) continue

            let meaning = new LexMeaning(prefix, this.lemmaString, _meaning, isHebrew)
            MarbleLemmas.meaningsDict.set(meaning.lexicalLink, meaning)

            if (meaning.references.length >= 200) {
                // log('!!!', meaning.lexicalLink, meaning.glosses('English'))
            }

            MarbleLemmas.refRangesToMeanings.addBbbvvvs(meaning, meaning.references)

            this.meanings.push(meaning)
        }
    }

    // If a video has been record for this biblical term return
    // its url, otherwise ''
    // signVideoUrl(rt: Root) {
    //     let signVideo = rt.project.signs[`sign/${this.id}`]
    //     return signVideo ? signVideo.url : ''
    // }

    // async deleteSignVideo(rt: Root) {
    //     log('deleteSignVideo', this.id)

    //     let signVideo = rt.project.signs[`sign/${this.id}`]
    //     if (signVideo) {
    //         log('found')
    //         await signVideo.delete()
    //     }
    // }
}

export class MarbleLemmas {
    // Lemmas are present for each ref (bbbcccvvv)
    public static lemmasDict: { [lemmaString: string]: MarbleLemma } = {}
    public static meaningsDict = new Map<string, LexMeaning>()

    public static refRangesToMeanings = new RefRangeMap<LexMeaning>()
    // get(RefRange[]) => LexMeaning[]


    static get(termId: string, doLog?: boolean) {
        // termId = "SDBG:γένεσις:000001|SDBG:γένεσις:000002"
        let lemmaString = termId.split(':')[1]

        let lemma = this.lemmasDict[lemmaString]
        
        if (doLog) {
            log('get', fmt({ lemmaString, found: lemma ? true : false }))
            console.groupCollapsed('lemma')
            log('get lemma', fmt({ lemma }))
            console.groupEnd()
        }

        if (!lemma) {
            return undefined
        }

        return lemma
    }

    // Fetch Greek and Hebrew terms info and add to table
    static async fetch() {
        await MarbleLemmas.fetchLemmas('sdbg')
        await MarbleLemmas.fetchLemmas('sdbh')
    }

    // Fetch sdbgs or sdbhs data from S3.
    static async fetchLemmas(prefix: string /* 'sdbg' or 'sdbh' */) {
        const origin = getOrigin()
        let url = `${origin}/data/${prefix}s.json`
        
        let response = await retriableFetch(url, {})
        if (!response.ok) {
            throw Error(`${response.url}: ${response.status} - ${response.statusText}`)
        }

        let jsons: any[] = await response.json()
        log('fetchLemmas', fmt({url, count: jsons.length}))

        for (let json of jsons) {
            let newLemma = new MarbleLemma(prefix, json)
            let _lemma = this.lemmasDict[newLemma.lemmaString]
            if (!_lemma) {
                this.lemmasDict[newLemma.lemmaString] = newLemma
                _lemma = newLemma
            }

            _lemma.addMeanings(prefix, json)
        }
    }
}

export function transliterateString(lemmaString: string, isHebrew: boolean) {
    return isHebrew ? hebrewTransliterate(lemmaString) : greekTransliterate(lemmaString)
}

function greekTransliterate(lemma: string) {
    lemma = lemma.normalize('NFD') // separate letters from diacritics
    lemma = lemma.replace(/[\u0300-\u036f]/g, "")  // remove diacritics

    let table = `α a
β b
γγ nγ
γκ nκ
γξ nξ
γχ nχ
γ g
δ d
ε e
ζ z
η ē
θ th
ι i
κ k
λ l
μ m
ν n
ξ x
ο o
π p
ῥ rh
ρ r
σ s 
ς s
τ t
aυ au
eυ eu
ēυ ēu
oυ ou
υi ui
υ y
φ ph
χ ch
ψ ps
ω ō
Α A
Β B
Γ G
Δ D
Ε E
Ζ Z
Η Ē
Θ Th
Ι I
Κ K
Λ L
Μ M
Ν N
Ξ X
Ο O
Π P
Ρ R
Σ S
Τ T
Υ Y
Φ Ph
Χ Ch
Ψ Ps
Ω Ō
῾ h`

    let trans = lemma
    table.split("\n").forEach(item => {
        let parts = item.trim().split(' ')
        while (trans.includes(parts[0])) {
            trans = trans.replace(parts[0], parts[1])
        }
    })

    return lemma === trans ? '' : trans
}
