import React, { FC, useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Root } from '../../models3/Root'
import { observer } from 'mobx-react'
import { version } from './NavigationBar' 
import { getCurrentAppClient } from '../utils/Auth0Utils'
import { tinyHash } from '../utils/tinyHash'

export const log = require('debug')('sltt:LDContextChangeListener') 

interface ILDContextChangeListener {
    rt: Root
}
// this exists to simply watch for context changes and notify ldClient
// (<LDProvider context={getLDContext(rt)} /> has rt undefined during initialization,
// and after initialization, rt may receive sync updates, so when such context updates
// are known then SLTT needs to let LaunchDarkly know about the context changes
// TODO: refactor SLTool to be function component and useLDContext() there instead.
export const LDContextChangeListener: FC<ILDContextChangeListener> = observer(({ rt }) => {
    const ldClient = useLDClient()
    const {
        username, iAmRoot, iAmDeveloper, uiLanguage,
        name: projectName, memberRole, project: { region, projectType },
        isTRLProject
    } = rt

    const locationOrigin = window.location.origin

    // try to only updateLDContext when something we care about changes
    useEffect(() => {
        updateLDContext()
    }, [username, iAmRoot, iAmDeveloper, uiLanguage,
        projectName, memberRole, region, projectType, isTRLProject, version, locationOrigin]
    )

    const updateLDContext = () => {
        const user = {
            kind: 'user',
            key: 'user-' + tinyHash(username),
            email: username,
            iAmRoot,
            iAmDeveloper,
            uiLanguage
        }
        const team = {
            kind: 'team',
            key: 'team-' + projectName,
            projectName: projectName,
            role: memberRole,
            region,
            projectType,
            isTRLProject
        }
        const app = {
            kind: 'app',
            key: getCurrentAppClient(),
            version,
            majorVersion: version.split('.')[0],
            minorVersion: version.split('.')[1],
            locationOrigin,
        }
        const multiContext = {
            kind: 'multi',
            user,
            team,
            app
        }
        log('updateLDContext', multiContext)
        ldClient?.identify(multiContext).catch((error) => console.error(error))
    }
    // console.log('render LDContextChangeListener', {
    //     username, iAmRoot, iAmDeveloper, uiLanguage,
    //     projectName, memberRole, region, projectType, isTRLProject
    // })

    return (<div className="ld-context-watcher"></div>)
})

