// Provides a button to click to clear video cache on next reload

import React, { useState, FC, useContext } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import { RootContext } from '../app/RootContext'

const log = require('debug')('sltt:VideoCacheResetRequest') 

interface IVideoCacheResetRequest {
}

const VideoCacheResetRequest: FC<IVideoCacheResetRequest> = observer(() => {
    const [showReloadMessage, setShowReloadMessage] = useState(false)
    const rt = useContext(RootContext)

    const requestReset = () => {
        localStorage.setItem('cacheResetRequest', 'YES')
        setShowReloadMessage(true)
    }

    if (showReloadMessage) {
        return (
            <div>{t`!!! You MUST close and reopen SLTT or refresh page to make this take effect`}</div>
        )
    }

    return (
        <div>
            <button type="button"
                className={"btn btn-default"}
                onClick={requestReset}>{t`Clear Video Cache!!!`}</button>
        </div> 
    )
})

export default VideoCacheResetRequest