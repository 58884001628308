import React, { Component } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

import "./DropTarget.css"

interface IDropTarget {
    upload: (files: FileList, shiftKey: boolean, ctrlKey: boolean) => Promise<void>,
    dropTargetView: JSX.Element,
}

class DropTarget extends Component<IDropTarget> {
    @observable active = false
    dragEnterCount = 0
    
    onDrop = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()

        let files = e.dataTransfer.files

        if (!files || files.length === 0) {
            this.resetDropTarget()
            return
        }

        this.props.upload(files, e.shiftKey, e.ctrlKey)
            .finally(this.resetDropTarget)
    }

    // In order for the onDrop event to be called, we must prevent the default
    // behavior of the dragEnter and dragOver events
    onDragOver = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
    }

    onDragEnter = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        if (!isFileTransfer(e.dataTransfer)) {
            return
        }
        this.dragEnterCount += 1
        if (this.dragEnterCount === 1) {
            this.active = true
        }
    }

    onDragLeave = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        this.dragEnterCount -= 1
        if (this.dragEnterCount === 0) {
            this.active = false
        }
    }

    resetDropTarget = () => {
        this.active = false
        this.dragEnterCount = 0
    }
    
    render() {
        let { onDrop, onDragOver, onDragLeave, onDragEnter, active } = this
        let { dropTargetView, children } = this.props

        let dropTargetChildrenClassName = `full-size ${active ? 'ignore-pointer-events' : ''}`

        return (
            <div
                className='drop-target'
                onDrop={onDrop}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
            >
                <div className={dropTargetChildrenClassName}>
                    {children}
                </div>
                {active && dropTargetView}
            </div>
        )
    }
}

const isFileTransfer = (dataTransfer: DataTransfer) => {
    if (dataTransfer.items.length === 0) {
        return false
    }
    for (let item of dataTransfer.items) {
        if (item.kind === 'file') {
            return true
        }
    }

    return false
}

export default observer(DropTarget)