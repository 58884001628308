import React, { FC, useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { ITRLPassage, TRLPassage, TRLResource } from '../../../models3/TRLModel'
import { TRLDownloadThumbnailVideoPlayer } from './TRLDownloadThumbnailVideoPlayer'
import { getBestTitle } from '../trlUtils'
import { RootContext } from '../../app/RootContext'
import ReactTooltip from 'react-tooltip'


export class TRLPassageWithPreloadedUrl extends TRLPassage {
    preloadedThumbnailUrl?: string

    constructor(passage: ITRLPassage, primaryLanguage: string, preloadedThumbnailUrl?: string) {
        super(passage, primaryLanguage, true /* skip thumbnail validation */)
        this.preloadedThumbnailUrl = preloadedThumbnailUrl
    }
}

interface IPassageBrowseItem {
    passage: TRLPassage | TRLPassageWithPreloadedUrl
    selectedPassage: TRLPassage | TRLPassageWithPreloadedUrl | undefined
    onPassageClick: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> | undefined, passage: TRLPassage | undefined) => void
    selectedResource: TRLResource
    playing: boolean
    showTitles: boolean
}
export const PassageBrowseItem: FC<IPassageBrowseItem> = observer(({
    passage, selectedPassage, onPassageClick, selectedResource, playing, showTitles }
) => {
    const [loadedThumbnailUrl, setLoadedThumbnailUrl] = useState<string>()
    const preloadedThumbnailUrl = (passage as TRLPassageWithPreloadedUrl).preloadedThumbnailUrl
    const rt = useContext(RootContext)
    const uiLanguage = rt?.uiLanguage ?? 'en'
    const title = getBestTitle(passage.titles, [uiLanguage, selectedResource.primaryLanguage])
    const ttId = `tooltip-${passage.videoUrl}`

    let itemClass = 'sl-trl-browse-item'
    if (!selectedResource.published) {
        itemClass += ' trl-unpublished-resource'
    }

    return <div
        key={passage.videoUrl}
        className={itemClass}
        tabIndex={0}
        role="button"
        onClick={(e) => !selectedPassage && onPassageClick(e, passage)}
        onKeyDown={(e) => !selectedPassage && onPassageClick(e, passage)}
        data-tip data-for={ttId} data-place={'bottom' ?? ''}
    >
        <span className={`sl-play-facade fas fa-fw fa-circle-play`}></span>
        <TRLDownloadThumbnailVideoPlayer
            loadedThumbnailUrl={loadedThumbnailUrl || preloadedThumbnailUrl}
            passageThumbnailVideoUrl={passage.thumbnail?.srcUrl}
            setLoadedThumbnailUrl={setLoadedThumbnailUrl}
            playThumbnailOnHoverOver={true}
            contentCreator={selectedResource.copyright}
            contentVideoUrl={passage.videoUrl}
            isTRLContentVideoPlaying={playing}
            setIsTRLContentVideoPlaying={() => onPassageClick(undefined, undefined)} />
        {showTitles && <div>{title}</div>}
        {!showTitles && title && <ReactTooltip id={ttId}
                backgroundColor="gray"
                effect="solid"
                delayShow={500}
                delayHide={500}
                clickable={false} >
            {title}
        </ReactTooltip>}
    </div>
})
