import React, { Component } from 'react'
import { observable } from 'mobx'
import {observer} from 'mobx-react'

import { IDrawablePassageGloss, Passage, PassageVideo, Project } from '../../models3/ProjectModels'
import './Gloss.css'
import GlossView from './GlossView'
import GlossEditor from './GlossEditor'
import { displayError } from '../utils/Errors'
import { SearchButton, ZoomInGlossButton, ZoomOutGlossButton } from '../utils/Buttons'
import { t } from 'ttag'
import { GlossTextSearch } from './GlossTextSearch'
import { fmt } from '../utils/Fmt'
import { IRoot } from '../../models3/Root'

const log = require('debug')('sltt:GlossBar')

interface IGlossBar {
    rt: IRoot,
    w: number,
    setLexicalLink: (lexicalLink: string) => void,
    glossBarDisplay: IGlossBarDisplay,
}

/*
tooltip for play button

adjust scale/font size
record video gloss
setStartIndex - use binary search to find start position in loop
 */

interface IGlossBarDisplay {
    glossScale: number,
    setGlossScale: (scale: number) => void,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    project: Project,
    currentTime: number,
    iAmInterpreter: boolean,
    drawableGloss: IDrawablePassageGloss | null,
    setDrawableGloss: (gloss: IDrawablePassageGloss | null) => void,
    play: (startTime?: number, endPosition?: number, resetTime?: number) => void,
    stop: () => void,
    resetCurrentTime: (newTime: number) => void,
    glossIndex: number, // -1 = no gloss selected for editing
}

class GlossBar extends Component<IGlossBar>  {
    drawables: IDrawablePassageGloss[] = []

    videoId = ''   // drawables were computed for this video/rev/glossScale
    videoRev = -1
    glossScale = -1

    constructor(props: IGlossBar) {
        super(props)

        // this.element = React.createRef()
        this.onDelete = this.onDelete.bind(this)
        this.onDone = this.onDone.bind(this)
    }

    timeToX(time: number) {
        let { glossScale } = this.props.glossBarDisplay
        let { w } = this.props

        return 2 * time * glossScale + w/2 + 100
    }

    XToTime(x: number) {
        let { glossScale } = this.props.glossBarDisplay
        let { w } = this.props

        return (x - w / 2 - 100) / (2 * glossScale)
    }

    // When the passageVideo changes, update the list of drawable glosses.
    // Drawable glosses are those that are not covered by later patches.
    // The x values must be recomputed when the glossScale changes.
    getDrawables() {
        let { glossBarDisplay } = this.props
        let { passage, passageVideo, glossScale } = glossBarDisplay

        this.drawables = []
        let { drawables } = this

        if (!passage || !passageVideo) return []

        this.videoId = passageVideo._id
        this.videoRev = passageVideo._rev
        this.glossScale = glossScale

        let glosses = passageVideo.getVisibleGlosses(passage)

        for (let i=0; i<glosses.length; ++i) {
            let gloss = glosses[i]
            let x = this.timeToX(gloss.time)
            let width = 100
            let duration = 0
            try {
                duration = gloss.durationByIndex(glosses, i)
                // Limit width to 2 seconds so that if the user only specifices a start time, the gloss play time will not go to the end of the video.
                duration = Math.min(duration, 2)

                // Calculate width in pixels based on duration and glossScale.
                // Four years ago I knew why this required a factor of 2, but I've forgotten. Sigh.
                width = 2 * glossScale * duration
            } catch (error) {
                log('###duration failed', fmt({text: gloss.text, time: gloss.time}))          
            }
            let time = gloss.time

            drawables.push({ x, duration, width, time, gloss })

            if (i > 0) {
                drawables[i - 1].width = drawables[i].x - drawables[i - 1].x
            }
        }

        // let dbg = (dr: IDrawablePassageGloss) => ({
        //     x: dr.x.toFixed(0), 
        //     duration: dr.duration.toFixed(0), 
        //     text: dr.gloss.text, 
        //     position: dr.gloss.position.toFixed(2)
        // })

        // log('drawables', JSON.stringify(drawables.map(dbg), null, 4))

        return drawables
    }

    render() {
        let { rt, glossBarDisplay, setLexicalLink } = this.props
        let { passage, passageVideo, currentTime, iAmInterpreter, drawableGloss,
            glossScale, setGlossScale, play, stop, resetCurrentTime, project, glossIndex } = glossBarDisplay
        if (!passage || !passageVideo) return null

        let { w } = this.props

        // access _rev forces redraw when anything about passage video changes
        let { _rev } = passageVideo

        let drawables = this.getDrawables()

        let { onDelete, setGlossIndex, onDone } = this
        let { searchIsOpen } = rt.gtsp

        let xCurrent = this.timeToX(currentTime)
        
        let xMin = xCurrent - w / 2
        let xMax = xCurrent + w / 2 - 60
        
        let start = drawables.findIndex(dr => dr.x > xMin)
        if (start === -1) {
            start = drawables.length
        }

        let end = start
        for (; end<drawables.length && drawables[end].x < xMax; ++end) {}
        
        // log(`xMin ${xMin}, start=${start}, end=${end}`)
        
        let _drawables = drawables.slice(start, end)

        let width = _drawables.length ? _drawables[0].x - xMin : 100
        let glossesAreaWidth = w - 27  // Leave room for scale adjuster buttons

        let editGloss = !!passageVideo && (glossIndex >= 0) && !!iAmInterpreter

        let enabled = !passage?.videoBeingCompressed
        let zoomInEnabled = enabled && drawables.length > 0
        let zoomOutEnabled = enabled && drawables.length > 0

        return (
            <div className="gloss-bar" >
                <div>
                    <div style={ {left: w/2 } } 
                        className="gloss-cursor">v</div>
                    <div className="gloss-glosses" style={{ width: glossesAreaWidth, display: 'inline-block', verticalAlign: '25px' }}>
                        <div style={{ width, display: 'inline-block' }} />
                        {_drawables.map((dr, i) => {
                            return (
                                <GlossView
                                    rt={rt}
                                    project={project}
                                    onClick={() => enabled && this.setGlossIndex(start+i)}
                                    key={i} 
                                    gloss={dr.gloss} 
                                    width={dr.width}
                                    focused={start+i === glossIndex}
                                    last={i === drawables.length-1} />
                            )
                        })}
                    </div>
                    <div className="gloss-scale-container">
                        <div>
                            <SearchButton
                                enabled={true}
                                className="gloss-search-button"
                                tooltip={/* translator: important */ t`Search for text in glosses`}
                                onClick={() => rt.gtsp.searchIsOpen = true} />
                        </div>
                        <div>
                            <ZoomInGlossButton enabled={zoomInEnabled} onClick={() => {
                                setGlossScale(1.1 * glossScale)
                                this.getDrawables() }} />
                            <ZoomOutGlossButton enabled={zoomOutEnabled} onClick={() => {
                                setGlossScale(.9 * glossScale)
                                this.getDrawables() }} />
                        </div>
                    </div>
                </div>
                {editGloss && <GlossEditor
                    {...{ passage, passageVideo, drawableGloss, play, stop, resetCurrentTime, drawables, glossIndex, setGlossIndex, onDelete, onDone, project }} />}
                {searchIsOpen && 
                    <GlossTextSearch {...{
                        rt,
                        play,
                        drawables,
                        setSearchIsOpen: (searchIsOpen: boolean) => rt.gtsp.searchIsOpen = searchIsOpen, 
                    }} />}
            </div>
        )
    }

    setGlossIndex = (index: number) => { 
        let { glossBarDisplay } = this.props
        let { setDrawableGloss, play } = glossBarDisplay

        if (index >= 0) {
            setDrawableGloss(this.drawables[index])  // remember last gloss selected
        }
        glossBarDisplay.glossIndex = index

        let { time, duration } = this.drawables[index]
        log(`setGlossIndex index=${index}, time=${time.toFixed(1)}, duration=${duration.toFixed(1)}`)
        play(time, time + duration, time)
    }

    async onDelete() {
        let { glossBarDisplay } = this.props
        let { passage, passageVideo, drawableGloss } = glossBarDisplay
        if (!passage || !passageVideo || !drawableGloss) return

        let { onDone } = this

        let containingVideo = drawableGloss.gloss.toVideo(passage)
        if (!containingVideo) return

        onDone()

        containingVideo.removeGloss(drawableGloss.gloss._id)
            .catch(displayError)
    }

    onDone() {
        let { glossBarDisplay } = this.props
        glossBarDisplay.glossIndex = -1
        glossBarDisplay.setDrawableGloss(null)
    }

}

export default observer(GlossBar)
