// Allow user to select which portion in the current project they wish to work with

import React, { Component } from 'react'
import {observer} from 'mobx-react'
import _ from 'underscore'

import { RootConsumer } from '../app/RootContext'
import { Portion } from '../../models3/ProjectModels'
import { Root } from '../../models3/Root'

const log = require('debug')('sltt:PortionSelector') 

interface IPortionSelector {
    rt: Root,
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    values: string[],
    selectedValue: string,
}

class PortionSelector extends Component<IPortionSelector> {
    constructor(props: IPortionSelector) {
        super(props)
    }

    render() {
        let { rt, values, selectedValue } = this.props
        let hashtags = rt.project.hashtagsArray()

        return ( 
            <select className='custom-select portion-selector'
                        value={selectedValue}
                        disabled={rt.project.videoBeingCompressed}
                        onChange={this.props.onChange}>
                {values.map(name => (<option key={name} value={name}> {name} </option>))}
                {hashtags.length ? (<option value="disabled" disabled>———————</option>) : null}
                {hashtags.map(tag => (<option key={tag} value={tag}> {tag} </option>))}
            </select>
        )
    }
}

export default observer(PortionSelector)
