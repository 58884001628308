export interface IProgress { total: number, loaded: number }
export interface IReportProgress { (progress: IProgress): void }

const log = require('debug')('sltt:API2')
const intest = (localStorage.getItem('intest') === 'true')


// Fetch a blob at path. Optionally report progress.
// CORS must <ExposeHeader>Content-Length</ExposeHeader>

export async function fetchBlob2(url: string, report?: IReportProgress) {
    let response = await fetch(url)
    if (!response.ok) throw Error(`${response.status} - ${response.statusText}`)

    if (intest) {
        // When testing ignore progress reporting because cypress cannot deal
        // I never managed to get this to work in Cypress to read from fixture files.
        let _blob = await response.blob()
        log('intest fetchBlob2', url, _blob.size, _blob.type)
        return _blob
    }

    const contentLength = response.headers.get('content-length')
    if (report && !contentLength) {
        // If this test fails it is very likely because the CORS policy in
        // S3 for this bucket does not have 
        //     <ExposeHeader>Content-Length</ExposeHeader>
        // In theory serverless.yml but has not been tested in a new deployment.
        throw Error('Content-Length response header unavailable')
    }

    const total = parseInt(contentLength || '9999', 10)
    let loaded = 0
    let reader = response.body!.getReader()

    // Create handler to consume blocks as they are received
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
    let start = async function (controller: any) {
        while (true) {
            const { done, value } = await reader.read()
            if (done) { break }

            if (value !== undefined) {
                loaded += value.byteLength
            }
            report && report({ loaded, total })
            //log('fetchBlob2', url, total, loaded, value.byteLength)

            controller.enqueue(value)
        }

        controller.close()

        // Sometimes when using the compressor the following line throws an
        // exception saying the reader has already been released. Don't know why.
        try { reader.releaseLock() } catch {}
    }

    let rs2 = new ReadableStream({start})
    let response2 = new Response(rs2)
    let blob = await response2.blob()

    return blob
}
