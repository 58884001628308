import React, { FC, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { t } from 'ttag'

import { ProjectStage, ProjectTask } from '../../../models3/ProjectModels'
import './EditableProjectTask.css'
import TextInput from '../../utils/TextInput'
import DifficultyView from '../DifficultyView'
import { Root } from '../../../models3/Root'
import { EditDifficultyButton } from '../../utils/Buttons'
import { observer } from 'mobx-react'

const log = require('debug')('sltt:EditableProjectTask') 


interface ITaskDetailsMenu {
    rt: Root,
    setEditingDetails: (editing: boolean) => void,
}

const TaskDetailsMenu: FC<ITaskDetailsMenu> = ({ rt, setEditingDetails }) => {
    if (!rt.iAmAdmin) return null // only admins can edit task details

    return (
        <div className = "task-details-menu" >
            <span
                data-toggle="tooltip"
                data-id={`edit-details-${name}`}
                title={t`Change details.`}
                onClick={e => { e.preventDefault(); setEditingDetails(true) }}
            >
                <i className="fas fa-fw fa-asterisk"></i>
            </span>
        </div>
    )
}

interface ITaskMenu {
    rt: Root,
    task: ProjectTask,
    setEditingName: (editing: boolean) => void,
    setEditingDetails: (editing: boolean) => void,
    setEditingDifficulty: (editing: boolean) => void,
    deleteTask: () => void,
}

const TaskMenu: FC<ITaskMenu> = ({ rt, task, setEditingName, setEditingDetails, setEditingDifficulty, deleteTask }) => {
    if (!rt.iAmAdmin || rt.useMobileLayout) return null

    function confirmDeletion(doDeletion: () => void) {
        confirmAlert({
            title: t`Delete task?`,
            message: t`Are you sure you want to delete task "${task.displayedName}"?`,
            confirmLabel: t`Delete task`,
            cancelLabel: t`Keep task`,
            onConfirm: doDeletion,
            onCancel: () => { },
        })
    }

    return (
        <div className="task-menu">
            <span className="portion-button"
                data-toggle="tooltip"
                data-id={'edit-' + name}
                title={t`Rename task.`}
                onClick={e => { e.preventDefault(); setEditingName(true) }}>
                <i className="fa fa-fw fa-pencil-alt"></i>
            </span>
            <span className="portion-button"
                    data-toggle="tooltip"
                    data-id={'delete-' + name}
                    title={t`Delete task.`}
                    onClick={e => { e.preventDefault(); confirmDeletion(() => { deleteTask() })
                }}>
                <i className="fa fa-fw fa-trash-alt"></i>
            </span>
            <EditDifficultyButton
                enabled={true}
                onClick={() => setEditingDifficulty(true) }
                className='portion-button clickable'
                tooltip={t`Current difficulty: ${task.difficulty}.\nClick to change.`}
            />
            {!task.details && 
                // If there are not details yet for this task we show the edit details button with 
                // the other menu items. Otherwise we show the edit details button beside the details.
                <TaskDetailsMenu {...{ rt, setEditingDetails}} />
            }
        </div>
    )
}

interface ITaskDetails {
    rt: Root,
    task: ProjectTask,
    editingDetails: boolean,
    setEditingDetails: (editing: boolean) => void,
}

const TaskDetails: FC<ITaskDetails> = observer(({ rt, task, editingDetails, setEditingDetails }) => {
    let { useMobileLayout } = rt
    let { details } = task

    if (useMobileLayout) return null
    if (!editingDetails && !details) return null

    function renameDetails(newDetails: string) {
        task.setDetails(newDetails)
        setEditingDetails(false)
    }

    if (editingDetails) return (
        <div className="task-details-editing">
            <TextInput
                initialValue={details}
                message={t`Type Enter to change details or Esc to cancel.`}
                _onEscape={() => setEditingDetails(false) }
                _onEnter={renameDetails}
                allowEmptyValue={true} />
        </div>
    )

    if (!details) return null

    return (
        <div className='blank-task-description'>
            {details}
            <TaskDetailsMenu {...{ rt, setEditingDetails }} />
        </div>
    )
})

interface IEditableProjectTask {
    rt: Root,
    task: ProjectTask,
    stage: ProjectStage,
    deleteTask: () => void,
}

export const EditableProjectTask: FC<IEditableProjectTask> = ({ rt, task, stage, deleteTask }) => {
    let [editingName, setEditingName] = useState(false)
    let [editingDetails, setEditingDetails] = useState(false)
    let [editingDifficulty, setEditingDifficulty] = useState(false)

    function rename(newName: string) {
        if (!validate(newName)) {
            task.setName(stage, newName)
        }
        setEditingName(false)
    }

    function validate(value: string) {
        value = value.trim()
        let otherTasks = stage.tasks.filter(t => t._id !== task._id)
        if (otherTasks.find(task => task.name === value)) {
            return t`Duplicate name`
        }
        if (value === '') {
            return t`Empty name`
        }
        return ''
    }

    let editableTaskName = task.name
    const startsWithNumber = new RegExp(/^(\d+\.?\d*\s*)*/)
    let parts = task.name.split(startsWithNumber)
    if (parts.length > 0) {
        editableTaskName = parts[parts.length - 1]
    }

    return (
        <div className='editable-project-task'>
            <div className="task-header">
                {editingName ? (
                    <TextInput
                        initialValue={editableTaskName}
                        message={t`Type Enter to change name or Esc to cancel.`}
                        _onEscape={() => { setEditingName(false) }}
                        _onEnter={rename}
                        validate={validate}
                    />
                ) : ( 
                    <span className='task-view'>
                        {task.displayedName} 
                        <TaskMenu {...{rt, task, setEditingName, setEditingDetails, setEditingDifficulty, deleteTask }} />
                    </span>
                )}
            </div>
            {editingDifficulty && (
                <div>
                    <DifficultyView
                        stopEditing={() => { setEditingDifficulty(false) }}
                        difficulty={task.difficulty}
                        setDifficulty={task.setDifficulty}
                    />
                </div>
            )}
            <TaskDetails {...{ rt, task, editingDetails, setEditingDetails }} />
        </div>
    )
}

export default observer(EditableProjectTask)