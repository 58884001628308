import { observer } from 'mobx-react'
import React, { FC } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { Passage } from '../../../models3/ProjectModels'
import { EditPublishedUrl } from './TRLPassageSettings'
import { useLoginInfo } from '../../utils/useLoginInfo'
import { VimeoVideoPlayer } from '../../utils/Player'
import { displayError } from '../../utils/Errors'

const log = require('debug')('sltt:TRLPublishedUrlModal')

interface ITRLPublishedUrlModal {
    setShowPublishedUrlModal: (show: boolean) => void
    passage: Passage
}

export const TRLPublishedUrlModal: FC<ITRLPublishedUrlModal> = observer((
    { setShowPublishedUrlModal, passage }
) => {
    const [loaded, setLoaded] = React.useState(false) // true iff vimeo video has successfully loaded
    const [editingUrl, setEditingUrl] = React.useState(false)
    const [publishedUrl, setPublishedUrl] = React.useState(passage.publishedUrl)

    const { iAmAdmin } = useLoginInfo()

    function savePublishedUrl() {
        passage.setPublishedUrl(publishedUrl).catch(displayError)
        setShowPublishedUrlModal(false)
    }

    async function _setPublishedUrl(url: string) {
        const keepLoaded = publishedUrl === url
        setPublishedUrl(url)
        setLoaded(keepLoaded)
    }

    // for some reason the vimeo player doesn't like it when we change an existing vimeo url link to
    // another vimeo url link (e.g. https://vimeo.com/714120159 to https://vimeo.com/202862112). 
    // Doing so causes a "The url must be a valid Vimeo url." error. 
    // However, changing to a videoId alone seems to work fine.
    // An alternative would be to somehow re-initialize the vimeo player...
    const videoId = publishedUrl.split('/').pop() || ''

    return (
        <Modal style={{ top: '1%' }} 
            bsSize="large"
            show={true}
            onHide={() => setShowPublishedUrlModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Published Url'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="trl-published-url-modal-body">
                    <EditPublishedUrl {...{ 
                        allowEdit: iAmAdmin, 
                        editingUrl,
                        setEditingUrl,
                        publishedUrl, 
                        setPublishedUrl: _setPublishedUrl }} />
                    <div className="trl-published-url-modal-body-vimeo-player">
                        <VimeoVideoPlayer videoUrl={videoId} 
                            onLoaded={() => { setLoaded(true) }}
                            onEnded={() => { }} 
                            className='trl-published-url-modal-body-vimeo-player' 
                            autoClose={false} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="trl-published-url-modal-footer-buttons">
                    <div className="trl-published-url-modal-footer-buttons-right">
                        <OKButton 
                            enabled={!editingUrl && (loaded || publishedUrl === '')}
                            onClick={() => { savePublishedUrl() }} buttonClassName='' className='ok-button' tooltip={''} />
                        <CancelButton enabled={!editingUrl} onClick={() => setShowPublishedUrlModal(false)} className='cancel-button' tooltip={''} />
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
})
