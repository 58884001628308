import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import VideoDownloading from './VideoDownloading'

const log = require('debug')('sltt:MediaThumbnail')
import { fmt } from '../utils/Fmt'
import Player from './Player'

export interface IMediaThumbnailPlayer {
    videoUrl: string,
    preloadedThumbnailUrl?: string,
    thumbnailIsImage?: boolean,
    creator: string,
    playOnHover?: boolean,
    playbackRateOnHover?: number,
    playing: boolean,
    setPlaying: (playing: boolean) => void,
}

export const VideoThumbnailPlayer: FC<IMediaThumbnailPlayer> = ({ videoUrl, preloadedThumbnailUrl='', thumbnailIsImage=false, creator, playOnHover=false, playbackRateOnHover, playing, setPlaying }) => {

    if (!playing) {
        return (
            <>
                <div className="note-item-main media-thumbnail">
                    <MediaThumbnail
                        url={videoUrl}
                        preloadedThumbnailUrl={preloadedThumbnailUrl}
                        creator={creator}
                        isImage={thumbnailIsImage}
                        currentTime={0.2}
                        playOnHover={playOnHover}
                        playbackRateOnHover={playbackRateOnHover}
                        onClickVideo={() => {
                            setPlaying(true)
                        }}
                        onClickImage={() => { }}
                    />
                </div>
            </>
        )
    }

    return (
        <div>
            <Player
                className="note-item-player"
                videoUrl={videoUrl}
                onEnded={() => setPlaying(false)} />
        </div>
    )
}


interface IMediaThumbnail {
    url: string,
    preloadedThumbnailUrl?: string,
    creator: string,
    isImage: boolean,
    currentTime: number,
    playOnHover?: boolean,
    playbackRateOnHover?: number,
    onClickImage: () => void,
    onClickVideo: () => void,
}

const MediaThumbnail: FC<IMediaThumbnail> = observer(({ url, preloadedThumbnailUrl='', creator, isImage, currentTime, playOnHover = false, playbackRateOnHover, onClickVideo, onClickImage }) => {
    const [src, setSrc] = useState('')

    useEffect(() => {
        setSrc('')
    }, [url])

    if (!url) {
        return null
    }

    const thumbnailSrc = preloadedThumbnailUrl || src 
    if (thumbnailSrc) {
        return (
            <div className='fill-area' data-id='loaded-thumbnail'>
                <LoadedThumbnail {...{ isImage, src: thumbnailSrc, currentTime, playOnHover, playbackRateOnHover, onClickVideo, onClickImage }}/>
            </div>
        )
    }

    return (
        <VideoDownloading
            className={'media-placeholder'}
            videoUrl={url}
            creator={creator}
            fontSizePt={30}
            onEnded={blob => setSrc(window.URL.createObjectURL(blob))}
        />
    )
})

interface ILoadedThumbnail {
    isImage: boolean,
    src: string,
    onClickImage: () => void,
    onClickVideo: () => void,
    currentTime: number,
    playOnHover: boolean,
    playbackRateOnHover?: number,
}

const LoadedThumbnail: FC<ILoadedThumbnail> = observer(({ isImage, src, onClickImage, onClickVideo, currentTime, playOnHover, playbackRateOnHover }) => {
    let videoRef = React.createRef<HTMLVideoElement>()
    let mouseEntered = useRef(false)

    useEffect(() => {
        if (isImage) {
            return
        }
        resetToCurrentTime()
    }, [currentTime])

    useEffect(() => {
        if (isImage) {
            return
        }
        if (playOnHover === false) {
            videoRef.current!.pause()
            resetToCurrentTime()
        }
    }, [playOnHover])

    const resetToCurrentTime = () => {
        if (isImage) {
            return
        }
        videoRef.current!.currentTime = currentTime
    }

    const handleMouseEnter = () => {
        if (!playOnHover) return
        mouseEntered.current = true
        const vid = videoRef.current
        if (!vid) {
            return
        }
        if (playbackRateOnHover !== undefined) {
            vid.playbackRate = playbackRateOnHover
        }
        else if (vid.duration < 6 || vid.duration === Infinity) {
            vid.playbackRate = 1.0
        } else if (vid.duration < 12) {
            vid.playbackRate = 2.0
        } else {
            vid.playbackRate = 3.0
        }
        // Sigh. refreshing browser has effect of breaking thumbnail video play on hoverOver
        // The user clicks somewhere in the page first.
        // Until then, any attempt to play() the video will produce the following
        // Error: "Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first."
        vid.play()
    }
  
    const handleMouseLeave = () => {
        if (!playOnHover) return
        videoRef.current!.currentTime = currentTime
        videoRef.current?.pause()
        mouseEntered.current = false
    }

    if (isImage) {
        return (
            <img
                src={src}
                alt={t`Click to see larger version of image`}
                className='note-image'
                onClick={onClickImage}
            />
        )
    }

    return (
        <video 
            ref={videoRef}
            className="note-video"
            preload="auto"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClickVideo}
            disablePictureInPicture={true}
            onCanPlayThrough={() => {
                if (playOnHover && mouseEntered.current)
                    return
                if (videoRef.current && Math.abs(videoRef.current.currentTime - currentTime) >= 0.05) {
                    resetToCurrentTime()
                }
            }}
            src={src}
        />
    )
})

export default MediaThumbnail