import _ from 'underscore';
import { fmt } from '../components/utils/Fmt';
import { Passage, PassageVideo } from '../models3/ProjectModels';
import { Root } from '../models3/Root';
import { EAFItem, readEAFFile } from './ELANReader'

/**
 * Routines to convert ELAN .eaf files into glosses, segments, and notes.
 */

const log = require('debug')('sltt:ELANCreator') 

const pixelWidth = require('string-pixel-width')

function textToPix(text: string) {
    return pixelWidth(text, { font: 'arial', size: 10 })
}

// If items are short combine them with previous item because we cannot display
// tiny time duration things correctly.
function combineShortItems(items: EAFItem[]) {
    let initialLength = items.length

    for (let j = items.length - 1; j > 0; j = j - 1) {
        if (items[j].position - items[j - 1].position < .15) {
            items[j - 1].text += ' ' + items[j].text
            items.splice(j, 1)
            
            j = j - 1
        }
    }

    log(`combineShortItems ${initialLength} => ${items.length}`)
}

async function importSplitGlosses(item: EAFItem, passageVideo: PassageVideo) {
    let { text, position, endPosition } = item

    let parts = text.split(/(\s+)/)

    // Add trailing white space for each word to word ...
    // because the trailing white space is used to adjust position of words
    // in the timeline.
    let pairs = _.chunk<string>(parts, 2) // each pair is a word and its trailing whitespace
    let wordsPlusTrailingSpaces = pairs.map(chunk => _.toArray(chunk).join(''))

    let totalPix = textToPix(text)

    // Set starting position for gloss porportional to its position in the string
    let items = wordsPlusTrailingSpaces.map((word, i) => {
        let prePix = textToPix(wordsPlusTrailingSpaces.slice(0, i).join(''))
        let glossPosition = (prePix/totalPix) * (endPosition-position) + position
        return new EAFItem(glossPosition, 0 /* not used for glosses */, word.trim())
    })

    combineShortItems(items)

    for (let item of items) {
        await passageVideo.addGloss(item.position, item.text)
    }
}

async function importGlosses(items: EAFItem[], passage: Passage, passageVideo: PassageVideo, creator: string) {
    log('importGlosses', fmt({creator, length: items.length}))

    combineShortItems(items)

    for (let item of items) {
        let { position, text } = item
        log('gloss', fmt({position, text}))
        // if (position > 300) return

        await passageVideo.addGloss(position, text)
    }
}

async function importNote(passage: Passage, passageVideo: PassageVideo, creator: string, item: EAFItem) {
    let { text, position, endPosition } = item

    let note = passageVideo.createNote(position)
    note.startPosition = position
    note.endPosition = endPosition

    await passageVideo.addNote(note)

    let _note = passage.findNote(note._id)
    if (!_note) {
        throw Error(`Could not find newly created note`)
    }

    let noteItem = _note.createItem()
    noteItem.text = text

    await note.addItem(noteItem, passage, creator)
}

async function importSegment(passage: Passage, video: PassageVideo, creator: string, item: EAFItem) {
    let { text, position, endPosition } = item
    log('segment', fmt({ position, text }))
    // if (position > 300) return

    let { segment } = await video.addSegment(position, [])

    await segment.setGloss(creator, text)
}

async function importSegments(items: EAFItem[], passage: Passage, passageVideo: PassageVideo, creator: string) {
    log('importSegments', fmt({ creator, length: items.length }))

    for (let item of items) {
        await importSegment(passage, passageVideo, creator, item)
    }
}

function getImporter(id: string) {
    let creator = localStorage.getItem('eafcreator') || "mwinedt@biblesocieties.org"
    
    id = id.toLowerCase()

    if (id.startsWith('gloss')) return ({ creator, importer: importGlosses })
    if (id.startsWith('segment')) return ({ creator, importer: importSegments })

    return ({creator, importer: null})
}

export async function importEAFFile(rt: Root, passage: Passage, passageVideo: PassageVideo, file: File) {
    log('importEAFFile')

    let tiers = await readEAFFile(file)
    
    for (let tier of tiers) {
        let {creator, importer} = getImporter(tier.id)
        if (importer) {
            log(`tier [${tier.id}] - import`)
            await importer(tier.items, passage, passageVideo, creator)
        } else {
            log(`tier [${tier.id}] - skipped`)
        }
    }
}



