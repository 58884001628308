    import React, { Component } from 'react'
    import { observer } from 'mobx-react'
    import { observable } from 'mobx'
    import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
    import { ToastContainer, ToastPosition } from 'react-toastify'
    import 'react-toastify/dist/ReactToastify.min.css'
    import { confirmAlert } from 'react-confirm-alert'
    import { t } from 'ttag'
    import { isMobileOnly } from 'react-device-detect'

    import { VideoCache } from '../../models3/VideoCache'
    import MembersEditor from '../projectSettings/members/MembersEditor'
    import StatusEditor from '../status/StatusEditor'
    import DatabaseEditor from './DatabaseEditor'
    import ProjectsEditor from './ProjectsEditor'
    import PortionsEditor from '../portions/PortionsEditor'
    import NavigationBar from './NavigationBar'
    // import Registration from './Registration'
    import './SLTool.css'
    import { AppRoot } from '../../models3/AppRoot'
    import { MarbleLemmas } from '../../scrRefs/Lemmas'
    import { displayError, systemError } from '../utils/Errors'
    import { RootProvider, RootConsumer } from './RootContext'
    import TitleComponent from './Title'
    import ProjectSettings from '../projectSettings/ProjectSettings'
    import { ErrorBoundary } from '../utils/Errors'
import LoadingMessage from '../utils/InitializationMessage'
import { Passage, Project } from '../../models3/ProjectModels'
import TranslationEditor from '../translation/TranslationEditor'
import NotAMember from './NotAMember'
import ProjectMessages from '../notifications/ProjectMessages'
import { LogoutOnIdTokenExpiration } from './Login'
import { Auth0Login } from './Auth0'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { fmt } from '../utils/Fmt'
import { LDContextChangeListener } from './LDContextChangeListener'
import { TRLProjectsLoader } from '../TRL/TRLProjectsLoader'
import { LocalStorageSetter } from './LocalStorageSetter'

    // SLTool - top level component for app

    const log = require('debug')('sltt:SLTool') 
    let iPadWarningIssued = false

    const _window = window as any

    function isChrome() {
        let _w: any = window

        let isChromium = _w.chrome
        let winNav = _w.navigator
        let vendorName = winNav.vendor
        let isOpera = typeof _w.opr !== "undefined"
        let isIEedge = winNav.userAgent.indexOf("Edge") > -1
        let isIOSChrome = winNav.userAgent.match("CriOS")

        if (isIOSChrome) { return true } 
        else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) { return true }

        return false
    }

    function issueIPadWarning() {
        if (iPadWarningIssued) return false
        iPadWarningIssued = true
        return (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
    }

    class SLTool extends Component<RouteComponentProps> {
        @observable selected = 'translation'
        narrowScreenListener?: MediaQueryList
        appRoot = new AppRoot(this.selectPage.bind(this))
        
        constructor(props: any) {
            super(props)
            
            _window.appRoot = this.appRoot
            _window._ = this.appRoot // less to type
            // VideoCacheUploader.uploadDone = this.appRoot.uploadDone.bind(this.appRoot)

            this.appRoot.useMobileLayout = isMobileOnly
            let { origin, pathname, hash, search } = window.location
            // Older versions of SLTT have the id_token embedded in the hash part of the url
            if (!search) {
                let nonHashURL = origin.concat(pathname).concat(hash.slice(2))
                search = (new URL(nonHashURL)).search ?? ''
            }
            this.appRoot.checkForRegistration(search)

            this.selected = localStorage.getItem('pageSelected') || 'translation'

            this.selectProject = this.selectProject.bind(this)

            this.fetchStaticData()
                .catch(err => {
                    this.appRoot.resourcesFetchDone = true
                    log(`fetchStaticData ERROR=${err}`)
                })
        }

        // !! Feels like this is a strange place to be fetching data relating to resources.
        // Where should it be really? appRoot?

        async fetchStaticData() {
            await MarbleLemmas.fetch()

            log('fetchStaticData done')
            this.appRoot.resourcesFetchDone = true
        }

        async componentDidMount() {
            let { appRoot } = this
            let { useMobileLayout } = appRoot
            log('componentDidMount')

            this.narrowScreenListener = window.matchMedia('(max-width: 900px)')
            this.setNarrowWidth(this.narrowScreenListener.matches)
            this.narrowScreenListener.addEventListener('change', (e) => {
                this.setNarrowWidth(e.matches)
            })

            let videoCacheLimitGB = 10
            if (useMobileLayout) {
                videoCacheLimitGB = 2
            }

            localStorage.setItem('videoCacheLimitGB', videoCacheLimitGB.toString())

            appRoot.initializeProjects()
                .then(rt => {
                    // If the user has not created any portions for this project yet
                    // force them to the portion creation page.
                    let portionsCount = rt?.project.portions.length ?? -1
                    if (portionsCount === 0) {
                        log('!!!force /portions')
                        this.props.history.push(`/portions`)
                    }
                })
                .catch(displayError)
        }

        componentWillUnmount() {
            this.narrowScreenListener?.removeEventListener('change', (e) => {
                this.setNarrowWidth(e.matches)
            })
        }

        setNarrowWidth(value: boolean) {
            let { appRoot } = this
            appRoot.useNarrowWidthLayout = value
            appRoot.rts.forEach(rt => rt.useNarrowWidthLayout = value)
        }

        render() {
            let { selected, appRoot, selectProject } = this
            let { rts, rt, iAmRoot, projectsInitialized, currentProjectName } = appRoot

            if (!isChrome())
                return (<div className="not-chrome">{/* translator: important */ t`You must use Chrome browser for SLTT.`}</div>)

            if (issueIPadWarning())
                displayError(t`SLTT does not work on iPad.`)

            if (appRoot.id_token === '') {
                return (<Auth0Login {...{appRoot}} />)
            }

            let selectPage = this.selectPage.bind(this)
            let projectName = rt ? rt.displayName : ''
            let portionName = rt && rt.portion ? rt.portion.name : ''
            let title = projectName && portionName ? `${projectName}/${portionName}` : 'Sign Language Translation Tool'

            /* Debugging routes.
             * It may be useful to put the following in react-router/es/matchPath.js>matchPath
             *
             *    console.log('!!!matchPath', pathname, path)
             */

            // I think Router matches against window.location.pathname
            if (rt && rt.uiLanguageChanging) {
                return (
                    <div>Language changing...</div>
                )
            }

            async function selectPassage(passage: Passage) {
                log('selectPassage', fmt({passage}))

                // Find the portion corresponding to this passage and select the portion
                const portion = rt?.project.findPortion(passage._id)
                if (!portion) {
                    log('### could not find matching portion')
                    return
                }
                await rt!.setPortion(portion)
                await rt!.setPassage(passage)
                selectPage('/')
            }

            // console.log('render SLTool', rt)

            return (
                <RootProvider initialValue={rt}>
                    <LDProvider clientSideID={process.env.REACT_APP_LD_CLIENT_KEY!}>
                        <div>
                            <LogoutOnIdTokenExpiration {...{ appRoot }} />
                            <LocalStorageSetter />
                            {rt && projectsInitialized && <LDContextChangeListener rt={rt} />}
                            {rt && projectsInitialized && <TRLProjectsLoader rt={rt} />}
                            <div>
                                <TitleComponent title={title} />
                                <NavigationBar
                                    appRoot={appRoot}
                                    selected={selected}
                                    selectPage={selectPage}
                                    openTour={rt && rt.openTour.bind(rt)}
                                    selectProject={selectProject}
                                />

                                <div className="app-content">
                                    {appRoot.id_token && !projectsInitialized && (
                                        <LoadingMessage loadingMessage={t`Initializing projects ...`} />
                                    )}

                                    {appRoot.id_token && projectsInitialized && (
                                        <>
                                            {rts.length === 0 && <NotAMember appRoot={appRoot} />}
                                            {rt && (
                                                <Route exact={true} path={`/(index.html)?`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            {rt && <TranslationEditor {...{ rt }} />}
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            )}

                                            {rt &&
                                                <Route path={`/portions`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <PortionsEditor rt={rt!} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }

                                            {rt &&
                                                <Route path={`/project-settings`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <ProjectSettings rt={rt!} appRoot={appRoot} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }

                                            {rt &&
                                                <Route path={`/messages`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <ProjectMessages rt={rt!} appRoot={appRoot} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }

                                            {rt &&
                                                <Route path={`/status`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <StatusEditor rt={rt!} selectPassage={selectPassage} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }

                                            {rt && rt.iAmRoot &&
                                                <Route path={`/database`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <DatabaseEditor rt={rt!} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }
                                            {iAmRoot &&
                                                <Route path={`/projects`}
                                                    render={(props: any) => (
                                                        <ErrorBoundary>
                                                            <ProjectsEditor appRoot={appRoot} />
                                                        </ErrorBoundary>
                                                    )}
                                                />
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                            <ToastContainer
                                position={ToastPosition.TOP_LEFT}
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                pauseOnHover
                            />
                        </div>
                    </LDProvider>
                </RootProvider>
            )
        }

        selectPage(selection: string) {
            this.selected = selection
            localStorage.setItem('pageSelected', selection)
        }

        selectProject(project: Project) {
            this.appRoot.setCurrentProject(project.name)
                .catch(systemError)
        }
    }

    export default withRouter(observer(SLTool))