import React, { FC, useEffect, useRef, useState } from 'react'

import { displayError } from '../utils/Errors'
import { fmt, s } from '../utils/Fmt'
import { AppRoot } from '../../models3/AppRoot'
import { t } from 'ttag'
import Jwt from 'jsonwebtoken'
import { useAuth0 } from "@auth0/auth0-react"
import API from '../../models3/API'
import { getRedirectUri } from '../utils/Auth0Utils'


let log = require('debug')('sltt:Login')

export function parseJwt(token: string) {
    let decoded: any = Jwt.decode(token)
    decoded._expiryDays = (decoded.exp - decoded.iat) / (24 * 3600)

    return decoded
}

/**
 * When id_token nears expiration and system is idle, sign out user.
 */
interface ILogoutOnIdTokenExpiration { appRoot: AppRoot }

export const LogoutOnIdTokenExpiration: FC<ILogoutOnIdTokenExpiration> = ({ appRoot }) => {
    // Requried idle time before expired token forces logout
    const REQUIRED_IDLE_SECONDS = localStorage.getItem('forceLogoutOnIdTokenExpiration') ? 15 : 15 * 60

    let { id_token } = appRoot

    let idleStartRef = useRef(Date.now())

    let [idTokenExpiring, setIdTokenExpiring] = useState(false)

    const signOut = useSignOut(appRoot)

    useEffect(() => {
        if (id_token === '') return

        const decoded = parseJwt(id_token)
        const totalExpirationSecondsForToken = decoded.exp - decoded.iat
        // current app has 8 days total expiration time, so 25% is 2 days 
        const twentyFivePercentOfExpirationInSeconds = totalExpirationSecondsForToken * 0.25 
        let secondsToExpiration = (decoded.exp - Date.now()/1000) 
        log(fmt({ secondsToExpiration, daysToExpiration: secondsToExpiration / (24 * 3600) }))

        // Force token to expire for testing purposes
        if (localStorage.getItem('forceLogoutOnIdTokenExpiration')) {
            secondsToExpiration = 5 // force auto logout, for testing
        }

        // Start logout process two days before expiration.
        // Logout will happen when there is idle time.
        // NOTE: according to chatGPT, when the computer goes to sleep, it delays the timer by the 
        // amount of time that it was asleep
        // as a result, we also need to handle "TokenExpiredError: jwt expired" errors from 
        // from fetch calls to the API server (e.g. via API.retriableFetch)
        const timer = setTimeout(() => { setIdTokenExpiring(true) }, 
            1000 * (secondsToExpiration-twentyFivePercentOfExpirationInSeconds))
        return () => clearTimeout(timer)
    }, [id_token])

    // Record activity to restart idle timeout
    useEffect(() => {
        idleStartRef.current = Date.now()

        if (!idTokenExpiring) return
        // log('start idle timeout')

        const recordActivity = (e: any) => { /* log('not idle') */ }

        document.addEventListener("mousemove", recordActivity)
        document.addEventListener("keydown", recordActivity)
        return function cleanup() {
            document.removeEventListener("mousemove", recordActivity)
            document.removeEventListener("keydown", recordActivity)
        }
    }, [idTokenExpiring])

    // If the idToken is expiring, wait for inactivity and then force logout
    useEffect(() => {
        const interval = setInterval(async () => {
            if (!idTokenExpiring) return

            // If we can't reach the SLTT server, assume we are offline and don't force logout
            if (!(await API.serverIsReachable())) return

            // require 5 minutes of inactiving before forcing logout
            let idleSeconds = (Date.now() - idleStartRef.current) / 1000
            log(fmt({ idleSeconds }))

            if (idleSeconds < REQUIRED_IDLE_SECONDS) return

            log('force logout')
            setIdTokenExpiring(false)
            signOut()
        }, 60000)
        return () => clearInterval(interval)
    }, [idTokenExpiring])

    return null
}

function useSignOut(appRoot: AppRoot) {
    const { isAuthenticated, logout } = useAuth0()

    function signOut() {
        log('signOut', fmt({ isAuthenticated, logout })) 
        
        if (isAuthenticated && logout) {
            logout({ returnTo: getRedirectUri() }) 
        }
        
        appRoot.signOutUser()
    }

    return signOut
}

interface ILogoutButton { appRoot: AppRoot }

export const LogoutButton: FC<ILogoutButton> = ({ appRoot }) => {
    const { username } = appRoot

    const signOut = useSignOut(appRoot)

    useEffect(() => {
        // The API module reset the id_token when it determines that the user has been removed from the project
        // has had their email address changed to a new address.
        // When this happens we automatically sign out the user.
        const interval = setInterval(() => {
            if (!API.id_token) {
                log('id_token cleared. sign out user')
                signOut()
            }
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <button className="sign-out-icon icon-hover" onClick={() => signOut()}>
            <span
                className={`fa fa-sign-out-alt`}
                data-id="logout-button"
                data-toggle="tooltip"
                title={t`Sign out` + `: ${username}`} />
        </button>
    )
}



