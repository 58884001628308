import API from '../../models3/API'
import { MemberNotifications } from '../../models3/ProjectModels'
import { IRoot } from '../../models3/Root'
import { displayError } from './Errors'
import routePrefix from '../app/routePrefix'

const log = require('debug')('sltt:PrepNotification')

interface INotification {
    text: string,
    noteRoot: IRoot,
    isVideoRecording?: boolean
}

export const prepNotification = ({ text, noteRoot, isVideoRecording = false }: INotification) => {
    const { project, note, passage, username } = noteRoot

    // Remove currently logged in member's email from emailsToNotify
    const emailsToNotify = project.members
        .filter(member => member.email !== username &&
            member.notifications === MemberNotifications.HOURLY)
        .map(member => member.email)
    if (emailsToNotify.length === 0) {
        log(`no emailsToNotify`)
        return
    }

    const noteDescription = note?.description ?? null
    const passageName = passage?.name ?? null
    // Currently this is Now, in the future it will be 24 hours from the creation date
    const scheduledSendDate = (new Date()).toISOString() 
    const currentDate = (new Date()).toISOString()
    const content = text
    const link = createLinkToNote({noteRoot, isVideoRecording})

    const noteLink = `<a href="${link}" target="_blank">Note</a> -`
    
    const emailHeading = createEmailHeading({ isVideoRecording, passageName, noteDescription })

    const emailBody = `${noteLink} ${emailHeading} (${currentDate}) <br/><br/> ${content} <br/><br/><br/>`
    
    API.addNotification(project.name, emailsToNotify, scheduledSendDate, emailBody).catch(displayError)   
    log(`emailsToNotify ${emailsToNotify} will receive ${emailBody}`)
}

interface ICreateEmailHeading {
    isVideoRecording: boolean, 
    passageName: string | null, 
    noteDescription: string | null
}

const createEmailHeading = ({ isVideoRecording, passageName, noteDescription }: ICreateEmailHeading) => {
    
    if (isVideoRecording && passageName) {
        return `"${passageName}" had a video update on`
    } else if(noteDescription) {
        return `"${passageName}" had a text update on`
    }
    
    return 'There is a text update on'
}

const createLinkToNote = ({ noteRoot, isVideoRecording = false }: { noteRoot: IRoot, isVideoRecording: boolean }) => {
    const { note, currentTime, project, passageVideo } = noteRoot
    const isPublicUrl = !!process.env.PUBLIC_URL
    const origin = isPublicUrl ? process.env.PUBLIC_URL : 'http://localhost:3000'
    const _id = isVideoRecording ? passageVideo?._id : note!._id
    const link = `${origin}${routePrefix}/#/index.html?project=${project.name}&id=${_id}&time=${currentTime}`

    return link
}