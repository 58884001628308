import React, { Component, FC, useEffect, useState } from 'react'
import {observer} from 'mobx-react'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import _ from 'underscore'
import { t } from 'ttag'

import { displayError, systemError, ErrorBoundary } from '../utils/Errors'
import { Root } from '../../models3/Root'
import PassageView from './PassageView'
import TourPassage from './TourPassage'
import PassageAdder from './PassageAdder'
import TourPassageAdder from './TourPassageAdder'
import PortionSelector from './PortionSelector'
import { Passage, Portion } from '../../models3/ProjectModels'
import { RootConsumer } from '../app/RootContext'
import VideoDropTarget from './VideoDropTarget'
import { UploadIcon } from '../utils/Icons'
import { DropTargetViewSmall } from '../utils/DropTargetView'
import './Passage.css'
import { useHoverDelay } from '../utils/Hooks'
import PassageEditor from './PassageEditor'

const log = require('debug')('sltt:PassageList') 

const DragHandle = SortableHandle(() => 
    <RootConsumer>
        {rt => (
            <span className="passage-handle" 
                data-toggle="tooltip" 
                title={t`Drag to reorder.`}>
                    <i className="fas fa-bars"></i>
            </span>
        )}
    </RootConsumer>
)

interface ISortableElement {
    rt: Root,
    passage: Passage,
}

const SortableItem = SortableElement(
    function (props: ISortableElement) {
        const [editing, setEditing] = useState(false)
        const [mouseOver, setMouseOver] = useState(false)
        const showEditingIcons = useHoverDelay(mouseOver, 50)

        let message = <div />
        let dropTargetView = <DropTargetViewSmall message={message} />
        let { rt, passage } = props
        let { useMobileLayout, username } = rt
        let assigned = passage.assignee === username && passage.task !== 'Finished'
        return (
            <VideoDropTarget rt={rt} passage={props.passage} videoIsPatch={false} dropTargetView={dropTargetView}>
                <div
                    className={`passage passage-list-item ${assigned ? 'passage-assigned' : ''}`}
                    data-id={'passage-' + props.passage.name}
                    onMouseEnter={() => setMouseOver(true)}
                    onMouseLeave={() => setMouseOver(false)}
                >
                    {!useMobileLayout && !editing && <DragHandle />}
                    <PassageEditor {...{ rt, passage, editing, setEditing }} />
                    <PassageView {...{ rt, passage, editing, setEditing, showEditingIcons }} />
                </div>
            </VideoDropTarget>
        )
    }
)

interface ISortableContainer {
    rt: Root,
    items: Array<Passage>,
}

const SortableList= SortableContainer(
    (props: ISortableContainer) => {
        return (
            <div>
                { props.items.map((passage, index) => (
                    <ErrorBoundary
                        key={passage._id}
                        fallbackUI={(
                            <div
                                className='passage passage-error'
                                data-id={'passage-' + passage.name}
                                title={t`Something went wrong while displaying this passage`}
                            >
                                <b>{`${passage.name}???`}</b>
                            </div>
                        )}
                        fallbackAction={() => {
                            props.rt.setPassage(null)
                        }}
                    >
                        <SortableItem 
                            index={index}
                            rt={props.rt}
                            passage={passage} />
                    </ErrorBoundary>
                ))}
            </div>
        )
    }
)

interface IExportTooltipVideoIds {
    rt: Root,
}

const ExportTooltipVideoIds: FC<IExportTooltipVideoIds> = ({ rt }) => 
{
    function _download() {
        let ids: string[] = []
        for (let portion of rt.project.portions) {
            for (let passage of portion.passages) {
                let video = passage.getLatestVideo()
                if (video) {
                    ids.push(`"${passage.name}|${video.url}"`)
                }
            }
        }

        let data = `export const videoTooltipIds = [${ids.join(',\n')}];`
        let blob = new Blob([data], { type: 'text/plain' });
        
        let href = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.setAttribute('href', href)
        link.setAttribute('download', `videoTooltipIds.ts`)
        
        link.click()
    }

    return (
        <button onClick={_download}>
            Download Ids
        </button>
    )
}

interface IPassageList {
    rt: Root,
}

class PassageList extends Component<IPassageList> {
    passageCounts = new Map<string, number>()

    constructor(props: IPassageList) {
        super(props)
    }

    componentDidUpdate() {
        let { rt } = this.props
        let { passage, passageVideo } = rt

        if (!passage) return

        // If the number of videos for the current passage changes,
        // reset to the last video present to make sure the user
        // sees that there is a new video.

        let count1 = this.passageCounts.get(passage._id) || 0
        let count2 = passage.videos.length
        log('componentDidUpdate', passage._id, count1, count2)

        if (count2 !== 0 && count1 !== count2) {
            let activeVideos = passage.videosNotDeleted
            if (activeVideos.length > 0) {
                rt.setPassageVideo(activeVideos.slice(-1)[0])
            }
        }
    }

    render() {
        let { rt } = this.props
        let { project, portion, useMobileLayout, hashtag } = rt
        
        let initial = ''
        if (hashtag) {
            initial = hashtag
        } else if (portion) {
            initial = portion.name
        }

        let passages = (portion && portion.passages) || []
        if (hashtag) {
            passages = project.passagesMatchingHashtag(hashtag)
        }

        /* eslint-disable no-unused-expressions */
        passages.length // required to access length or list appears empty in view!!! why?

        // Remember how many videos each passage has
        this.passageCounts.clear()
        passages.forEach(item => this.passageCounts.set(item._id, item.videos.length))

        let values = _.pluck(project.portions, 'name')

        let isSLTTLoc = rt.project.name === 'SLTTLoc'

        return (
            <div>
                <div className="portion-selector-div">
                    <PortionSelector
                        rt={rt}
                        onChange={this.onChange.bind(this)}
                        values={values}
                        selectedValue={initial} />
                </div>
                <div className="passages">
                    <SortableList 
                        rt={rt} 
                        items={passages}
                        onSortEnd={this.onSortEnd.bind(this)}
                        distance={5} />
                    { !hashtag && portion && !useMobileLayout &&
                        <PassageAdder rt={rt} />
                    }
                    { isSLTTLoc && <ExportTooltipVideoIds {...{rt}} />}
                </div>
            </div>
        )
    }

    onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let { rt } = this.props
        let { project } = rt

        let name = event.target.value
        if (name.startsWith('#')) {
            rt.hashtag = name
            rt.setPassage(null)
            return
        }

        rt.hashtag = ''

        let portions: Array<Portion> = (project.portions && project.portions) || []
        let portion = _.findWhere(portions, {name})
        if (portion) {
            rt.setPortion(portion)
              .catch(displayError)
        } else {
            log(`### could not find portion [${name}]`)
        }
    }


    onSortEnd(indices: any) {
        let { rt } = this.props
        let { iAmTranslator, portion } = rt
        
        if (!iAmTranslator) return
        let _id = portion!.passages[indices.oldIndex]._id

        portion!.movePassage(_id, indices.newIndex)
            .catch(systemError)
    }

}

export default observer(PassageList)