/*
    Show all projects.
    Allow root users to add and remove projects
 */


import React, { Component } from 'react'
//import _ from 'underscore'
import { observer } from 'mobx-react'

import ProjectAdder, { validProjectName } from './ProjectAdder'
import ProjectDeleter from './ProjectDeleter'
import { AppRoot } from '../../models3/AppRoot'
import _ from 'underscore'
import './ProjectsEditor.css'

const log = require('debug')('sltt:ProjectsEditor') 

interface IProjectsEditor {
    appRoot: AppRoot,
}

class ProjectsEditor extends Component<IProjectsEditor> {
    render() {
        log(`render`)
        
        let { appRoot } = this.props
        let { rts, useMobileLayout } = appRoot

        let totalDestruction = localStorage.getItem('totalDestruction')
        let allowTotalDestruction = (totalDestruction === 'true')

        rts = _.sortBy(rts, r => r.name.toLowerCase())

        return (
            <div className="projects-editor-container">
                { rts.map(({ name, displayName, project }) =>
                    (<p key={name}>
                        {name} ({displayName}) - {project.description.trim() ? project.description.trim() : <i>No description</i>}
                    {allowTotalDestruction  && <ProjectDeleter appRoot={appRoot} projectName={name} />}
                    {!validProjectName(name) && <span className="projects-editor-error">&nbsp;*** Invalid name!!! ***</span>}
                    </p>)) 
                }
                {!useMobileLayout && <ProjectAdder appRoot={appRoot} />}
            </div>
        )
    }
}

export default observer(ProjectsEditor)