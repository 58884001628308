import React from 'react'
import { observer } from 'mobx-react'

import { Root } from '../../models3/Root'

const debug = require('debug')('sltt:PlayTourVideo') 

interface ITourVideoPlayer {
    rt: Root,
}

class TourVideoPlayer extends React.Component<ITourVideoPlayer> {
    render() {
        let { rt } = this.props
        let { videoTourSignedUrl } = rt
        if (!videoTourSignedUrl) return null   // nothing to play

        debug(`render`)

        let style = {
            position: 'absolute' as 'absolute', // I have no idea why cast is necessary to make typescript happy
            left: 10,
            top: 10,
            width: 1000,
            height: 750,
            zOrder: 100,
        }

        return (
            <div style={style}>
                <video 
                    controls
                    src={videoTourSignedUrl}
                    onEnded={ this.onEnded }
                    autoPlay={true} />
            </div> 
        )
    }

    onEnded = () => {
        this.props.rt.videoTourSignedUrl = ''
    }
}

export default observer(TourVideoPlayer)