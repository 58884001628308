import React, { FC, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import NewWindow from 'react-new-window'

import { displayError } from '../utils/Errors'
import VideoPlayer from './VideoPlayer'
import { Passage, PassageVideo, Portion } from '../../models3/ProjectModels'
import { ViewableVideoCollection } from './ViewableVideoCollection'

const log = require('debug')('sltt:DetachedVideoPlayer') 

type DetachedVideoPlayerProps = {
    detachedPlayer: IDetachedVideoPlayer,
    setShowDetachedPlayer: (value: boolean) => void,
}

interface IDetachedVideoPlayer extends EventEmitter {
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    currentVideos: ViewableVideoCollection,
    portion: Portion | null,
    currentTime: number,
    playbackRate: number,
    selectionStartTime: number,
    selectionEndTime: number,
    selectionPresent: () => boolean,
}

let f = (x?:number) => x?.toFixed(2)

export const DetachedVideoPlayer: FC<DetachedVideoPlayerProps> = observer(({ detachedPlayer, setShowDetachedPlayer }) => {
    const videoPlayerRef = useRef<VideoPlayer>(null)
    const [playAllInProgress, setPlayAllInProgress] = useState(false)

    let { passage, passageVideo, currentVideos, portion, currentTime, selectionStartTime,
        selectionEndTime, selectionPresent, addListener, removeListener, playbackRate } = detachedPlayer

    useEffect(() => {
        addListener('play', play)
        addListener('pause', stopAndDoNotReset)
        addListener('stop', stopAndReset)
        addListener('playAll', playAll)
        addListener('setCurrentTime', setCurrentTime)
        addListener('setPassageVideo', setPassageVideo)
        addListener('onEnded', onEnded)

        return () => {
            removeListener('play', play)
            removeListener('pause', stopAndDoNotReset)
            removeListener('stop', stopAndReset)
            removeListener('playAll', playAll)
            removeListener('setCurrentTime', setCurrentTime)
            removeListener('setPassageVideo', setPassageVideo)
            removeListener('onEnded', onEnded)
        }
    })

    function play(startTime?: number, endTime?: number, resetTime?: number) {
        log(`play3 ${f(startTime)}/${f(endTime)} [resetTime=${f(resetTime)}] [${f(selectionStartTime)}..${f(selectionEndTime)}]`)

        if (startTime === undefined && selectionPresent()) {
            startTime = selectionStartTime
            endTime = selectionEndTime
            resetTime = startTime
        }

        startTime = startTime ?? currentTime

        let vp = getVideoPlayer()
        vp.current?.play(startTime, endTime)
            .catch(displayError)
    }

    function stopAndReset() {
        log('stop')
        stop()
    }

    function stopAndDoNotReset() {
        log('pause')
        stop()
    }

    function playAll() {
        log('playAll')
        if (!getVideoPlayer().current) return

        play()
        setPlayAllInProgress(true)
    }

    function setCurrentTime(newTime: number) {
        log('setCurrentTime', newTime)
        let vp = getVideoPlayer()
        vp.current?.setCurrentTime(newTime)
    }

    function setPassageVideo() {
        if (!passageVideo) return

        log('setPassageVideo', playAllInProgress, passageVideo._id)
        stop()

        currentVideos
            .waitUntilDownloaded()
            .then(() => {
                log('passageVideoHasChanged downloaded')
                playAllInProgress && play()
            })
    }

    function onEnded() {
        log(`onEnded playAll=${playAllInProgress}, ${passageVideo?._id}`)

        if (!playAllInProgress) return

        if (!portion || !passage) {
            abandonPlayAllInProgress('no portion or no passage')
            return
        }

        let { passages } = portion
        let playablePassages = passages.filter(p => p.videosNotDeleted.length > 0)
        let passageIndex = playablePassages.findIndex(p => p._id === passage!._id)
        log(`onEnded playAll passageIndex=${passageIndex}`)
        if (passageIndex < 0) {
            abandonPlayAllInProgress('could not find passage')
            return
        }

        if (passageIndex >= playablePassages.length-1) {
            abandonPlayAllInProgress('onEnded playAll stop')
            return
        }
    }

    function abandonPlayAllInProgress(msg: string) {
        log('abandonPlayAllInProgress')
        stopAndDoNotReset()
        setPlayAllInProgress(false)
        log(msg)
    }

    function stop() {
        let vp = getVideoPlayer()
        vp.current?.stop()
    }
    
    function getVideoPlayer() {
        if (!videoPlayerRef) log('### no videoPlayer present')
        return videoPlayerRef
    }

    return (
        <NewWindow features={{ width: 640, height: 480 }} onUnload={() => setShowDetachedPlayer(false)}>
            <div className='detached-video-player-area'>
                {passage && passageVideo && (
                    <VideoPlayer
                        ref={videoPlayerRef}
                        passage={passage}
                        video={passageVideo}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        play={() => {}}
                        stop={() => {}}
                        autoPlay={playAllInProgress}
                        disableOnClick
                        muted
                    />
                )}
            </div>
        </NewWindow>
    )
})