import _, { sortBy } from 'underscore'
import { t } from 'ttag'

// import API, { NonretriableSyncFailure, delayUntilOnline } from './API'
import { DBChangeNotifier } from './DBChangeNotifier'

const log = require('debug')('sltt:Levelup')
const dbg = require('debug')('slttdbg:Levelup')

import { fmt, s } from '../components/utils/Fmt'
import { DBEntry, IDB, IDBAcceptor, IDBItem, IDBIterator, IDBModDoc, IDBObject, IDBOperations, IDBSyncApi, IRemoteDBItem, NonretriableSyncFailure, minLocalSeq } from './DBTypes'
import { hasSharedLANStorage, listDocs, storeDoc, retrieveDoc } from './SlttAppStorage'
import { doOnceWhenBackOnline, getIsAppOnlineOrDefault, getIsAppOnlineOrWait, overridableUpdateOnlineStatus } from '../components/utils/ServiceStatus'
import { isHavingConnectionIssues, isNeedingLogout } from './API'
import { normalizeUsername } from './DBAcceptor.utils'
import { logDocSyncError } from '../components/utils/Errors'

const intest = (localStorage.getItem('intest') === 'true')
export const acceptLocalThruKeyString = localStorage.acceptLocalThruKey
const acceptLocalThruKey = Number.parseInt(acceptLocalThruKeyString || '')

if (acceptLocalThruKeyString) {
    if (Number.isNaN(acceptLocalThruKey)) {
        throw Error(`acceptLocalThruKey must be a number: ${acceptLocalThruKeyString}`)
    }
}
log(`intest ${intest} acceptLocalThruKey ${acceptLocalThruKeyString}`)

const maxSeq = 9999999999

export const MAXTOSYNC = 10

// At the moment the backend Express server can only accept 100K bytes.
// We need to fix that.
export const MAXTOSYNCSIZE = 80000

function delay(duration: number) {
    return new Promise<void>(function (resolve) {
        setTimeout(() => resolve(), duration)
    })
}

function isLocalBrowserKey(key: unknown) { return typeof key === 'number' && key >= minLocalSeq }

interface IAccept {
    label?: string,
    seq?: number | string
}

export type dbPutWithOnlyAcceptParam = {
    put: (doc: IDBModDoc, onlyAccept: boolean) => void
}

export class _LevelupDB implements IDB {
    public db: IDBOperations
    acceptor?: IDBAcceptor
    changeDeferrer: DBChangeDeferrer
    notifier?: DBChangeNotifier
    api: IDBSyncApi

    static forceSyncExceptionCount = 0

    // All documents in the LevelUp DB have a numeric keys.
    // Key values 0..minLocalSeq represent documents that exist in DynamoDB.
    //
    // Key values localSeqBase+1..localSeqLast represent documents that have been created
    // locally but not yet synced to DynamoDB.
    // These documents are deleted once they have been synced to DynamoDB.
    // Sync will return to us from DynamoDB a permanent copy of each of these documents
    // to store locally [provided conflict resolution does not cause a specific
    // update to be ignored]

    // Largest local key present in indexedDB.
    // minLocalSeq if none present.
    // Local keys start at minLocalSeq+1.
    // WARNING: This value may be updated in the middle of a sync operation.
    localSeqLast = minLocalSeq
    
    // Largest local key that has been sent to sync API.
    // When localSeqLast > localSeqBase there are local docs waiting to be synced to DynamoDB.
    localSeqBase = minLocalSeq

    // Largest remote key that has been received from sync API (remote keys start at 1)
    remoteSeq = 0

    numPendingSyncs = 0

    // Don't do an upsync when this is > 0.
    // When Date.now() is larger than this, reset this to 0.
    retryUpsyncTime = 0

    upsyncCount = 0 // number of times upsync attempted
    downsyncCount = 0 // number of times downsync attempted
    upsyncDocCount = 0
    downsyncDocCount = 0
    deletedCount = 0

    delayBeforeSyncRetry = 5 * 60 * 1000 // 5 minutes

    systemError: (error: any) => void

    constructor(public name: string, public username: string, db: IDBOperations, api: IDBSyncApi) {
        dbg(`constructor ${name}`)
        this.db = db
        this.name = name
        this.changeDeferrer = new DBChangeDeferrer(this)
        this.doSync = this.doSync.bind(this)
        this.api = api

        this.systemError = (error: any) => { console.error(`### [${name}] System Error`, error) }
    }

    getRemoteSeq() {
        return this.remoteSeq
    }

    /**
     * @param seqStart (exclusive)
     * @param seqEnd (inclusive)
     * @returns IDBObject[seq] where seqStart < seq <= seqEnd
     */
    async get(seqStart: number | string, seqEnd: number | string) {
        return await this.db.get(seqStart, seqEnd)
    }

    async initialize(acceptor: IDBAcceptor, progress: (message: string) => void): Promise<number> {
        this.acceptor = acceptor
        let benchMarkStart = Date.now()
        let dbRecordCount = await this.acceptLocalDBRecords(progress)
        await this.doSync(progress)
        doOnceWhenBackOnline(
            'setupDbChangeNotifierWebsocket',
            this.setupDbChangeNotifierWebsocket
        )
        let benchMarkEnd = Date.now()
        log(`_LevelupDB.initialize() finished in ${`${((benchMarkEnd - benchMarkStart)/1000).toFixed(2)}`}s`)
        return dbRecordCount
    }

    setupDbChangeNotifierWebsocket = () => {
        log(`_LevelupDB.setupDbChangeNotifierWebsocket()`)
        if (!intest) {
            let onChange = async (maxseq: number) => {
                if (maxseq > this.remoteSeq) {
                    dbg('onChange', fmt({maxseq, remoteSeq: this.remoteSeq}))
                    await this.doSync()
                }
            }
            this.notifier = new DBChangeNotifier(this.name, onChange)
            this.notifier.requestNotifications()
        }
    }

    private async writeAndAcceptDocsFromLAN(
        filenames: string[],
        isFromRemote: boolean,
        fnShouldSkip: (filename: string) => Promise<boolean>,
        fnWriteItemsAndAccept: (items: IDBItem[], skipStoreDocsToDisk: boolean) => Promise<void>,
        notifyLoadingProgress: (percent: number) => void
    ) {
        const items: IDBItem[] = []
        for (let i = 0; (i < filenames.length || items.length); i++) {
            if (items.length === 100 || i >= filenames.length - 1 && items.length > 0) {
                // only do 100 at a time. hopefully to avoid "Error: EMFILE: too many open files"
                await fnWriteItemsAndAccept(items, true)
                items.length = 0 // clear items
                if (i >= filenames.length - 1) break // finished last items
            }
            if (i % 100 === 0) {
                const percent = 100 * i / filenames.length
                notifyLoadingProgress(percent)
                await delay(1)
            }
            const filename = filenames[i]
            if (await fnShouldSkip(filename)) continue
            const response = await retrieveDoc({ project: this.name, filename, isFromRemote }, '_LevelupDB.initialize()')
            if (!response) continue // throw?
            const { remoteSeq, filenameModDate, doc } = response
            const seq: number | string = isFromRemote ? Number.parseInt(remoteSeq) : filenameModDate
            items.push({ project: this.name, seq, doc })
        }
    }

    /** Called once on initialize to accept all records in DB.
    * Side effect: sets localSeqBase, localSeqlast, remoteSeq
    */
    async acceptLocalDBRecords(progress: (message: string) => void): Promise<number> {
        let notifyLocalProgress = (percent: number) => {
            progress(t`Initializing ...` + `${percent.toFixed(1)}%`)
        }
        let percent = 0
        notifyLocalProgress(percent)
        let entries = await this.db.readDBRecords()
        reorderEntriesWithLANKeys(entries)

        let firstLocalKeyFound = false
        for (let i = 0; i < entries.length; ++i) {
            let entry = entries[i]
            if ((typeof entry.key === 'number' && entry.key <= 0) || !entry.doc || !entry.doc._id) {
                log('###BAD ENTRY', entry)
            }

            let { key } = entry

            if (typeof key === 'string') {
                // LAN key
            } else if (isLocalBrowserKey(key)) {
                /** 
                 * Each page load starts with a fresh localSeqBase (1000000000) by default.
                 * If on a previous page load we did not sync not all local records (e.g. leaving 1000000005 unsynced),
                 * we need to find the smallest local key that has not been synced yet
                 * and set localSeqBase to that key value - 1 (e.g. 1000000004) to ensure that the next sync
                 * starts with the first unsynced local record (e.g. 1000000005)
                 */
                if (!firstLocalKeyFound) { 
                    firstLocalKeyFound = true
                    this.localSeqBase = key - 1 
                }

                this.localSeqLast = key

                const { modEntry } = await this.tryMigration(entry)
                if (modEntry) {
                    entry = modEntry
                }
            } else {
                this.remoteSeq = key
            }

            if (i % 500 === 0) {
                let percent = 100 * i / entries.length
                await delay(1) // let ui redraw to show progress
                notifyLocalProgress(percent)
            }

            if (acceptLocalThruKey && typeof entry.key === 'number' && (entry.key > acceptLocalThruKey)) {
                if (entry.key === acceptLocalThruKey) {
                    log('acceptLocalDBRecords acceptLocalThruKey', { acceptLocalThruKey, acceptLocalThruKeyString, key, entry, })
                }
                continue // to allow remoteSeq to reflect latest remoteSeq
            }
            this.accept(entry.doc, {seq: key})
        }

        return entries.length
    }

    accept(doc: any, arg?: IAccept) {
        let label = arg?.label ?? ''

        if (label) {
            dbg(`accept[${label}]`, doc._id)
        }
        let seq: number | string = arg?.seq ?? -1
        try {
            this.acceptor?.accept(doc, label, seq)
        } catch (error) {
            //!!! send .errors to remote log
            console.error(`_LevelupDB [${this.name}] accept seq ${seq} label '${label}' doc ${s(doc)}`, error)
        }
    }

    // Synchronize items in local DB with central  DB
    async doSync(progress?: (message: string) => void) {
        if (!this.api.loggedIn()) return

        // If we run multiple simultaneous sync we will get duplicate db records.
        // Just wait until we are done with this sync.
        if (this.numPendingSyncs > 0) {
            return
        }

        this.numPendingSyncs++
        progress?.(t`Syncing`)

        try {
            if (hasSharedLANStorage()) {
                await this.loadDocsFromLANStorage(progress)
            }
            if (await getIsAppOnlineOrWait()) {
                // if there's an internet connection, then do apiSync
                await this.syncDocs()
            } else {
                // when internet comes back, try doSync() again
                doOnceWhenBackOnline('doSync', this.doSync)
            }
        } catch (error) {
            if (isHavingConnectionIssues(error)) {
                // set offline status and when internet comes back, try doSync() again
                await overridableUpdateOnlineStatus(false)
                doOnceWhenBackOnline('doSync', this.doSync)
            } else if (isNeedingLogout(error) || !this.api.loggedIn()) {
                this.systemError(error)
                // allow user to be logged out
            } else {
                this.systemError(error)
                // report to rollbar, if we haven't already done so
                if (isNeedingReporting(error) && getIsAppOnlineOrDefault(true)) {
                    let docs: IDBModDoc[] = []
                    try {
                        const { docs: sliceDocs } = await this.getDocsSlice()
                        docs = sliceDocs
                        // TODO: try again when back online?
                        logDocSyncError((error as Error).message, docs, this.name, false)
                    } catch (e) {
                        this.systemError(e)
                    }
                }
                this.setupRetryTimeout()
            }
        } finally {
            this.numPendingSyncs--
        }

        if (hasSharedLANStorage()) {
            // if there are unsynced local browser docs, then backup to LAN local storage
            await this.storeLocalBrowserDocsToLANStorage(progress)
        }
    }

    async loadDocsFromLANStorage(progress: ((message: string) => void) | undefined) {
        log(`_LevelupDB.doSync(): loading LAN remote docs...`)
        const remoteLANfilenames = await listDocs({ project: this.name, isFromRemote: true }, '_LevelupDB.doSync(): load LAN remote docs')
        const lastLANFilename = remoteLANfilenames.slice(-1)[0]
        const lastLANRemoteSeq = lastLANFilename ? Number.parseInt(lastLANFilename.split('__')[0]) : 0
        if (lastLANRemoteSeq > this.remoteSeq) {
            const newRemoteFilenames = remoteLANfilenames.filter((filename: string) => Number.parseInt(filename.split('__')[0]) > this.remoteSeq)
            await this.writeAndAcceptDocsFromLAN(newRemoteFilenames, true,
                async () => false,
                async (items) => {
                    const compatibleItems = items.map(item => ({ ...item, seq: item.seq as number }))
                    await this.acceptRemoteItems(compatibleItems, true)
                }, (percent: number) => {
                    progress?.(t`Loading LAN remote docs ...` + `${percent.toFixed(1)}%`)
                }
            )
        } else {
            // discovered browser cached remote docs missing from LAN storage which we need to store
            await this.storeRemoteBrowserDocsToLANStorage(lastLANRemoteSeq, progress)
        }

        log(`_LevelupDB.doSync(): loading LAN local docs...`)
        // if there's a LAN connection, then load local LAN docs
        const allLocalLANfilenames = await listDocs({ project: this.name, isFromRemote: false }, '_LevelupDB.doSync(): load LAN local docs')
        log(`_LevelupDB.doSync(): docs`, allLocalLANfilenames)
        let iterator: ReturnType<IDBOperations['getIterator']> | undefined
        try {
            await this.writeAndAcceptDocsFromLAN(allLocalLANfilenames, false,
                async (filename) => {
                    const key = filename.split('__')[1] // modDate
                    const maxTries = iterator ? 2 : 1 
                    for (let i = 0; i < maxTries; i++) {
                        if (iterator === undefined) {
                            iterator = await this.db.getIterator(key)
                        }
                        let item = await iterator.next()
                        if (item.key === key) {
                            return true // found
                        } else {
                            await iterator.end()
                            iterator = undefined // start over
                        }
                    }
                    return false
                },
                async (items) => {
                    await this.db.writeItems(items)
                    items.forEach(item => this.accept(item.doc, { seq: item.seq }))
                }, (percent: number) => {
                    progress?.(t`Loading LAN local docs ...` + `${percent.toFixed(1)}%`)
                }
            )
        } finally {
            if (iterator) {
                await iterator.end()
            }
        }
        // now store any browser cached LAN docs missing from LAN storage
        await this.storeLANBrowserDocsToLANStorage(allLocalLANfilenames, progress)
    }

    /**
     * LAN storage can lack browser cached remote docs, e.g.
     * if browser local docs got migrated to LAN storage (while offline)
       if the client then got disconnected from LAN storage but synced with remote server (back online)
       then, the new remote docs were not stored in LAN storage, so we need to do so now.
     * @param lastLANRemoteSeq 
     * @param progress 
     * @returns 
     */
    async storeRemoteBrowserDocsToLANStorage(lastLANRemoteSeq: number, progress: ((message: string) => void) | undefined) {
        const missingDocCount = this.remoteSeq - lastLANRemoteSeq
        if (missingDocCount <= 0) return
        let i = 0
        let iterator = await this.db.getIterator(lastLANRemoteSeq + 1)
        try {
            let nextItem = await iterator.next()
            while(nextItem.key !== undefined && typeof nextItem.key === 'number' && nextItem.key <= this.remoteSeq) {
                if (i % 10 === 0) {
                    const percent = 100 * i / missingDocCount
                    progress?.(t`Storing browser remote docs to LAN storage ... ` + `${percent.toFixed(1)}%`)
                    await delay(1)
                }
                if (!nextItem.value) {
                    log(`###BAD ENTRY`, nextItem)
                    continue
                }
                await storeDoc(
                    { project: this.name, doc: nextItem.value, remoteSeq: nextItem.key },
                    '_LevelupDB.storeRemoteBrowserDocsToLANStorage()'
                )
                nextItem = await iterator.next()
            }
        } finally {
            await iterator.end()
        }
    }

    /**
     * LAN storage can lack browser LAN docs, e.g. 
     * if they were deleted from the LAN storage
        or client connected to a different LAN storage device
     * @param allLocalLANfilenames
     * @param progress 
     */
    async storeLANBrowserDocsToLANStorage(allLocalLANfilenames: string[], progress: ((message: string) => void) | undefined) {
        const allBrowserKeys = await this.db.readKeys()
        const browserLanKeys: string[] = allBrowserKeys.filter(key => typeof key === 'string') as string[]
        const storedLANKeys = new Set(allLocalLANfilenames.map(filename => filename.split('__')[1]))
        // see if we can find any browser cached LAN keys that are not in LAN storage
        for (let i = 0; i < browserLanKeys.length; i++) {
            const key = browserLanKeys[i]
            if (i % 10 === 0) {
                const percent = 100 * i / browserLanKeys.length
                progress?.(t`Storing browser LAN docs to LAN storage ... ` + `${percent.toFixed(1)}%`)
                await delay(1)
            }
            if (storedLANKeys.has(key)) continue

            const iterator = await this.db.getIterator(key)
            try {
                let nextItem = await iterator.next()
                if (!nextItem.key) continue // not found
                if (!nextItem.value) {
                    log(`###BAD ENTRY`, nextItem)
                    continue
                }
                await storeDoc(
                    { project: this.name, doc: nextItem.value, remoteSeq: Number.NaN },
                    '_LevelupDB.storeLANBrowserDocsToLANStorage()'
                )
            } finally {
                await iterator.end()
            }
        }
    }

    async storeLocalBrowserDocsToLANStorage(progress: ((message: string) => void) | undefined) {
        const { localSeqBase, localSeqLast } = this
        const migratedLANLocalItems = []
        for (let seq = localSeqBase + 1; seq <= localSeqLast; ++seq) {
            if (seq === (localSeqBase + 1) || seq % 100 === 0) {
                const percent = 100 * (seq - localSeqBase) / (localSeqLast - localSeqBase)
                progress?.(t`Storing browser local docs to LAN local storage ... ` + `${percent.toFixed(1)}%`)
                await delay(1)
            }
            let _docs = await this.db.get(seq - 1, seq)
            const doc = _docs[0]
            const response = await storeDoc({ project: this.name, doc, remoteSeq: Number.NaN }, '_LevelupDB.doSync() store browser local doc')
            const { filenameModDate } = response!
            migratedLANLocalItems.push({ project: this.name, seq: filenameModDate, doc })
            if (migratedLANLocalItems.length === 100 || seq === localSeqLast) {
                await this.db.writeItems(migratedLANLocalItems)
                migratedLANLocalItems.length = 0 // Clear the array after writing
                await this.deleteLocalDocs(localSeqBase, seq, '')
            }
        }
    }

    // Sync docs with remote server.
    // Do upsync in modest size chunks to stay within server request size limit.
    // Repeat down sync if we got a lot of records, because there may be more to get.
    async syncDocs() {
        let lastLanKey = ''
        while (true) {
            let { localSeqBase, localSeqLast  } = this
            dbg(`syncDocs`, fmt({ localSeqBase, localSeqLast, retryUpsyncTime: this.retryUpsyncTime }))

            if (!this.api.loggedIn()) break // no use trying to sync if not logged in

            if (this.retryUpsyncTime <= Date.now()) {
                dbg('syncDocs retry upsync')
                this.retryUpsyncTime = 0
            }

            if (this.retryUpsyncTime === 0) { // no nonretirable error in progress
                ++this.upsyncCount
                const { docs, lastSeq, lastLanKey: lastLanKeyFromSlice } = await this.getDocsSlice()
                if (lastLanKeyFromSlice) {
                    lastLanKey = lastLanKeyFromSlice
                }
                // If requested from console, force N sync errors.
                // In console: window._LevelupDB.forceSyncExceptionCount = 1000
                if (_LevelupDB.forceSyncExceptionCount > 0) { 
                    --_LevelupDB.forceSyncExceptionCount
                    log('syncDocs test error handling')
                    throw Error(TEST_ERROR_HANDLING_MESSAGE)
                }

                if (this.api.blockServerUpdates() && docs.length > 0) {
                    log(`### Aborting sync: API.blockServerUpdates() is true. There are ${docs.length} doc(s) in ${this.fullDbName}, e.g. [${lastSeq}: ${docs.slice(-1)[0]._id}]`)
                    return
                }
                const { error, newItems } = await this.apiSync(docs) // This will set retryUpsyncTime if there is a nonretriable upsync error.
                if (!error) {
                    await this.acceptRemoteItems(newItems)
                    await this.deleteLocalDocs(localSeqBase, lastSeq, lastLanKey)

                    if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do  */ }
                    // since localSeqBase can be >= this.localSeqLast (thus would otherwise break out of loop)
                    // yet getDocsSlice() could still have more LAN docs to upsync...
                    if (docs.length >= MAXTOSYNC) { continue /* repeat sync loop, likely more upsync to do */ }
                }
            }

            if (this.retryUpsyncTime > 0) { // nonretriable error in progress
                ++this.downsyncCount

                const { error, newItems } = await this.apiSync([]) // do downsyc only

                if (!error) {
                    await this.acceptRemoteItems(newItems)
                }
                if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do */ }
            }

            if (this.retryUpsyncTime > 0) { break }
            if (this.localSeqBase >= this.localSeqLast) { break }
        }
    }

    async tryMigration(originalEntry: DBEntry): Promise<{ modEntry: DBEntry | undefined }> {
        if (!isLocalBrowserKey(originalEntry.key)) { return { modEntry: undefined } }
        // create modEntry only if we need to modify the entry (preserve performance)
        // clone it from originalEntry to avoid modifying the original entry
        let modEntry: DBEntry | undefined = undefined
        const getModEntry = () => {
            if (!modEntry) {
                modEntry = JSON.parse(JSON.stringify(originalEntry))
            }
            return modEntry!
        }
        
        if (!originalEntry.doc.modBy) {
            // all new upsynced docs are expected to have a modBy field
            const modBy = normalizeUsername(this.username)
            const modEntry = getModEntry()
            modEntry.doc.modBy = modBy
            console.log(`tryMigration: added modBy '${modBy}' to entry (${modEntry.key}, ${modEntry.doc._id})`)
        }
        if (originalEntry.doc._id === 'project' && 'projectBookName' in originalEntry.doc) {
            // cause of `/sync ERROR: Error: "members" field missing`
            // see https://app.rollbar.com/a/biblesocieties/fix/item/SLTT/248
            /* {
                    "_id": "project", // --> "teamPreferences" 
                    "creator": "caio.cascaes@gmail.com",
                    "creationDate": "2024/02/26 18:33:32.399Z",
                    "modDate": "2024/02/26 18:37:48.026Z",
                    "bbbccc": "001",
                    "projectBookName": "1 Mosebog"
                }, 
            */
            const modEntry = getModEntry();
            (modEntry.doc as any)._id = 'teamPreferences'
            console.log(`tryMigration: changed _id from 'project' to 'teamPreferences' in entry (${modEntry.key}) doc: ${s(modEntry.doc)}`)
        }
        if (!!modEntry) {
            const modEntry = getModEntry()
            await this.db.put(modEntry.key, modEntry.doc)
        }
        return { modEntry }
    }

    setupRetryTimeout() {
        if (this.retryUpsyncTime > 0) { return } // avoid multiple timers
        if (!this.api.loggedIn()) { return } // no use trying to sync if not logged in
        if (this.api.blockServerUpdates()) { return } // no use trying to sync if blockServerUpdates is true
        if (!getIsAppOnlineOrDefault(false)) { return } // no use trying to sync until online
        // Record the fact that future upsyncs are likely to fail.
        this.retryUpsyncTime = Date.now() + this.delayBeforeSyncRetry

        // In 5 minutes retry the sync in case something has happened to resolve the error.
        // We wait just a little longer than the delayBeforeRetry to ensure that the
        // time check in the main loop realizes we are past the delayBeforeRetry time.
        setTimeout(() => { 
            this.doSync().catch(this.systemError) 
        }, 1.1*this.delayBeforeSyncRetry)
    }

    async apiSync(docs: any[]) {
        try {
            const newItems = await this.api.sync(this.name, docs, this.remoteSeq)
            this.downsyncDocCount += newItems.length
            this.upsyncDocCount += docs.length

            return { error: null, newItems }
        } catch (_error) {
            if (isHavingConnectionIssues(_error) ||
                isNeedingLogout(_error) || 
                isNeedingReporting(_error)) {
                // offline...no point in retrying until back online
                // or user needs to login before syncing again
                // or error still needs to be reported before setupRetryTimeout() is called
                throw _error
            }
            const error = _error as Error
            this.setupRetryTimeout()
            return { error, newItems: [] }
        }
    }

    async deleteLocalDocs(localSeqBase: number , seqLast: number, lanKeyLast: string) {
        for (let i = localSeqBase+1; i <= seqLast; ++i) {
            dbg(`deleteLocalDocs[${i}]`)
            await this.db.deleteDoc(i)
            this.deletedCount++
        }

        // Record the fact that we have successfully sent local records to sync api.
        // The Math.max is probably a nop.
        // It does make sure that we never move localSeqBase backwards 
        // ... because that could result in us upsyncing the same records again.
        this.localSeqBase = Math.max(seqLast, this.localSeqBase)
        if (lanKeyLast) {
            // NOTE: While acceptRemoteItems() also handles deletion of LAN local keys (after downsync)
            // However, there may be a lot of remote items to downsync before receiving the newly upsynced lan docs
            // Furthermore, the server may quietly ignore some of the upsynced docs due to conflict resolution
            // on the same _id. To avoid trying to upsync the same docs over and over 
            // it's probably best to delete the LAN local keys here and trust we will
            // downsync them later
            let iterator = await this.db.getIterator('')
            try {
                let nextItem = await iterator.next()
                while(nextItem.key !== undefined && nextItem.key !== null && nextItem.key <= lanKeyLast) {
                    await this.db.deleteDoc(nextItem.key)
                    nextItem = await iterator.next()
                }
            } finally {
                await iterator.end()
            }
            await this.db.deleteDoc(lanKeyLast)
        }
        dbg('deleteLocalDocs done', fmt({ localSeqBase: this.localSeqBase, seqLast, lanKeyLast }))
    }

    acceptRemoteItems = async (items: IRemoteDBItem[], skipWriteToDisk: boolean = false) => {
        if (items.length === 0) return

        let lastSeq = items.slice(-1)[0].seq
        dbg(`acceptRemoteItems`, fmt({ lastSeq, items: items.length, remoteSeq: this.remoteSeq }))
        dbg(`acceptRemoteItems`, items)

        await this.db.writeItems(items)
        this.remoteSeq = lastSeq

        items.forEach(item => this.accept(item.doc, {seq: item.seq}))
        if (hasSharedLANStorage() && !skipWriteToDisk) {
            for (let item of items) {
                const response = await storeDoc({ project: this.name, doc: item.doc, remoteSeq: item.seq }, '_LevelupDB.acceptRemoteItems()')
                const { filenameModDate } = response!
                await this.db.deleteDoc(filenameModDate) // delete any LAN local key
            }
        }
    }

    /**
     * It should not be possible to create a big doc, but if so truncate it so
     * that it does not crash the sync process.
     */
    limitDocSize(doc: any) {
        const length = 2*JSON.stringify(doc).length // 2* because of utf-16
        if (length < MAXTOSYNCSIZE) return length
        
        // This assumes that the size problem in the text or src fields.
        doc.text = ''
        doc.src = ''
        doc.error = "*TOO BIG*"
        return 2*JSON.stringify(doc).length
    }

    /**
     * Return a slice of (local) docs to (up)sync starting at localSeqBase+1.
     * Must not be more than MAXTOSYNC docs.
     * Must not be more than MAXTOSYNCSIZE bytes.
     */
    async getDocsSlice() {
        const docs: IDBModDoc[] = []
        const items: IDBItem[] = []
        const { localSeqBase, localSeqLast, remoteSeq } = this
        let lastSeq = localSeqBase
        let lastLanKey: string = ''
        let totalLength = 0

        // Get any LAN docs that have not been synced to remote
        // NOTE: LAN local docs (string keys) will be stored after all number keys (remote and local browser keys)
        // therefore, try to get the first key after all number keys 
        let dbIterator = await this.db.getIterator('') // first string key
        let processingLANKeys = true
        type ResolvedType<T> = T extends PromiseLike<infer U> ? U : T;
        let nextItem: ResolvedType<ReturnType<IDBIterator['next'] | IDBIterator['end']>>

        const getNextItem = async () => {
            let nextItem = await dbIterator.next()
            if (nextItem.key === undefined && processingLANKeys) {
                // Switch to processing number keys if no more string keys
                processingLANKeys = false
                await dbIterator.end()
                dbIterator = this.db.getIterator(localSeqBase + 1)
                nextItem = await dbIterator.next()
            }
            if (!processingLANKeys && typeof nextItem.key === 'string') {
                return await dbIterator.end()
            }
            return nextItem
        }
        
        try {
            nextItem = await getNextItem()
            while (nextItem.key !== undefined && nextItem.key !== null) {
                const doc = nextItem.value
                if (!doc) {
                    log(`###BAD DOC`, fmt({nextItem}))
                    continue
                }
    
                const length = this.limitDocSize(doc)
                totalLength += length
    
                if (totalLength > MAXTOSYNCSIZE) { break }
    
                docs.push(doc)
                items.push({ project: this.name, seq: nextItem.key, doc: doc })
                if (typeof nextItem.key === 'number') {
                    lastSeq = nextItem.key
                } else {
                    lastLanKey = nextItem.key
                }
    
                if (docs.length >= MAXTOSYNC) { break }

                nextItem = await getNextItem()
            }
    
        } finally {
            await dbIterator.end()
        }
        // This should never happen.
        // However if it did we would be stuck in a loop of trying to sync the same records.
        if (lastSeq < localSeqLast && docs.length === 0) {
            debugger;
            logDocSyncError(
                `${NonretriableSyncFailure}: unexpected lastSeq ${lastSeq} in getDocsSlice, no docs found in range ${localSeqBase + 1}..${localSeqLast}`,
                docs, this.name, true
            )
            this.setupRetryTimeout()
        }

        return { docs, lastSeq, lastLanKey, items }
    }

    put = async (doc: IDBModDoc, onlyAccept: boolean = false, skipWriteToDisk: boolean = false) => {
        if (onlyAccept || this.api.blockServerUpdates()) {
            this.accept(doc)
            return
        }

        let { db } = this
        this.localSeqLast += 1
        dbg('put', fmt({localSeqlast: this.localSeqLast}))
        await db.put(this.localSeqLast, doc)

        let seq = this.localSeqLast
        this.accept(doc, {label: `put ${seq}`, seq})

        this.doSync().catch(this.systemError) // do not wait for sync to finish
    }

    /**
     * Some operations, such as dragging a gloss, create a lot of potential changes to the db.
     * For these operations do not commit a change until we go 5 seconds without getting
     * a new value and then commit only the latest value.
     */
    submitChange(doc: IDBObject) {
        if (this.api.blockServerUpdates()) {
            this.accept(doc)
        } else {
            let seq = this.localSeqLast + 1
            this.accept(doc, {label: `put ${seq}`, seq})
            this.changeDeferrer.submitChange(doc)
        }
    }

    static lastId = ''

    static dateToId(date: Date, tag: string = '') {
        // When running tests use a constant time stamp
        if (intest) {
            return tag + '190101_020304'
        }

        let _id = date.toISOString().slice(2, -5)
        _id = _id.replace('T', '_')
        _id = _id.replace(/\-/g, '')
        _id = _id.replace(/:/g, '')

        let lastId = _LevelupDB.lastId
        if (lastId >= _id) {
            let parts = lastId.split('_')
            let newTime = (parseInt(parts[1]) + 1).toString()
            _id = parts[0] + '_' + newTime.padStart(6, '0')
        }

        _LevelupDB.lastId = _id

        return tag + _id
    }

    getNewId(existing: any[], date: Date, tag: string = '') {
        let newId = _LevelupDB.dateToId(date, tag)

        // If we accidentally insert a bogus id into the list of existing ids
        // it can corrupt all future ids if it the alphabetic largetst.
        // Ignore invalid ids when creating new ids.
        function isValidId(id: string) {
            if (!id) return false
            let _parts = id.split('_')
            return _parts.length === 2 && !isNaN(parseInt(_parts[1], 10))
        }
        existing = existing.filter(e => isValidId(e._id))

        if (existing.length === 0) { return newId }

        existing = existing.map(e => e._id.split('/').slice(-1)[0])
        let maxId = existing.reduce((max: string, next: string) => next > max ? next : max)

        if (newId > maxId) { return newId }

        let parts = maxId.split('_')
        let new2id = parts[0] + '_' + (parseInt(parts[1], 10) + 1).toString().padStart(6, '0')

        return new2id
    }
    
    async delete(doc: any /* really IDBObject */) {
        doc.removed = true
        await this.put(doc)
    }

    // async getOne(_id: string) {
    //     return this.db.get(_id)
    // }
    
    getDate() {
        if (intest) {
            return '2019/09/10 11:12Z'
        }

        return _LevelupDB.getDate()

    }

    // Returns a UMT date with format 2020/10/03 19:01:14.093Z
    // WARNING: we use this date to determine in the back end what change is the latest.
    // Changing the format of this will likely cause data loss due to items being judged outdated
    // and being discarded.
    static getDate() {
        return convertToCanonicalDateTimeFormat(new Date(Date.now()))
    }

    cancel() {
    }
    
    slice() {
        throw Error('Only supported in _MemoryDB for unit testing')
        return []
    }
    
    reset(nextId: number) {
        throw Error('Only supported in _MemoryDB for unit testing')
    }

    get fullDbName() { return `level-js-${this.name}-db` }

    async deleteDB(): Promise<void> {
        await this.db.close()
        return new Promise((resolve, reject) => {
            let deleteRequest = indexedDB.deleteDatabase(this.fullDbName)

            deleteRequest.onerror = event => { reject('Error deleting db') }

            deleteRequest.onsuccess = event => { resolve() }

            deleteRequest.onblocked = event => { reject('Delete db request blocked') }
        })
    }
}

interface IChange {
    doc: any,
    timer: null | ReturnType<typeof setTimeout>
}

// Batch all db changes that update the same field(s) of the same object and debounce them.
class DBChangeDeferrer {
    private changes: IChange[] = []

    constructor(private db: _LevelupDB) {
        this.submitChange = this.submitChange.bind(this)
        this.commitChange = this.commitChange.bind(this)
    }

    submitChange(doc: any) {
        // Two changes are equivalent if they have the same _id and all the field
        // names are the same
        function sameItem(doc2: any) {
            if (doc._id !== doc2._id) return false
            
            // A request to remove should we replace any other request.
            // Otherwise the item flashes back into existence when the original commit happens
            // and then re-disappears when the removal commit happens.
            if (doc.removed) return true
            
            let existingDocKeys = Object.keys(doc2).sort()
            let docKeys = Object.keys(doc).sort()
            return JSON.stringify(existingDocKeys) === JSON.stringify(docKeys)
        }

        let existingIndex = this.changes.findIndex(item => sameItem(item.doc))

        if (existingIndex > -1) {
            dbg('submitChange replacement', JSON.stringify(doc))
            let existing = this.changes[existingIndex]
            existing.timer && clearTimeout(existing.timer)
            existing.timer = setTimeout(() => this.commitChange(doc), 5000)
            existing.doc = doc
        } else {
            dbg('submitChange', JSON.stringify(doc))
            let timer = setTimeout(() => this.commitChange(doc), 5000)
            this.changes.push({ doc, timer })
        }
    }

    private async commitChange(doc: any) {
        dbg('commitChange', JSON.stringify(doc))
        await this.db.put(doc) // <-- _LevelupDB.put()

        let index = this.changes.findIndex(c => c.doc === doc)
        if (index > -1) {
            this.changes.splice(index, 1)
        } else {
            log('### commitChange doc not in changes!')
        }
    }
}

const TEST_ERROR_HANDLING_MESSAGE = '!!! TEST ERROR HANDLING'

const isNeedingReporting = (error: unknown) => (error instanceof Error) &&
    (!error.message.startsWith(NonretriableSyncFailure) &&
        error.message !== TEST_ERROR_HANDLING_MESSAGE)

/**
 *  typically (for sltt-pwa) entries are sorted by number
    however, if sltt-pwa has connected to a LAN proxy server, then
    it may also have LAN keys (string)
    If so, re-order in-place so that remote (global seq number) keys are first,
    then LAN keys (string), then isLocalBrowserKey (large number)
 * @param entries 
 * @returns 
 */
function reorderEntriesWithLANKeys<T>(entries: DBEntry[]): void {
    if (typeof entries.slice(-1)[0]?.key === 'number') {
        // no string keys so no need to reorder
        return
    }
    const firstLargeKeyIndex = entries.findIndex(
        entry => isLocalBrowserKey(entry.key)
    )
    const firstStringKeyIndex = entries.findIndex(
        entry => typeof entry.key === 'string'
    )

    if (firstLargeKeyIndex === -1 || firstStringKeyIndex === -1) {
        // If there are no large keys or no string keys, so don't reorder
        return
    }

    const stringKeys = entries.splice(firstStringKeyIndex)
    entries.splice(firstLargeKeyIndex, 0, ...stringKeys)
}

// Returns a UMT date with format 2020/10/03 19:01:14.093Z
export function convertToCanonicalDateTimeFormat(date: Date) {
    let iso = date.toISOString()

    iso = iso.replace('-', '/')
    iso = iso.replace('-', '/') // replace second occurence of -
    iso = iso.replace('T', ' ')

    return iso
}

const _window = window as any
// allow console.log access to _LevelupDB
_window._LevelupDB = _LevelupDB
