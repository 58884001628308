import React from 'react'
import './Buttons.css'
import { t } from 'ttag'
import _PlayFromBeginningButton from './PlayFromBeginningButton.gif'
import { RootConsumer } from '../app/RootContext'
import { ReferencesIcon, ApproveIcon, DisapproveIcon, ArrowsIcon, PencilIcon, ConsultantIcon,
    EditDifficultyIcon, CircleIcon, ForwardIcon, DbsPlayIcon, EditIcon, ClipboardIcon, GoBackIcon, 
    GoForwardIcon, CloneIcon } from './Icons'
import ReactTooltip from 'react-tooltip'

import { SlttHelp2 } from './SLTTHelp2'

const cmd = (navigator.platform.startsWith('Mac')) ? '<Command>' : '<Ctrl>'
const option = (navigator.platform.startsWith('Mac')) ? '<Option>' : '<Alt>'

export function enable(cns, enabled) { return enabled ? cns : cns + ' sl-button-disabled' }
function enableImg(cns, enabled) { return enabled ? cns : cns + ' sl-image-button-disabled' }

// className must include the icon selector, e.g. fa-arrow-left
export const FAButton = function ({ tooltip, enabled, onClick, className }) {
    return (
        <span>
            <span
                className={enable(`sl-fa-button fas ${className}`, enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const PassageAddButton = function({enabled, onClick, tooltip}) {
    return (
        <span className={enable('sl-fa-button sl-passage-add-button far fa-plus-square', enabled)}
            onClick={(e) => onClick && onClick(e)}
            data-toggle="tooltip" 
            title={tooltip} >
        </span>
    )
}

export const MembersViewButton = function({tooltip, onClick}) {
    return (
        <span className="sl-fa-button sl-members-view-button fas fa-user"
            onClick={(e) => onClick && onClick(e)}
            data-toggle="tooltip" 
            title={tooltip} >
        </span>
    )
}

export const PortionsViewButton = function({tooltip, onClick}) {
    return (
        <span className="sl-fa-button sl-portions-view-button fas fa-bars"
            onClick={(e) => onClick && onClick(e)}
            data-toggle="tooltip" 
            title={tooltip} >
        </span>
    )
}

export const TourButton = function ({ tooltip, onClick }) {
    return (
        <span className="sl-fa-button fa-bus fas sl-tour-button"
            onClick={(e) => onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}


export const RecordButton = function ({ enabled, onClick, className, tooltip, selectionPresent }) {
    let _className = className
    if (selectionPresent) _className += ' sl-record-selection-button'

    return (
        <RootConsumer>
            {rt => {
                //let className = classNames("et-right", "fa", "fa-2x", "fa-circle", "fa-fw", "text-danger", "video-up1", 
                return (
                    <span
                        className={enable(`${_className} sl-fa-button fas fa-circle`, enabled)}
                        onClick={(e) => {
                            if (enabled && onClick) { onClick(e.shiftKey) }
                        }}
                        data-toggle="tooltip"
                        title={tooltip ?? ''}
                    />
                )
            }}
        </RootConsumer>
    )
}

export const RecordButtonWithLabel = function ({ enabled, onClick, buttonClassName, className, tooltip, selectionPresent }) {
    let _className = className
    if (selectionPresent) _className += ' sl-record-selection-button'

    return (
        <button className={`${buttonClassName} center-align-flex wraparound-button`} onClick={e => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${_className} sl-fa-button fas fa-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip ?? ''}
            />
            {'<Space>'}
        </button>
    )
}

export const StopButton = function({ enabled, onClick, className, tooltip }) {
    return (
        <span className={enable(`${className} sl-fa-button sl-stop-button clickable fas fa-fw fa-stop`, enabled)}
            onClick={(e) => enabled && onClick && onClick(e)}
            data-id="stop-recording-button"
            data-toggle="tooltip" 
            title={tooltip} >
        </span>
    )
}

export const StopButtonWithLabel = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='center-align-flex wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button sl-stop-button fas fa-stop`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Enter>'}
        </button>
    )
}

export const PlayButton = function ({ enabled, onClick, className, tooltip, selectionPresent }) {
    let _className = enable(`${className} sl-play-button sl-fa-button fas fa-fw fa-play`, enabled)
    if (selectionPresent) _className += ' sl-selection-present'

    return (
        <span
            className={_className}
            onClick={(event) => enabled && onClick && onClick(event)}
            data-id="play-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PlayFromBeginningButton = function ({ onClick, className }) {
    const title = /* translator: important */ t`Play from timeline cursor`

    return (
        <RootConsumer>
            {rt => (
                <span>
                    <img
                        className={`${className} sl-play-button`}
                        src={_PlayFromBeginningButton}
                        onClick={(e) => onClick && onClick(e)}
                        alt={title}
                        title={title} />
                </span>
            )}
        </RootConsumer>
    )
}

export const PauseButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <span
            className={enable(`${className} sl-pause-button sl-fa-button fas fa-fw fa-pause`, enabled)}
            onClick={(e) => enabled && onClick && onClick(e)}
            data-id="pause-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const RepeatButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <span
            className={enable(`${className} sl-pause-button sl-fa-button fas fa-fw fa-repeat`, enabled)}
            onClick={(e) => enabled && onClick && onClick(e)}
            data-id="repeat-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PauseButtonWithLabel = function ({ enabled, onClick, buttonClassName, className, tooltip }) {
    return (
        <button className={`${buttonClassName} center-align-flex wraparound-button`} onClick={e => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${className} sl-pause-button sl-fa-button fas fa-pause`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Space>'}
        </button>
    )
}

//    color: ${props => props.enabled ? 'red' : 'lightgrey'};

export const SlttHelp = function ({ tooltip, id, children, place }) {
    let _id = `details/${id}`
    return (
        <span data-tip data-for={_id} data-place={place ?? ''}>
            { children }
            <SlttHelpTooltip {...{ id: _id, tooltip } } />
        </span>
    )
}

/**
 * In order to get the link to the help system to work when SlttHelpTooltip
 * was used in a <MenuItem> I had to change the <a> to call this function
 * on click. The normal click handler was disabled (somehow!?) by the
 * MenuItem.
 */
const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const SlttHelpTooltip = function ({tooltip, id}) {
    return (
        <ReactTooltip id={id}
                backgroundColor="gray"
                effect="solid"
                delayShow={1500}
                delayHide={500}
                clickable={true} >
            {tooltip} 
            <a onClick={(e) => openInNewTab(`https://s3.amazonaws.com/sltt-help/${id}`)} target="_blank">
                <span className={'sl-help-icon fas fa-question-circle'} />
            </a>
        </ReactTooltip>
    )
}

export const CreateNoteButton = function ({ enabled, onClick }) {
    let tooltip = /* translator: important */ t`Create note at current location.`
    let id = 'notes.html'

    return (
        <SlttHelp2 {...{ id, tooltip, video: 'a3' }} place="bottom">
            <span
                className={enable('sl-fa-button sl-create-note-button fas fa-comment', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-tip data-for={id} />
            
        </SlttHelp2>
    )
}

export const ZoomInTimelineButton = function ({ enabled, onClick }) {
    let tooltip = /* translator: important */ t`Zoom in timeline.`
    return (
        <SlttHelp2 {...{ id: 'zoomintimeline', tooltip, video: 'a9' }} place="bottom">
            <span
                className={enable('sl-fa-button sl-zoom-in-timeline-button fas fa-plus', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-id="zoom-in-timeline-button" />
        </SlttHelp2>
    )
}

export const ZoomOutTimelineButton = function ({ enabled, onClick }) {
    let tooltip = /* translator: important */ t`Zoom out timeline.`
    return (
        <SlttHelp2 {...{ id: 'zoomouttimeline', tooltip, video: 'a10' }} place="bottom">
            <span
                className={enable('sl-fa-button sl-zoom-out-timeline-button fas fa-minus', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-id="zoom-out-timeline-button" />
        </SlttHelp2>
    )
}

export const TrashButton = ({ onClick, tooltip, buttonClassName, className, enabled }) => (
    <button className={`wraparound-button ${buttonClassName}`} onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-trash-alt`, enabled)}
            data-id="trash-button"
            data-toggle="tooltip"
            alt={tooltip}
            title={tooltip}
        />
    </button>
)

export const PlayTourStepButton = function ({ enabled, onClick }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = enabled ? t`Click to play sign language explanation or drag and drop an .mp4 file to supply an explanation in your sign language.`
                : t`Drag and drop an .mp4 file here to supply an explanation in your sign language.`

                return (
                    <span
                        className={enable('sl-fa-button sl-play-tour-step-button fas fa-sign-language', enabled)}
                        onClick={(e) => enabled && onClick && onClick(e)}
                        data-toggle="tooltip"
                        title={tooltip} >
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const DeletePatchVideoButton = function({ onClick, enabled }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = /* translator: important */ t`Delete patch video.`
                return (
                    <span
                        className={enable('delete-patch-video-button clickable sl-fa-button fas fa-fw fa-trash', enabled)}
                        onClick={(e) => enabled && onClick && onClick(e)}
                        data-toggle="tooltip"
                        title={tooltip}
                    />
                )
            }}
        </RootConsumer>
    )
}


export const CreateSegmentButton = function ({ enabled, onClick }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = /* translator: important */ t`Create segment in video at current location.`

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-create-segment-button fas fa-tag', enabled)}
                            onClick={(e) => enabled && onClick && onClick(e)}
                            data-toggle="tooltip"
                            title={tooltip} >
                        </span>
                        {/* 
                        ??? should we support a dropdown menu to select a segment?
                        <Dropdown id="dropdown-select-segment">
                            <Dropdown.Toggle bsStyle="default" />
                            <Dropdown.Menu>
                                <MenuItem eventKey="1">Luke 15.11-24</MenuItem>
                                <MenuItem eventKey="2">Luke 15.25-31</MenuItem>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const EditSegmentButton = function ({ enabled, onClick, tooltip, tourSelector, className }) {
    let className2 = `sl-fa-button sl-edit-segment-button fas fa-edit ${className}`
    
    className2 = enable(className2, enabled)
    if (tourSelector && tourSelector.startsWith('.sl-edit-segment')) {
        className2 += ' sl-edit-segment-button-tour'
    }

    return (
        <span>
            <span
                className={className2}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const DictateSegmentButton = function ({ enabled, onClick, tooltip, tourSelector }) {
    let className = `sl-fa-button sl-edit-segment-button sl-dictate-segment-caption-button fas fa-microphone`

    let className2 = enable(className, enabled)
    if (tourSelector && tourSelector.startsWith('.sl-dictate-segment')) {
        className2 += ' sl-edit-segment-button-tour'
    }

    return (
        <span>
            <span
                className={className2}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const OkEditSegmentButton = function ({ enabled, onClick }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = /* translator: important */ t`Save edits.`

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-ok-edit-segment-labels-button fas fa-check', enabled)}
                            onClick={(e) => enabled && onClick && onClick(e)}
                            data-toggle="tooltip"
                            title={tooltip} >
                        </span>
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const CancelEditSegmentButton = function ({ enabled, onClick }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = /* translator: important */ t`Cancel edits.`

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-cancel-edit-segment-labels-button far fa-times-circle', enabled)}
                            onClick={(e) => enabled && onClick && onClick(e)}
                            data-toggle="tooltip"
                            title={tooltip} >
                        </span>
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const DeleteSegmentButton = function ({ tooltip, enabled, onClick }) {
    return (
        <span
            className={enable('sl-fa-button sl-delete-segment-button fas fa-trash-alt', enabled)}
            onClick={(e) => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}

export const PreviousSegmentButton = function ({ tooltip, enabled, onClick }) {
    return (
        <span>
            <span
                className={enable('sl-fa-button sl-previous-segment-button fas fa-arrow-left', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const NextSegmentButton = function ({ tooltip, enabled, onClick }) {
    return (
        <span>
            <span
                className={enable('sl-fa-button sl-next-segment-button fas fa-arrow-right', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const ApprovalIcon = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-thumbs-up`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const SegmentLabelButton = function ({ enabled, className, tooltip, onClick }) {
    return (
        <span
            className={enable(`${className} sl-fa-button fas fa-tag`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
            onClick={(e) => enabled && onClick && onClick(e)}
        />
    )
}

export const SegmentReferencesButton = ({ enabled, className, tooltip, onClick }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <ReferencesIcon className={enable(className, enabled)} tooltip={tooltip} />
    </button>
)

export const SegmentVideoSketchButton = function ({ enabled, className, tooltip, onClick }) {
    return (
        <span
            className={enable(`${className} sl-fa-button fas fa-paintbrush`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
            onClick={(e) => enabled && onClick && onClick(e)}
        />
    )
}

export const EmailRegistrationButton = function ({ tooltip, enabled, onClick }) {
    return (
        <span>
            <span
                className={enable('sl-fa-button sl-email-registration-button fas fa-envelope', enabled)}
                onClick={(e) => enabled && onClick && onClick(e)}
                data-toggle="tooltip"
                title={tooltip} >
            </span>
        </span>
    )
}

export const DeleteButton = function ({ className, tooltip, enabled, onClick }) {
    return (
        <button
            className='wraparound-button'
            onClick={e => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={enable(`sl-fa-button fas fa-trash-alt ${className}`, enabled)} />
        </button>
    )
}

export const AdjustCurrentTimeButtons = function ({ enabled, adjustCurrentTime, includeSeconds }) {
    return (
        <span className="sl-adjust-current-time-buttons">
            { includeSeconds && <PreviousSecond cmd='<Shift>' option={option} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut /> }
            <PreviousFrame cmd={cmd} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            <NextFrame cmd={cmd} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            { includeSeconds && <NextSecond cmd='<Shift>' option={option} enabled={enabled} adjustCurrentTime={adjustCurrentTime} showShortcut /> }
        </span>
    )
}

export const GlossPositionAdjustTimeButtons = function({ previousFrameEnabled, nextFrameEnabled, adjustCurrentTime }) {
    return (
        <span className='sl-adjust-current-time-buttons'>
            <PreviousFrame cmd={cmd} enabled={previousFrameEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
            <NextFrame cmd={cmd} enabled={nextFrameEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
        </span>
    )
}

export const SegmentPositionAdjustTimeButtons = function({ previousSecondEnabled, previousFrameEnabled, nextSecondEnabled, nextFrameEnabled, adjustCurrentTime }) {
    return (
        <RootConsumer>
            {rt => (
                <span className="sl-adjust-current-time-buttons">
                    <PreviousSecond cmd='<Shift>' enabled={previousSecondEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
                    <PreviousFrame cmd={cmd} enabled={previousFrameEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
                    <NextFrame cmd={cmd} enabled={nextFrameEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
                    <NextSecond cmd='<Shift>' enabled={nextSecondEnabled} adjustCurrentTime={adjustCurrentTime} showShortcut />
                </span>
            )}
        </RootConsumer>
    )
}

export const FineAdjustmentTimeButtons = function({ previousFrameEnabled, nextFrameEnabled, adjustCurrentTime, showShortcut }) {
    return (
        <span className='sl-adjust-current-time-buttons'>
            <PreviousFrame {...{ cmd, adjustCurrentTime, showShortcut }} enabled={previousFrameEnabled} />
            <NextFrame {...{ cmd, adjustCurrentTime, showShortcut }} enabled={nextFrameEnabled} />
        </span>
    )
}

export const PreviousSecond = function({ cmd, option, enabled, adjustCurrentTime, showShortcut }) {
    let title = ''
    if (showShortcut) {
        title = /* translator: important */ t`Go back 1 second. Shortcut: ${cmd} Left Arrow.` + ' ' +
            /* translator: important */ t`[Go back 10 seconds. ${option} Left Arrow]`
    } else {
        title = /* translator: important */ t`Go back 1 second.`
    }
    return (
        <span>
            <SlttHelp2 {...{ id: 'previoussecond', tooltip: title, video: 'a12' }} place="bottom">
                <span
                    className={`${enableImg('sl-adjust-current-time-button', enabled)} d-flex flex-row`}
                    onClick={(e) => enabled && adjustCurrentTime(-1.0)}
                    title=""
                >
                    <span className="sl-adjust-seconds-button">1</span>
                    <i className="fas text-right fa-caret-left fa-lg" />
                </span>
            </SlttHelp2>
        </span >
    )
}

export const PreviousFrame = function({ cmd, enabled, adjustCurrentTime, showShortcut }) {
    let title = ''
    if (showShortcut) {
        title = /* translator: important */ t`Go back 1 frame. Shortcut: ${cmd} Left Arrow.`
    } else {
        title = /* translator: important */ t`Go back 1 frame.`
    }
    return (
        <span>
            <SlttHelp2 {...{ id: 'previousframe', tooltip: title, video: 'a13' }} place="bottom">
                <span
                    className={`${enableImg('sl-adjust-current-time-button', enabled)} d-flex flex-row`}
                    onClick={(e) => enabled && adjustCurrentTime(-2.0 / 30.0)}
                    title=""
                >
                    <span className="sl-adjust-frame-button">F</span>
                    <i className="fas text-right fa-caret-left fa-lg" />
                </span>
            </SlttHelp2>
        </span >
    )
}

export const NextFrame = function({ cmd, enabled, adjustCurrentTime, showShortcut }) {
    let title = ''
    if (showShortcut) {
        title = /* translator: important */ t`Go forward 1 frame. Shortcut: ${cmd} Right Arrow.`
    } else {
        title = /* translator: important */ t`Go forward 1 frame.`
    }
    return (
        <span>
            <SlttHelp2 {...{ id: 'nextframe', tooltip: title, video: 'a14' }} place="bottom">
                <span
                    className={`${enableImg('sl-adjust-current-time-button disable-select', enabled)} d-flex flex-row`}
                    data-id="forward-one-frame"
                    onClick={(e) => enabled && adjustCurrentTime(2.0 / 30.0)}
                    title=""
                >
                    <i className="fas fa-caret-right fa-lg" />
                    <span className="sl-adjust-frame-button">F</span>
                </span>
            </SlttHelp2>
        </span>
    )
}

export const NextSecond = function({ cmd, option, enabled, adjustCurrentTime, showShortcut }) {
    let title = ''
    if (showShortcut) {
        title = /* translator: important */ t`Go forward 1 second. Shortcut: ${cmd} Right Arrow.` + ' ' +
            /* translator: important */ t`[Go forward 10 seconds. ${option} Right Arrow]`
    } else {
        title = /* translator: important */ t`Go forward 1 second.`
    }
    return (
        <span>
            <SlttHelp2 {...{ id: 'nextsecond', tooltip: title, video: 'a15' }} place="bottom">
                <span
                    className={`${enableImg('sl-adjust-current-time-button', enabled)} d-flex flex-row`}
                    onClick={(e) => enabled && adjustCurrentTime(1.0)}
                    title=""
                >
                    <i className="fas fa-caret-right fa-lg" />
                    <span className="sl-adjust-seconds-button">1</span>
                </span>    
            </SlttHelp2>
        </span>
    )
}

export const AdjustNoteMarkerButtons = function({ enabled, adjustTime }) {
    return (
        <RootConsumer>
            {rt => (
                <span className="sl-adjust-current-time-buttons">
                    <span
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        onClick={(e) => enabled && adjustTime(-1.0)}
                        title={t`Go back 1 second.`}
                    >
                        <span className="sl-adjust-seconds-button">1</span>
                        <i className="fas text-right fa-caret-left fa-lg" />
                    </span>
                    <span
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        onClick={(e) => enabled && adjustTime(-0.05)}
                        title={t`Go back 1 frame.`}
                    >
                        <span className="sl-adjust-frame-button">F</span>
                        <i className="fas text-right fa-caret-left fa-lg" />
                    </span>
                    <span
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        data-id="forward-one-frame"
                        onClick={(e) => enabled && adjustTime(0.05)}
                        title={t`Go forward 1 frame.`}
                    >
                        <i className="fas fa-caret-right fa-lg" />
                        <span className="sl-adjust-frame-button">F</span>
                    </span>
                    <span
                        className={enableImg('sl-adjust-current-time-button', enabled)}
                        onClick={(e) => enabled && adjustTime(1.0)}
                        title={t`Go forward 1 second.`}
                    >
                        <i className="fas fa-caret-right fa-lg" />
                        <span className="sl-adjust-seconds-button">1</span>
                    </span>
                </span>
            )}
        </RootConsumer>
    )
}

export const VideoSegmentStartingPointButton = function({ tooltip, className, enabled, onClick }) {
    return (
        <span
            className={enable(`${className} ${onClick && 'clickable'} segment-button sl-fa-button fas fa-step-backward`, enabled)}
            onClick={(e) => { enabled && onClick && onClick(e) }}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const VideoSegmentEndingPointButton = function({ tooltip, className, enabled, onClick }) {
    return (
        <span
            className={enable(`${className} ${onClick && 'clickable'} segment-button sl-fa-button fas fa-step-forward`, enabled)}
            onClick={(e) => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const DbsPlayButton = ({ onClick, className, tooltip, enabled }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <DbsPlayIcon className={enable(className, enabled)} tooltip={tooltip} />
    </button>
)

export function SooSLLinkButton({ onClick, enabled }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = t`Link term to SooSL sign`

                return (
                    <span className={enable('sl-fa-button sl-soosl-link-button fas fa-link', enabled)}
                        onClick={(e) => onClick && onClick(e)}
                        data-toggle="tooltip"
                        title={tooltip} >
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export function SooSLUnlinkButton({ onClick, enabled }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = t`Remove link between term and SooSL sign.`

                return (
                    <span className={enable('sl-fa-button sl-soosl-unlink-button fas fa-unlink', enabled)}
                        onClick={(e) => onClick && onClick(e)}
                        data-toggle="tooltip"
                        title={tooltip} >
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const SooSLCancelLinkButton = function ({ enabled, onClick }) {
    return (
        <RootConsumer>
            {rt => {
                let tooltip = t`Cancel adding SooSL link.`

                return (
                    <span>
                        <span
                            className={enable('sl-fa-button sl-soosl-cancel-link-button far fa-times-circle', enabled)}
                            onClick={(e) => enabled && onClick && onClick(e)}
                            data-toggle="tooltip"
                            title={tooltip} >
                        </span>
                    </span>
                )
            }}
        </RootConsumer>
    )
}

export const PaneCloseButton = function ({ enabled, onClick, tooltip, className }) {
    return (
        <button
            className='wraparound-button'
            onClick={e => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={enable(`${className} sl-fa-button far fa-window-close`, enabled)} />
        </button>
    )
}

export const EditDifficultyButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <EditDifficultyIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const LockButton = ({ enabled, onClick, className, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-lock`, enabled)}
            data-id="lock-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const UnlockButton = ({ enabled, onClick, className, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-lock-open`, enabled)}
            data-id="unlock-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const PinButton = ({ enabled, onClick, className, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-thumbtack`, enabled)}
            data-id="pin-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const SquareNoteMarker = function ({ className, enabled }) {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-square`, enabled)}
            data-id="square-note-marker"
        />
    )
}

export const CircleNoteMarker = function ({ className, enabled }) {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-circle`, enabled)}
            data-id="circle-note-marker"
        />
    )
}

export const TriangleNoteMarker = function ({ className, enabled }) {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-play fa-rotate-270`, enabled)}
            data-id="triangle-note-marker"
        />
    )
}

export const ColorSelectorNoteMarker = function ({ className, enabled }) {
    return (
        <span
            className={enable(`${className} note-marker sl-fa-button fas fa-shapes`, enabled)}
            data-id="paintbrush-note-marker"
        />
    )
}

export const UnresolvedNoteMarker = function ({ className }) {
    return (
        <span
            className={enable(`${className} sl-play-button sl-fa-button far fa-circle`, true)}
            data-id="unresolved-note-marker"
        />
    )
}

export const ColorSquare = function ({ color, width, height }) {
    return (
        <span style={{
            display: 'inline-block',
            backgroundColor: color,
            border: '1px solid black',
            width: width,
            height: height,
            marginRight: '5px',
        }} />
    )
}

export const DragHandle = function ({ className, tooltip }) {
    return (
        <span
            className={`${className} fas fa-fw sl-fa-button fa-bars`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ClearNotificationsButton = function ({ className, onClick, tooltip }) {
    return (
        <span
            className={`${className} clickable fas fa-fw sl-fa-button fa-unlink`}
            data-id="clear-visible-notifications"
            data-toggle="tooltip"
            title={tooltip}
            onClick={(e) => onClick && onClick(e)}
        />
    )
}

export const DownArrowButton = function ({ className, onClick, tooltip }) {
    return (
        <button
            className='wraparound-button'
            onClick={(e) => onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span className={`${className} fas fa-fw fa-arrow-circle-down`} />
        </button>
    )    
}

export const ReferencesButton = function ({ className, tooltip, onClick }) {
    return (
        <button className='wraparound-button' onClick={e => onClick && onClick(e)}>
            <ReferencesIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const OKButton = function ({ enabled, onClick, buttonClassName, className, tooltip }) {
    return (
        <button
           className={`${buttonClassName} wraparound-button`}
            onClick={e => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span
                className={enable(`${className} sl-fa-button fas fa-check`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const CancelButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button
            className='wraparound-button'
            onClick={e => enabled && onClick && onClick(e)}
            data-toggle="tooltip"
            title={tooltip}
        >
            <span
                className={enable(`${className} sl-fa-button far fa-times-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export const CancelButtonWithLabel = function ({ enabled, onClick, buttonClassName, className, tooltip }) {
    return (
        <button className={`${buttonClassName} center-align-flex wraparound-button`} onClick={e => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${className} sl-fa-button far fa-times-circle`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
            {'<Esc>'}
        </button>
    )
}

export const ApproveButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <ApproveIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const PencilButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <PencilIcon className={enable(className, enabled )} tooltip={tooltip} />
        </button>
    )
}

export const CloneButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <CloneIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const GoBackButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <GoBackIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const GoForwardButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <GoForwardIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const EditButton = ({ enabled, onClick, className, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <EditIcon className={enable(className, enabled)} tooltip={tooltip} />
    </button>
)

export const DisapproveButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <DisapproveIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const ExpandButton = function ({ src, className, tooltip }) {
    return (
        <a href={src} target='_blank' aria-label={tooltip}>
            <span className='sr-only'>Opens in a new window</span>
            <ArrowsIcon aria-hidden='true' className={className} tooltip={tooltip} />
        </a>
    )
}

export const ConsultantButton = function ({ enabled, onClick, className, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
            <ConsultantIcon className={className} tooltip={tooltip} />
        </button>
    )
}

export const CircleButton = function ({ onClick, className, style, tooltip }) {
    return (
        <button className='wraparound-button' onClick={e => onClick && onClick(e)}>
            <CircleIcon {...{ className, style, tooltip }} />
        </button>
    )
}

export const DoublePlayButton = ({ onClick, className, tooltip, enabled, selectionPresent }) => {
    let _className = "wraparound-button"
    if (selectionPresent) _className += ' sl-selection-present'

    return (
        <button className={_className} onClick={e => enabled && onClick && onClick(e)}>
            <ForwardIcon className={enable(className, enabled)} tooltip={tooltip} />
        </button>
    )
}

export const UndoButton = ({ onClick, buttonClassName, className, tooltip, enabled }) => (
    <button className={`${buttonClassName} wraparound-button`} onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={`${className} sl-fa-button fas fa-undo-alt`}
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const AddButton = ({ className, enabled, onClick, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button far fa-plus-square`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const ClipboardButton = ({ buttonClassName, className, enabled, onClick, tooltip }) => (
    <button className={`${buttonClassName} wraparound-button`} onClick={e => enabled && onClick && onClick(e)}>
        <ClipboardIcon className={enable(className, enabled)} tooltip={tooltip} />
    </button>
)

export const SyncButton = ({ className, enabled, onClick, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-chevron-down`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export function EyeButton({ className, enabled, tooltip, value, setValue }) {
    if (value) {
        return (
            <button className='wraparound-button' onClick={(e) => enabled && setValue && setValue(false)}>
                <span
                    className={enable(`${className} sl-fa-button far fa-eye`, enabled)}
                    data-toggle="tooltip"
                    title={tooltip}
                />
            </button>
        )
    }

    return (
        <button className='wraparound-button' onClick={(e) => enabled && setValue && setValue(true)}>
            <span
                className={enable(`${className} sl-fa-button far fa-eye-slash`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export function StarButton({ onClick, className, tooltip, enabled, isHollow }) {
    let _className = className + (isHollow ? ' far' : ' fas')
    return (
        <button className={`${className}-button`} onClick={(e) => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${_className} fa-star`, enabled)}
                data-toggle="tooltip"
                title={tooltip}
            />
        </button>
    )
}

export function TransliterateButton({ onClick, buttonClassName, className, tooltip, enabled }) {
    return (
        <button className={`wraparound-button ${buttonClassName}`} onClick={(e) => enabled && onClick && onClick(e)}>
            <span
                className={enable(`${className}`, enabled)}
                data-toggle="tooltip"
                title={tooltip}>Ω/ℵ</span>
        </button>
    )
}

export function DontTransliterateButton({ onClick, buttonClassName, className, tooltip, enabled }) {
    return (
        <button className={`wraparound-button ${buttonClassName}`} onClick={(e) => enabled && onClick && onClick(e)}>
            <span
                className={enable(className, enabled)}
                data-toggle="tooltip"
                title={tooltip}>Z</span>
        </button>
    )
}
export const CheckboxTreeButton = ({ className, enabled, onClick, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-tasks`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const SearchButton = ({ className, enabled, onClick, tooltip }) => (
    <button className='wraparound-button' onClick={e => enabled && onClick && onClick(e)}>
        <span
            className={enable(`${className} sl-fa-button fas fa-search`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    </button>
)

export const ZoomInGlossButton = function ({ enabled, onClick }) {
    let tooltip = /* translator: important */ t`Make gloss boxes wider.`
    return (
        <span
            className={enable('sl-fa-button sl-zoom-in-gloss-button fas fa-plus', enabled)}
            onClick={() => enabled && onClick && onClick()}
            data-id="zoom-in-gloss-button"
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}

export const ZoomOutGlossButton = function ({ enabled, onClick }) {
    let tooltip = /* translator: important */ t`Make gloss boxes narrower.`
    return (
        <span
            className={enable('sl-fa-button sl-zoom-out-gloss-button fas fa-minus', enabled)}
            onClick={() => enabled && onClick && onClick()}
            data-id="zoom-out-gloss-button"
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}

export const FullScreenButton = function ({ enabled, onClick }) {
    const tooltip = t`Full screen. Shortcut:` + ` ${cmd}<Shift>F`
    return (
        <span
            className={enable('sl-fa-button sl-full-screen-button fas fa-expand sl-full-screen-button', enabled)}
            onClick={() => enabled && onClick && onClick()}
            data-id="full-screen-button"
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}

export const FullScreenOffButton = function ({ enabled, onClick }) {
    const tooltip = t`Turn off full screen. Shortcut: Esc.`
    return (
        <span
            className={enable('sl-fa-button sl-full-screen-button fas fa-compress sl-full-screen-off-button', enabled)}
            onClick={() => enabled && onClick && onClick()}
            data-id="full-screen-button"
            data-toggle="tooltip"
            title={tooltip} >
        </span>
    )
}
