import { t } from 'ttag'
import { displayInfo } from '../components/utils/DynamicErrors'
import { fmt } from '../components/utils/Fmt'
import * as DateUtilities from './DateUtilities'
// import { PassageVideo } from './ProjectModels'

const log = require('debug')('sltt:FileDateParser') 

export class FileDateParser {
    existingDates: Date[] = []
    file: File

    constructor(existingVideos: any[], file: File) {
        this.existingDates = existingVideos.map(e => new Date(e.creationDate))
        this.file = file
    }

    getCreationDate() {
        let creationDate = this.getCreationDateFromFile()
        return DateUtilities.adjustForDuplicates(this.existingDates, creationDate)
    }

    private getCreationDateFromFile() {
        let { file } = this
        let isoDateFormat = file.name.match(/(\d\d\d\d)[-]?(\d\d)[-]?(\d\d)/)
        if (isoDateFormat) {
            let year = parseInt(isoDateFormat[1])
            let month = parseInt(isoDateFormat[2])
            let day = parseInt(isoDateFormat[3])
            log('getCreationDateFromFile', fmt({year, month, day}))

            let displayedDay = day.toString().padStart(2, '0')
            let displayedMonth = month.toString().padStart(2, '0')
    
            // User probably switched month and day
            if (month > 12) {
                displayInfo(t`${year}-${displayedMonth}-${displayedDay} is not a valid YYYY-MM-DD date.
                        Using ${year}-${displayedDay}-${displayedMonth}. If this date is not correct
                        1) delete the video, 2) change the file name to include the
                        correct date, 3) drag and drop the video again.`)
                let tmp = day
                day = month
                month = tmp
            }

            month -= 1  // months are 0-based

            let creationDate = new Date(year, month, day, 0, 0)
            return creationDate
        }
        log('Filename did not contain a date that we could parse. Using file modification date instead')

        return new Date(file.lastModified)
    }
}