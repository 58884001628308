import React, { FC, useState, useRef, useEffect } from 'react'
import { jt, t } from 'ttag'

import './EnhancedResources.css'
//import { displayError, systemError } from '../utils/Errors'
import Modal from 'react-bootstrap/lib/Modal'
import { MarbleLemmas, MarbleLemma, LexMeaning } from '../../scrRefs/Lemmas'
import { Root } from '../../models3/Root'
import { ProjectReference } from '../../models3/ProjectReferences'
import { displayError } from '../utils/Errors'
import Player from '../utils/Player'
import ERTermView from './ERTermView'
import { ProjectReferenceVideoPlayer } from './ProjectReferenceVideoPlayer'
import { fmt } from '../utils/Fmt'
import { observer } from 'mobx-react'
import { ProjectTerm, PassageVideo, Passage } from '../../models3/ProjectModels'
import { BiblicalSignsIcon } from '../utils/Icons'
import { IKeyTermRecording, KeyTermGlossRecorder, startRecordingGloss } from './KeyTermGlosses'
import { GlossReference } from '../../models3/GlossReferences'
import { useResumableRecording } from '../utils/useResumableRecording'

const log = require('debug')('sltt:ERTermModal')

/*
          +-------------+
          | MarbleLemma |
          |             |
termId ===| id          |    +--------------+    +-------------+    Portion (isGlossary === true)
          | meanings    |==+ | LexMeaning   |    | ProjectTerm |     
          |             |    |              |    |             |    +----------+
          |             |    | lexicalLink  |====| lexicalLink |    | Passage  |
          |             |    | definitions  |    | isKeyTerm   |    |          |
          |             |    | references   |    | glosses     |==+ | name     |
          +-------------+    +--------------+    +-------------+    +----------+
 */

interface IERTermModal {
    rt: Root,
    termId: string, // MarbleLemma.id, e.g. SDBG:ἄνθρωπος:000007 (the 7th sense of ἄνθρωπος)
    setTermId: (resource: string) => void
}

const ERTermModal: FC<IERTermModal> = observer(({ rt, termId, setTermId }) => {
    // Playing a passage video from the Glossary portion
    let [signPlaying, setSignPlaying] = useState<PassageVideo | null>(null)
    
    let [recording, setRecording] = useState<IKeyTermRecording|null>(null)

    let [glossShowing, setGlossShowing] = useState('')

    // Currently select meaning, i.e. sense

    let [projectReference, setProjectReference] = useState<ProjectReference | null>(null)

    // Used when playing the part of a passage video corresponding to a gloss
    // GlossReference { passage, gloss: PassageGloss, passageVideo }
    let [glossReference, playGlossReference] = useState<GlossReference | null>(null)
    
    let lemma: MarbleLemma | undefined = MarbleLemmas.get(termId, true)

    let _meaning = lemma?.meanings.find(m => m?.lexicalLink === termId)
    let [meaning, setMeaning] = useState<LexMeaning | null>(_meaning ?? null)

    const { resumableRecording } = useResumableRecording()

    console.groupCollapsed('ERTermModal')
    log('ERTermModal', fmt({termId, lemma}))
    console.groupEnd()

    if (!lemma) { return null }

    rt.projectReferences.update(rt) // expensive, memoize?

    return (
        <Modal style={{ top: '1%' } }
            onKeyDown={(event) => {
                // We do this so that keystrokes inside this dialog do not get passed on to the main window
                // causing unwanted reactions there.
                // Unfortunately, for reasons I don't understand and don't currently know how to fix,
                // there are some actions, e.g. after editing the text of a gloss, that cause the body (for the whole page)
                // to become the activeElement. You can see this by setting the "live expression" box in the Chrome console
                // to "document.activeElement". When this happens things like space keys still get passed to the
                // main window causing it to start playing even tho the modal dialog is still open. Sigh.
                
                // We stop propagation of keydown events so that they do not effect the main window.
                // However if we are recording we must let them pass through to the resumable recorder.
                if (!resumableRecording) {
                    event.stopPropagation()
                }

            } }
            bsSize="large"
            show={true}
            // onEnter={() => rt.termModalOpen = true}
            onHide={() => { setTermId('') }}
        >
            <Modal.Header closeButton>
                <h3><BiblicalSignsIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Biblical Signs`} />&nbsp;&nbsp;{/* translator: important */ t`Biblical Term Viewer`}</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="er-term-modal-body">
                    {recording &&
                        <KeyTermGlossRecorder {...{ rt, recording, setRecording }} />}
                    {signPlaying && 
                        <ERPlaySign {...{ signPlaying, setSignPlaying}} />}
                    {projectReference && 
                        <ProjectReferenceVideoPlayer {...{ rt, projectReference, setProjectReference }} />}
                    {!signPlaying && !recording && !projectReference && !glossReference &&
                        <ERTermView {...{ rt, lemma, setProjectReference, 
                            meaning, setMeaning,
                            termId, setTermId, 
                            setRecording, 
                            setSignPlaying, 
                            playGlossReference,
                            setGlossShowing
                        }} />}
                </div>
            </Modal.Body>
        </Modal>
    )
})

interface IERPlaySign {
    signPlaying: PassageVideo,
    setSignPlaying: (sign: PassageVideo | null) => void,
    startTime?: number,
    stopTime?: number,
}

export const ERPlaySign: FC<IERPlaySign> = ({ signPlaying, setSignPlaying }) => {
    log(`playSign`)

    let startTime: number | undefined
    let stopTime: number | undefined

    if (signPlaying.segments.length === 1) {
        let segment = signPlaying.segments[0]
        if (segment.endPosition) {
            startTime = segment.position
            stopTime = segment.endPosition
        }
    }

    log('ERPlaySign', fmt({ startTime, stopTime, signPlaying: signPlaying.dbg(null, 's') }))

    return (
        <div className="er-video-element">
            <Player
                className="sign-player"
                {...{startTime, stopTime}}
                videoUrl={signPlaying.url}
                autoClose={false}
                onEnded={() => setSignPlaying(null)} />
        </div>
    )
}

export default ERTermModal
