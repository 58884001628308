/*
    Show all database keys for current project.
    Click on a key to see coressponding doc.
    Filter by entering a key prefix in the text box.
    Optionally delete all keys matching a prefix, BE CAREFUL! (but it won't let you delete w/o a filter value)
 */


import React, { useEffect, useState } from 'react'
import _ from 'underscore'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import { displayError, systemError } from '../utils/Errors'
import '../utils/Buttons.css'
import LoadingMessage from '../utils/InitializationMessage'
import { fmt } from '../utils/Fmt'
import { _LevelupDB } from '../../models3/_LevelupDB'

const log = require('debug')('sltt:DatabaseEditor') 

interface IRowView {
    setDetails: (details: string) => void,
    setRow: (row: any) => void,
    row: any,
    deleteOne: (row: any) => Promise<void>,
}

function RowView(props: IRowView) {
    let { setDetails, setRow, row, deleteOne } = props
    let className = row.removed ? 'db-row-removed' : ''

    return ( 
        <p> 
            <span className={className} 
                  onClick={() => {
                        setRow(row)
                        setDetails(JSON.stringify(row, null, 2))
                    }}>
                {row._id}
            </span>
            &nbsp;
            {!row.removed && 
                <span className='fas fa-trash-alt'
                    style={ {
                        fontSize: '170%',
                        paddingLeft: '10px',
                    } }
                    onClick={() => {
                        deleteOne(row)
                        .catch(displayError)

                    }}>
                </span>
            }
        </p> 
    )
}

// interface IVcrView {
//     setDetails: (details: string) => void,
//     vcr: VideoCacheRecord,
// }

// function VcrView(props: IVcrView) {
//     let { setDetails, vcr } = props

//     return( 
//         <p>
//             <span 
//                 onClick={() => setDetails(JSON.stringify(vcr, null, 2))}>
//                 {vcr._id} {vcr.downloaded ? '*' : ''}{vcr.uploaded ? '^' : ''}
//             </span>
//         </p>
//     )
// }

// !! Include portion/passage name in primary displayed info
// allow filter by name
// make item name visually clickable

interface IDatabaseEditor {
    rt: Root,
}

function DatabaseEditor({ rt }: IDatabaseEditor) {
    const [allRows, setAllRows] = useState<any[]>([])
    const [details, setDetails] = useState('')
    const [row, setRow] = useState<any>(undefined)
    const [filter, setFilter] = useState(rt.passage?._id || '')

    useEffect(() => {
        log('rt changed', rt.name)

        // Default to viewing currently selected passage
        rt.initialize()
            .then(() => {
                if (rt.passage) {
                    setFilter(rt.passage._id)
                }
                setDetails('')
                return load()
            })
            .catch(systemError)
    }, [rt])

    // Read all rows from database
    async function load() {
        let { db } = rt.project
        log('load', rt.name, db)

        let _db: _LevelupDB = db as any

        let _rows: any[] = await _db.db.readDBRecords()
        _rows = _rows.map((rec: any) => rec.doc)

        log(`db rows read = ${_rows.length}`) 
        setAllRows(_rows.map((row, key) => Object.assign({key}, row)))
    }

    async function deleteOne(row: any) {
        let { db } = rt.project
        log('deleteOne', JSON.stringify(row, null, 4))

        let { _id, creator, creationDate } = row
        let doc: any = { _id, creator, creationDate }
        doc.modDate = db.getDate()

        await db.delete(doc)
        load().catch(displayError)
    }

    function acceptRow(_row: any) {
        log('acceptRow', JSON.stringify(_row, null, 4))
        let _db: any = rt.project.db
        _db.acceptor.accept(_row)
    }

    function deleteItemsMatchingFilter() {
        let { db } = rt.project

        // Don't allow deleting everything!!!
        if (!filter || filter.length === 0) return

        db.delete(filter, filter + '\uffff')
            .then(() => {
                load()
                    .catch(displayError)

                setFilter('')
            })
            .catch(displayError)
    }

    let { initialized, loadingMessage } = rt
    if (!initialized) {
        return <LoadingMessage {...{ loadingMessage }} />
    }

    let rows = allRows.filter(row => !filter || JSON.stringify(row).includes(filter))

    return (
        <div>
            <input
                autoFocus
                value={filter}
                onChange={e => setFilter(e.target.value)} /> &nbsp;&nbsp;&nbsp;&nbsp;

            <button
                type="button"
                className="delete-db-keys btn btn-primary btn-warning"
                onClick={deleteItemsMatchingFilter} >
                {t`Delete!!!`}
            </button>

            <div className="app-database">
                <div className="app-database-list">
                    {rows.map(row =>
                        <RowView key={row.key}
                            row={row}
                            setDetails={setDetails}
                            setRow={setRow}
                            deleteOne={deleteOne} />)
                    }
                    {/* <div>VideoCacheRecords</div>
                    {this.vcrs.map(vcr => (
                        <VcrView key={vcr._id}
                            vcr={vcr}
                            setDetails={(details: string) => this.details = details} />
                    ))} */}
                </div>
                <div className="app-database-details">
                    <pre> {details} </pre>
                    <button
                        type="button"
                        className="delete-db-keys btn btn-primary btn-warning"
                        onClick={() => acceptRow(row)} >
                        {t`Accept`}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default observer(DatabaseEditor)