import React, { Component } from 'react'
//import _ from 'underscore'
//import {extendObservable} from 'mobx'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import TextInput from '../utils/TextInput'
import { displayError } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { Portion } from '../../models3/ProjectModels'

// Edit name (rename) existing portion
// It must be a valid portion name and not create a duplicate portion name

const log = require('debug')('sltt:PortionEditor')

interface IPortionEditor {
    rt: Root,
    portion: Portion,
    setEditingPortionName: (editing: boolean) => void,
}

class PortionNameEditor extends Component<IPortionEditor> {

    render() {
        let { portion, setEditingPortionName } = this.props
        return (
            <div data-id='portion-name-editor'>
                <TextInput
                    message={t`Type Enter to change name or Esc to cancel.`}
                    initialValue={portion.name}
                    validate={this.validate.bind(this)}
                    _onEscape={() => setEditingPortionName(false)}
                    _onEnter={this.onEnter.bind(this)} />
            </div>
        )
    }

    onEnter(newName: string) { 
        newName = newName.trim()

        let { portion, setEditingPortionName } = this.props
        if (this.validate(newName)) {
            setEditingPortionName(false)
            return
        }

        portion.setName(newName)
            .then(() => setEditingPortionName(false))
            .catch((err: any) => {
                displayError(err)
                setEditingPortionName(false)
            })
    }

    validate(newName: string) {
        newName = newName.trim()

        let { rt, portion } = this.props

        if (newName === '') {
            return t`Empty name`
        }

        if (newName === portion.name) {
            return ''
        }

        if (rt.project.portions.some(portion => portion.name === newName)) {
            return t`Duplicate name`
        }

        return ''
    }

}

export default observer(PortionNameEditor)