import React, { FunctionComponent, Component } from 'react'
import { observer } from 'mobx-react'
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts'
import { t } from 'ttag'

import { expectedCompletionDate, getGraphData } from './ProgressUtils'
import './ProgressGraph.css'
import { Root, ProgressSelection } from '../../../models3/Root'
import { passagesMatchingProgressSelection, ProjectDataFilter } from '../StatusBoardEditor'
import { Theme } from '../../utils/LocalStorage'

const log = require('debug')('sltt:ProgressGraph')

interface IProgressGraphPage {
    rt: Root,
    selection: ProgressSelection | string,
    setSelection: (selection: ProgressSelection | string) => void,
}

export class ProgressGraphPage extends Component<IProgressGraphPage> {
    render() {
        let { rt, selection, setSelection } = this.props
        let { project, uiLanguage } = rt
        let passages = passagesMatchingProgressSelection(rt, selection)

        let plan = project.plans[0]
        let data = plan ? getGraphData(passages, plan, uiLanguage) : []

        const theme = Theme.currentTheme()

        return (
            <div>
                <ProgressGraphHeader {...{rt, selection, setSelection}} />
                <div className="progress-graph-separator" />
                {data.length === 0
                    ? (<>
                        <div className="progress-graph-dropdown" />
                        {t`No data available`}
                    </>)
                    : (<>
                        <div className="progress-graph-table">
                            <ProgressGraph data={data} theme={theme} />
                        </div>
                        <div className="progress-graph-separator" />
                        <ProgressGraphFooter {...{ rt, data }} />
                    </>)
                }
            </div>
        )
    }
}

export default observer(ProgressGraphPage)

interface IProgressGraphHeader {
    rt: Root,
    selection: ProgressSelection | string,
    setSelection: (selection: ProgressSelection | string) => void,
}

const ProgressGraphHeader: FunctionComponent<IProgressGraphHeader> = 
    ({ rt, selection, setSelection }) => {

    return (
        <div className="progress-graph-header">
            <h4 className="progress-graph-project-name">
                <b>{rt.project.displayName}</b>
            </h4>
            <div className="progress-graph-header-item">
                {t`Report of`}
                <ProjectDataFilter {...{ rt, selection, setSelection }} />
            </div>
        </div>
    )
}

interface IProgressGraphFooter {
    rt: Root,
    data: {name: string, date: Date, percent: number}[],
}

const ProgressGraphFooter: FunctionComponent<IProgressGraphFooter> = ({ rt, data }) => {
    let entries = data.map(entry => {
        let { date, percent } = entry
        return {
            date,
            percent,
        }
    })
    let sortedEntries = [...entries].sort((a, b) => a.date.getTime() - b.date.getTime())
    if (sortedEntries[sortedEntries.length - 1].percent === 100) {
        return (
            <div>
                {t`Already completed`}
            </div>
        )
    }
    let { dateFormatter } = rt
    let completionDate = expectedCompletionDate(sortedEntries, new Date())
    let formattedCompletionDate = ''
    if (completionDate !== undefined) {
        formattedCompletionDate = dateFormatter.format(completionDate)
    }
    return (
        <div>
            {completionDate !== undefined ? (
                t`Projected completion: ${formattedCompletionDate}`
            ) : (
                t`Projected completion N/A`
            )}
        </div>
    )
}

interface IProgressGraph {
    data: { name: string, date: Date, percent: number }[],
    theme: string,
}

export const ProgressGraph: FunctionComponent<IProgressGraph> = ({ data, theme }) => {
    const entries = data.map(entry => {
        let { name, percent } = entry
        return {
            name,
            percent,
        }
    })
    const axisStroke = theme === 'light' ? 'gray' : 'lightgray'
    const numberOfEntries = entries.length
    const localizedDateOfFirstEntry = data[0]?.name || ''
    let interval: number | undefined = undefined
    if (numberOfEntries && localizedDateOfFirstEntry) {
        if (localizedDateOfFirstEntry.length > 14) {  // Some languages have longer translations for dates
            interval = Math.round(numberOfEntries / 7)  // If the date is too long, we will show 7 dates only
        } else {
            interval = Math.round(numberOfEntries / 10) // Otherwise, 10 dates fit in the graph
        }
    }
    return (
        <div>
            <LineChart
                width={1000}
                height={400}
                data={entries}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <Line type="linear" dataKey="percent" stroke="#82ca9d" isAnimationActive={false} />
                <XAxis dataKey="name" stroke={axisStroke} interval={interval} />
                <YAxis domain={[0, 100]} stroke={axisStroke} />
                <Tooltip content={<CustomTooltip />} />  
            </LineChart>
        </div>
    )
}

interface TooltipProps {
    active?: boolean
    payload?: { payload: { name: number, percent: number } }[]
    label?: string
}

const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const percentage = payload[0].payload.percent.toFixed(1)
        const date = payload[0].payload.name
        const tooltip = `${percentage}% (${date})`
        return (
            <span style={{ margin: 10, color: '#82ca9d', fontWeight: 'bold' }}>{tooltip}</span>
        )
    }
    return null
}
