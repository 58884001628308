import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import './ServiceStatus.css'
import { getIsAppOnlineOrDefault, MESSAGE_IS_APP_ONLINE_STATUS_UPDATED } from '../utils/ServiceStatus'

let log = require('debug')('sltt:ServiceStatus')

interface IServiceStatus { }

export const ServiceStatus: FC<IServiceStatus> = observer(({ }) => {
    const [isOnline, setIsOnline] = useState(getIsAppOnlineOrDefault(navigator.onLine))

    useEffect(() => {
        const syncOnlineStatus = () => {
            setIsOnline(getIsAppOnlineOrDefault(navigator.onLine))
        }

        // Initial sync
        syncOnlineStatus()

        window.addEventListener(MESSAGE_IS_APP_ONLINE_STATUS_UPDATED, syncOnlineStatus)

        return () => {
            window.removeEventListener(MESSAGE_IS_APP_ONLINE_STATUS_UPDATED, syncOnlineStatus)
        }
    }, [])

    // If user is online, do nothing at this time
    // In the future we might turn this into a button to turn the app online/offline
    if (isOnline) {
        return null
    }

    // since user is offline, display the icon
    return (
        <span className={`service-status-icon service-status-offline`} title={t`Your device is offline`}>
            <i className={`fa fa-solid fa-globe`} />
            <span className="offline-indicator">
                <i className={`fa fa-solid fa-slash fa-rotate-90`} />
            </span>
        </span>
    )
})
