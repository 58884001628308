// Show a horizontal timeline with a vertical bar indicating current position in video.
// Allow user to set video position by clicking or dragging.

import React, { Component } from 'react'
import {observer} from 'mobx-react'

import { IVideoPosition } from './VideoPositionBar'
import { Theme } from '../utils/LocalStorage'

// make adjustment display optional

const log = require('debug')('sltt:ThinVideoPositionBar')

interface IThinVideoPositionBar {
    videoPosition: IVideoPosition,
}

class ThinVideoPositionBar extends Component<IThinVideoPositionBar> {
    private width = 640
    private height = 10
    private mousedown = false
    private canvas: any

    render() {
        let { videoPosition } = this.props
        let { width, height } = this
        // Necessary to force re-render when current time changes to show video position cursor
        // eslint-disable-next-line
        let { currentTime, passage, passageVideo, timelineZoom } = videoPosition

        // Don't need to show the thin timeline if we are not zoomed
        if (timelineZoom <= 1) return null

        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes
        passage?.videoBeingCompressed
        // log('*** render', passageVideo && passageVideo._rev)

        //let pvs = (passageVideo && passageVideo.segments) || []
        //log('positions', pvs.map(pv => pv.position))

        // Check to see if segment time have changed and if so reset them
        passage && passageVideo && passageVideo.setSegmentTimes(passage)

        return (
            <div data-id='thin-video-positionbar'>
                <canvas
                    className="thin-video-positionbar"
                    width={width}
                    height={height}
                    ref={c => { this.canvas = c }}
                    onMouseUp={this.mouseup.bind(this)}
                    onMouseDown={this._mousedown.bind(this)}
                    onMouseMove={this.mousemove.bind(this)}>
                </canvas>
            </div>
        )
    }

    setCurrentTime(x: number) {
        let { duration, resetCurrentTime, passage } = this.props.videoPosition
        let { width } = this

        if (!passage?.videoBeingCompressed) {
            let ct = (x * duration) / width
            //console.log('setCurrentTime', x, rt.duration, w, ct)
            resetCurrentTime(ct)
        }
    }

    displayXToModelX(e: React.MouseEvent) {
        let boundingRect = e.currentTarget.getBoundingClientRect()
        let x = e.clientX - boundingRect.left
        return x * (this.canvas.width / this.canvas.offsetWidth)
    }

    // If I call this "mousedown" it never gets called! why?
    _mousedown(e: any) {
        let { passage } = this.props.videoPosition
        if (!passage?.videoBeingCompressed) {
            let x = this.displayXToModelX(e)
            console.log("mousedown", x)
            this.setCurrentTime(x)
            this.mousedown = true
        }
    }

    mouseup(e: any) {
        //console.log("mouseup")
        this.mousedown = false
    }

    mousemove(e: any) {
        //console.log("mousemove")
        let { passage } = this.props.videoPosition
        if (this.mousedown && !passage?.videoBeingCompressed) { 
            let x = this.displayXToModelX(e)
            this.setCurrentTime(x)
        }
    }

    updateCanvas() {
        let { canvas } = this
        if (!canvas) return

        let { videoPosition } = this.props
        let { width, height } = this
        let { currentTime, duration, passage, passageVideo, timelineStart, timelineEnd } = videoPosition
        if (!passage || !passageVideo) return

        let m = height / 2

        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, width, height)

        ctx.lineWidth = .5
        ctx.strokeStyle = Theme.getColor('black', 'white')
        ctx.beginPath()
        ctx.moveTo(0, m)
        ctx.lineTo(width, m)
        ctx.stroke()

        let startPosition = (timelineStart / duration) * width
        startPosition = Math.max(5, startPosition)

        ctx.lineWidth = 2
        ctx.strokeStyle = Theme.getColor('purple', '#E0B0FF')
        ctx.beginPath()
        ctx.moveTo(startPosition - 15, m - 15)
        ctx.lineTo(startPosition, m)
        // ctx.lineTo(startPosition - 5, m + 5)
        ctx.stroke()

        let endPosition = (timelineEnd / duration) * width
        
        ctx.lineWidth = 2
        ctx.strokeStyle = Theme.getColor('purple', '#E0B0FF')
        ctx.beginPath()
        ctx.moveTo(endPosition + 15, m - 15)
        ctx.lineTo(endPosition, m)
        // ctx.lineTo(endPosition + 5, m + 5)
        ctx.stroke()

        // Draw current position cursor
        let position = (currentTime / duration) * width
        if (position < 1) position = 1  // if we draw cursor at origin we can only see half of it

        ctx.lineWidth = 2
        ctx.strokeStyle = Theme.getColor('black', 'white')
        ctx.beginPath()
        ctx.moveTo(position, m - 5)
        ctx.lineTo(position, m + 5)
        ctx.stroke()
    }

  componentDidMount() { this.updateCanvas() }

  componentDidUpdate() {this.updateCanvas() }

}

export default observer(ThinVideoPositionBar)
