import { ImageMetadata } from "./ImageMetadata"
import { RefRange } from "../scrRefs/RefRange"
import { Project } from "../models3/ProjectModels"
import API from "../models3/API"
import { fmt } from "../components/utils/Fmt"
import _ from "underscore"
import { displayError } from "../components/utils/Errors"

const log = require('debug')('sltt:ProjectImages')

/**
 * This class represents the data stored in the DynamoDB table sltt-project-images-prd.
 * There is one entry for each image uploaded by a project.
 */
class RemoteProjectImageInfo {
    public projectAndFilename = ''   // project/filename
    public title? = ''
    public shared = false   // True if consultant has request that other projects see this image.
    public references = ''  // 011002001-011002005,011002007-011002009
    public copyright? = ''
    public url = ''         // 'TESTnm/project-images/full/upload 3.png-1', name of bucket in sltt-videos-prd
    sharedRequested = false // not used but back end code expects to see it

    constructor(item?: any) {
        item = item ?? {}

        this.projectAndFilename = item.projectAndFilename ?? ''
        this.title = item.title ?? ''
        this.shared = item.shared ?? false
        this.references = item.references ?? ''
        this.copyright = item.copyright ?? ''
        this.url = item.url ?? ''
    }

    get refRanges() {
        let _refRanges = this.references.split(',').map(e => {
            let [start, end] = e.split('-')
            return new RefRange(start, end ?? start)
        })

        return _refRanges
    }

    toImageMetadata() {
        let { projectAndFilename, title, copyright, shared, url, refRanges } = this
        
        let image = new ImageMetadata()

        let [project, fileName] = projectAndFilename.split('/')
        image.id = projectAndFilename
        image.definitions = [{
            languageCode: 'en',
            title: title ?? '',
            description: '',
        }]
        image.copyright = copyright ?? ''
        image.references = refRanges
        image.project = project
        image.shared = shared
        image.thumbnail = url
        image.path = project
        image.isProjectImage = true
        image.fileName = fileName

        image.setSortKey()

        return image
    }

    setFromImageMetadata(image: ImageMetadata) {
        let { definitions, fileName, copyright, references, project, shared, thumbnail } = image
        
        this.references = references.map(e => e.startRef !== e.endRef ? `${e.startRef}-${e.endRef}` : e.startRef).join(',')

        this.projectAndFilename = `${project}/${fileName}`
        this.title = definitions[0].title || undefined
        this.shared = shared
        this.copyright = copyright || undefined
        this.url = thumbnail
    }
}

export class ProjectImages {
    private static imagesByProjectBbbccc: { [project: string]: ImageMetadata[] } = {}

    static async fetchInfo(projectName: string, bbbccc: string): Promise<ImageMetadata[]>
    {
        let { imagesByProjectBbbccc } = ProjectImages

        let images = imagesByProjectBbbccc[projectName + bbbccc]
        if (!images) {
            try {
                images = await ProjectImages.getProjectImages(projectName, bbbccc)
                imagesByProjectBbbccc[projectName + bbbccc] = images
            } catch (error) {
                return []
            }
        }

        let _bbbcccs = [new RefRange(bbbccc, bbbccc)]

        return images.filter(image => image.references.some(refRange => refRange.overlaps(_bbbcccs)))
    }

    /** Get ImageMetadata from DynamoDB for some or all project images.
     * If bbbcccvvv is undefined, get all project images.
     * Otherwise get just those images matching
     * @throws Throws if non-200 HTTP response.
     */
    static async getProjectImages(projectName: string, bbbccc: string): Promise<ImageMetadata[]> {
        // WARNING currently we must include bbbccc because the code path without it
        // has a bug which does not return shared images from other projects.
        let url = `${API.getHostUrl()}/images/?project=${projectName}&bbbcccvvv=${bbbccc}`
        if (bbbccc === '') {
            url = `${API.getHostUrl()}/allImages/?project=${projectName}`
        }

        let options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + API.id_token
            },
        }

        let response = await fetch(url, options)
        if (!response.ok) {
            throw Error(`${response.url}: ${response.status} - ${response.statusText}`)
        }
        let infos = await response.json()

        let images: ImageMetadata[] = infos.map((info: any) => (new RemoteProjectImageInfo(info)).toImageMetadata())

        log('getProjectImages', fmt({url, images}))
        return images
    }

    static async deleteProjectImage(image: ImageMetadata) {
        await API.deleteProjectImageMetadata(image)

        ProjectImages.clearCache()
    }

    /** Upload a project image data and metadata.
     * @throws Throws if non-200 HTTP response or Project.copyFileToVideoCache undefined
     */
    static async putProjectImageDataAndMetadata(file: File, image: ImageMetadata) {
        let { path, fileName } = image
        image.thumbnail = await this.putProjectImageDataToS3(file, path, fileName)
        await ProjectImages.putProjectImageMetadataToDynamoDB(image)
    }

    static async putProjectImageMetadataToDynamoDB(image: ImageMetadata) {
        if (!image.thumbnail) {
            displayError('Something went wrong. No url.')
            return
        }

        let info = new RemoteProjectImageInfo()
        info.setFromImageMetadata(image)
        
        await API.putProjectImageMetadata(info)

        ProjectImages.clearCache()
    }

    private static async putProjectImageDataToS3(file: File, project: string, fileName: string) {
        let s3Path = `${project}/project-images/full/${fileName}`
        
        if (!Project.copyFileToVideoCache) throw Error('Project.copyFileToVideoCache not set')
        
        return await Project.copyFileToVideoCache(file, s3Path, undefined, true)
    }

    static clearCache() {
        ProjectImages.imagesByProjectBbbccc = {}
    }
   
}

let _window: any = window
_window.ProjectImages = ProjectImages