import React, { Component } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { t } from 'ttag'

import { AppRoot } from '../../models3/AppRoot'
import { displayError } from '../utils/Errors'
import { RootConsumer } from './RootContext'

interface IProjectDeleter {
    appRoot: AppRoot,
    projectName: string,
}

class ProjectDeleter extends Component<IProjectDeleter> {

    render() {
        return (
            <RootConsumer>
                {rt => (
                    <span className="delete-project-button"
                        data-toggle="tooltip"
                        title={t`Delete project.`}
                        onClick={this.onDelete.bind(this)} >
                            <i className="fas fa-fw fa-trash-alt"></i>
                    </span>
                )}
            </RootConsumer>
        )
    }

    confirmDeletion(doDeletion: () => void) {
        // No need to confirm, if no video has been recorded yet
        let { projectName } = this.props

        confirmAlert({
            title: t`DELETE ${projectName} project!!!`,
            message: t`We move it to sltt_deleted_projects directory on the server so in theory we can get it back.`,
            confirmLabel: t`Delete!!!`,
            cancelLabel: t`Keep.`,
            onConfirm: doDeletion,
            onCancel: () => { },
        })
    }

    onDelete(e: any) {
        e.preventDefault()
        let { projectName } = this.props

        this.confirmDeletion(() => {
            this.props.appRoot.deleteProject(projectName)
                .catch(displayError)
        })
    }

}

export default ProjectDeleter