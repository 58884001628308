import { fmt } from "../components/utils/Fmt"

const log = require('debug')('sltt:ELANReader') 


async function getText(file: File): Promise<string> {
    let reader = new FileReader()
    return new Promise(resolve => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsText(file)
    })
}

export class EAFItem {
    constructor(
        public position: number, 
        public endPosition: number, 
        public text: string) {}
}

export class EAFTier {
    constructor(
        public id: string,
        public items: EAFItem[],
    ) {}
}

function elementByName(root: Element, name: string) {
    let elements = [...root.getElementsByTagName(name)]
    if (elements.length !== 1) {
        debugger
        throw Error()
    }

    return elements[0]
}

function getAttribute(element: Element, name: string) {
    let value = element.getAttribute(name)
    if (value === null) {
        throw Error(`Attribute missing: ${name}`)
    }

    return value
}

type Timeslots = Map<string, number>

function getTimeslots(doc: Document) {
    let timeOrder = elementByName(doc.documentElement, 'TIME_ORDER')
    let slots = [...timeOrder.getElementsByTagName('TIME_SLOT')]
    if (slots.length === 0) {
        throw Error('No TIME_SLOTs present')
    }

    let timeslots  = new Map<string, number>()
    slots.forEach(slot => {
        let id = getAttribute(slot, 'TIME_SLOT_ID')
        let value = getAttribute(slot, 'TIME_VALUE')

        timeslots.set(id, parseInt(value)/1000)
    })

    return timeslots
}

function convertEAFAnnotation(timeslots: Timeslots, annotation: Element) {
    let alignable = elementByName(annotation, 'ALIGNABLE_ANNOTATION')
    let ts1 = getAttribute(alignable, 'TIME_SLOT_REF1')
    let ts2 = getAttribute(alignable, 'TIME_SLOT_REF2')
    
    let position = timeslots.get(ts1)
    if (position === undefined) throw Error(`Timeslot missing: ${ts1}`)
    let endPosition = timeslots.get(ts2)
    if (endPosition === undefined) throw Error(`Timeslot missing: ${ts2}`)

    let text = elementByName(annotation, 'ANNOTATION_VALUE').textContent ?? ''

    // log('convertEAFAnnotation', fmt({position, endPosition, text}))
    return new EAFItem(position, endPosition, text)
}

function convertEAFTier(timeslots: Timeslots, tier: Element) {
    let id = getAttribute(tier, 'TIER_ID')

    let annotations = [...tier.getElementsByTagName('ANNOTATION')]
    log('convertEAFTier', fmt({id, annnotations: annotations.length}))
    
    return new EAFTier(
        id,
        annotations.map(annotation => convertEAFAnnotation(timeslots, annotation)))
}

function convertEAFDoc(doc: Document) {
    let timeslots = getTimeslots(doc)

    let tiers = [...doc.getElementsByTagName('TIER')]

    return tiers.map(tier => convertEAFTier(timeslots, tier))
}

/*
 * Read .eaf file and convert it to tier objects.
 * Will throw an Error if file cannot be read and parsed.
 */
export async function readEAFFile(file: File): Promise<EAFTier[]> {
    log('readEAFFile', file.name)

    let xmlText = await getText(file)

    let domParser = new DOMParser()
    let doc = domParser.parseFromString(xmlText, "text/xml")

    return convertEAFDoc(doc)
}
