import React, { FC, useContext, useState } from 'react'
import { observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import { TRLPortion, TRLResource } from '../../../models3/TRLModel'
import { t } from 'ttag'
import { CancelButton } from '../../utils/Buttons'
import { TRLDownloadThumbnailVideoPlayer } from './TRLDownloadThumbnailVideoPlayer'
import { getBestTitle } from '../trlUtils'
import { RootContext } from '../../app/RootContext'

interface IPortionBrowseItem {
    portion: TRLPortion
    preloadedThumbnailUrl?: string
    selectedPortion: TRLPortion | undefined
    onPortionClick: (portion: TRLPortion | undefined) => void
    showTitles: boolean
    selectedResource: TRLResource
    portionPlaying: boolean
    onPortionPlaying: (playing: boolean) => void
}
export const PortionBrowseItem: FC<IPortionBrowseItem> = observer((
    { portion, preloadedThumbnailUrl, onPortionClick, showTitles, portionPlaying, onPortionPlaying, selectedPortion, selectedResource }
) => {
    const [loadedThumbnailUrl, setLoadedThumbnailUrl] = useState<string>()
    const rt = useContext(RootContext)
    const uiLanguage = rt?.uiLanguage ?? 'en'

    const ttId = `tooltip-${portion.portion_id}`
    const title = getBestTitle(portion.titles, [uiLanguage, selectedResource.primaryLanguage])

    let folderClass = 'video-set-folder'
    if (!selectedResource.published) {
        folderClass += ' trl-unpublished-resource'
    }

    return <div key={portion.portion_id} className={`sl-trl-browse-item toggle-selection selected-${!!selectedPortion}`}>
        <div
            className={folderClass}
            onClick={() => onPortionClick(portion)}
            data-tip data-for={ttId} data-place={'bottom' ?? ''}
        >
            <div><input type="image" src="/trl/video-set-folder.svg" /> </div>
            <TRLDownloadThumbnailVideoPlayer
                loadedThumbnailUrl={loadedThumbnailUrl || preloadedThumbnailUrl}
                passageThumbnailVideoUrl={portion.thumbnail?.srcUrl}
                setLoadedThumbnailUrl={setLoadedThumbnailUrl}
                playThumbnailOnHoverOver={!(portion === selectedPortion)}
                contentCreator={selectedResource.copyright}
                contentVideoUrl={portion.passages[0].videoUrl}
                isTRLContentVideoPlaying={portionPlaying}
                setIsTRLContentVideoPlaying={() => onPortionPlaying(true)} />
            {showTitles && <div className="portion-title">{title}</div>}
            {(!showTitles || selectedPortion) && title && <ReactTooltip id={ttId}
                backgroundColor="gray"
                effect="solid"
                delayShow={500}
                delayHide={500}
                clickable={false} >
                {title}
            </ReactTooltip>}
        </div>
        <CancelButton
            enabled={true}
            onClick={() => onPortionClick(undefined)}
            className='cancel-button'
            tooltip={t`Cancel`} />
    </div>
})
