import React, { useState } from 'react'
import {observer} from 'mobx-react'
// import 'react-confirm-alert/src/react-confirm-alert.css'
// import { confirmAlert } from 'react-confirm-alert'
import { t } from 'ttag'

import './Gloss.css'
import { PassageGloss, Project, ProjectTerm } from '../../models3/ProjectModels'
import { StarButton } from '../utils/Buttons'
import { fmt } from '../utils/Fmt'
import { MarbleLemmas } from '../../scrRefs/Lemmas'
import { displayError } from '../utils/Errors'
import { IRoot } from '../../models3/Root'

const log = require('debug')('sltt:GlossView') 

interface IGlossProjectTermsButtons {
    rt: IRoot,
    text: string,
}

const GlossProjectTermsButtons = observer(({ rt, text }: IGlossProjectTermsButtons) => {
    let { project, passage, passageVideo } = rt
    if (!passage) return null

    let { references } = passage // rerender when references change
    let { terms_rev } = project // rerender when ProjectTerms changes

    text = text.trim().toLocaleLowerCase()
    if (!text) return null

    let terms = passage.getTermsByGloss(project, passageVideo).get(text) ?? []
    if (!terms) return null

    return (
        <span>
            {terms.map(term => (
                <StarButton
                    className='gloss-star-icon'
                    isHollow={!term.isKeyTerm}
                    onClick={(e: any) => {
                        // Make sure no one else handles this event
                        e.stopPropagation()
                        // log('onClick', fmt({termId: term.lexicalLink}))
                        rt.termId = term.lexicalLink
                    }}
                    enabled={true}
                    tooltip={t`View term` + ': ' + MarbleLemmas.meaningsDict.get(term.lexicalLink)?.glosses(rt.uiLanguage) ?? ''}
                />
            ))}
        </span>
    )
})

interface IGlossView {
    rt: IRoot,
    gloss: PassageGloss,
    width: number,
    last: boolean, // last gloss displayed in GlossBar
    focused: boolean, // this gloss is open in GlossEditor
    onClick: () => void,
    project: Project,
}

const GlossView = observer(({ rt, gloss, width, onClick, last, focused, project }: IGlossView) => {
    let { _rev, text } = gloss

    let [selected, setSelected] = useState(0)

    function onDragEnter(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        e.stopPropagation()
        setSelected(selected => selected + 1)
    }

    function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        e.stopPropagation()
        setSelected(selected => selected - 1)
    }

    function onDragOver(e: React.DragEvent<HTMLDivElement>) {
        // The default action for dragOver is "Reset the current drag operation to none". 
        // So unless you cancel it the drop doesn't work.
        e.preventDefault()
        e.stopPropagation()
    }

    function onDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
        e.stopPropagation()

        let _text = e.dataTransfer.getData('text/plain')
        log('onDrop', _text)

        // If the user starts to drag the gloss box but ends up dropping it back
        // where they started, detect the _id and ignore it.
        if (_text.match(/^\d{6,6}_\d{6,6}/)) return

        if (_text.trim()) {
            gloss.set(gloss.position, _text).catch(displayError)
        }

        setSelected(0)
    }

    // Remember the _id of the gloss being dragged
    function onDragStart(e: React.DragEvent<HTMLDivElement>) {
        log('onDragStart')
        e.dataTransfer.setData('text/plain', gloss._id)
    }

    let className = last ? 'gloss-box gloss-box-last' : 'gloss-box'
    if (focused || selected > 0) className += ' gloss-box-focused'
    // log(`render |text=${gloss.text}| [duration=${duration.toFixed(2)}]`)

    //!!! must be consultant
    //!!! not working
    let draggable = gloss.text.trim() ? true : undefined

    return (
        <div {... { draggable, className, onDragStart, onDragEnter, onDragLeave, onDragOver, onDrop }} 
                data-id={`gloss-${gloss.text}`}
                style={{ width }}
                onClick={onClick}>
            {text}
            <GlossProjectTermsButtons {...{rt, text }} />
        </div>
    )
})

export default GlossView