// Show a horizontal timeline with a vertical bar indicating current position in video.
// Allow user to set video position by clicking or dragging.

import React, { Component, FC, useCallback, useRef, useState } from 'react'
import {observer} from 'mobx-react'
import { observable } from 'mobx'
import _ from 'underscore'
import { t } from "ttag";

import { IDrawablePassageGloss, Passage, PassageSegment, PassageSegmentApproval, PassageVideo, PassageVideoReference, Portion, Project } from '../../models3/ProjectModels'
import { RefRange } from '../../scrRefs/RefRange'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'
import { Theme } from '../utils/LocalStorage'

const log = require('debug')('sltt:VideoPositionBar')

// increment  is time between each tick drawn on the timeline.
// We need to show the time value for ticks, but not too often.
// Keep the count of ticks showing a number to 12 or less.
// We also want to show it at reasonable boundaries, e.g. 1.0, 2.0, ... not 1.5, 2.5, ...
// When increment is <1 we need to include fractions of a second so maxTicks that can be shown is lower.
// Tick counts which are a multiple of major or minor will get a number in the timeline.
// minor = 10000 means only no minor ticks will be shown
const incrementTable = [
    { increment: .1, major: 1, minor: 10000, maxTicks: 12 }, // up to 1.2 seconds, showing time every .1 second
    { increment: .1, major: 10, minor: 5, maxTicks: 60 }, // up to 6 seconds, showing time every .5 second and a tick every .1 second
    { increment: .5, major: 1, minor: 10000, maxTicks: 12 }, // up to 6 seconds, showing time every .5 second
    { increment: 1, major: 1, minor: 10000, maxTicks: 12 }, // up to 12 seconds, showing time every second
    { increment: 1, major: 5, minor: 10000, maxTicks: 60 }, // up to 1 minute, showing time every 5 seconds and a tick every second
    { increment: 5, major: 12, minor: 2, maxTicks: 24 }, // up to 2 minutes, showing time every 10 seconds and a tick every 5 seconds
    { increment: 10, major: 6, minor: 3, maxTicks: 36 }, // up to 6 minutes, showing time every 30 seconds and a tick every 10 seconds
    { increment: 20, major: 3, minor: 10000, maxTicks: 36 }, // up to 12 minutes, showing time every minute and a tick every 20 seconds
    { increment: 60, major: 5, minor: 10000, maxTicks: 60 }, // up to 60 minutes, showing time every 5 minutes and a tick every minute
]
const _window = window as any
_window.incrementTable = incrementTable // allow accessing in browser console

interface IVideoPositionBar {
    videoPosition: IVideoPosition,
}

// The following videoPosition properties can take assignments
// be sure to make these @observable in an object class for reactivity
interface IVideoPositionObservables {
    selectionStartTime: number,
    selectionEndTime: number,
    dbsRefs: RefRange[],
    verseReference?: PassageVideoReference | undefined
}

export interface IVideoPosition extends IVideoPositionObservables {
    currentTime: number,
    iAmConsultant: boolean,
    resetCurrentTime: (time: number) => void,
    duration: number,
    timelineStart: number,
    timelineEnd: number,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    drawableGloss: IDrawablePassageGloss | null,
    useMobileLayout: boolean,
    timelineZoom: number,
    selectionStartTime: number,
    selectionEndTime: number,
    setDbsRefs: (portion: Portion | null, passageSegment: PassageSegment | null) => void,
    dbsRefs: RefRange[],
    parseReferences: (references: string) => RefRange[],
    project: Project,
    portion: Portion | null,
    passageSegment: PassageSegment | null,
    displayableReferences: (references: RefRange[] | undefined | null) => string,
    setDefault: (tag: string, value: string | null) => void,
    getDefault: (tag: string) => string | null,
    editingSegment: boolean,
    verseReference?: PassageVideoReference,
}

interface IPoint {
    x: number,
    y: number,
}

interface IDrawableVerseReference {
    path: Path2D,
    boundingBox: Path2D,
    reference: PassageVideoReference,
}

interface IDrawableSegment {
    startX: number,
    segment: PassageSegment,
    actualSegment: PassageSegment,
    boundingBox: Path2D,
    path: Path2D,
}

const VIDEO_POSITION_BAR_WIDTH = 640
const VIDEO_POSITION_BAR_HEIGHT = 35
const VIDEO_POSITION_BAR_TIME_HEIGHT = 24
const TIMELINE_START_Y = 10
const TIMELINE_MIDDLE_Y = (VIDEO_POSITION_BAR_HEIGHT - TIMELINE_START_Y) / 2 + TIMELINE_START_Y

class VideoPositionBar extends Component<IVideoPositionBar> {
    private canvas: React.RefObject<HTMLCanvasElement>
    @observable mousePosition: IPoint = { x: -1, y: -1 }
    @observable verseBoundaries: IDrawableVerseReference[] = []
    @observable drawableSegments: IDrawableSegment[] = []
    @observable segment?: PassageSegment
    @observable reference?: PassageVideoReference

    draggingSegmentOrReference = false
    mouseHandler?: CanvasDragAndClickDetector
    videoPositionBarDrawer?: VideoPositionBarDrawer

    constructor(props: IVideoPositionBar) {
        super(props)
        this.canvas = React.createRef()
        this.getVerseReferenceByPoint = this.getVerseReferenceByPoint.bind(this)
        this.getSegmentByPoint = this.getSegmentByPoint.bind(this)
        this.onClick = this.onClick.bind(this)
        this.onDrag = this.onDrag.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.onAdjustSelection = this.onAdjustSelection.bind(this)
        this.xPosition = this.xPosition.bind(this)
        this.gatherVerseBoundaries = this.gatherVerseBoundaries.bind(this)
        this.gatherDrawableSegments = this.gatherDrawableSegments.bind(this)
        this.setSegment = this.setSegment.bind(this)
        this.updateCanvas = this.updateCanvas.bind(this)
        this.displayXToModelX = this.displayXToModelX.bind(this)
        this.displayYToModelY = this.displayYToModelY.bind(this)
        this.mousemove = this.mousemove.bind(this)
        this.mouseleave = this.mouseleave.bind(this)
        this.xToTime = this.xToTime.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.saveSegmentPosition = this.saveSegmentPosition.bind(this)
    }

    render() {
        let { videoPosition } = this.props
        let { mousePosition, setReference, reference } = this
        const { theme } = Theme // DO NOT REMOVE UNLESS YOU TEST: this is for mobx observer() to notice Theme.theme changes :-(

        // WARNING! There are a lot of unused variables in this line.
        // However, extracting them here forces the VideoPositionBar to re-render when
        // one of the changes so be very careful about removing these.
        let { passage, passageVideo, drawableGloss, useMobileLayout, currentTime, duration,
            timelineStart, timelineEnd, selectionStartTime, selectionEndTime, iAmConsultant,
            editingSegment, resetCurrentTime } = videoPosition

        passage?.videoBeingCompressed
        passageVideo?.references

        let { x, y } = mousePosition
        
        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes
        // log('*** render', passageVideo && passageVideo._rev)

        //let pvs = (passageVideo && passageVideo.segments) || []
        //log('positions', pvs.map(pv => pv.position))

        // Check to see if segment time have changed and if so reset them
        passage && passageVideo && passageVideo.setSegmentTimes(passage)

        return (
            <div data-id='video-positionbar' date-theme={theme}>
                <canvas 
                    className="video-positionbar"
                    width={VIDEO_POSITION_BAR_WIDTH} 
                    height={VIDEO_POSITION_BAR_HEIGHT + VIDEO_POSITION_BAR_TIME_HEIGHT}
                    ref={this.canvas} 
                    onMouseLeave={this.mouseleave.bind(this)}
                    onMouseMove={this.mousemove.bind(this)}>
                </canvas>
            </div>
        )
    }
    
    setSegment(segment?: PassageSegment) {
        this.segment = segment
    }

    setReference(reference?: PassageVideoReference) {
        this.reference = reference
        // I dont think we need to do anything with dbsRefs because they should be set based on the position in the timeline
    }

    async saveSegmentPosition(segmentTime: number) {
        let { segment } = this
        let { videoPosition } = this.props
        let { passage, passageVideo, resetCurrentTime } = videoPosition

        if (!passage || !passageVideo || !segment || !segment.canChangePositionToTime(segmentTime, passageVideo)) {
            return
        }

        resetCurrentTime(segmentTime)
        await passageVideo.saveSurroundingSegmentPositions(passage, segmentTime, segment)
    }

    async onClick(x: number, y: number) {
        let { verseBoundaries, canvas } = this
        let { videoPosition } = this.props
        let { iAmConsultant, editingSegment, passage } = videoPosition

        let disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        this.setCurrentTime(x)
        videoPosition.selectionStartTime = -1
        videoPosition.selectionEndTime = -1
        
        const ctx = canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        let ref: PassageVideoReference | undefined = undefined
        for (let boundary of verseBoundaries) {
            let { boundingBox, reference } = boundary
            if (ctx.isPointInPath(boundingBox, x, y)) {
                ref = reference
            }
        }

        let allowedToEditReference = iAmConsultant
        if (allowedToEditReference && !videoPosition.verseReference) {
            log('onClick', JSON.stringify(ref?.dbg(), null, 4))
            videoPosition.verseReference = ref
        }
    }

    onDrag(startX: number, startY: number, currentX: number, currentY: number) {
        let { videoPosition } = this.props
        let { passageVideo, iAmConsultant, editingSegment, passage } = videoPosition
        let { draggingSegmentOrReference, setSegment, reference, segment } = this

        let disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        let ref = this.getVerseReferenceByPoint(startX, startY)
        let seg = this.getSegmentByPoint(startX, startY)
        
        let allowedToEditReference = iAmConsultant
        let allowedToEditSegment = iAmConsultant

        if (allowedToEditReference) {
            if (reference) {
                let newTime = this.xToTime(currentX)
                this.saveReference(newTime)
            } else if (ref) {
                this.reference = ref
            }
        }

        if (allowedToEditSegment) {
            if (segment) {
                let newTime = this.xToTime(currentX)
                this.saveSegmentPosition(newTime)
            } else if (seg && passageVideo && seg.isAllowedToDrag(passageVideo)) {
                setSegment(seg)
            }
        }

        if (ref || seg) {
            videoPosition.selectionStartTime = -1
            videoPosition.selectionEndTime = -1
            this.draggingSegmentOrReference = true
        } else if (!draggingSegmentOrReference && startY >= TIMELINE_START_Y) {
            this.adjustSelectionRange(startX, currentX, videoPosition)
        }
        this.setCurrentTime(currentX)
    }

    private adjustSelectionRange(startX: number, currentX: number, videoPosition: IVideoPosition) {
        let startTime = this.xToTime(startX)
        let ct = this.xToTime(currentX)
        if (ct < startTime) {
            videoPosition.selectionStartTime = ct
            videoPosition.selectionEndTime = startTime
        } else {
            videoPosition.selectionStartTime = startTime
            videoPosition.selectionEndTime = ct
        }
    }

    async saveReference(referenceTime: number) {
        let { reference } = this
        let { videoPosition } = this.props
        let { portion, passageSegment, passageVideo, passage, setDbsRefs, resetCurrentTime } = videoPosition

        if (!passageVideo || !passage || !reference || !reference.canChangePositionToTime(referenceTime, passageVideo, passage)) {
            return
        }

        resetCurrentTime(referenceTime)
        let _reference = await passageVideo.saveReferencePosition(passage, referenceTime, reference)

        this.setReference(_reference)
    }


    async onDragEnd(startX: number, startY: number, endX: number, endY: number) {
        let { videoPosition } = this.props
        let { selectionStartTime, selectionEndTime, editingSegment, passage } = videoPosition

        let disabled = !!passage?.videoBeingCompressed || editingSegment

        if (disabled) {
            return
        }

        // Make sure startTime < endTime if the user has dragged left
        if (selectionStartTime >= 0 && selectionEndTime >= 0 && selectionStartTime > selectionEndTime) {
            videoPosition.selectionStartTime = selectionEndTime
            videoPosition.selectionEndTime = selectionStartTime
        }

        this.draggingSegmentOrReference = false
        this.setSegment(undefined)
        this.setReference(undefined)
    }

    gatherVerseBoundaries() {
        let { videoPosition } = this.props
        let { xPosition } = this
        let { passage, passageVideo } = videoPosition
        
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx || !passage) {
            return []
        }
        
        let verseBoundaries: IDrawableVerseReference[] = []
        let visibleReferences = passageVideo?.getVisibleReferences(passage) || []
        let sorted = _.sortBy(visibleReferences, 'time')
        for (let reference of sorted) {
            let { time } = reference
            let drawPosition = xPosition(time)
            let boundingBox = new Path2D()
            ctx.lineWidth = 2
            boundingBox.rect(drawPosition - 8, 0, 16, TIMELINE_START_Y)

            ctx.beginPath()
            ctx.lineWidth = 2
            let path = new Path2D()
            path.moveTo(drawPosition - 3, 1)
            path.lineTo(drawPosition + 2, 10)
            path.lineTo(drawPosition + 7, 1)
            verseBoundaries.push({ reference, path, boundingBox })
        }
        return verseBoundaries
    }
    
    gatherDrawableSegments() {
        let { videoPosition } = this.props
        let { xPosition } = this
        let { passage, passageVideo, timelineStart, timelineEnd } = videoPosition
        
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return []
        }

        let segments = (passageVideo && passageVideo.segments) || []
        let drawableSegments: IDrawableSegment[] = []
        for (let i=0; i<segments.length; ++i) {
            let segment = segments[i]
            let actualSegment = segment.actualSegment(passage!)
            let { time } = actualSegment

            if (time + actualSegment.duration < timelineStart) continue
            if (time > timelineEnd) continue

            let startX = Math.max(xPosition(time), 0)

            let boundingBox = new Path2D()
            ctx.strokeStyle = Theme.getColor('black', 'white')
            ctx.lineWidth = 4
            boundingBox.rect(startX - 4, TIMELINE_START_Y + 1, 8, VIDEO_POSITION_BAR_HEIGHT - TIMELINE_START_Y - 1)

            ctx.beginPath()
            let path = new Path2D()
            ctx.beginPath()
            path.moveTo(startX, TIMELINE_MIDDLE_Y - 10)
            path.lineTo(startX, TIMELINE_MIDDLE_Y + 10)

            let nextEntry = { startX, segment, actualSegment, boundingBox, path }
            drawableSegments.push(nextEntry)
        }

        drawableSegments = _.sortBy(drawableSegments, 'startX')
        return drawableSegments
    }

    updateCanvas() {
        this.drawableSegments = this.gatherDrawableSegments()
        this.verseBoundaries = this.gatherVerseBoundaries()
        this.videoPositionBarDrawer?.draw(this.mousePosition, this.verseBoundaries, this.drawableSegments)
    }

    getVerseReferenceByPoint(x: number, y: number) {
        let { canvas, verseBoundaries } = this
        let ref: PassageVideoReference | undefined = undefined
        let ctx = canvas.current?.getContext('2d')
        if (ctx) {
            for (let boundary of verseBoundaries) {
                let { boundingBox, reference } = boundary
                if (ctx.isPointInPath(boundingBox, x, y)) {
                    ref = reference
                }
            }
        }
        return ref
    }

    getSegmentByPoint(x: number, y: number) {
        let { canvas, drawableSegments } = this
        let seg: PassageSegment | undefined = undefined
        let ctx = canvas.current?.getContext('2d')
        if (ctx) {
            for (let ds of drawableSegments) {
                let { segment, boundingBox } = ds
                if (ctx.isPointInPath(boundingBox, x, y)) {
                    seg = segment
                }
            }
        }
        return seg
    }

    displayYToModelY(e: React.MouseEvent) {
        let boundingRect = e.currentTarget.getBoundingClientRect()
        let y = e.clientY - boundingRect.top
        let { current } = this.canvas
        let canvasHeight = current?.height || 0
        let canvasOffsetHeight = current?.offsetHeight || 1 // prevent divide by 0
        return y * (canvasHeight / canvasOffsetHeight)
    }

    displayXToModelX(e: React.MouseEvent) {
        let boundingRect = e.currentTarget.getBoundingClientRect()
        let x = e.clientX - boundingRect.left
        let { current } = this.canvas
        let canvasWidth = current?.width || 0
        let canvasOffsetWidth = current?.offsetWidth || 1   // prevent divide by 0
        return x * (canvasWidth / canvasOffsetWidth)
    }

    mousemove(e: any) {
        let x = this.displayXToModelX(e)
        let y = this.displayYToModelY(e)
        this.mousePosition = { x, y }
    }

    mouseleave() {
        this.mousePosition = { x: -1, y: -1 }
    }

    xPosition(time: number) {
        let { videoPosition } = this.props
        let { timelineStart, timelineEnd } = videoPosition
        let timelineDuration = timelineEnd - timelineStart
        return (time - timelineStart) / timelineDuration * VIDEO_POSITION_BAR_WIDTH
    }

    xToTime(x: number) {
        let { videoPosition: videoPositionDisplay } = this.props
        let { timelineStart, timelineEnd } = videoPositionDisplay
        let timelineDuration = timelineEnd - timelineStart

        let frac = x / VIDEO_POSITION_BAR_WIDTH
        return timelineStart + frac * timelineDuration
    }

    setCurrentTime(x: number) {
        let { videoPosition } = this.props
        let { resetCurrentTime } = videoPosition
        resetCurrentTime(this.xToTime(x))
    }

    onAdjustSelection(x: number) {
        // shift+click at a distance from current video position will work like onDrag
        const { videoPosition } = this.props
        const xVideoPosition = this.xPosition(videoPosition.currentTime)
        const { selectionStartTime: selectionStartTimeOrig,
            selectionEndTime: selectionEndTimeOrig } = videoPosition
        if (selectionEndTimeOrig > 0) {
            const selectionEndXOrig = this.xPosition(selectionEndTimeOrig)
            const selectionStartXOrig = this.xPosition(selectionStartTimeOrig)
            // adjust selection by keeping the furthest selection point
            // (ie. only move the selection endpoint nearest to click position)
            const selectionFurthestX =
                (Math.abs(x - selectionStartXOrig) >= Math.abs(x - selectionEndXOrig)) ?
                    selectionStartXOrig : selectionEndXOrig
            log('onAdjustSelection', { x, selectionFurthestX, selectionEndXOrig, selectionStartXOrig })
            this.adjustSelectionRange(selectionFurthestX, x, videoPosition)
        } else {
            // use current video position for one end of the range,
            // and the current click position as the other
            log('onAdjustSelection', { x, xVideoPosition })
            this.adjustSelectionRange(xVideoPosition, x, videoPosition)
        }
        this.setCurrentTime(x)
    }

    async componentDidMount() {
        this.mouseHandler = new CanvasDragAndClickDetector(
            this.canvas,
            this.onClick,
            this.onDrag,
            this.onDragEnd,
            this.onAdjustSelection
        )
        this.videoPositionBarDrawer = new VideoPositionBarDrawer(this.canvas, this.props.videoPosition)
        this.updateCanvas()
    }

    async componentDidUpdate() {
        this.updateCanvas()
    }
}

class VideoPositionBarDrawer {
    constructor(
        private canvas: React.RefObject<HTMLCanvasElement>,
        private videoPosition: IVideoPosition,
    ) {
        _.bindAll(this,
            'draw',
            'xPosition',
            'drawSelection',
            'drawGloss',
            'drawTimelineBoundary',
            'drawCurrentPositionCursor',
            'drawVerseBoundaries',
            'drawSegments',
        )
    }

    draw(mousePosition: IPoint, verseBoundaries: IDrawableVerseReference[], drawableSegments: IDrawableSegment[]) {
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }
    
        ctx.clearRect(0, 0, VIDEO_POSITION_BAR_WIDTH, VIDEO_POSITION_BAR_HEIGHT + VIDEO_POSITION_BAR_TIME_HEIGHT)
    
        this.drawSelection()
        this.drawSegments(mousePosition, drawableSegments)
        this.drawGloss()
        this.drawTimelineBoundary()

        ctx.lineWidth = 5;
        this.drawCurrentPositionCursor(ctx)
        ctx.lineWidth = 1;
        this.drawTimes(ctx)
        
        this.drawVerseBoundaries(mousePosition, verseBoundaries)
    }

    drawTimes = (ctx:CanvasRenderingContext2D) => {
        const { timelineStart, timelineEnd } = this.videoPosition
        const _duration = timelineEnd - timelineStart

        const incrementEntry  = incrementTable.find(incr => _duration / incr.increment <= incr.maxTicks)
        const { increment, major, minor } = incrementEntry || { increment: 600, major: 1, minor: 10000 }

        let time = increment * Math.round(timelineStart / increment)
        const ticks = Math.floor(_duration / increment)

        // log('drawTimes (a)', fmt({ increment, ticks, major, minor }))

        for (; time<timelineEnd; time += increment) {
            if (time < timelineStart) continue
            
            const count = Math.round(time / increment)
            const isMajor = (count % major) === 0
            const isMinor = (count % minor) === 0

            let height = 4
            if (isMajor) height = 11
            if (isMinor) height = 8

            let x = this.drawTick(ctx, time, 0, height)
            // log('drawTimes (c)', fmt({ count, time, x, isMajor, isMinor }))

            if ((isMajor || isMinor) && (x >= 15)) {
                let mm = Math.floor(time / 60).toString().padStart(2, '0')
                let ss: string
                if (increment === .1 || increment === .5) {
                    ss = (time % 60).toFixed(1).padStart(4, '0')
                } else {
                    ss = (time % 60).toFixed(0).padStart(2, '0')
                } 
                let text = `${mm}:${ss}`
                ctx.font = '12px Helvetica'
                ctx.fillStyle = Theme.getColor('black', 'white')
                ctx.fillText(text, x - 15, VIDEO_POSITION_BAR_HEIGHT + 20)
            }
        }
    }

    private xPosition(time: number) {
        let { videoPosition } = this
        let { timelineStart, timelineEnd } = videoPosition
        let timelineDuration = timelineEnd - timelineStart
        return (time - timelineStart) / timelineDuration * VIDEO_POSITION_BAR_WIDTH
    }

    private drawSelection() {
        let { videoPosition } = this
        let { xPosition } = this
        let { timelineStart, timelineEnd, selectionStartTime, selectionEndTime, } = videoPosition
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }
        
        if (selectionStartTime >= 0 && selectionStartTime <= timelineEnd && selectionEndTime >= timelineStart) {
            let selectionStartPosition = xPosition(Math.max(selectionStartTime, timelineStart))
            let selectionEndPosition = xPosition(Math.min(selectionEndTime, timelineEnd))
            let selectionWidth = selectionEndPosition - selectionStartPosition
            ctx.beginPath()
            ctx.fillStyle = 'lightgray'
            const originalGlobalAlpha = ctx.globalAlpha
            ctx.globalAlpha = Theme.theme === 'dark' ? 0.2 : originalGlobalAlpha
            ctx.fillRect(selectionStartPosition, TIMELINE_START_Y, selectionWidth, VIDEO_POSITION_BAR_HEIGHT);
            ctx.globalAlpha = originalGlobalAlpha
        }
    }

    private drawGloss() {
        let { videoPosition } = this
        let { xPosition } = this
        let { drawableGloss } = videoPosition

        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        if (drawableGloss) {
            let { time, duration } = drawableGloss
            let xg1 = xPosition(time)
            let xg2 = xPosition(time+duration)

            if (xg1 <= VIDEO_POSITION_BAR_WIDTH && xg2 >= 0) {
                xg1 = Math.max(0, xg1)
                xg2 = Math.max(xg1+12, xg2)
                xg2 = Math.min(VIDEO_POSITION_BAR_WIDTH, xg2)

                ctx.strokeStyle = Theme.getColor('purple', '#E0B0FF')
                ctx.lineWidth = 5
                ctx.beginPath()
                ctx.moveTo(xg1, TIMELINE_MIDDLE_Y - 5)
                ctx.lineTo(xg2, TIMELINE_MIDDLE_Y - 5)
                ctx.stroke()
            }
        }
    }

    private drawTimelineBoundary() {
        let { videoPosition } = this
        let { duration, timelineStart, timelineEnd } = videoPosition
        
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        if (timelineStart > .1) {
            ctx.strokeStyle =  Theme.getColor('black', 'white')
            ctx.lineWidth = 3
            ctx.setLineDash([3,3,3])
            ctx.beginPath()
            ctx.clearRect(0, TIMELINE_MIDDLE_Y - 1, 20, 3)  // x,y,width,height
            ctx.moveTo(20, TIMELINE_MIDDLE_Y)
            ctx.lineTo(20, TIMELINE_MIDDLE_Y)
            ctx.stroke()
        }

        if (timelineEnd < duration - .1) {
            ctx.strokeStyle = Theme.getColor('black', 'white')
            ctx.lineWidth = 3
            ctx.setLineDash([3, 3, 3])
            ctx.beginPath()
            ctx.clearRect(VIDEO_POSITION_BAR_WIDTH-20, TIMELINE_MIDDLE_Y - 1, 20, 3)  // x,y,width,height
            ctx.moveTo(VIDEO_POSITION_BAR_WIDTH - 20, TIMELINE_MIDDLE_Y)
            ctx.lineTo(VIDEO_POSITION_BAR_WIDTH, TIMELINE_MIDDLE_Y)
            ctx.stroke()
        }

        ctx.setLineDash([])
    }

    private drawTick = (ctx: CanvasRenderingContext2D, time: number, up: number, down: number) => {
        let timeX = Math.max(this.xPosition(time), 1)

        ctx.lineWidth = 1
        ctx.strokeStyle = Theme.getColor('black', 'white')
        ctx.beginPath()
        ctx.moveTo(timeX, TIMELINE_MIDDLE_Y - up)
        ctx.lineTo(timeX, TIMELINE_MIDDLE_Y + down)
        ctx.stroke()    

        return timeX
    }

    private drawCurrentTick = (ctx: CanvasRenderingContext2D, time: number, up: number, down: number) => {
        let timeX = Math.max(this.xPosition(time), 1)

        ctx.lineWidth = 5
        ctx.strokeStyle = Theme.getColor('green', '#85F5FF');
        ctx.beginPath()
        ctx.moveTo(timeX, TIMELINE_MIDDLE_Y - up)
        ctx.lineTo(timeX, TIMELINE_MIDDLE_Y + down)
        ctx.stroke()    

        return timeX
    }

    drawCurrentPositionCursor(ctx: CanvasRenderingContext2D) {
        let { currentTime } = this.videoPosition
        this.drawCurrentTick(ctx, currentTime, 8, 8)
    }

    private drawVerseBoundaries(mousePosition: IPoint, verseBoundaries: IDrawableVerseReference[]) {
        let { videoPosition } = this
        let { displayableReferences, editingSegment, passage } = videoPosition
        let disabled = !!passage?.videoBeingCompressed || editingSegment
        
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        let { x, y } = mousePosition

        function drawText(ctx: CanvasRenderingContext2D, text: string) {
            ctx.font = '14px Helvetica Neue'
            let measureText = ctx.measureText(text)
            ctx.fillStyle = Theme.getColor('white', 'black')
            let rectWidth = measureText.width + 7
            let rectHeight = measureText.actualBoundingBoxAscent + 7
            let rectX = x + 3
            let rectY = y - 3 - measureText.actualBoundingBoxAscent
            if (VIDEO_POSITION_BAR_WIDTH - rectX < rectWidth) {
                rectX = VIDEO_POSITION_BAR_WIDTH - rectWidth
            }
            if (rectY < 0) {
                rectY = 0
            }
            ctx.fillRect(rectX, rectY, rectWidth, rectHeight)
            ctx.fillStyle = Theme.getColor('black', 'white')
            ctx.fillText(text, rectX + 4, rectY + 4 + measureText.actualBoundingBoxAscent)
        }

        for (let boundary of verseBoundaries) {
            let { path, boundingBox, reference } = boundary
            if (ctx.isPointInPath(boundingBox, x, y)) {
                ctx.strokeStyle = disabled ? 'grey' : 'red'
                let text = displayableReferences(reference.references)
                if (text) {
                    drawText(ctx, text)
                }
            } else {
                ctx.strokeStyle =  Theme.getColor('black', 'white')
            }
            ctx.stroke(path)
        }
    }

    private drawSegments(mousePosition: IPoint, drawableSegments: IDrawableSegment[]) {
        let { videoPosition } = this
        let { passageVideo, useMobileLayout, editingSegment, passage } = videoPosition

        let disabled = !!passage?.videoBeingCompressed || editingSegment
        
        const ctx = this.canvas.current?.getContext('2d')
        if (!ctx) {
            return
        }

        let { x, y } = mousePosition

        drawableSegments.forEach((entry, i) => {
            let { startX, segment, actualSegment, path, boundingBox } = entry

            // Draw next part of timeline
            let nextPosition = i !== drawableSegments.length - 1 ? drawableSegments[i + 1].startX : VIDEO_POSITION_BAR_WIDTH
            ctx.lineWidth = segment.videoPatchHistory.length > 0 ? 3 : 2
            if (disabled) {
                ctx.strokeStyle = 'gray'
            } else if (segment.videoPatchHistory.length > 0) {
                ctx.strokeStyle = '#da8383'
            } else {
                ctx.strokeStyle = Theme.getColor('black', 'white')
            }

            if (actualSegment.ignoreWhenPlayingVideo) {
                ctx.setLineDash([10, 10])
            }
            ctx.beginPath()
            ctx.moveTo(startX, TIMELINE_MIDDLE_Y)
            ctx.lineTo(nextPosition, TIMELINE_MIDDLE_Y)
            ctx.stroke()
            ctx.setLineDash([])

            // Draw segment boundary
            ctx.clearRect(startX-4, TIMELINE_MIDDLE_Y - 2, 8, 4)
            let isAllowedToDrag = passageVideo && segment.isAllowedToDrag(passageVideo) && !disabled
            ctx.lineWidth = 8
            if (ctx.isPointInPath(boundingBox, x, y) && isAllowedToDrag) {
                ctx.strokeStyle = 'red'
            } else {
                ctx.strokeStyle = Theme.getColor('lightblue', '#da8383')
            }
            ctx.stroke(path)

            const CHECK_SCALE_FACTOR = 0.7
            const XSHIFT = 2.8
            const YSHIFT = 4.7
            
            function drawCheck(scale = CHECK_SCALE_FACTOR, xOffset = XSHIFT, yOffset = YSHIFT) {
                if (!ctx) {
                    return
                }
                ctx.strokeStyle = Theme.getColor('black', 'white')
                ctx.lineWidth = 2
                ctx.beginPath()
                ctx.moveTo((startX + xOffset + (2 * scale)), (TIMELINE_MIDDLE_Y + yOffset + (17 * scale)))
                ctx.lineTo((startX + xOffset + (6 * scale)), (TIMELINE_MIDDLE_Y + yOffset + (21 * scale)))
                ctx.lineTo((startX + xOffset + (12 * scale)), (TIMELINE_MIDDLE_Y + yOffset + (15 * scale)))
                ctx.stroke()
            }

            function drawCircle() {
                if (!ctx) {
                    return
                }
                ctx.strokeStyle = Theme.getColor('black', 'white')
                ctx.lineWidth = 2
                let radius = 8 
                ctx.beginPath()
                ctx.arc(startX + radius, radius + 32, radius - 2, 0, 2 * Math.PI, false)
                ctx.stroke()
            }

            if (!useMobileLayout) {
                if (actualSegment.approved === PassageSegmentApproval.State1) {
                    drawCheck()
                } else if (actualSegment.approved === PassageSegmentApproval.State2) {
                    drawCircle()
                } else if (actualSegment.approved === PassageSegmentApproval.State3) {
                    drawCircle()
                    drawCheck()
                }
            }
        })
    }
}

const MIN_DRAG_DISTANCE_PIXELS = 10

class CanvasDragAndClickDetector {
    @observable mousePosition: IPoint = { x: -1, y: -1 }
    @observable dragStart: IPoint = { x: -1, y: -1 }
    maxDistanceDraggedFromStart = 0
    @observable mousedown = false

    constructor(
        private canvasRef: React.RefObject<HTMLCanvasElement>,
        private onClick: (x: number, y: number) => void,
        private onDrag: (startX: number, startY: number, currentX: number, currentY: number) => void,
        private onDragEnd: (startX: number, startY: number, endX: number, endY: number) => void,
        private onAdjustSelection: (x: number) => void
    ) {
        this.onDrag = _.throttle(this.onDrag, 100)
        this.displayXToModelX = this.displayXToModelX.bind(this)
        this.displayYToModelY = this.displayYToModelY.bind(this)
        let { current } = canvasRef
        if (current) {
            current.onmousedown = this._mousedown.bind(this)
            current.onmouseup = this.mouseup.bind(this)
            current.onmousemove = this.mousemove.bind(this)
            current.onmouseleave = this.mouseleave.bind(this)
        }
    }

    displayYToModelY(e: React.MouseEvent) {
        let boundingRect = e.currentTarget.getBoundingClientRect()
        let y = e.clientY - boundingRect.top
        let { current } = this.canvasRef
        let canvasHeight = current?.height || 0
        let canvasOffsetHeight = current?.offsetHeight || 1  // prevent divide by 0
        return y * (canvasHeight / canvasOffsetHeight)
    }

    displayXToModelX(e: React.MouseEvent) {
        let boundingRect = e.currentTarget.getBoundingClientRect()
        let x = e.clientX - boundingRect.left
        let { current } = this.canvasRef
        let canvasWidth = current?.width || 0
        let canvasOffsetWidth = current?.offsetWidth || 1    // prevent divide by 0
        return x * (canvasWidth / canvasOffsetWidth)
    }

    mouseup(e: any) {
        let { maxDistanceDraggedFromStart } = this
        let { x, y } = this.mousePosition
        let { x: dragStartX, y: dragStartY } = this.dragStart
        let didDrag = dragStartY > -1 && dragStartX > -1 && maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
        if (didDrag) {
            this.onDragEnd(dragStartX, dragStartY, x, y)
        } else if (e.shiftKey) {
            // don't do onClick. otherwise it'll undo shift+click for onAdjustSelection()
        } else {
            this.onClick(x, y)
        }
        this.mousedown = false
        this.dragStart = { x: -1, y: -1 }
        this.maxDistanceDraggedFromStart = 0
    }

    mousemove(e: any) {
        let { mousedown } = this
        let x = this.displayXToModelX(e)
        let y = this.displayYToModelY(e)
        this.mousePosition = { x, y }
        let { x: dragStartX, y: dragStartY } = this.dragStart
        if (mousedown) {
            this.maxDistanceDraggedFromStart = Math.max(this.maxDistanceDraggedFromStart, Math.abs(x - dragStartX))
            let isDragging = Math.abs(x - dragStartX) >= MIN_DRAG_DISTANCE_PIXELS || this.maxDistanceDraggedFromStart >= MIN_DRAG_DISTANCE_PIXELS
            if (isDragging) {
                if (e.shiftKey) {
                    // just extend existing selection range during drag
                    this.onAdjustSelection(x)
                } else {
                    this.onDrag(dragStartX, dragStartY, x, y)
                }
            }
        }
    }

    _mousedown(e: any) {
        let { x, y } = this.mousePosition
        this.dragStart = { x, y }
        this.mousedown = true
        if (e.shiftKey) {
            this.onAdjustSelection(x)
        }
    }

    mouseleave(e: any) {
        this.mousePosition = { x: -1, y: -1 }
        this.dragStart = { x: -1, y: -1 }
        this.mousedown = false
        this.maxDistanceDraggedFromStart = 0
    }
}


export default observer(VideoPositionBar)
