import React, { Component } from 'react'
import {observable} from 'mobx'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import TextInput from '../utils/TextInput'
import { AppRoot } from '../../models3/AppRoot'
import { displayError } from '../utils/Errors'

export function validProjectName(name: string) {
    return name.match(/^[a-zA-Z0-9_-]+$/)
}

// Names are considered duplicate if they are the same except for case.
export function duplicateProjectName(name: string, names: string[]) {
    return names.filter(nm => nm.toLowerCase() === name.toLowerCase()).length > 0
}

interface IProjectAdder {
    appRoot: AppRoot,
}

class ProjectAdder extends Component<IProjectAdder> {

    @observable adding = false
    @observable name = ''

    render() {
        if (!this.adding) {
            return (
                <div
                    className="project-adder"
                    data-toggle="tooltip" title={t`Add new project.`} 
                    onClick={ () => {
                        this.adding = true; this.name = ''
                    } }>
                    <i className="far fa-fw fa-plus-square"></i>
                </div>
            )
        }

        return (
            <div className="project-name-box">
                <TextInput
                    message={t`Type Enter to add new project name or Esc to cancel.`}
                    initialValue=""
                    validate={this.validate.bind(this)}
                    _onEscape={this.onCancel.bind(this)}
                    _onEnter={this.onEnter.bind(this)} />
            </div>
        )
    }

    onCancel() { 
        this.adding = false
    }

    async onEnter(newName: string) { 
        let { appRoot } = this.props

        let message = this.validate(newName)
        if (message) {
            displayError(message)
            this.adding = false
            return
        }

        try {
            await appRoot.createProject(newName)
        } catch (error) {
            console.log(error)
            displayError(error)
        }

        this.adding = false
    }

    validate(newName: string) {
        let names = this.props.appRoot.rts.map(rt => rt.name)
        if (duplicateProjectName(newName, names)) return t`Duplicate name`

        if (newName === '') return t`Empty name`
        
        // not localizing this because it is only for web master
        if (!validProjectName(newName)) return `Name contains characters other than a-z, A-Z, underscore, and dash.`
        
        return ''
    }

}

export default observer(ProjectAdder)