import React, { Component } from 'react'
import _ from 'underscore'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { t } from 'ttag'

import { Root, ProgressSelection } from '../../models3/Root'
import { systemError } from '../utils/Errors'
import { ProjectPlanEditor } from './plan/ProjectPlanEditor'
import StatusBoardEditor from './StatusBoardEditor'
import ProgressReport from './progress/ProgressReport'
import { ProgressGraphPage } from './progress/ProgressGraph'
import LoadingMessage from '../utils/InitializationMessage'
import { ErrorBoundary } from '../utils/Errors'
import { Passage } from '../../models3/ProjectModels'

const log = require('debug')('sltt:StatusEditor')

interface IStatusEditor{
    rt: Root,
    selectPassage: (passage: Passage) => void,
}

class StatusEditor extends Component<IStatusEditor> {
    constructor(props: IStatusEditor) {
        super(props)
    }

    setSelection = (selection: ProgressSelection | string) => {
        let { rt } = this.props
        rt.setProgressSelection(selection)
    }

    render() {
        let { rt, selectPassage } = this.props
        let { initialized, loadingMessage } = rt
        if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

        let { progressSelection: selection } = rt
        let setSelection = this.setSelection

        return (
            <div className="status-editor">
                <Tabs>
                    <TabList>
                        <Tab>{t`Status`}</Tab>
                        <Tab>{t`Plan`}</Tab>
                        <Tab>{t`Progress`}</Tab>
                        <Tab>{t`Graph`}</Tab>
                    </TabList>

                    <TabPanel>
                        <ErrorBoundary>
                            <StatusBoardEditor {...{rt, selection, setSelection, selectPassage }} />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProjectPlanEditor rt={rt} />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProgressReport rt={rt} />
                        </ErrorBoundary>
                    </TabPanel>
                    <TabPanel>
                        <ErrorBoundary>
                            <ProgressGraphPage  {...{ rt, selection, setSelection }} />
                        </ErrorBoundary>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default observer(StatusEditor)