import React, { Component, FC } from "react"
import { confirmAlert } from "react-confirm-alert"
import { t } from "ttag"
import { DeletePatchVideoButton } from "../utils/Buttons"
import { Root } from "../../models3/Root"
import { Passage, PassageSegment, PassageVideo } from "../../models3/ProjectModels"
import { systemError } from "../utils/Errors"

interface IPatchVideoDeleter {
    rt: Root,
    passage: Passage,
    passageVideo: PassageVideo,
    segment: PassageSegment,
    deleteSelectionSegments?: boolean,
}

interface IPatchVideoDeleterComponent {
    deleter: IPatchVideoDeleter,
    enabled: boolean,
}

export const PatchVideoDeleter: FC<IPatchVideoDeleterComponent> = (props) => {
    return (
        <DeletePatchVideoButton onClick={() => {
            confirmDeletion(() => { deletePatch(props.deleter).catch(systemError) })
        }} enabled={props.enabled} />
    )
}

function confirmDeletion(doDeletion: () => void) {
    confirmAlert({
        title: t`DELETE this patch video?!`,
        message: t`All notes and glosses that were applied to this patch will be 
                deleted as well. This segment will revert back to the unpatched 
                version. Are you sure you want to do this?`,
        confirmLabel: t`Delete!!!`,
        cancelLabel: t`Keep.`,
        onConfirm: doDeletion,
        onCancel: () => { },
    })
}

export async function deletePatch(props: IPatchVideoDeleter) {
    let { rt, passage, passageVideo, segment, deleteSelectionSegments } = props

    let segmentIndex = passageVideo.findSegmentIndex(segment._id, true)
    let patchVideo = passage.findVideo(segment.videoPatchHistory.slice(-1)[0])

    if (!passage || !patchVideo || !patchVideo.isPatch || segmentIndex < 0) {
        throw Error(t`Something went wrong`)
    }

    await passage.deletePatchVideo(passageVideo, patchVideo, segment)
    
    // If no patches in the previous (and next) segment,
    // reset the ending (starting) position back to the default value.
    if (segmentIndex > 0) { // not first segment
        let previousSegment = passageVideo.segments[segmentIndex - 1]
        if (!previousSegment.isPatched) {
            let newEndPosition = previousSegment.hardEndPosition(passageVideo)
            await previousSegment.setEndPosition(newEndPosition, passageVideo)
        }
    }
    if (segmentIndex < passageVideo.segments.length - 1) { // not last segment
        let nextSegment = passageVideo.segments[segmentIndex + 1]
        if (!nextSegment.isPatched) {
            let newStartPosition = nextSegment.hardStartPosition(passageVideo)
            await nextSegment.setStartPosition(newStartPosition, passageVideo)
        }
    }

    // If we auto added two segments, remove them now
    if (deleteSelectionSegments) {
        await passageVideo.removeSegment(passageVideo.segments[segmentIndex + 1]._id)
        await passageVideo.removeSegment(passageVideo.segments[segmentIndex]._id)
    }

    // Reset times to account for the fact that we removed a patch and thus
    // changed the length of the total video.
    passageVideo.setSegmentTimes(passage, true)

    await rt.setPassageVideo(passageVideo)  // Cause view to refresh
    rt.resetCurrentTime(segment.time + 0.01, passageVideo.computedDuration)
}
