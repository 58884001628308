import { VideoCache } from '../../models3/VideoCache'
import { displayError, displayInfo } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { getVideoDuration } from '../../models3/VideoDuration'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import { t } from 'ttag'

const log = require('debug')('sltt:PatchFromNote')

export function canCreatePatchFromNoteItem(rt: Root) {
    let { passage, clonedNoteItem: item } = rt

    if (!passage || !item || !item.url) return false
    return item._id.startsWith(passage._id)
}

export async function createPatchFromNoteItem(rt: Root, setEditingSegmentPosition: (value: EditingSegmentPosition) => void) {
    let { passage, passageVideo, passageSegment, iAmTranslator,
        clonedNoteItem: item } = rt
    if (!passage || !passageVideo || !passageSegment || !iAmTranslator) return

    if (!item || !item.url || !item._id.startsWith(passage._id)) {
        displayInfo(t`Cannot <shift> click this button yet. First click a video in the note dialog to select it as a patch.`)
        return
    }

    let patchableSelectionPresent = rt.patchableSelectionPresent()
    let { selectionStartTime, selectionEndTime, name } = rt

    let video = passage.createVideo(name)
    video.url = item.url

    let duration = item.duration
    if (duration === 0) {
        let blob = await VideoCache.getVideoBlob(item.url)
        if (!blob) {
            displayError('Could not find video')
            return
        }
        duration = await getVideoDuration(blob)
    }
    video.duration = duration
    video.log(passage, 'patch')

    passageVideo.log(passage, 'before patch')
    let segment = passageSegment
    if (patchableSelectionPresent) {
        let { segment: _segment } = await passageVideo.createSelectionSegment(passage, selectionStartTime, selectionEndTime)
        segment = _segment
        rt.setPassageSegment(segment)
    }

    await rt.passage!.addPatchVideo(passageVideo, video, segment)
    passageVideo.log(passage, 'after patch')

    /**
     * This is necessary to ensure that all the videos in the passage including
     * the new patch are present in viewable video collection
     */
    rt.setPassageVideo(passageVideo)

    setEditingSegmentPosition(EditingSegmentPosition.Both)
}



