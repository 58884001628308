import { VideoCacheRecord } from './VideoCacheRecord'
import { VideoCache } from './VideoCache'
import { observable } from 'mobx'
import { getIsAppOnlineOrWait } from '../components/utils/ServiceStatus'
import { hasSharedLANStorage } from './SlttAppStorage'

let log = require('debug')('sltt:VideoCacheUploader')

export class VideoCacheUploader {
    static systemError = (err: any): void => { throw Error(err) }

    static uploadDone: (_id: string) => Promise<void>

    // At the moment using BroadcastChannel is overkill.
    // However, we want to move this a ServiceWorker and at that point in time
    // we need this in order to communciate across processes.

    // static channel = new BroadcastChannel('VideoCacheUploader')

    @observable uploadInProgress = false
    @observable uploadCount = 0 // number of blocks waiting to upload
    private vcrBlobsAlreadyWrittenToDisk = new Set()

    constructor(public displayError?: (message: any) => void) {
        // VideoCacheUploader.channel.onmessage = this.onmessage.bind(this)
        if (displayError) VideoCacheUploader.systemError = displayError
    }

    postMessage(message: any) {
        log('postMessage', message)
        this.onmessage({ data: message } as MessageEvent)
    }

    async onmessage(ev: MessageEvent) {
        let { _id } = ev.data
        log('onmessage', _id)

        let vcr = await VideoCacheRecord.get(_id)
        if (!vcr.needsUploaded) return

        await vcr.saveUploadRequest()

        // If we can start the service worker upload, we are done
        // if (await this.startServiceWorkerUpload()) return

        // Otherwise start a client upload cycle
        await this.startClientUpload()
    }

    /* returns true if service worker upload started */
    // private async startServiceWorkerUpload() {
    //     //log('requestUpload', !!process.env.PUBLIC_URL, !!navigator.serviceWorker, !!navigator.serviceWorker.controller,
    //     //    !!window.SyncManager)

    //     //? Do we need t4st for PUBLIC_URL, it makes it hard to test locally
    //     let canUseServiceWorker = !!process.env.PUBLIC_URL && !!navigator.serviceWorker && !!navigator.serviceWorker.controller && !!window.SyncManager

    //     // DISABLE service worker uploads for now (until we can figure out how to do reliably)
    //     canUseServiceWorker = false 

    //     if (!canUseServiceWorker) return false

    //     try {
    //         // Pass control of when to upload to Background Sync.
    //         // https://developers.google.com/web/updates/2015/12/background-sync
    //         let registration = await navigator.serviceWorker.ready
    //         await registration.sync.register('upload-blobs')
    //     } catch (error) {
    //         VideoCacheUploader.systemError(error)
    //         return false
    //     }

    //     return true
    // }

    // If client upload is not already in progress, start it
    private async startClientUpload() {
        if (!this.uploadInProgress) {
            this.uploadInProgress = true
            try {
                await this.uploadAllPendingRequests()
            } catch (error) {
                log(`startClientUpload exception ${error}`)
                VideoCacheUploader.systemError(error)
            } finally {
                this.uploadInProgress = false
            }
            log('Done uploading')
        }
    }

    // Have client upload all videos waiting in the VideoCache.cachedVideos database
    private async uploadAllPendingRequests() {
        let attempt = 1
        while (true) {
            let vcrs = await VideoCache.getAllVcrs()
            let pendingUploads = vcrs.filter(vcr => vcr.needsUploaded)
            
            let uploadCount = 0
            pendingUploads.forEach(vcr => {uploadCount += vcr.uploadCount})
            this.uploadCount = uploadCount
            log('uploadCount', uploadCount)

            if (pendingUploads.length === 0) break
            log(`upload requests pending=${pendingUploads.length} attempt=${attempt}`)
            if (attempt > 1) {
                // breathe 5 seconds between attempts
                // otherwise any error or offline status can cause tight loop 
                await new Promise(resolve => setTimeout(resolve, 5000))
            }
            if (hasSharedLANStorage()) {
                for (let vcr of pendingUploads.filter(vcr => !this.vcrBlobsAlreadyWrittenToDisk.has(vcr._id))) {
                    // NOTE: this.vcrBlobsAlreadyWrittenToDisk is not persistent, so will only work
                    // until the browser is refreshed. That should be okay
                    // since it's okay to write the videos to disk again once
                    const wroteAllVcrBlobsToDisk = await vcr.storeBlobsNeedingUploadToDisk()
                    if (wroteAllVcrBlobsToDisk) {
                        this.vcrBlobsAlreadyWrittenToDisk.add(vcr._id)
                    }
                }
            }
            if (await getIsAppOnlineOrWait()) {
                // NOTE we don't want to use ServiceStatus.delayUntilOnline()
                // since we want to keep this loop and thus uploadCount responsive to newly added videos
                for (let vcr of pendingUploads) {
                    await this.uploadPendingRequest(vcr)
                }
            }
            attempt++
        }
    }

    // Have client upload one video
    private async uploadPendingRequest(vcr: VideoCacheRecord) {
        let { _id } = vcr
        log(`uploadPendingRequest [${_id}]`)

        await vcr.saveUploadStartTime()

        let decrementUploadCount = () => {
            if (this.uploadCount > 0) { --this.uploadCount }
            log('decrementUploadCount', this.uploadCount)
        }
        await vcr.uploadToServer(decrementUploadCount)

        vcr = await VideoCacheRecord.get(_id)
        if (vcr.uploaded) {
            await vcr.sanitize()
            await vcr.saveUploadFinishTime()
            log(`uploadPendingRequest complete rate=${vcr.uploadRate().toFixed(1)} KB/s`)
        } else {
            log(`uploadPendingRequest failed after ${Date.now()-vcr.uploadStartTimeMs}ms, error=${vcr.error}`)
        }
    }
}