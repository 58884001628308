import React, { FunctionComponent } from "react";
import { addLocale, useLocale as _useLocale } from "ttag";
import { Root } from "../../models3/Root";

export const RootContext = React.createContext<Root | null>(null)

interface IRootProvider {
    initialValue: Root | null,
}

export const RootProvider: FunctionComponent<IRootProvider> = ({ initialValue, children }) => {
    if (initialValue) {
        let { uiLanguage } = initialValue
        if (uiLanguage !== 'en') {  // Strings are already in English
            let translationObject = require(`../../resources/translations/${uiLanguage}.po.json`)
            addLocale(uiLanguage, translationObject)
        }
        _useLocale(uiLanguage)
    }
    return (
        <RootContext.Provider value={initialValue}>
            {children}
        </RootContext.Provider>
    )
}

interface IRootConsumer {
    children: (context: Root | null) => JSX.Element | null
}

export const RootConsumer: FunctionComponent<IRootConsumer> = ({ children }) => {
    return (
        <RootContext.Consumer>
            {context => children(context)}
        </RootContext.Consumer>
    )
}