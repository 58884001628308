import React, { FC, useState, useContext, useEffect } from 'react'
import {observer} from 'mobx-react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { t } from 'ttag'
import { displayError, displayInfo } from '../utils/Errors'
import PortionNameEditor from './PortionEditor'
import { Root } from '../../models3/Root'
import { Portion } from '../../models3/ProjectModels'
import './Portion.css'
import { VideoCache } from '../../models3/VideoCache'
import { RootConsumer } from '../app/RootContext'
import { SortableHandle, SortableElement } from 'react-sortable-hoc'
import { confirmAlert } from 'react-confirm-alert'
import { PortionNotificationList } from '../notifications/Notifications'
import { TrlNotificationList } from "../notifications/TrlNotificationList"
import { useToggle } from '../utils/Hooks'
import { PortionsListTRLContext, ProjectTRLIssue } from '../utils/TRL'
import { TRLPublish } from './TRLPublish'
import { TRLIssueEditor } from './TRLIssueEditor'
import { TitlesEditor } from './TitlesEditor'

export const log = require('debug')('sltt:PortionView')

interface ISortableElement {
    rt: Root,
    portion: Portion,
}

const PortionListItem = SortableElement(
    function (props: ISortableElement) {
        let { rt, portion } = props
        const [toggleBox, setToggleBox] = useToggle('')
        let { iAmInterpreter, useMobileLayout, username, iAmAdmin } = rt
        const trlContext = useContext(PortionsListTRLContext)
        const { projectTrlIssue } = trlContext

        function onDelete() { 
            log('onDelete')
            let { project } = rt

            confirmDeletion( () => {
                project.removePortion(portion._id)
                    .catch(displayError)
            })
        }

        function confirmDeletion(doDeletion: () => void) {
            // No need to confirm, if no video has been recorded yet
            if (!portion.videod()) { doDeletion(); return }

            confirmAlert({
                title: /* translator: important */ t`Delete Video!?`,    
                message: /* translator: important */ t`Video has already been recorded for this portion.`, 
                confirmLabel: /* translator: important */ t`Delete video!`,    
                cancelLabel: /* translator: important */ t`Keep video.`,    
                onConfirm: doDeletion, 
                onCancel: () => {}, 
            })
        }

        const className = toggleBox && 'portion-toggle-box-open'|| ''
        let assigned = portion.passages.some(passage => passage.assignee === username && passage.task !== 'Finished')
        const hasProjectTRLIssue = projectTrlIssue !== ProjectTRLIssue.None
        const trlToggleBox = hasProjectTRLIssue && ToggleBox.TRLIssue || ToggleBox.TRLPublish
        const { project } = rt
        return (
            <div className={className}>
                <div className={`portion-portion ${assigned ? 'passage-assigned' : ''}`}>
                    { iAmInterpreter && !useMobileLayout && <DragHandle /> }
                    <PortionView
                        portion={props.portion}
                        rt={props.rt}
                        onDelete={onDelete}
                        onToggleTitles={() => setToggleBox(ToggleBox.Titles)}
                        onToggleTRL={() => setToggleBox(trlToggleBox)}
                    />
                </div>
                {toggleBox === ToggleBox.Titles && <TitlesEditor
                    portion={portion}
                    toggleTitles={setToggleBox}
                />}
                {toggleBox === ToggleBox.TRLIssue && <TRLIssueEditor
                    {...{ projectTrlIssue }} toggleTRLIssue={setToggleBox}
                />}
                {toggleBox === ToggleBox.TRLPublish && <TRLPublish
                    {...{ rt, portion }} onToggleTRL={() => setToggleBox(ToggleBox.TRLPublish)} 
                />}
            </div>
        )
    }
)

enum ToggleBox {
    Titles = 'titles',
    TRLIssue = 'trl-issue',
    TRLPublish = 'trl-publish',
}

const DragHandle = SortableHandle(() => 
    <RootConsumer>
        {rt => (
            <span className="portion-handle"
                data-toggle="tooltip" 
                title={t`Drag to reorder.`}>
                <i className="fas fa-bars"></i>
            </span>
        )}
    </RootConsumer>
)

interface IDownloadCountView {
    setDownloading: (downloading: boolean) => void,
}

// Show number of video blocks waiting to download
export const DownloadCountView: FC<IDownloadCountView> = ({ setDownloading }) => {
    const [downloadCount, setDownloadCount] = React.useState(VideoCache.videoCacheDownloader.downloadCount)

    // Update download count every 3 seconds.
    // Stop count display when it reaches 0.
    // Always shows for at least 3 seconds so that user can see that something happened when they
    // clicked download.
    useEffect(() => {
        const intervalId = setInterval(() => {
            const _downloadCount = VideoCache.videoCacheDownloader.downloadCount
            setDownloadCount(_downloadCount)
            if (_downloadCount === 0) {
                setDownloading(false)
                clearInterval(intervalId)
            }
        }, 3000) // update every 3 seconds

        return () => {
            clearInterval(intervalId) // Clear the interval when the component is unmounted
        }
    }, [])

    return (
        <div className="download-count">
            <span
                className={`fas fa-cloud-download-alt download-count-icon`}
                data-toggle="tooltip"
                title={/* translator: important */ t`Number of video blocks waiting to download.`}
            />
            <span className="upload-count-value">{downloadCount}</span>
        </div>
    );
}


// Start downloading default videos for all passages in this portion and their notes
async function startPortionDownload(portion: Portion) {
    for (let passage of portion.passages) {
        let video = passage.getDefaultVideo('')
        if (video) {
            await VideoCache.videoCacheDownloader.implicitVideoDownload(video.url)

            for (let note of video.notes) {
                for (let item of note.items) {
                    if (item.url) {
                        await VideoCache.videoCacheDownloader.implicitVideoDownload(item.url)
                    }
                }
            }
        }
    }
}

interface IPortionView {
    rt: Root,
    portion: Portion,
    onDelete: () => void,
    onToggleTitles: () => void,
    onToggleTRL: () => void,
}

const PortionView: FC<IPortionView> = observer((
    {
        rt, portion, onDelete, onToggleTitles, onToggleTRL
    }
) => {
    const [editingPortionName, setEditingPortionName] = useState(false)
    const [editingReferences, setEditingReferences] = useState(false)
    const [downloading, setDownloading] = useState(false)
    
    let { iAmTranslator, useMobileLayout, isTRLProject } = rt

    if (editingPortionName) {
        return <PortionNameEditor {...{ rt, portion, setEditingPortionName }} />
    }

    async function downloadVideos() {
        await startPortionDownload(portion)
        setDownloading(true)
    }

    return (
        <>
            <div title={t`Portion name (double-click to edit)`} className="portion-name"
                    data-id={portion.name} 
                    onDoubleClick={() => setEditingPortionName(true)}>
                    {portion.name}
            </div>
            <div className="portion-notifications">
                <PortionNotificationList {...{ rt, portion, onToggleTitles, onToggleTRL }} />
            </div>
            { !useMobileLayout && (
                <PortionViewMenu {...{ rt, iAmTranslator, portion, onDelete, downloadVideos, setEditingReferences, setEditingPortionName, onToggleTitles, onToggleTRL }} />
            )}
            {isTRLProject && <div className="portion-notifications">
                <TrlNotificationList {...{ rt, portion, onToggleTitles, onToggleTRL }} />
            </div>}
            {downloading && <DownloadCountView {...{ setDownloading }} />}
        </> 
    )
})

interface IPortionViewMenu {
    rt: Root,
    portion: Portion,
    onDelete: () => void,
    downloadVideos: () => void,
    setEditingPortionName: (edit: boolean) => void,
    setEditingReferences: (edit: boolean) => void,
    iAmTranslator: boolean,
    onToggleTitles: () => void,
    onToggleTRL: () => void,
}

const PortionViewMenu: FC<IPortionViewMenu> = ({ rt, portion, onDelete, downloadVideos, 
            setEditingPortionName, setEditingReferences, iAmTranslator }) => 
{
    return (
        <div className="portion-menu">
            {iAmTranslator && <span className="portion-button portion-shadow-menu-item"
                    data-toggle="tooltip" 
                    data-id={'edit-' + portion.name} 
                    title={t`Rename portion.`} 
                    onClick={e => {
                        e.preventDefault()
                        setEditingPortionName(true)
                    }}>
                <i className="fas fa-fw fa-pencil-alt"></i>
            </span>}
            {iAmTranslator && <span className="portion-button portion-shadow-menu-item"
                        data-toggle="tooltip" 
                        data-id={'delete-' + portion.name} 
                        title={t`Delete portion.`}    
                        onClick={e => {
                            e.preventDefault()
                            onDelete()
                        }}>
                <i className="fas fa-fw fa-trash-alt"></i>
            </span>}

            <span className="portion-button portion-download-button portion-shadow-menu-item"
                data-toggle="tooltip"
                data-id={'download-' + portion.name}
                title={t`Download videos in portion to temporary storage on this computer. This allows playing them later without an internet connection or waiting for a download.`}
                onClick={e => {
                    e.preventDefault()
                    downloadVideos()
                }}>
                <i className="fas fa-fw fa-download"></i>
            </span>

            {/* <ReferencesButton
                className='portion-button'
                tooltip={t`Edit references for this portion`}
                onClick={(e: any) => {
                    e.preventDefault()
                    setEditingReferences(true)
                }}
            /> */}
        </div>
    )
}

export default PortionListItem
