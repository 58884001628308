// Play video based on events signaled on Root.
// rt.passageVideo is the video that is played.
// Handles stepping through passages on "play all passages" event

import React, { Component } from 'react'
import { observable, observe} from 'mobx'
import {observer} from 'mobx-react'
import '../../../node_modules/react-input-slider/dist/input-slider.css'

import { Root } from '../../models3/Root'
import './Video.css'
import { PassageVideo } from '../../models3/ProjectModels'
import { displayError, displayInfo } from '../utils/Errors'
import SegmentLabelsPosition from '../segments/SegmentLabelsPosition'
import InputSlider from 'react-input-slider'
import { createFalse } from 'typescript'
import { fmt } from '../utils/Fmt'
import VideoPlayer from './VideoPlayer'
import { SlttHelp2 } from '../utils/SLTTHelp2'

/**
 * RootVideoPlayer - communitate to/from root to player
 *    VideoPlayer - handle changes at semgnet/patch boundaries
 *       CoreVideoPlayer - On request select/play main video or patch. Manage time poller.
 */

// Sticking an - in VideoPlayer in order to allow using chrome developer
// tools log filtering to distinguish between VideoPlayer and RootVideoPlayer
// messages.
const log = require('debug')('sltt:RootVideo-Player')

interface IRootVideoPlayer {
    rt: Root,
    showSegmentLabels?: boolean,
    setContainerHeight: (height: number) => void,
    initialTime?: number,
}

@observer
export default class RootVideoPlayer extends Component<IRootVideoPlayer> {
    playAllInProgress = false
    // @observable passageVideo: PassageVideo | null = null
    
    videoPlayer: VideoPlayer | null = null

    resetTime = 0 // If resetTimeRequested, reset time to this point when play ends
    resetTimeRequested = false
    rootVideoPlayerRef = React.createRef<HTMLDivElement>()
    @observable videoWidth = 0

    constructor(props: IRootVideoPlayer) {
        super(props)
        let { rt } = this.props

        this.play = this.play.bind(this)
        this.playAll = this.playAll.bind(this)
        this.stopAndDoNotReset = this.stopAndDoNotReset.bind(this)
        this.stopAndReset = this.stopAndReset.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)

        this.onEnded = this.onEnded.bind(this)
        this.abandonPlayAllInProgress = this.abandonPlayAllInProgress.bind(this)
        this.onSegmentChanged = this.onSegmentChanged.bind(this)
        this.onPlayingStatus = this.onPlayingStatus.bind(this)
        this.setVideoWidth = this.setVideoWidth.bind(this)
        this.setVideoHeight = this.setVideoHeight.bind(this)
        this.videoPlayerStopped = this.videoPlayerStopped.bind(this)
        this.getVideoPlayer = this.getVideoPlayer.bind(this)

        this.addListenters(rt)

        this.setPassageVideo()
    }

    addListenters(rt: Root) {
        if (rt.rootVideoPlayerListenersAdded) return
        log('addListenters', rt.project.name)

        rt.addListener('play', this.play)
        rt.addListener('pause', this.stopAndDoNotReset)
        rt.addListener('playAll', this.playAll)
        rt.addListener('stop', this.stopAndReset)
        rt.addListener('setCurrentTime', this.setCurrentTime)
        rt.addListener('setPassageVideo', this.setPassageVideo)
        rt.addListener('onEnded', this.onEnded)

        rt.rootVideoPlayerListenersAdded = true
    }

    removeListenters(rt: Root) {
        if (!rt.rootVideoPlayerListenersAdded) return
        log('removeListenters', rt.project.name)

        rt.removeListener('play', this.play)
        rt.removeListener('pause', this.stopAndDoNotReset)
        rt.removeListener('playAll', this.playAll)
        rt.removeListener('stop', this.stopAndReset)
        rt.removeListener('setCurrentTime', this.setCurrentTime)
        rt.removeListener('setPassageVideo', this.setPassageVideo)
        rt.removeListener('onEnded', this.onEnded)

        rt.rootVideoPlayerListenersAdded = false
    }

    componentWillUnmount() {
        // log('componentWillUnmount', this.props.rt.project.name, this.constructTime)
        /**
         * When we switch projects sometimes this gets called.
         * componentDidUpdate will re-add them if necessary.
         */
        this.removeListenters(this.props.rt)
    }

    componentDidMount() {
        this.setVideoHeight()
        this.addListenters(this.props.rt)
    }

    componentDidUpdate(prevProps: IRootVideoPlayer) {
        // log('componentDidUpdate', this.props.rt.project.name)
        this.setVideoHeight()
        // componentDidUpdate gets called when we switch projects giving us a chance to re-add the listeners
        // if they were removed by componentWillUnmount above.
        if (prevProps.rt.name !== this.props.rt.name) {
            this.removeListenters(prevProps.rt)
            this.addListenters(this.props.rt)
        }
    }

    // When root.passageVideo changes setup to display the newly
    // chosen value.
    setPassageVideo = () => {
        let { rt } = this.props
        let { passageVideo, currentVideos } = rt
        if (!passageVideo) return

        let { playAllInProgress } = this

        log('setPassageVideo', fmt({playAllInProgress, passageVideo}))
        
        let vp = this.getVideoPlayer()
        vp && vp.stop()

        currentVideos
            .waitUntilDownloaded()
            .then(() => {
                log('passageVideoHasChanged downloaded')
                if (this.playAllInProgress) this.play()
            })
    }

    setVideoHeight() {
        let { current } = this.rootVideoPlayerRef
        let { setContainerHeight } = this.props
        if (current) {
            let { width } = current.getBoundingClientRect()
            let height = width * 0.5625 // 16:9 aspect ratio
            height = Math.min(height, window.innerHeight - 75)
            setContainerHeight(height)
        }
    }

    render() {
        let { rt, showSegmentLabels, initialTime } = this.props
        let { playAllInProgress, videoWidth, setVideoWidth } = this
        let { passage, currentVideos, passageVideo, editingSegmentLabels, segmentLabelsDraft,
            setSegmentLabelsDraft, playbackRate, passageSegment, videoPlaybackKeydownEnabled } = rt

        if (!passageVideo || !passage) { return null }
        
        //log('render', fmt({passageVideo}))

        let tooltip = `Speed = ${playbackRate.toFixed(1)}`

        let segment = passageSegment?.actualSegment(passage)

        return (
            <div className='root-video-player'>
                <div className='video-player-container' ref={this.rootVideoPlayerRef}>
                    <VideoPlayer
                        ref={videoPlayer => this.videoPlayer = videoPlayer}
                        passage={passage}
                        video={passageVideo}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onEnded={() => rt.emit('onEnded')}
                        disablePlay={!videoPlaybackKeydownEnabled}
                        onTick={currentTime => rt.setCurrentTime(currentTime)}
                        onPlayingStatus={this.onPlayingStatus}
                        onSegmentChange={this.onSegmentChanged}
                        autoPlay={playAllInProgress}
                        play={rt.play}
                        stop={this.videoPlayerStopped}
                        setVideoWidth={setVideoWidth}
                        initialTime={initialTime}
                    >
                        {(showSegmentLabels && segment) && ( 
                            <SegmentLabelsPosition {...{ segmentLabelsDraft, editingSegmentLabels, 
                                setSegmentLabelsDraft, segment, videoWidth }} /> )} 
                    </VideoPlayer>
                </div>
                <div className='video-player-controls'>
                    <div className="u-slider u-slider-y video-player-slider">
                        <SlttHelp2 id="playbackrate"
                                tooltip={tooltip}
                                place="bottom"
                                video="a8">
                            <InputSlider
                                className="slider video-rate-input-slider"
                                slidertooltip={tooltip}
                                axis="y"
                                y={2.0 - playbackRate}
                                ymax={2}
                                ystep={0.1}
                                onChange={this.onRateChange} />
                        </SlttHelp2>
                    </div>
                </div>
            </div>
        )
    }

    videoPlayerStopped(hitEndingTime?: boolean) {
        let { rt } = this.props
        let { resetTimeRequested, resetTime } = this
        log('video-PlayerStopped', fmt({ hitEndingTime, resetTimeRequested, resetTime }))

        if (resetTimeRequested) {
            rt.pause()
            rt.resetCurrentTime(resetTime)
            this.resetTimeRequested = false
            return
        }

        rt.pause()
    }

    setVideoWidth(width: number) {
        this.videoWidth = width
    }

    onRateChange = (pos: any /* {x:, y: } */) => {
        console.log(`rateChange ${pos.y}`)
        let { setPlaybackRate } = this.props.rt
        let playbackRate = 2.0 - pos.y
        setPlaybackRate(playbackRate)
    }

    onEnded() {
        let { rt } = this.props
        let { passage, portion, passageVideo } = rt
        log(`onEnded playAll=${this.playAllInProgress}, ${passageVideo && passageVideo._id}`)

        if (!this.playAllInProgress) return

        // move to next videoPassage when doing "play all passages"

        if (!portion || !passage) {
            this.abandonPlayAllInProgress('no portion or no passage')
            return
        }

        let { passages } = portion
        let playablePassages = passages.filter(p => p.videosNotDeleted.length > 0)
        let passageIndex = playablePassages.findIndex(p => p._id === passage!._id)
        log(`onEnded playAll passageIndex=${passageIndex}`)
        if (passageIndex < 0) {
            this.abandonPlayAllInProgress('could not find passage')
            return
        }

        if (passageIndex >= playablePassages.length-1) {
            this.abandonPlayAllInProgress('onEnded playAll stop')
            return
        }

        let nextPassage = playablePassages[passageIndex + 1]
        log('nextPassage', nextPassage._id, this.playAllInProgress)

        rt.setPassage(nextPassage)
            .catch(displayError)

        // let nonPatchVideos = nextPassage.videosNotDeleted.filter(v => !v.isPatch)
        // let video = nonPatchVideos.slice(-1)[0]
        // if (!video) {
        //     this.abandonPlayAllInProgress('video had no non-patches')
        //     return
        // }

        // log('onEnded playAll play next', video._id)
        // this.passageVideo = video // trigger display/play of next passage
    }

    abandonPlayAllInProgress(msg: string) {
        log('abandonPlayAllInProgress')

        this.stopAndDoNotReset()
        this.playAllInProgress = false
        log(msg)
    }

    onPlayingStatus(playing: boolean) {
        let { rt } = this.props
        if (playing === rt.playing) return

        let { resetTime, resetTimeRequested } = this
        rt.playing = playing
        log('onPlayingStatus', fmt({playing, resetTimeRequested, resetTime}))

        // If user requested to reset time at end of play, do so
        if (!playing && resetTimeRequested) {
            setTimeout(() => rt.resetCurrentTime(resetTime), 800)
            this.resetTimeRequested = false
        }
    }

    playAll() {
        log('playAll')
        if (!this.getVideoPlayer()) return

        this.playAllInProgress = true
        this.play()
    }

    // startTime = undefined, means play from current position.
    // endTime = undefined, means play through until end.
    // resetTime = undefined, means when you reach endTime stop there
    //             otherwise go to resetTime.
    // If a selection is present in the timeline and the caller has
    // not specified a startTime, play the selection.

    play(startTime?: number, endTime?: number, resetTime?: number) {
        let { rt } = this.props
        let { currentTime, selectionStartTime, selectionEndTime, passageVideo } = rt

        log('play', fmt({
            startTime, endTime, resetTime,
            currentTime,
            duration: passageVideo?.computedDuration
        }))

        // If we have a start time that is close to or beyond our best guess for
        // the duration of this video, then assume the user really wants to play
        // from time 0. Issue #218.
        const _startTime = startTime ?? currentTime
        if (passageVideo) {
            let duration = passageVideo.computedDuration
            if (duration > 1 && _startTime + .1 > duration) {
                log('close to end of video, reset to play at start', fmt({startTime, duration}))
                startTime = 0
            }
        }

        log('play', fmt({ 
            startTime, endTime, resetTime,
            currentTime, selectionStartTime, selectionEndTime }))

        if (startTime === undefined && rt.selectionPresent()) {
            startTime = selectionStartTime
            endTime = selectionEndTime
            resetTime = startTime
        }

        startTime = startTime ?? currentTime
        this.resetTimeRequested = (resetTime !== undefined)
        this.resetTime = resetTime ?? 0

        let vp = this.getVideoPlayer()
        if (vp) {
            vp.play(startTime, endTime)
                .then(() => this.addViewedBy())
                .catch(displayError)
        }
    }

    async addViewedBy() {
        let { rt } = this.props
        let { username, passageVideo } = rt
        
        if (passageVideo) {
            await passageVideo.addViewedBy(username)
        }
    }

    stopAndDoNotReset() {
        log('stopAndDoNotReset')
        
        this.resetTimeRequested = false
        let vp = this.getVideoPlayer()
        vp?.stop()
    }

    stopAndReset() {
        log('stop')
        this.resetTimeRequested = true
        let vp = this.getVideoPlayer()
        vp && vp.stop()
    }

    // Invoked externally based on event
    // Sets the currently active video as well as the current time in that video
    setCurrentTime(newTime: number) {
        let vp = this.getVideoPlayer()
        log('setCurrentTime2', fmt({newTime, vp: vp && '*present*'}))

        vp && vp.setCurrentTime(newTime)
    }

    onSegmentChanged(videoId: string, segmentIndex: number) {
        let { rt } = this.props
        let { passage, passageVideo, passageSegment } = rt

        if (!passageVideo || !passage) {
            log('onSegmentChange !passageVideo || !passage')
            return
        }

        // Ignore segment changes if not the currently selected video
        if (passageVideo._id !== videoId) return

        log('onSegmentChanged', fmt({videoId, segmentIndex}))
        let segment = passageVideo.segments[segmentIndex]
        if (!segment || segment._id === passageVideo?._id) return
        
        rt.setPassageSegment(segment)
    }

    getVideoPlayer() {
        let { videoPlayer } = this
        if (!videoPlayer) log('### no videoPlayer present')
        return videoPlayer
    }
}
