
import React, { Component, FC, FunctionComponent, useEffect, useRef, useState } from 'react'
import { observable } from 'mobx'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import { displayError, displayInfo, systemError } from '../utils/Errors'
import VideoRecorder, { RecordingState } from '../video/VideoRecorder'
import { NoteItem } from './NoteItem'
import { Passage, PassageNote, PassageNoteItem, PassageVideo } from '../../models3/ProjectModels'
import NoteLocationEditor from '../video/NoteLocationEditor'
import MediaThumbnail from '../utils/MediaThumbnail'
import { WarningIcon } from '../utils/Icons'
import { CancelButtonWithLabel, PauseButtonWithLabel, RecordButtonWithLabel, StopButtonWithLabel } from '../utils/Buttons'
import { ClipboardButton, PencilButton, SlttHelp } from '../utils/Buttons'
import TextInput from '../utils/TextInput'
import { IDateFormatter } from '../../models3/DateUtilities'
import routePrefix from '../app/routePrefix'
import { NoteTextEditor } from './NoteTextEditor'
import { fmt, s } from '../utils/Fmt'
import { IRoot } from '../../models3/Root'
import { prepNotification } from '../utils/PrepNotification'
import { ResumableVideoRecorder } from './ResumableVideoRecorder'
import { NoteReactionPicker } from './NoteReaction'  
import { uploadNote, uploadNoteIfCan  } from './NoteDropTarget';
import { allowedImageTypes } from '../images/AllowedImages'
import { canUploadWithoutCompressionTypes } from '../images/AllowedVideos'
const log = require('debug')('sltt:NoteMain')
const intest = (localStorage.getItem('intest') === 'true')


interface INoteMain {
    noteRoot: IRoot,
    closeNoteDialog: () => void,
    editing: boolean,
    setEditing: (value: boolean) => void,
    searchResult: string,
        // '' - no search result currently selected
        // PassageNote._id - text was found in note descirption
        // PassageNoteItem._id - text was found in a note item
    searchText: string,
}

@observer
export class NoteMain extends Component<INoteMain>  {
    @observable recordingVideoItem?: PassageNoteItem
    @observable editingTextItem?: PassageNoteItem
    @observable currentNote = this.props.noteRoot.note
    @observable firstTimeSaved = false

    constructor(props: INoteMain) {
        super(props)
        this.setEditingTextItem = this.setEditingTextItem.bind(this)
    }

    componentDidUpdate() {
        if (this.currentNote?._id !== this.props.noteRoot.note?._id) {
            this.setEditingTextItem(undefined)
            this.props.setEditing(false)
            this.currentNote = this.props.noteRoot.note
        }
    }

    componentWillUnmount() {
        this.props.setEditing(false)
    }

    setEditingTextItem(item?: PassageNoteItem) {
        this.editingTextItem = item
        this.props.setEditing(!!item)
    }

    render() {
        let { noteRoot, closeNoteDialog, editing, setEditing, searchResult, searchText } = this.props
        let { name, note, passage, passageVideo, dateFormatter, canViewConsultantOnlyFeatures, iAmInterpreter } = noteRoot
        let { recordingVideoItem, createLinkToNote, editingTextItem } = this

        if (!passage || !passageVideo || !note) { return null }

        if (recordingVideoItem) {
            return (
                <ResumableVideoRecorder
                    cancel={this.closeRecorder}
                    url={recordingVideoItem.url}
                    projectName={name}
                    save={this.addVideoDone.bind(this)} />
            )
        }

        if (editingTextItem) {
            return (
                <div>
                    <NoteTextEditor
                        initialText=''
                        onSave={async text => {
                            log('NoteTextEditor onSave', text)
                            await this.saveNoteItemChanges.bind(this)(text)
                            this.firstTimeSaved = false
                            this.setEditingTextItem(undefined)
						    prepNotification({ text, noteRoot })
                        }}
                        onCancel={() => {
                            log('NoteTextEditor onCancel')
                            this.firstTimeSaved = false
                            this.setEditingTextItem(undefined)
                        }}
                    />
                </div>
            )
        }

        let allowEditing = !editing
        return (
            <div className='note-main-body'>
                <NoteMessage {...{ passage, note, video: passageVideo, dateFormatter }} />
                
                <div className="note-main-header">
                    <div className="note-main-header-left">
                        <NoteCitation {...{ noteRoot, setEditing, allowEditing, position: noteRoot.note!.position }} />
                        <div className='note-description-wrapper'>
                            <NoteDescription {...{ noteRoot, setEditing, allowEditing,
                                searchResult, searchText }} />
                        </div>
                    </div>
                    <div>
                        <ClipboardButton
                            buttonClassName=''
                            className='clipboard-button'
                            enabled={true}
                            onClick={createLinkToNote}
                            tooltip={t`Create link.`}
                        />
                    </div>
                </div>
                {note.visibleItems(canViewConsultantOnlyFeatures).map((item, i) => (
                    <NoteItem key={item._id} {...{ noteRoot, item, closeNoteDialog, setEditing,    
                             allowEditing, searchResult, searchText }} />
                ))}
                <NoteAddItemButtons
                    iAmInterpreter={iAmInterpreter}
                    note={note}
                    noteRoot={noteRoot}
                    addText={this.createNoteItem}
                    addVideo={this.addVideo}
                    addReaction={this.addReaction}
                    emojis={noteRoot.project.emojis}
                />
            </div>
        )
    }

    createLinkToNote = async () => {
        let { noteRoot: rt } = this.props
        let { note, currentTime, project } = rt
        let isPublicUrl = !!process.env.PUBLIC_URL
        let origin = isPublicUrl ? 'https://sltt-bible.net' : 'localhost:3000'
        let text = `${origin}${routePrefix}/#/index.html?project=${project.name}&id=${note!._id}&time=${currentTime}`
        try {
            await navigator.clipboard.writeText(text)
            displayInfo(t`Link copied to clipboard. You can paste this into a note or an email.`)
        } catch (error) {
            displayError(error, 'Failed to copy!')
        }
    }

    saveNoteItemChanges = async (text: string) => {
        let { noteRoot } = this.props
        let { editingTextItem, firstTimeSaved } = this
        let { passage } = noteRoot

        if (!passage) {
            log('### no passage')
            debugger
            return
        }

        if (!editingTextItem) {
            log('### no editingTextItem')
            debugger
            return
        }

        let note = passage.findNote(editingTextItem._id)
        if (!note) {
            log('### cannot find note')
            debugger
            return
        }
        log('saveNoteItemChanges', fmt({text, note, editingTextItem, firstTimeSaved}))

        try {
            let items = note.items
            let exists = items.find(item => item._id === editingTextItem?._id)
            if (exists && firstTimeSaved && text.trim() === '') {
                log('saveNoteItemChanges remove item')
                await note.removeItem(editingTextItem._id)
            } 
            else if (exists && !firstTimeSaved) {
                log('saveNoteItemChanges update item')
                await editingTextItem.updateText(text)
            } 
            else if (!exists && text.trim() !== '') {
                log('saveNoteItemChanges add item')
                editingTextItem.text = text
                await note!.addItem(editingTextItem, passage)
                this.firstTimeSaved = true
            }
        } catch (err) {
            systemError(err)
        }
    }

    createNoteItem = async () => {
        let { noteRoot } = this.props

        let { createNoteIfNonexistent, note } = noteRoot

        try {
            // ### It is probably a mistake to call this here.
            // If there are no note items yet and the user ends up cancel the
            // addition of this item, it will create an empty note on the video.
            // The user does not see this because notes with no items are not shown.
            //
            // We should probably move this call to saveNoteItemChanges.
            // However, the whole process if note creation is both critically important
            // and complicated so I am reluctant to make this change.
            await createNoteIfNonexistent()
            
            let item = note!.createItem()
            this.setEditingTextItem(item)
        } catch (err) {
            this.setEditingTextItem(undefined)
            systemError(err)
        }
    }

    addReaction = async (reaction: string) => {
        const { noteRoot } = this.props
        const { note, createNoteIfNonexistent, passage } = noteRoot

        if (!passage) {
            log('### no passage')
            return
        }

        try {
            await createNoteIfNonexistent()

            const item = note!.createItem()
            item.text = reaction
            await note!.addItem(item, passage)

            prepNotification({ text: reaction, noteRoot })
        } catch (err) {
            systemError(err)
        }
    }

    addVideo = () => {
        let { noteRoot, setEditing } = this.props
        let { note, name } = noteRoot
        this.recordingVideoItem = note!.createItem()
        this.recordingVideoItem.url = `${name}/${this.recordingVideoItem._id}`
        setEditing(true)
    }

    addVideoDone = async (err: any, blobsCount?: number, url: string = '', duration: number = 0) => {
        let { recordingVideoItem } = this
        let { noteRoot } = this.props
        let { note, passage, createNoteIfNonexistent } = noteRoot
        
        if (err) {
            this.recordingVideoItem = undefined
            displayError(err)
            return
        }

        if (intest) { // force to test url which is always present
            recordingVideoItem!.url = url
        }

        recordingVideoItem!.url = recordingVideoItem!.url + '-' + blobsCount

        try {
            await createNoteIfNonexistent()
            await note!.addItem(recordingVideoItem!, passage)
            this.recordingVideoItem = undefined
            this.props.setEditing(false)
            prepNotification({ text: `Video added to note.`, noteRoot, isVideoRecording: true })
        } catch (error) {
            systemError(err)
        }
    }

    closeRecorder = () => {
        this.recordingVideoItem = undefined
        this.props.setEditing(false)
    }
}


interface INoteAddItemButtons {
    addText: () => void
    addVideo: () => void
    addReaction: (reaction: string) => Promise<void>
    iAmInterpreter: boolean,
    note: PassageNote,
    noteRoot: IRoot,
    emojis: string,
}
const NoteAddItemButtons: FunctionComponent<INoteAddItemButtons> = ({
    iAmInterpreter, note, noteRoot, addText, addVideo, addReaction, emojis }) => {
    if (!iAmInterpreter || note?.resolved) { return null }
    const uploadNoteFileInputRef = useRef<HTMLInputElement>(null)
    const { name, passage, setNote, username } = noteRoot
    const titleRecordNote = /* translator: important */ t`Record Note`
    const titleWriteNote = /* translator: important */ t`Write Note`
    const titleUploadNote = /* translator: important */ t`Upload Note`
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}> 
        <div className="note-add-buttons">
                <div className="note-record-button">
                    <div className=" note-record-button-video et-right fa fa-3x fa-circle text-danger" 
                            data-id="create-video-note-item"
                            onClick={addVideo} title={titleRecordNote}/>
                    <div>{titleRecordNote}</div>
                </div>
                <div className="note-record-button">
                    <div className="note-record-button-write et-right fa fa-3x fa-edit" 
                            data-id="create-text-note-item"
                            onClick={addText}
                            title={titleWriteNote} />
                    <div>{titleWriteNote}</div>
                </div>
                <div className="note-record-button">
                    <div className="note-record-button-upload et-right fa fa-3x fa-cloud-arrow-up"
                        data-id="create-file-note-item"
                        onClick={() => uploadNoteFileInputRef.current?.click()} title={t`Upload or drop video or image as a note`} />
                    <input
                        type="file"
                        ref={uploadNoteFileInputRef}
                        accept={`${[...canUploadWithoutCompressionTypes, ...allowedImageTypes, 'application/json' /* elan */].join(',')}`}
                        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                            // NOTE: onChange() is triggered after user selects files prompted by the onClick() handler using uploadNoteFileInputRef above
                            const { files } = event.target
                            if (!files || files.length === 0) return
                            await uploadNoteIfCan((file) => uploadNote(note, username, name, file, passage, setNote), noteRoot, files)
                        }}
                        style={{ display: 'none' }}
                    />
                    <div>{titleUploadNote}</div>
                </div>
                <NoteReactionPicker {...{ emojis, addReaction }} />
            </div>
        </div>
    )
}

interface INoteMessage {
    passage: Passage | null,
    video: PassageVideo,
    note: PassageNote,
    dateFormatter: IDateFormatter,
}

const NoteMessage: FunctionComponent<INoteMessage> = ({ passage, video, note, dateFormatter }) => {
    if (note.onTop && !note.inIgnoredSegment) return null
    
    let noteVideo = note.toVideo(passage!)
    if (!noteVideo) return null

    let patchedOverMessage = ''
    if (!note.onTop) {
        if (noteVideo._id === video?._id) {
            patchedOverMessage = t`Note does not apply to current patch. It applies to original video.`
        } else {
            let videoDate = new Date(noteVideo.creationDate)
            let visibleVideoDate = dateFormatter.format(videoDate)
            patchedOverMessage = t`Note does not apply to current patch. It applies to ${visibleVideoDate} patch.`
        }
    }

    return (
        <div className='note-messages'>
            <div className='note-messages-icons'>
                <WarningIcon className='note-messages-warning-icon' tooltip='' />
            </div>
            <div className='note-messages-list'>
                <div>{patchedOverMessage}</div>
                { note.inIgnoredSegment && <div>{t`Note is in a segment that is being ignored.`}</div> }
            </div>
        </div>
    )
}


interface INoteCitation {
    noteRoot: IRoot,
    allowEditing: boolean,
    setEditing: (value: boolean) => void,
    position: number,
}

// Show the user the citation from the main video that this note applies to.
// Allow the user to adjust the citation.

const NoteCitation: FunctionComponent<INoteCitation> = ({ noteRoot, allowEditing, setEditing, position }) => {
    let [adjusting, setAdjusting] = useState(false)

    let tooltip = /* translator: important */ t`Play video passage for note.`
    let id = 'notes.html#notecitation'

    let { passage, passageVideo, note } = noteRoot

    if (!passageVideo || !passage) return null

    let citationVideo = passage.findVideo(note!._id)
    if (!citationVideo) return null
    
    function _setAdjusting(value: boolean) {
        setAdjusting(value)
        setEditing(value)
    }
    
    let baseVideo = citationVideo?.baseVideo(passage) || citationVideo
    if (!adjusting) return (
        <div className='media-thumbnail note-citation' data-tip data-for={id}>
            <SlttHelp id={id} tooltip={tooltip} place="top">
                <MediaThumbnail
                    url={citationVideo.url}
                    creator={baseVideo.creator}
                    isImage={false}
                    currentTime={position}
                    onClickVideo={() => allowEditing && _setAdjusting(true) }
                    onClickImage={() => { }} />
            </SlttHelp>
        </div>
    )

    return (
        <NoteLocationEditor {...{ noteRoot }}
            onDoneEditing={() => _setAdjusting(false)}
        />
    )
}


interface INoteSearchTarget {
    searchResult: string,
    _id: string,
    className: string,
    children: JSX.Element,
}

export const NoteSearchTarget: FC<INoteSearchTarget> = ({searchResult, _id, className, children}) =>
{
    const targetRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (searchResult === _id) {
            targetRef.current?.scrollIntoView()
        }
    }, [searchResult])

    className = 'note-search-target ' + className

    if (searchResult === _id) {
        className += ' note-search-target-selected'
    }

    return (
        <div ref={targetRef} {...{className}}>
            {children}
        </div>
    )
}

interface INoteDescription {
    noteRoot: IRoot,
    allowEditing: boolean,
    setEditing: (value: boolean) => void,
    searchResult: string,
    searchText: string,
}

const NoteDescription: FunctionComponent<INoteDescription> = ({ 
        noteRoot, setEditing, allowEditing, searchResult, searchText }) => {
    let { iAmAdmin, note, username, useMobileLayout, createNoteIfNonexistent } = noteRoot
    let { description } = note!

    let [editing, setLocalEditing] = useState(false)

    function _setEditing(value: boolean) {
        setLocalEditing(value)
        setEditing(value)
    }

    let placeholder = /* translator: important */ t`Note description...`
    let canEdit = !note!.resolved && (iAmAdmin || note!.creator === username)

    if (!canEdit) {
        if (description) return (
            <NoteSearchTarget className="note-description" {...{searchResult, _id: note!._id}} >
                <span>{description}</span>
            </NoteSearchTarget>
        )
        return null
    }

    if (!editing) {
        let tooltip = /* translator: important */ t`Write a short description for a note.`
        let id = 'notes.html#notedescription'

        return (
            <NoteSearchTarget className="note-description" {...{ searchResult, _id: note!._id }} >
                <div className="note-description">
                    {!description && <i>{placeholder}</i>}
                    {description}
                    {allowEditing && !useMobileLayout && 
                        <SlttHelp id={id} tooltip={tooltip} place="top">
                            <PencilButton
                                enabled='true'
                                className='note-item-edit-button'
                                onClick={() => _setEditing(true)}
                                tooltip="" />
                        </SlttHelp>
                    }
                </div>
            </NoteSearchTarget>
        )
    }

    const save = async (value: string) => {
        try {
            await createNoteIfNonexistent()
            await note!.setDescription(value)
            prepNotification({ text: value, noteRoot })
            _setEditing(false)
        } catch (err) {
            systemError(err)
        }
    }

    return (<TextInput 
        initialValue={description}
        message=""
        placeholder={placeholder}
        _onEnter={save}
        _onEscape={() => _setEditing(false)}
        allowEmptyValue={true}
    />)
}