import React, { FunctionComponent } from 'react'
import ReactSwitch from "react-switch"

const log = require('debug')('sltt:Switch') 

interface ISwitch {
    value: boolean,
    setValue: (value: boolean) => void,
    tooltip: string,
    enabled?: boolean,
    className?: string,
}

export const Switch: FunctionComponent<ISwitch> = ({value, setValue, enabled, tooltip, className, children  }) => {
    // log(`render ${value} [${tooltip}]`)

    return (
        <span className={className} data-toggle="tooltip" title={tooltip}>
            {children}
            <ReactSwitch
                aria-label={tooltip}
                checked={value}
                onChange={(value) => {
                    log(`onChange =${value}`)
                    setValue(value)
                }}
                disabled={enabled === false}
                height={15}
                width={30}
                handleDiameter={20}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                uncheckedIcon={false}
                checkedIcon={false}
            />
        </span>
    )
}
