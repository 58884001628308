import React, { FC, useEffect, useState } from 'react'
import { t } from 'ttag'

const log = require('debug')('sltt:TextSearchParameters') 

import { observer } from 'mobx-react'
import { checkedItemName, SearchableItemsSelector } from './TextSearchItemsSelector'
import { SearchIcon } from './Icons'

import './Utils.css'
import { CancelButton, CheckboxTreeButton, OKButton, PaneCloseButton } from './Buttons'
import { Passage, PassageVideo, Portion, Project } from '../../models3/ProjectModels'
import { s } from './Fmt'
import { CheckedItem } from '../../models3/RootBase'
import { IRoot } from '../../models3/Root'

/** <TextSearchParameters>
 * Allow user to enter the following parameters for searching for a text string
 * 
 *    text to search for
 *    what portions, passages, passageVideso to search
 *    (optional) whether to include resolved notes
 */

interface ISearchText {
    searchText: string,
    setSearchText: (searchText: string) => void,
    placeholder: string,
}

const SearchText: FC<ISearchText> = ({ searchText, setSearchText, placeholder }) => 
{
    return (
        <input type="text"
            autoFocus
            className={'utils-search-text'}
            onChange={element => setSearchText(element.target.value)}
            value={searchText}
            placeholder={placeholder} />
    )
}

interface ISelectSearchableItems {
    rt: IRoot,
    searchableItemsOpen: boolean,
    setSearchableItemsOpen: (searchableItemsOpen: boolean) => void,
    searchChecked: CheckedItem[], // searchChecked array can contain: portion ids, passage ids, video ids
    setSearchChecked: (searchChecked: CheckedItem[]) => void,
}

const SelectSearchableItems: FC<ISelectSearchableItems> = (
    { rt, searchChecked, setSearchChecked, searchableItemsOpen, setSearchableItemsOpen }) => 
{
    let enabled = true
    let buttonClassName = ''
    let className = ''
    let tooltip = ''

    let [_checked, _setChecked] = useState(searchChecked.slice())

    return (
        <div className="utils-select-searchable-items">
            <div className="utils-searchable-items-selector">
                <SearchableItemsSelector {...{ rt, checked: _checked, setChecked: _setChecked }} />
            </div>
            <div className="utils-searchable-items-buttons">
                <OKButton {... {
                    enabled, buttonClassName, className, tooltip,
                    onClick: () => { setSearchChecked(_checked); setSearchableItemsOpen(false) }
                }} />
                <CancelButton {... {
                    enabled, className, tooltip,
                    onClick: () => { setSearchableItemsOpen(false) }
                }} />
            </div>
        </div>
    )
}

interface IDisplaySearchableItems {
    rt: IRoot,
    searchChecked: CheckedItem[], 
    setSearchableItemsOpen: (searchableItemsOpen: boolean) => void,
}

const DisplaySearchableItems: FC<IDisplaySearchableItems> = ({ rt, searchChecked, setSearchableItemsOpen }) => {
    let names = searchChecked.map(item => checkedItemName(rt, item, searchChecked)).filter(name => name)

    let display = '???'

    if (names.length > 0) {
        display = names[0]
        if (names.length > 1) {
            display += ` ... (+${names.length - 1})`
        }
    }

    return (
        <div className="utils-display-searchable-items" data-toggle="tooltip" title={names.join('; ')}>
            {display}
            <div className="utils-checkbox-tree-button">
                <CheckboxTreeButton {...{
                    className: '', 
                    enabled: true, 
                    onClick: () => { setSearchableItemsOpen(true) }, 
                    tooltip: t`Click to select passages to search`}} />
            </div>
        </div>
    )
}

interface IDisplayCheckboxTreeButton {
    rt: IRoot,
    searchChecked: CheckedItem[],
    setSearchableItemsOpen: (searchableItemsOpen: boolean) => void,
}

const DisplayCheckboxTreeButton: FC<IDisplayCheckboxTreeButton> = ({ rt, searchChecked, setSearchableItemsOpen }) => {
    return (
        <div className="utils-checkbox-tree-button">
            <CheckboxTreeButton {...{
                className: '',
                enabled: true,
                onClick: () => { setSearchableItemsOpen(true) },
                tooltip: t`Click to select passages to search`
            }} />
        </div>
    )
}

interface IIncludedResolvedNotesSelector {
    includeResolvedNotes?: boolean,
    setIncludeResolvedNotes?: (includeResolvedNotes: boolean) => void,
}

const IncludedResolvedNotesSelector: FC<IIncludedResolvedNotesSelector> = 
    ({ includeResolvedNotes, setIncludeResolvedNotes }) => 
{
    let tooltip = t`Do not include resolved notes in search. Click to include resolved notes in search`
    if (includeResolvedNotes) {
        tooltip = t`Include resolved notes in search. Click to exclude resolved notes from search.`
    }

    if (includeResolvedNotes) {
        return (
            <span className="utils-include-resolved-notes" 
                    data-toggle="tooltip"
                    title={tooltip} 
                    onClick={() => { setIncludeResolvedNotes!(false) }}>
                {"\u25ef"}
            </span>
        )
    }

    return (
        <span className="utils-exclude-resolved-notes" 
                data-toggle="tooltip"
                title={tooltip} 
                onClick={() => { setIncludeResolvedNotes!(true) }}>
            {"\u2205"}
        </span>
    )
}

type ITextSearchParameters = {
    rt: IRoot,
    searchText: string,
    setSearchText: (searchText: string) => void,
    searchIsOpen: boolean,
    setSearchIsOpen: (searchIsOpen: boolean) => void,
    searchChecked: CheckedItem[], // searchChecked array can contain: portion ids, passage ids, video ids
    setSearchChecked: (searchChecked: CheckedItem[]) => void, 
    
    includeResolvedNotes?: boolean,
    setIncludeResolvedNotes?: (includeResolvedNotes: boolean) => void,
}

export const TextSearchParameters: FC<ITextSearchParameters> = observer((
    { rt, searchText, setSearchText, searchIsOpen, setSearchIsOpen, searchChecked, setSearchChecked, includeResolvedNotes, setIncludeResolvedNotes }) => 
{
    searchChecked.length // force access to searchChecked so that we rerender on change

    let [searchableItemsOpen, setSearchableItemsOpen] = useState(false)

    let searchingNotes = !!setIncludeResolvedNotes
    let searchingGlosses = !searchingNotes

    if (searchableItemsOpen) {
        return (
            <SelectSearchableItems {...{ rt, searchChecked, setSearchChecked, searchableItemsOpen, setSearchableItemsOpen }} />
        )
    }

    return (
        <div className="util-text-search-parameters">
            <SearchIcon className="utils-search-icon" tooltip="" />
            <SearchText {...{
                searchText, 
                setSearchText,
                placeholder: searchingNotes ? t`Type note text + <Enter>` : t`Type gloss + <Enter>`,
            }} />
            {searchingGlosses && <DisplayCheckboxTreeButton {...{ rt, searchChecked, setSearchableItemsOpen }} />}
            {searchingNotes && <DisplaySearchableItems {...{ rt, searchChecked, setSearchableItemsOpen }} />}
            {searchingNotes && <IncludedResolvedNotesSelector {...{ includeResolvedNotes, setIncludeResolvedNotes }} />}
            {searchingNotes && <div className='util-text-search-close-button'>
                <PaneCloseButton
                    enabled={true}
                    onClick={() => setSearchIsOpen(false)}
                    tooltip={t`Close text search`}
                    className='' />
            </div>}
        </div>
    )
})


