import React, { FC, useMemo, useState } from 'react'
import './TRLPassageSettings.css'
import VideoPositionBarControls from '../../video/VideoPositionBarControls'
import { IVideoPlayerEmmitter } from '../../../models3/RootBase'
import VideoPositionBar from '../../video/VideoPositionBar'
import { Root } from '../../../models3/Root'
import { observer } from 'mobx-react'
import { PaneCloseButton, PauseButton, PlayButton } from '../../utils/Buttons'
import { SlttHelp2 } from '../../utils/SLTTHelp2'
import { TRLThumbnailVideoCreatorModal } from './TRLThumbnailVideoCreatorModal'
import ThinVideoPositionBar from '../../video/ThinVideoPositionBar'
import { TRLVideoPlayer } from './TRLVideoPlayer'
import { TRLVideoBarControlsContextComponent, TRLVideoPositionControlContext, IVideoControlsContext } from './TRLVideoBarControlsContextComponent'
import { PassageThumbnailVideo, ensureRanksAreInOrder } from '../../../models3/ProjectModels'
import VideoDownloading from '../../utils/VideoDownloading'
import API from '../../../models3/API'
import AutowidthInput from '../../utils/AutowidthInput'
import { LinkIcon } from '../../utils/Icons'
import { TRLPublishedUrlModal } from './TRLPublishedUrlModal'
import { ToggleEditor } from '../../utils/ToggleEditor'

const log = require('debug')('sltt:TRLPassageSettings')

interface ITRLPassageSettings {
    rt: Root
    passageThumbnailVideo: PassageThumbnailVideo | undefined
    setIsInTRLPassagSettingsMode: (value: boolean) => void
}

const TRLPassageSettings: FC<ITRLPassageSettings> = observer((
    { rt, passageThumbnailVideo, setIsInTRLPassagSettingsMode }
) => {
    const { portion, passage, name, project: { inputLanguagePrimary: primaryLanguage } } = rt
    const [videoUrl, setVideoUrl] = useState<string>()
    const [playShown, setPlayShown] = useState(true)
    const [pauseShown, setPauseShown] = useState(false)

    const passageVideoUrl = passage?.getDefaultVideo('')?.url
    const [showThumbnailCreatorModal, setShowThumbnailCreatorModal] = useState(false)
    const [showPublishedUrlModal, setShowPublishedUrlModal] = useState(false)
    const publishedUrl = useMemo(() => rt.passage?.publishedUrl || '', [rt.passage?.publishedUrl])

    if (!passage) {
        return <div className="main-trl-passage-settings no-passage"></div>
    }

    const onTimeUpdate = (time: number, isPlaying: boolean) => {
        log('onTimeUpdate', { time, isPlaying })
        playShown === isPlaying && setPlayShown(!isPlaying)
        pauseShown !== isPlaying && setPauseShown(isPlaying)
    }

    const handleShowThumbnailModal = async () => {
        // first make sure all portions have proper ranks
        await ensureRanksAreInOrder(rt.project.portions)
        setShowThumbnailCreatorModal(true)
        log('handleRecordThumbnail')
    }

    const handlePlayVideo = (videoPlayerEmittor: IVideoPlayerEmmitter) => {
        log('handlePlayVideo', { videoPlayerEmittor })
        videoPlayerEmittor.play()
    }

    const handlePauseVideo = (videoPlayerEmittor: IVideoPlayerEmmitter) => {
        log('handlePlayVideo', { videoPlayerEmittor })
        videoPlayerEmittor.pause()
    }

    const handleOnSaveThumbnail = async (newThumbnailVideoBlob: Blob,
        srcVideoUrl: string, selectionStartTime: number, selectionEndTime: number
    ) => {
        log('handleOnSaveThumbnail', {
            srcVideoUrl, selectionStartTime, selectionEndTime,
            fileType: newThumbnailVideoBlob.type,
            size: newThumbnailVideoBlob.size,
        })
        const savedThumbnail = await passage.uploadThumbnailVideo(name,
            newThumbnailVideoBlob as File,
            {
                srcVideoUrl, selectionStartTime, selectionEndTime
            }
        )
        setShowThumbnailCreatorModal(false)
        if (API.blockServerUpdates()) {
            const sandboxThumbnails = JSON.parse(localStorage.getItem(`sandboxThumbnails`) || '{}')
            sandboxThumbnails[passage._id] = savedThumbnail
            function replacer(key: string, value: string) {
                if (key == "db") return undefined
                else if (key == "dbAcceptor") return undefined
                else return value
            }
            localStorage.setItem(`sandboxThumbnails`, JSON.stringify(sandboxThumbnails, replacer))
        }
    }

    const getThumbnailButtonTitle = (value: IVideoControlsContext) => {
        const hasSelectionPresent = isSelectionPresent(value)
        const hasSelectionWithinRange = isSelectionWithinRange(value)
        const requiresSelectionRangeMessage = ' (requires 1-10 second timeline selection)'
        if (!passageThumbnailVideo) {
            return 'Create thumbnail video' + (!hasSelectionWithinRange ? requiresSelectionRangeMessage : '')
        }
        if (!hasSelectionPresent) {
            return 'View thumbnail video'
        }
        if (!hasSelectionWithinRange) {
            return 'Replace thumbnail' + requiresSelectionRangeMessage
        }
        return 'Replace thumbnail'
    }

    const getRangeSelectionMessage = (value: IVideoControlsContext) => {
        // (To view existing thumbnail, first click on timeline without making a selection)
        // (To make a new one, first select 1-10 seconds on timeline)
        const hasSelectionPresent = isSelectionPresent(value)
        const hasSelectionWithinRange = isSelectionWithinRange(value)
        if ((!passageThumbnailVideo || hasSelectionPresent) && !hasSelectionWithinRange) {
            const className = !passageThumbnailVideo && 'trl-thumbnail-warning-selection-out-of-range' || ''
            return <div className={className}>{'Thumbnail video requires selection between 1 and 10 seconds'}</div>
        }
    }

    const getThumbnailMessage = (value: IVideoControlsContext) => {
        const toViewExistingThumbnail = 'To view existing thumbnail, first click on timeline without making a selection'
        const toReplaceExistingThumbnail = 'To replace existing thumbnail, first select 1-10 seconds on timeline'
        const hasSelectionPresent = isSelectionPresent(value)
        if (!passageThumbnailVideo) {
            return <div></div>
        }
        if (hasSelectionPresent) {
            return <div>{toViewExistingThumbnail}</div>
        }
        return <div>{toReplaceExistingThumbnail}</div>
    }

    const handleOnClickThumbnailUpdaterButton = (value: IVideoControlsContext) => {
        if (passageThumbnailVideo || isSelectionWithinRange(value))
            handleShowThumbnailModal()
    }

    const editPublishedUrlButton = (
        <i
            className={`trl-passage-settings-published-url-button sl-fa-button fa-solid fa-link`}
            onClick={() => setShowPublishedUrlModal(true)}
            title={`Update published url`}
        />
    )

    return (<div className="main-trl-passage-settings">
        <TRLVideoBarControlsContextComponent rt={rt}>
            <TRLVideoPositionControlContext.Consumer>
                {value => <div className="trl-passage-settings-toolbar">
                    <PaneCloseButton
                        onClick={() => setIsInTRLPassagSettingsMode(false)}
                        enabled={true}
                        tooltip={'Close pane'}
                        className='sl-pane-close-button' />
                    {playShown &&
                        <SlttHelp2 id="playbutton"
                            tooltip={isSelectionPresent(value!) ? 'Play selection.' : 'Play.'}
                            place="bottom"
                            video="a1">
                            <PlayButton
                                enabled={true}
                                selectionPresent={isSelectionPresent(value!)}
                                tooltip=""
                                className="main-video-play-button"
                                onClick={async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                    handlePlayVideo(value!.videoPlayerEmitter!)
                                }} />
                        </SlttHelp2>
                    }
                    {pauseShown &&
                        <PauseButton
                            className='main-video-pause-button'
                            enabled={true}
                            tooltip={'Pause'}
                            onClick={() => handlePauseVideo(value!.videoPlayerEmitter!)} />
                    }
                    <i
                        className={`sl-selection-${(isSelectionWithinRange(value!) ? 'present' : 'absent')} sl-thumbnail-updater-button sl-fa-button fa-solid fa-photo-film`}
                        onClick={() => handleOnClickThumbnailUpdaterButton(value!)}
                        title={getThumbnailButtonTitle(value!)}
                    />
                    {editPublishedUrlButton}
                </div>}
            </TRLVideoPositionControlContext.Consumer>
            <TRLVideoPositionControlContext.Consumer>
                {value => <div className="trl-updater-video-player" style={{ height: '360px' }}>
                    {videoUrl && <TRLVideoPlayer
                        videoEventEmitter={value!.videoEventEmitter}
                        videoUrl={videoUrl!}
                        selectionPresent={value!.videoPositionUtils.selectionPresent}
                        rootBaseCurrentTime={value!.currentTime}
                        setRootBaseCurrentTime={(time) => value!.videoPosition.currentTime = time}
                        onTimeUpdate={onTimeUpdate}
                        selectionStartTime={value!.selectionStartTime}
                        selectionEndTime={value!.selectionEndTime}
                    />}
                    {!videoUrl && passageVideoUrl && <VideoDownloading
                        className={'media-placeholder'}
                        videoUrl={passageVideoUrl}
                        creator={''}
                        fontSizePt={30}
                        onEnded={blob => setVideoUrl(window.URL.createObjectURL(blob))}
                    />}
                    {videoUrl && passageVideoUrl && showThumbnailCreatorModal && <TRLThumbnailVideoCreatorModal
                        show={true}
                        setShow={(show) => setShowThumbnailCreatorModal(show)}
                        projectName={rt.name}
                        copyright={rt.project.copyrightNotice}
                        modelPortion={portion!}
                        modelPassage={passage}
                        primaryLanguage={primaryLanguage}
                        videoUrl={videoUrl}
                        passageThumbnailVideo={!isSelectionPresent(value!) ? passageThumbnailVideo : undefined}
                        passageVideoUrl={passageVideoUrl}
                        onSaveThumbnailVideo={handleOnSaveThumbnail}
                    />}
                    {showPublishedUrlModal && <TRLPublishedUrlModal {...{ setShowPublishedUrlModal, passage }} />}
                </div>}
            </TRLVideoPositionControlContext.Consumer>
            <div className="trl-passage-settings-video-position-control-bar">
                <TRLVideoPositionControlContext.Consumer>
                    {value => (videoUrl && <>
                        <VideoPositionBarControls videoPosition={value!.videoPosition} videoPositionControls={value!.videoPositionControls} />
                        <VideoPositionBar videoPosition={value!.videoPosition} />
                        <ThinVideoPositionBar {...{ videoPosition: value!.videoPosition, timelineZoom: value!.videoPositionControls.timelineZoom }} />
                        <div>
                            <div>Selection is <span className="trl-thumbnail-selection-duration">{getTotalSelectionTime(value!).toFixed(3)}</span> second(s)</div>
                            {getRangeSelectionMessage(value!)}
                            {getThumbnailMessage(value!)}
                        </div>
                        <div>
                            <span className="trl-passage-settings-published-url-button-small">
                                {editPublishedUrlButton}
                            </span>
                            <span>{publishedUrl}</span>
                        </div>
                    </>)}
                </TRLVideoPositionControlContext.Consumer>
            </div>
        </TRLVideoBarControlsContextComponent>
    </div>)

    function isSelectionPresent(value: IVideoControlsContext) {
        return !!getSelectionRange(value)
    }

    function isSelectionWithinRange(value: IVideoControlsContext) {
        if (!isSelectionPresent(value)) return false

        const totalTime = getTotalSelectionTime(value)
        return totalTime >= 1 && totalTime <= 10
    }

    function getTotalSelectionTime(value: IVideoControlsContext) {
        const startTime = value.videoPosition.selectionStartTime
        const endTime = value.videoPosition.selectionEndTime
        const totalTime = endTime - startTime
        return totalTime
    }

    function getSelectionRange(value: IVideoControlsContext) {
        const startTime = value.videoPosition.selectionStartTime
        const endTime = value.videoPosition.selectionEndTime
        return ((endTime - startTime) > 0) ? { startTime, endTime } : null
    }
})

interface IEditPublishedUrl {
    allowEdit: boolean,
    publishedUrl: string,
    setPublishedUrl: (publishedUrl: string) => void,
    editingUrl: boolean,
    setEditingUrl: (editingUrl: boolean) => void,
}

export const EditPublishedUrl: FC<IEditPublishedUrl> = ({ allowEdit, publishedUrl, setPublishedUrl,
    editingUrl, setEditingUrl }) => {
    const placeholder = `URL for Vimeo video`
    const title = `Update published URL`
    const [tempUrl, setTempUrl] = useState(publishedUrl)
    const handleClickOk = () => {
        const finalValue = tempUrl.trim()
        setPublishedUrl(finalValue)
        setTempUrl(finalValue)
        setEditingUrl(false)
    }
    const handleCancel = () => {
        setTempUrl(publishedUrl) // reset tempUrl to the last confirmed value
        setEditingUrl(false)
    }

    return (
        <div>
            <div className="trl-passage-settings-published-url">
                <div className="trl-passage-settings-published-url-flex">
                    <LinkIcon className="trl-passage-settings-published-url-link-icon"
                        tooltip={placeholder} />
                    <div className="trl-passage-settings-published-url-toggle-editor">
                        <ToggleEditor
                            allowEditing={allowEdit}
                            isEditing={editingUrl}
                            setIsEditing={setEditingUrl}
                            title={title}
                            iconClass=""
                            handleClickOk={handleClickOk}
                            handleCancel={handleCancel}
                        >
                            <AutowidthInput
                                className="trl-passage-settings-published-url-input"
                                value={tempUrl}
                                placeholder={placeholder + '...'}
                                onChange={(e) => setTempUrl(e.target.value)}
                                readOnly={!editingUrl}
                            />
                        </ToggleEditor>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <AutowidthInput
                        className={`trl-passage-settings-published-url-example ${editingUrl ? 'visible-opacity' : 'hidden-opacity'}`}
                        title={`Example copyright notice`}
                        placeholderIsMinWidth={true}
                        value={`${`Example: https://vimeo.com/202862112`}`}
                        readOnly={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default TRLPassageSettings
