import React, { Component } from 'react'
import {observer} from 'mobx-react'
import { observable } from 'mobx'
import Tour from 'reactour'
import SplitPane from 'react-split-pane'

import PassageList from '../passages/PassageList'
import VideoMain from '../video/VideoMain'
import './Translation.css'
import '../video/Video.css'
import './Resizer.css'
import TourVideoPlayer from './TourVideoPlayer'
import { Root } from '../../models3/Root'
import { tourSteps } from './TranslationEditorTour'
import TranslationRightPane from './TranslationRightPane'
import NoteDialog from '../notes/NoteDialog'
import '../notes/Note.css'
import LoadingMessage from '../utils/InitializationMessage'
import { ErrorBoundary } from '../utils/Errors'
import { DetachedVideoPlayer } from '../video/DetachedVideoPlayer'
import ERTermModal from '../enhancedResources/ERTermModal'

// eslint-disable-next-line
const log = require('debug')('sltt:TranslationEditor') 

interface ITranslationEditor {
    rt: Root,
}

class TranslationEditor extends Component<ITranslationEditor> {
    topDiv: HTMLDivElement | null = null
    listenersAdded = false
    intervalId: NodeJS.Timeout | null = null
    @observable rightPanelMaxSize = 657
    @observable showDetachedPlayer = false

    constructor(props: ITranslationEditor) {
        super(props)
        this.getSizeOfRightPanel = this.getSizeOfRightPanel.bind(this)
        this.setShowDetachedPlayer = this.setShowDetachedPlayer.bind(this)
    }

    render() {
        let { rt } = this.props
        let { note, tourOpen, initialized, loadingMessage, useMobileLayout, useNarrowWidthLayout, termId } = rt
        let { rightPanelMaxSize, showDetachedPlayer, setShowDetachedPlayer } = this
        let adjustVideoTime = true

        if (!initialized) return <LoadingMessage {...{ loadingMessage }} />

        // Is this the right thing to do? All we want is to ensure note is never
        // undefined in the note dialog
        let noteRoot = rt
        let closeNoteDialog = () => rt.setNote(undefined)

        let detachedPlayer = rt

        return (
            <div ref={c => { this.topDiv = c }}>
                { note && <NoteDialog {...{ adjustVideoTime, noteRoot, closeDialog: closeNoteDialog }} /> }
                { showDetachedPlayer && <DetachedVideoPlayer {...{ detachedPlayer, setShowDetachedPlayer }} /> }

                <div className="translation-top-container">
                    <SplitPane
                        split={useNarrowWidthLayout ? "horizontal" : "vertical"}
                        minSize={useNarrowWidthLayout ? "auto" : 100}
                        size={useNarrowWidthLayout ? "auto" : 200}
                        maxSize={useNarrowWidthLayout ? "auto" : 400}
                        style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
                    >
                        <div className="translation-left-pane">
                            <ErrorBoundary>
                                <PassageList rt={rt} />
                            </ErrorBoundary>
                        </div>
                        <ErrorBoundary>
                            <div className="translation-right-panel" data-id="translation-right-panel">
                                {useMobileLayout ? (
                                    <VideoMain {...{ rt }} />
                                ) : (
                                    <SplitPane
                                        split={"vertical"}
                                        minSize={375}                                   // This minSize prevents icons from overlaying the splitter when minimized.  
                                                                                        // Increased from 300 to 375 to fit max videoLabel length plus (Finished)
                                        defaultSize={Math.min(672, rightPanelMaxSize)}  // This defaultSize launches the translation-right-panel wide enough to accommodate
                                                                                        // max videoLabel length plus (Finished) (ie.  ABABABABAABABABBB (Finished) 🗩 )
                                                                                        // when unresolved notes 🗩 (fa-comment) icon is also present in that default selection.
                                                                                        // That way SLTT doesn't launch with wrap-around.
                                        maxSize={rightPanelMaxSize}
                                        style={{ position: 'relative', height: 'auto', overflow: 'visible' }}
                                    >
                                        <VideoMain {...{ rt, setShowDetachedPlayer }} />
                                        <div className="translation-right-pane">
                                            <TranslationRightPane rt={rt} />
                                        </div>
                                    </SplitPane>
                                )}
                            </div>
                        </ErrorBoundary>
                    </SplitPane>
                </div>
                <Tour
                    steps={tourSteps}
                    getCurrentStep={rt.getCurrentTourStep.bind(rt)}
                    maskClassName="tour-mask"
                    isOpen={tourOpen}
                    showNavigation={false}
                    onRequestClose={() => rt.closeTour()} />
                <TourVideoPlayer rt={rt} />
            </div>
        )
    }

    setShowDetachedPlayer(value: boolean) {
        this.showDetachedPlayer = value
    }

    preventDrop(e: DragEvent) {
        if (!e) return

        e.preventDefault()

        if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = "none"
            e.dataTransfer.dropEffect = "none"
        }
    }

    getSizeOfRightPanel() {
        let rightPanel = document.querySelectorAll("[data-id='translation-right-panel']")
        for (let element of rightPanel) {
            let _element = element as HTMLElement
            let isVisible = _element.offsetWidth > 0 && _element.offsetHeight > 0
            if (isVisible) {
                // Allow some room for pane 3
                const BUFFER_WIDTH = 150
                let distanceToRightScreenEdge = document.documentElement.clientWidth - _element.getBoundingClientRect().x
                this.rightPanelMaxSize = distanceToRightScreenEdge - BUFFER_WIDTH
            }
        }
    }

    componentDidMount() {
        // window.addEventListener('keydown', this.keydown.bind(this))
        // let { rt } = this.props
        // log(`!!!!! componentDidMount ${rt.name}`) 
        // if (!rt.initialized) {
        //     rt.initialize()
        //         .catch((err: any) => {
        //             displayError(err)
        //         })
        // }
        this.intervalId = setInterval(this.getSizeOfRightPanel, 100)
        if (!this.topDiv || this.listenersAdded) return

        this.topDiv.addEventListener('dragover', this.preventDrop, false)
        this.topDiv.addEventListener('drop', this.preventDrop, false)
        this.listenersAdded = true
    }

    componentWillUnmount() {
        this.intervalId && clearInterval(this.intervalId)
        this.intervalId = null
    }
}

export default observer(TranslationEditor)
