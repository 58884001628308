// Display note selected by user OR create new note

import React, { Component, FunctionComponent, useEffect, useState, FC, useRef } from 'react'
import { t } from 'ttag'
import { fmt } from '../utils/Fmt'
import { displayError } from '../utils/Errors'
import { useOnClickOutside } from '../utils/Hooks'

const log = require('debug')('sltt:NoteReactionPicker')

// https://github.com/missive/emoji-mart (1.6M)
// https://www.npmjs.com/package/emoji-picker-react (2.5M)
// github.com/cesarwbr/react-input-emoji (.7M) --- does not support skin tone selection

// Match a single emoji, optionally followed by a skin tone modifier
export const emojiRegex = /(\p{Emoji_Presentation}(?:[\u{1F3FB}-\u{1F3FF}])?)/u

interface INoteReactionPicker {
    emojis: string,
    addReaction: (reaction: string) => Promise<void>,
}

export const NoteReactionPicker: FC<INoteReactionPicker> = ({ emojis, addReaction }) => 
{
    const [pickerExpanded, setPickerExpanded] = useState(false)

    const ref = useRef()

    useOnClickOutside(ref, () => {
        log('useOnClickOutside')
        setPickerExpanded(false)
    })

    const reactions = emojis.trim().split(/\s*,\s*/)


    function reactionSpans(_reactions: string[]) {
        return (
            <>
                {_reactions.map((emoji, index) => 
                    <span key={index}
                        className="note-reaction-emoji" 
                        onClick={() => {
                            setPickerExpanded(false)
                            addReaction(`<p><span class="ql-size-huge">${emoji}</span></p>`).catch(displayError)
                        }
                        }>
                            {emoji}
                </span>)}
            </>
        )
    }

    const unexpandedEmojis = 6 // Number of emojis to show before user clicks ...

    if (reactions.length <= unexpandedEmojis || pickerExpanded) {
        return (
            <div className="note-reaction" ref={ref as any}>
                {reactionSpans(reactions)}
            </div>
        )
    }

    return (
        <div className="note-reaction">
            {reactionSpans(reactions.slice(0, unexpandedEmojis))}
            {(reactions.length > unexpandedEmojis) && 
                <span className="note-reaction-expand" onClick={() => setPickerExpanded(true)}>
                    ...
                </span>}
        </div>
    )
}



