
export const NonretriableSyncFailure = 'Nonretriable Sync failure'

export const minLocalSeq = 1000000000 // Starting key for docs not yet synced to central DB

export interface IRemoteDBItem {
    project: string,
    seq: number,
    doc: IDBModDoc,
}

export interface IDBItem {
    project: string,
    seq: number | string,
    doc: IDBModDoc,
}

// This is what we store in level-js-PROJECT-db
export type DBEntry = {
    key: number | string // local
    doc: IDBModDoc // IDBModDoc to be passed to DBAcceptor
}

export interface IDBIterator {
    next(): Promise<{ key: string | number | undefined, value: IDBModDoc | undefined }>
    end(): Promise<{ key: null, value: null }>
}

export type IDBOperations = {
    /**
     * @param seqStart (exclusive)
     * @param seqEnd (inclusive)
     * @returns IDBObject[seq] where seqStart < seq <= seqEnd
     */
    get(seqStart: number | string, seqEnd: number | string): Promise<IDBModDoc[]> // seqStart < seq <= seqEnd
    put(seq: number | string, doc: IDBObject): Promise<void>
    deleteDoc(key: number | string): Promise<void>
    writeItems(items: IDBItem[]): Promise<void>
    readKeys(): Promise<(number | string)[]>
    readDBRecords(): Promise<DBEntry[]>
    /**
     * should be used in a try-finally block where finally calls iterator.end()
     * @param currentKey (inclusive)
     */
    getIterator<T extends string | number>(currentKey: T): IDBIterator
    count(): Promise<number>
    deleteDB(): Promise<void>
    close(): void
}

export type IDBSyncApi = {
    loggedIn: () => boolean,
    sync: (name: string, docs: IDBModDoc[], remoteSeq: number) => Promise<IRemoteDBItem[]>,
    blockServerUpdates: () => boolean,
    delayUntilOnline: (logContext: string) => Promise<void>,
    syncCount?: number, // number of sync operations
}

export interface IDBObject {
    _id: string
    removed?: boolean
    viewed?: boolean
}

export interface IDBModDoc extends IDBObject {
    modDate: string
    modBy?: string
}

export interface IDBAcceptor {
    accept: (doc: IDBObject, label?: string, seq?: number | string) => void
}

export interface IDB {
    localSeqLast: number
    localSeqBase: number

    initialize: (acceptor: IDBAcceptor, progress: (message: string) => void) => Promise<number>
    put: (obj: IDBModDoc) => Promise<void>
    get(seqStart?: number | string, seqEnd?: number | string): Promise<IDBModDoc[]>
    delete: (_id: string, _lastId?: string) => Promise<void>
    getNewId: (existing: any[], date: Date, tag?: string) => string
    username: string
    getDate: () => string
    doSync: () => void
    deleteDB: () => Promise<void>
    getRemoteSeq: () => number
    submitChange: (obj: IDBModDoc) => void,

    // Unit testing only! should be separate interface
    slice: () => any[]
    reset: (nextId: number) => void
}

