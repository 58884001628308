import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import './StorageLimitStatus.css'
import { calculateStorageLimitStatus } from '../utils/StorageLimitStatus'
import { HardDriveIcon } from '../utils/Icons'

let log = require('debug')('sltt:StorageLimitStatus')

async function checkStorage() {
    try {
        if (!navigator.storage?.estimate) {  
            return { error: t`Storage estimation is not supported in this environment.`, message: '' }  
        }
        const { usage, quota } = await navigator.storage.estimate()  
        const usageOverride = parseInt(localStorage.storageLimitOverrideUsage) || null // non-zero Usage override  
        const quotaOverride = parseInt(localStorage.storageLimitOverrideQuota) || null // non-zero Quota override  
        const status = calculateStorageLimitStatus(usageOverride || usage || 0, quotaOverride || quota || 0)
        quotaOverride && console.log(`quotaOverride: ${quotaOverride}`)  
        usageOverride && console.log(`usageOverride: ${usageOverride}`)  
        console.log(`Is storage low: ${status.isStorageLow}`) 
        return { message: status.message, isStorageLow: status.isStorageLow }
    }
    catch (error) {
        console.error(error)
        return { message: t`An error occurred while estimating storage.` }
    }
}

export const StorageLimitStatusCheck = () => {  
    const [isStorageLow, setIsStorageLow] = useState<boolean | undefined>(false)
    const [tooltip, setTooltip] = useState('')
    
    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchStorage = async () => {
            const storage = await checkStorage()
            setTooltip(storage.message)
            setIsStorageLow(storage.isStorageLow)
        }
        // Immediately call the async function
        fetchStorage()

        // Set up the interval
        const intervalId = setInterval(() => {
            fetchStorage()
        }, 1200000) // Check storage every 20 minutes

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId)
    }, [])

    return (
        <span>
            {isStorageLow && (
                <HardDriveIcon id={"storage-limit-status-icon"} 
                    className={`storage-limit-status-icon ${isStorageLow ? 'storage-limit-status-low' : ''}`}
                    tooltip={tooltip} />
            )}
        </span>
    )
}
