import _ from 'underscore'
import { fmt } from '../components/utils/Fmt'

import { RefRange } from '../scrRefs/RefRange'

const log = require('debug')('sltt:albums') 
const intest = (localStorage.getItem('intest') === 'true')


// records all the passages for a given book/chapter
export type PassagesMap = { [bbbccc: string]: SLBiblePassage[] }
let passagesByBbbccc: PassagesMap = {}

// --------------------------------------

export type SLBiblePassage = {
    languageName: string;
    copyright: string;
    teamName: string;
    albumName: string;
    hostedBy: string;
    name: string;
    url: string;
    refs: RefRange[];
}

async function getSLBiblePassagesByBbbccc(bbbccc: string) {
    let passages = passagesByBbbccc[bbbccc]
    if (passages !== undefined) {
        return passages
    }

    let url = `https://s3.amazonaws.com/sltt-resources/SLBibleIndex/${bbbccc}.json`
    let response = await fetch(url)
    if (!response.ok) {
        log(`### SLBibleIndex fetch failed: ${response.url}: ${response.status}`)
        passages = []
    } else {
        let jsons: any[] = []
        try {
            jsons = await response.json()
        } catch (error) {
            log('### getSLBiblePassagesByBbbccc response.json', error)
        }

        for (let passage of jsons) {
            let refs: any[] = []
            try {
                refs = JSON.parse(passage.refs)
            } catch (error) {
                log('### getSLBiblePassagesByBbbccc parse refs ERROR', passage)
            }
            passage.refs = refs.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
        }

        passages = jsons
        log('getSLBiblePassagesByBbbccc', fmt({bbbccc, count: passages.length}))
    }

    passagesByBbbccc[bbbccc] = passages
    return passages
}

export async function getSLBiblePassages(refs: RefRange[]) {
    let passages: SLBiblePassage[] = []

    for (let ref of refs) {
        for (let bbbccc of ref.chapterIterator()) {
            // Get all the published video passages for this book/chapter
            let _passages = await getSLBiblePassagesByBbbccc(bbbccc)

            for (let _passage of _passages) {
                // If this video passage does not overlap at the verse level 
                // with any of the refs the user entered, skip it
                if (!refs.some(r => r.overlaps(_passage.refs))) continue

                // If this video passage is not already in the list, add it
                if (!passages.some(p => p.url === _passage.url)) {
                    passages.push(_passage)
                }
            }
        }
    }

    return passages
}

