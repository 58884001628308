import React, { useState } from 'react'
import {observer} from 'mobx-react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import SegmentsEditor from '../segments/SegmentsEditor'
import { PassageSegmentsIcon, ImageReferencesIcon, BiblicalSignsIcon, CompareDraftIcon, DbsPlayIcon, PassageDocumentIcon, StarIcon, HollowStarIcon, TRLPanelIcon } from '../utils/Icons'
import EnhancedResourceViewer from '../enhancedResources/EnhancedResourceViewer'
import { Images } from '../images/Images'
import { ErrorBoundary } from '../utils/Errors'
import CompareDraftVideoMain from '../video/CompareDraftVideoMain'
import DbsPlayer from '../dbs/DbsPlayer'
import PassageResources from '../passages/PassageDocuments'
import PassageKeyTermsViewer from '../enhancedResources/PassageKeyTermsViewer'
import { TRLPanel } from '../TRL/Browse/TRLPanel'
import { Theme } from '../utils/LocalStorage'

const log = require('debug')('sltt:TranslationRightPane')

let displayPassageKeyTermsTab = true

interface ITranslationRightPane {
    rt: Root,
}

export enum EditingSegmentPosition { None, Starting, Ending, Both }

const TranslationRightPane = observer(({ rt }: ITranslationRightPane) => {
    const [tabIndex, setTabIndex] = useState(parseInt(rt.getDefault('TRANSLATIONRIGHTPANEINDEX') || '0'))

    // Needed to lift this up to this component because lower level components
    // were being re-constructed at getting this value set back to 0
    // after uploading a patch
    const [editingSegmentPosition, setEditingSegmentPosition] = useState<EditingSegmentPosition>(EditingSegmentPosition.None)
        /* 0 = no, 1 = starting position, 2 = ending position, 3 = starting and ending */

    let { dbsRefs, project, portion, passage, passageVideo, passageSegment, iAmInterpreter } = rt
    if (!portion || !passage) return null

    let { tourSelector /* so that children re-render */ } = rt
    let { _rev } = passage

    let references = rt.displayableReferences(dbsRefs)

    let showSegments = (passageVideo !== null)
    let showCompareDraftVideoMain = (passageVideo !== null)
    let showPassageResources = (passage !== null)

    function _setEditingSegmentPosition(value: number) {
        log('setEditingSegmentPosition', value)
        setEditingSegmentPosition(value)
    }

    const _setTabIndex = (tabIndex: number) => {
        rt.setDefault('TRANSLATIONRIGHTPANEINDEX', tabIndex.toString())
        setTabIndex(tabIndex)
    }

    return (
        <Tabs selectedIndex={tabIndex} 
                onSelect={tabIndex => { 
                    if (rt.segmentTabEditingInProgress || rt.editingImage) return
                    _setTabIndex(tabIndex)
                } } >
            <TabList>
                <Tab key='segments'>
                    <PassageSegmentsIcon 
                        id="segments.html"
                        className='sl-show-passage-sement'
                        enabled={true}
                        tooltip={/* translator: important */ t`Show video segments in this passage.`}
                        place='bottom'
                        enabledColor={`${Theme.getColor('black', 'white')}`}
                        nonEnabledColor={`${Theme.getColor('grey', 'lightgrey')}`}
                        onClick={() => {}}
                        style={{}} />
                </Tab>
                <Tab key='image-references'>
                    <ImageReferencesIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Images`} />
                </Tab>
                <Tab key='draft-compare'>
                    <CompareDraftIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Compare video to other drafts in this passage`} />
                </Tab>
                <Tab key='dbs-references'>
                    <DbsPlayIcon className='right-pane-tab-icon sl-dbs-play-icon' tooltip={/* translator: important */ t`Show published videos.`} />
                </Tab>
                <Tab key='signs'>
                    <BiblicalSignsIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Show published written Scripture`} />
                </Tab>
                { displayPassageKeyTermsTab && (
                    <Tab key='passage-key-terms'>
                        <HollowStarIcon className={`right-pane-tab-icon passage-key-terms-icon`} tooltip={t`Passage Key Terms`} />
                    </Tab>
                )}
                <Tab key='translation-resource-library'>
                    <TRLPanelIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Translation Resource Library`} />
                </Tab>
                <Tab key='passage-docs'>
                    <PassageDocumentIcon className='right-pane-tab-icon' tooltip={/* translator: important */ t`Passage documents`} />
                </Tab>
            </TabList>

            <TabPanel key='segments'>
                <ErrorBoundary>
                    { showSegments && <SegmentsEditor {...{ rt, editingSegmentPosition, setEditingSegmentPosition: _setEditingSegmentPosition }} /> }
                </ErrorBoundary>
            </TabPanel>

            <TabPanel key='image-references'>
                <ErrorBoundary>
                    <Images rt={rt} />
                </ErrorBoundary>
            </TabPanel>

            <TabPanel key='draft-compare'>
                <ErrorBoundary>
                {showCompareDraftVideoMain && <CompareDraftVideoMain {...{ rt }} />}
                </ErrorBoundary>
            </TabPanel>

            <TabPanel key='dbs-references'>
                <ErrorBoundary>
                    <DbsPlayer rt={rt} />
                </ErrorBoundary>
            </TabPanel>

            <TabPanel key='signs'>
                <ErrorBoundary>
                    <EnhancedResourceViewer rt={rt} />
                </ErrorBoundary>
            </TabPanel>

            { displayPassageKeyTermsTab && (
                <TabPanel key='passage-key-terms'>
                    <ErrorBoundary>
                        <PassageKeyTermsViewer
                            rt={rt} passage={rt.passage} passageVideo={rt.passageVideo} />
                    </ErrorBoundary>
                </TabPanel>
            )}

            <TabPanel key='translation-resource-library'>
                <ErrorBoundary>
                    <TRLPanel trlResources={rt.trlResources} />
                </ErrorBoundary>
            </TabPanel>

            <TabPanel key='passage-docs'>
                <ErrorBoundary>
                    {showPassageResources && <PassageResources {...{ rt, passage }} />}
                </ErrorBoundary>
            </TabPanel>
        </Tabs>
    )
})

export default (TranslationRightPane)