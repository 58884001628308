import React, { Component, FunctionComponent } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { t } from 'ttag'

import { Project } from '../../../models3/ProjectModels'
import { getPercentCompleteThroughStage } from './ProgressUtils'
import { ColorSquare } from '../../utils/Buttons'
import './ProgressReport.css'
import { Root } from '../../../models3/Root'
import dateLocales from './DateLocales'
import { RootConsumer } from '../../app/RootContext'

interface IProgressReport {
    rt: Root,
}

class ProgressReport extends Component<IProgressReport> {
    @observable startDate: Date
    @observable endDate: Date
    @observable reportBy = `portion`

    constructor(props: IProgressReport) {
        super(props)
        let today = new Date()
        let todayYear = today.getFullYear()
        let quarters = [
            new Date(todayYear, 0),
            new Date(todayYear, 3),
            new Date(todayYear, 6),
            new Date(todayYear, 9)
        ]
        let todayMonth = today.getMonth()
        if (todayMonth < 3) {
            this.startDate = quarters[0]
        } else if (todayMonth < 6) {
            this.startDate = quarters[1]
        } else if (todayMonth < 9) {
            this.startDate = quarters[2]
        } else {
            this.startDate = quarters[3]
        }
        this.endDate = today
    }
    
    render() {
        let { rt } = this.props
        let { project } = rt
        let { displayName } = project
        let { reportBy, startDate, endDate } = this
        return (
            <div>
                <ProgressReportHeader
                    rt={rt}
                    projectName={displayName}
                    reportBy={reportBy}
                    setReportBy={item => this.reportBy = item}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={date => this.startDate = date}
                    setEndDate={date => this.endDate = date}
                />
                <div className='progress-report-separator' />
                <div className='progress-report-table'>
                    <ProgressTable
                        rt={rt}
                        project={project}
                        byPortion={reportBy === `portion`}
                        beginDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        )
    }
}

export default observer(ProgressReport)

interface IProgressReportHeader {
    projectName: string,
    reportBy: string,
    setReportBy: (item: string) => void,
    startDate: Date,
    endDate: Date,
    setStartDate: (date: Date) => void,
    setEndDate: (date: Date) => void,
    rt: Root,
}

const ProgressReportHeader: FunctionComponent<IProgressReportHeader> = ({ rt, projectName, reportBy, setReportBy, startDate, endDate, setStartDate, setEndDate }) => {
    let { uiLanguage } = rt
    let { dateFormat } = rt.project
    let localeData = dateLocales[rt.uiLanguage]
    registerLocale(rt.uiLanguage, localeData)

    return (
        <div className='progress-report-header'>
            <h4 className='progress-report-project-name'>
                <b>{projectName}</b>
            </h4>
            <div className='progress-report-controls'>
                <div className='progress-report-header-item'>
                    {t`Report by`}
                    <ReportBy
                        currentlySelected={reportBy}
                        onSelect={setReportBy}
                    />
                </div>
                <div className='progress-report-header-item'>
                    {t`Period begin date`}
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                            locale={uiLanguage}
                            dateFormat={dateFormat}
                        />
                    </div>
                </div>
                <div className='progress-report-header-item'>
                    {t`Period end date`}
                    <div>
                        <DatePicker
                            selected={endDate}
                            onChange={setEndDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            locale={uiLanguage}
                            dateFormat={dateFormat}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface IReportBy {
    currentlySelected: string,
    onSelect: (item: string) => void,
}

const ReportBy: FunctionComponent<IReportBy> = (props) => {
    let { currentlySelected, onSelect } = props
    return (
        <div>
            <select
                className='custom-select progress-report-dropdown-menu'
                data-id='progress-report-dropdown-menu'
                value={currentlySelected}
                onChange={e => onSelect(e.target.value)}
            >
                <option key='portion' value='portion'>{t`portion`}</option>
                <option key='passage' value='passage'>{t`passage`}</option>
            </select>
        </div>
    )
}

interface IProgressTable {
    rt: Root,
    project: Project,
    byPortion: boolean,
    beginDate: Date,
    endDate: Date,
}

const ProgressTable: FunctionComponent<IProgressTable> = (props) => {
    let { rt, project, byPortion, beginDate, endDate } = props

    let { portions, plans } = project

    let allPassages = portions.map(portion => portion.passages).reduce((a, acc) => a.concat(acc), [])
    let showItems = allPassages.filter(e => e.difficulty > 0).length > 0
    let plan = plans[0]
    let viewableStages = plan?.viewableStages || []

    // If a stage has no tasks, it is not viewable for project reporting
    // purposes.
    viewableStages = viewableStages.filter(stage => stage.tasks.length > 0)

    return (
        <div>
            {showItems ? (
                <div>
                    <div className='progress-table-legend'>
                        <ProgressTableLegend
                            beginDate={beginDate}
                            endDate={endDate}
                            beginColor='green'
                            endColor='deepskyblue'
                        />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <th className='progress-table-item' />
                                {viewableStages.map(stage => (
                                    <th key={`${stage.name}`} className='progress-table-item'>{stage.displayedName}</th>
                                ))}
                            </tr>
                        </tbody>
                        {byPortion ? (
                            portions.map((portion, i) => {
                                let shouldShow = portion.passages.filter(passage => passage.difficulty > 0).length > 0
                                return shouldShow ? (
                                    <tbody key={portion.name}>
                                        <tr>
                                            <td className='progress-table-item'>
                                                <Link to={`/index.html`} onClick={e => {
                                                    rt.selectPage('/')
                                                    rt.setPortion(portion)
                                                }}>
                                                    {portion.name}
                                                </Link>
                                            </td>
                                            {viewableStages.map(stage => {
                                                let { passages } = portion
                                                let beginPercent = getPercentCompleteThroughStage(passages, plan, stage, beginDate) * 100
                                                let endPercent = getPercentCompleteThroughStage(passages, plan, stage, endDate) * 100
                                                return (
                                                    <td key={`${stage.name}-${portion.name}`} className='progress-table-item progress-report-progress'>
                                                        <OverlappingProgressBar
                                                            beginPercent={beginPercent}
                                                            endPercent={endPercent}
                                                        />
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                ) : null
                            })
                        ) : (
                            portions.map(portion => {
                                let shouldShow = portion.passages.filter(passage => passage.difficulty > 0).length > 0
                                return shouldShow ? (
                                    <tbody key={portion.name}>
                                        <tr>
                                            <td className='progress-table-item'>
                                                <Link to={`/index.html`} onClick={e => {
                                                    rt.selectPage('/')
                                                    rt.setPortion(portion)
                                                }}>
                                                    <b>{portion.name}</b>
                                                </Link>
                                            </td>
                                            {viewableStages.map(stage => (
                                                <td key={stage.name} className='progress-table-item progress-report-progress' />)
                                            )}
                                        </tr>
                                        {portion.passages.map(passage => (
                                            <tr key={`${portion.name}-${passage.name}`}>
                                                <td className='progress-table-item'>&nbsp;&nbsp;&nbsp;&nbsp;{`${passage.name}`}</td>
                                                {viewableStages.map(stage => {
                                                    let beginPercent = getPercentCompleteThroughStage([passage], plan, stage, beginDate) * 100
                                                    let endPercent = getPercentCompleteThroughStage([passage], plan, stage, endDate) * 100
                                                    return (
                                                        <td key={`${stage.name}-${portion.name}-${passage.name}`} className='progress-table-item progress-report-progress'>
                                                            <OverlappingProgressBar
                                                                beginPercent={beginPercent}
                                                                endPercent={endPercent}
                                                            />
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                        <tr className='blank-table-row'/>
                                    </tbody>
                                ) : null
                            })
                        )}
                        <tbody>
                            <tr key='total'>
                                <td className='progress-table-item'>{t`TOTAL`}</td>
                                {viewableStages.map(stage => {
                                    let passages = project.portions.flatMap(portion => portion.passages)
                                    let beginPercent = getPercentCompleteThroughStage(passages, plan, stage, beginDate) * 100
                                    let endPercent = getPercentCompleteThroughStage(passages, plan, stage, endDate) * 100
                                    return (
                                        <td key={stage.name} className='progress-table-item progress-report-progress'>
                                            <OverlappingProgressBar
                                                beginPercent={beginPercent}
                                                endPercent={endPercent}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>{t`There are no passages with nonzero difficulty.`}</p>
            )}
        </div>
    )
}

interface IProgressTableLegend {
    beginDate: Date,
    endDate: Date,
    beginColor: string,
    endColor: string,
}

const ProgressTableLegend: FunctionComponent<IProgressTableLegend> = (props) => {
    return (
        <RootConsumer>
            {rt => {
                if (!rt) return null

                let { dateFormatter } = rt
                let { beginDate, endDate, beginColor, endColor } = props
                let formattedBeginDate = dateFormatter.format(beginDate)
                let formattedEndDate = dateFormatter.format(endDate)
                return (
                    <div>
                        <div>
                            <ColorSquare
                                color={beginColor}
                                width='20px'
                                height='20px'
                            />
                            {t`Progress as of` + ` ${formattedBeginDate}`}
                        </div>
                        <div>
                            <ColorSquare
                                color={endColor}
                                width='20px'
                                height='20px'
                            />
                            {t`Progress as of` + ` ${formattedEndDate}`}
                        </div>
                    </div>
                )
            }}
        </RootConsumer>
    )
}

interface IOverlappingProgressBar {
    beginPercent: number,
    endPercent: number,
}

const OverlappingProgressBar: FunctionComponent<IOverlappingProgressBar> = (props) => {
    let { beginPercent, endPercent } = props
    return (
        <ProgressBar className='progress-bar-custom'>
            <ProgressBar bsStyle='success' now={beginPercent} />
            <ProgressBar bsStyle='info' now={endPercent - beginPercent} />
        </ProgressBar>
    )
}