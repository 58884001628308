import React, { Component } from 'react'
import { observer, /* inject */ } from 'mobx-react'

const log = require('debug')('sltt:SegmentLabelTextEditor')

interface ISegmentLabelTextEditor {
    text: string,
    update: (value: string) => void,
    save: () => void,
    cancel: () => void,
}

class SegmentLabelTextEditor extends Component<ISegmentLabelTextEditor>  {
    render() {
        let { text } = this.props

        return (
            <span>
                <input
                    className="segment-label-text-editor"
                    type={'text'}
                    value={text}
                    onKeyDown={this.onKeyDown.bind(this)}
                    onChange={this.onChange.bind(this)} />
            </span>
        )
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        let { save, cancel } = this.props
        
        e.stopPropagation()

        // <enter> = complete editing
        if (e.keyCode === 13) {
            save()
            return
        }

        // <esc> = abandon edit
        if (e.keyCode === 27) {
            cancel()
            return
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.update(e.target.value)
    }

}

export default observer(SegmentLabelTextEditor)
