import { string } from "parsimmon"
import _ from "underscore"
import { Passage, ProjectTask, ProjectStage, PassageVideo, ProjectPlan } from "../../../models3/ProjectModels"
import { fmt } from "../../utils/Fmt"

const log = require('debug')('sltt:ProgressUtils') 

export const expectedCompletionDate = (entries: {date: Date, percent: number}[], endDate: Date) => {
    let recentEntries = getRecentEntries(entries, endDate)
    if (recentEntries.length < 2) {
        return
    }
    let x0 = recentEntries[0].date.getTime()
    let y0 = recentEntries[0].percent
    let x1 = recentEntries[recentEntries.length - 1].date.getTime()
    let y1 = recentEntries[recentEntries.length - 1].percent
    let slope = (y1 - y0) / (x1 - x0)
    if (slope <= 0) {
        return
    }
    let intercept = yIntercept(x0, y0, slope)
    let completionSeconds = interceptsValue(100, intercept, slope)
    let completionDate = new Date(completionSeconds)
    return completionDate
}

function getRecentEntries(entries: {date: Date, percent: number}[], endDate: Date) {
    let sortedEntries = [...entries].sort((a, b) => a.date.getTime() - b.date.getTime())
    let aYearAgo = new Date(endDate)
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)
    let recentEntries = sortedEntries.filter(entry => entry.date.getTime() - aYearAgo.getTime() >= 0)
                            .sort((a, b) => a.date.getTime() - b.date.getTime())
    return recentEntries
}

const yIntercept = (x: number, y: number, slope: number) => y - slope * x

const interceptsValue = (value: number, yIntercept: number, slope: number) => (value - yIntercept) / slope

const percentComplete = (allTasks: ProjectTask[], taskSearchRange: ProjectTask[], passages: Passage[], date: Date) => {
    let completed = completedEffort(allTasks, taskSearchRange, passages, date)
    let total = totalEffort(taskSearchRange, passages)
    if (total === 0) {
        return 0
    }
    return completed / total
}

const completedEffort = (allTasks: ProjectTask[], taskSearchRange: ProjectTask[], passages: Passage[], date: Date) => {
    let effort = 0
    passages.forEach(passage => {
        taskSearchRange.forEach(task => {
            if (hasCompletedPassageTask(allTasks, task, passage, date)) {
                effort += passageTaskEffort(passage, task)
            }
        })
    })
    return effort
}

export const totalEffort = (tasks: ProjectTask[], passages: Passage[]) => {
    let effort = 0
    passages.forEach(passage => {
        tasks.forEach(task => {
            effort += passageTaskEffort(passage, task)
        })
    })
    return effort
}

export const hasCompletedPassageTask = (allTasks: ProjectTask[], task: ProjectTask, passage: Passage, date: Date) => {
    let { videosNotDeleted } = passage
    let video = findMostRecentVideoBeforeDate(videosNotDeleted, date)
    if (!video) { return false }

    let videoTaskIndex = allTasks.findIndex(t => t.id === video?.status)
    let taskTaskIndex = allTasks.findIndex(t => t.id === task.id)

    const completed = videoTaskIndex >= 0 && videoTaskIndex > taskTaskIndex
    return completed
}

export const findMostRecentVideoBeforeDate = (videos: PassageVideo[], date: Date): PassageVideo | undefined => {
    let dateDate = date.getTime()
    let i = _.findLastIndex(videos, v => v.status && (Date.parse(v.creationDate) <= dateDate))
    return i >= 0 ? videos[i] : undefined
}

// const earliestVideo = (videos: PassageVideo[]): PassageVideo | undefined => {
//     let sortedVideos = [...videos].sort((a, b) => Date.parse(a.creationDate) - Date.parse(b.creationDate))
//     return sortedVideos[0]
// }

// Has currentTask completed task?
// export const hasCompletedTask = (allTasks: ProjectTask[], task: ProjectTask, currentTask: ProjectTask) => {
//     if (currentTask.id === 'Finished') {
//         return true
//     }

//     if (!allTasks.find(t => t._id === task._id || t._id === currentTask._id)) {
//         return false
//     }
    
//     let completed = false
//     let foundVideoTask = false
//     allTasks.forEach(t => {
//         if (completed || foundVideoTask) {
//             return
//         }
//         if (currentTask._id === t._id) {
//             foundVideoTask = true   // currentTask < task
//             return
//         }
//         if (task._id === t._id) {
//             completed = true        // currentTask > task
//         }
//     })
//     return completed
// }

/*
 * Calculate contribution for this passage/task. 
 * Don't let task effort be zero however because that makes it difficult to draw bars
 * if all the tasks have zero effort.
 */
const passageTaskEffort = (passage: Passage, task: ProjectTask) => {
    // If task difficulty is zero, make it a small number so that we can show some progress
    let { difficulty: taskDifficulty } = task
    if (taskDifficulty === 0 && !task.firstTask && !task.lastTask) {
        taskDifficulty = .001
    }
    return passage.difficulty * taskDifficulty
}

// const statusToTask = (status: string, tasks: ProjectTask[]) => tasks.find(e => e.id === status)

export function getPercentCompleteThroughStage(passages: Passage[], plan: ProjectPlan, stage: ProjectStage, date: Date) {
    let { stages } = plan
    let allTasks = stages.flatMap(stage => stage.tasks)
    let taskRange = stage.tasks
    return percentComplete(allTasks, taskRange, passages, date)
}

// export function getPercentCompleteThroughPlan(passages: Passage[], plan: ProjectPlan, date: Date) {
//     return percentComplete(plan.tasks, plan.tasks, passages, date)
// }

function getCumulativeTaskDifficulty(plan: ProjectPlan) {
    let cumulativeTaskDifficulty = new Map<string, number>()

    let difficulty = 0
    for (let stage of plan.stages) {
        for (let task of stage.tasks) {
            cumulativeTaskDifficulty.set(task.id, difficulty)
            difficulty += Math.max(task.difficulty, 0.1) // Require task have some difficulty in order to show some progress
        }
    }

    return { cumulativeTaskDifficulty, totalDifficultyAllTasks: difficulty }
}

interface PassageTaskProgress {
    passage: Passage,
    time: number,
    taskId: string, // ProjectTask.id for task started at this time
}

// Get all progress events for this passage list
function getPassageProgressEvents(passages: Passage[], plan: ProjectPlan) {
    let progress: PassageTaskProgress[] = []

    let now = Date.now()
    let fiveYearsBack = now - 5*365*24*3600*1000

    for (let passage of passages) {
        for (let video of passage.videosNotDeleted) {
            if (video.status) {
                let time = (new Date(video.statusModDate)).getTime()
                if (time > now || time < fiveYearsBack ) continue // ignore videos with impossible dates
                
                progress.push( {passage, time, taskId: video.status})
            }
        }
    }

    progress = _.sortBy(progress, 'time')
    return progress
}

// passage effort = passage.difficulty * total difficulty of all tasks completed for this passage

export function getGraphData(passages: Passage[], plan: ProjectPlan, locale: string) {
    let data: {name: string, date: Date, percent: number}[] = []

    // Get all the events where progress was made sorted oldest to newest
    let progressEvents: PassageTaskProgress[] = getPassageProgressEvents(passages, plan)
    let _events = progressEvents.map(pe => ({
        name: pe.passage.name, 
        time: new Date(pe.time), 
        taskId: pe.taskId
    }))
    log("progressEvents", fmt({_events}))

    // Determine cumulative progress for each task id
    let {cumulativeTaskDifficulty, totalDifficultyAllTasks} = getCumulativeTaskDifficulty(plan) // task.id => total difficulty up to this task
    
    let totalEffort = 0
    for (let passage of passages) {
        totalEffort += passage.difficulty * totalDifficultyAllTasks
    }

    let currentTime = progressEvents.length ? progressEvents[0].time : 0

    const weekLength = 7*24*3600*1000 // week in milliseconds
    let passageEffortCompleted = new Map<string, number>() // passage._id => progress effort

    let i = 0
    while (i < progressEvents.length) {
        // Process events for one week updating completed effort by passage
        while (i < progressEvents.length) {
            let event = progressEvents[i]
            if (event.time >= currentTime + weekLength) break

            let effortCompleted = event.passage.difficulty * (cumulativeTaskDifficulty.get(event.taskId) ?? 0)
            passageEffortCompleted.set(event.passage._id, effortCompleted)
            i = i +1
        }

        // Total completed efforts for all passages up to and including current week
        let effortCompleted = 0
        for (let completed of passageEffortCompleted.values()) { effortCompleted += completed }

        data.push({ 
            name: (new Date(currentTime)).toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric'}), 
            date: new Date(currentTime), 
            percent: 100*effortCompleted/totalEffort})
        
        currentTime += weekLength
    }

    return data
}
