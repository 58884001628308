/**
 * TRL = Translation Resource Library
 * A project producing videos to assist the translation process.
 * 
 * This modules contains the data structures and the code necessary to
 * serialize/deserialize a TRLResource from S3.
 */
import { fmt } from "../components/utils/Fmt"
import { validateIso639dash1LanguageCode } from '../components/utils/Languages'
import API from "./API"
import { DEFAULT_RANK, Passage, Portion } from "./ProjectModels"
import { Root } from './Root'

const resourcesFileName = 'TRLResources.json'
const resourceFileName = 'TRLResource.json'

const log = require('debug')('sltt:TRLModel')

// Return contents of https path as json.
// If no such s3 object return null (technically all we can do is look for a 403
// result code ... this code will be return if the s3 object does not exist ...
// unfortunately it will also be returned if the object exists but we have not managed
// to create a correctly signed url)
// 
async function getJsonFromS3(path: string): Promise<any|null> {
    // get a signed url for the resource for GET operation
    let [error, signedUrl] = await API._getUrl(path, false, 5000) /* 5 second timeout */
    if (error) {
        // todo: used cached list if available
        throw error
    }

    let response = await fetch(signedUrl)
    if (response.status === 403) {
        return null
    }

    if (!response.ok) {
        throw Error(`${response.url}: ${response.status} - ${response.statusText}`)
    }

    let json: any = await response.json()
    return json
}

async function putJsonToS3(path: string, json: any) {
    // get signed url for the resource for PUT operation
    let [error, signedUrl] = await API._getUrl(path, true)
    if (error) {
        throw error
    }

    let options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/octet-stream' },
        body: JSON.stringify(json),
    }

    let response = await fetch(signedUrl, options)

    if (!response.ok) {
        throw Error(`${response.url}: ${response.status} - ${response.statusText}`)
    }
}

interface ITRLResources {
    resourceNames: string[]
} 

/**
 *  This is a list of all the TRL authoring projects.
 *  This information will be in s3://sltt-videos/TRLResources.json.
 *  Since there are only a handful of these projects, for now this file will be manually edited
 *  whenever a new TRL authoring project is created.
 */
export class TRLResources {
    static publishedResourceNames: string[] = []  // short names of all published projects that are used for TRL authoring

    /*
     * Called with no argument to construct an empty resource.
     * Called with an argument to reconstruct from json data read from S3.
     * Argument content will be validated and an error thrown if invalid.
     */
    constructor({ resourceNames }: ITRLResources) {
        TRLResources.publishedResourceNames = resourceNames ?? []
    }

    // S3 path to TRLResources.json
    static path = `${resourcesFileName}`

    static isPublishedResource(resourceName: string) {  
        return TRLResources.publishedResourceNames.includes(resourceName)
    }

    static async getPublishedResourceNamesFromS3() {
        TRLResources.publishedResourceNames = []

        let json = await getJsonFromS3(TRLResources.path)
        if (json === null) {
            return
        }

        let { resourceNames } = json
        if (!Array.isArray(resourceNames)) {
            throw new TRLMalformedError('resourceNames is not an array')
        }

        TRLResources.publishedResourceNames = resourceNames
    }

    static async getAllResourcesFromS3(resourceNames: string[] = []) {
        const loadedResources: TRLResource[] = []
        for (const resourceName of resourceNames) {
            try {
                const trlResource = await TRLResource.getFromS3(resourceName);
                if (trlResource)
                  loadedResources.push(trlResource);
            } catch (e) {
                log('error in getAllResourcesFromS3', fmt(e))
            }
        }
        return loadedResources
    }

    static async publishPortionAndUpdateResourceList(rt: Root, portion: Portion) {
        const trlResource = createTRLResource(rt)
        await trlResource.publishPortion(portion, trlResource.primaryLanguage)
        // TODO: should optimize when this takes too long
        await rt.loadTRLResources()
    }

    async putToS3() {
        const resourceNames = TRLResources.publishedResourceNames
        await putJsonToS3(TRLResources.path, { resourceNames })
    }
}

export function createTRLResource(rt: Root) {
    const {
        name: resourceName,
        copyrightNotice: copyright,
        license,
        inputLanguagePrimary: primaryLanguage,
        organizationLogoUrl: logoUrl,
    } = rt.project
    // TODO: this looks a bit awkward. is there a way to refactor this so that it
    // it doesn't appear that this resource is starting with empty portions?
    // It's true that this might be the first time we've published, but....
    const trlResource = new TRLResource(
        { primaryLanguage, resourceName, copyright, license, portions: [], logoUrl }
    )
    return trlResource
}

export interface IMultilingualString {
    language: string  // ISO 639-1
    text: string
}

export enum TRLLicense {
    ASK = 'Ask',
    CC0 = 'CC0',
    CC_BY = 'CC BY',
    CC_BY_SA = 'CC BY-SA',
    CC_BY_NC = 'CC BY-NC',
    CC_BY_NC_SA = 'CC BY-NC-SA',
    CC_BY_ND = 'CC BY-ND',
 }

// Certain field values are required to successfully create a TRL resource.
// The following errors are thrown when a required value is missing.
// This can happen when the resource is initially built or (in the event of a SLTT bug)
// when data for the resource is read from S3.

export class TRLUserResponsibilityError extends Error {}

// User has not entered required metadata, e.g. copyright, license, ...
export class TRLMissingMetadataError extends TRLUserResponsibilityError { }

// User has not entered a required title for the primary language
export class TRLMissingTitlesError extends TRLUserResponsibilityError { }

// User has not created a thumbnail video for a passage
export class TRLMissingThumbnailVideoError extends TRLUserResponsibilityError { }

// User has not created a passage for a portion
export class TRLMissingPassageError extends TRLUserResponsibilityError { }

// User has not created a video for a passage
export class TRLMissingVideoError extends TRLUserResponsibilityError { }

// SLTT software bug has created a malformed resource
export class TRLMalformedError extends Error { }


export interface ITRLResource {
    primaryLanguage: string,  // primary language for titles
    resourceName: string, // name of TRL project
    copyright: string,
    license: TRLLicense,
    portions: ITRLPortion[],
    logoUrl: string,
}

/**
 *  This is the validated, published metadata for a TRL project.
 *  For TRL project xyz this info will be in s3://sltt-videos/xyz/TRLResource.json.
 *  This file will be created/updated by SLTT client whenever the consultant 
 *  clicks the 'publish' function a portion.
 */
export class TRLResource implements ITRLResource {
    primaryLanguage: string // primary language for titles
    resourceName: string = '' // name of TRL project
    copyright: string = ''
    license: TRLLicense = TRLLicense.ASK
    portions: TRLPortion[] = []
    logoUrl: string = ''

    published: boolean = false // true if this resource is visible to all projects

    /*
       If a resource object is passed we validate all its contents and 
       create a TRLResource.
       If any data in the input resource is missing or invalid we throw one of the
       TRL* excetptions defined above.

       To construct a TRLResource from a Project

           let { inputLanguagePrimary: primaryLanguage, name: resourceName, titles, copyright, license, portions } = project
           let trlResource = new TRLResource({ primaryLanguage, resourceName, titles, copyright, license, portions })
     */
    constructor(json: ITRLResource) {
        shallowValidateTRLResource(json)
        const { primaryLanguage, resourceName, copyright, license, portions, logoUrl } = json
        this.resourceName = resourceName
        this.license = license
        this.copyright = copyright
        this.logoUrl = logoUrl
        this.primaryLanguage = primaryLanguage
        this.portions = portions.map(portion => new TRLPortion(portion, primaryLanguage))
        shallowValidateTRLResource(this)

        this.published = TRLResources.isPublishedResource(resourceName)
    }

    async publishPortion(portion: Portion, primaryLanguage: string) {
        let trlPortion = TRLPortion.create(portion, primaryLanguage)
        let trlResource = await TRLResource.getFromS3(this.resourceName)

        if (trlResource !== null) {
            this.portions = trlResource.portions
        }

        // replace or append this trlPortion
        let i = this.portions.findIndex(p => p.portion_id === portion._id)
        if (i >= 0) {
            this.portions[i] = trlPortion
        } else {
            this.portions.push(trlPortion)
        }
        this.portions.sort((a, b) => (a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0))

        // update the resource list in s3

        await this.putToS3()
    }

    // Read sltt-video/${resourceName}/TRLResource.json and return it as TRLResource object.
    // Return null if s3 object does not exist yet.
    //
    // Test from console: window.TRLResource.getFromS3('RD').then(console.log)
    // Test toJson(): 
    //     - window.TRLResource.getFromS3('TESTnm').then(resource => global.resource1 = resource).then(console.log)
    //     - JSON.stringify(global.resource1) === JSON.stringify(global.resource1.toJson())
    static async getFromS3(resourceName: string): Promise<TRLResource | null> {
        // get a signed url for the resource
        let path = `${resourceName}/${resourceFileName}`
        let json = await getJsonFromS3(path)
        if (json === null) {
            return null
        }

        let resource = new TRLResource(json) // validate all contents of the resource
        return resource
    }

    // This will throw TRL* if required information (e.g. titles) is missing from the resource
    async putToS3() {
        new TRLResource(this) // validate all contents of the resource
        let { resourceName } = this
        let path = `${resourceName}/${resourceFileName}`
        log(`Publishing to TRL: ${fmt(this)}})`)
        await putJsonToS3(path, this)
    }

    // Call this from consle: TRLResource.testAPI().catch(console.log)
    // WBVN is this ended up in a unit test!
    /* 
    static async testAPI() {
        let resource = `{
            "resourceName": "TESTnm",
            "titles": [{"language": "en", "text": "_resource title_"}],
            "copyright": "_copyright_",
            "license": "CC_BY",
            "portions": [
                {
                    "portion_id": "_portion id_",
                    "titles":[{"language": "en", "text": "_portion title_"}],
                    "passages": [
                        {
                            "titles": [{"language": "en", "text": "_passage title_"}],
                            "videoUrl": "_url_"
                        }
                    ]
                }
            ]
        }`
    
        let trl = new TRLResource(JSON.parse(resource))
        await trl.putToS3()
        let trl2 = await TRLResource.getFromS3('TESTnm').catch(console.log)
        log(fmt({trl2}))
    }
    */
}

export enum ThumbnailType {
    video = 'video',
    image = 'image',
}

export interface ITRLThumbnail {
    srcUrl: string,
    type: ThumbnailType,
}

export interface ITRLPortion {
    portion_id: string
    rank: string
    titles: IMultilingualString[]
    passages: ITRLPassage[]
    thumbnail: ITRLThumbnail|undefined
}

export class TRLPortion implements ITRLPortion {
    portion_id: string = ''
    rank: string = DEFAULT_RANK
    titles: IMultilingualString[] = []
    passages: TRLPassage[] = []
    thumbnail: ITRLThumbnail|undefined = undefined

    constructor({ portion_id, rank, titles, passages, thumbnail }: ITRLPortion,
        primaryLanguage: string,
        firstPassageIsThumbnail = false,
        skipThumbnailValidation = false,
        skipPassageValidation = false,
    ) {
        primaryLanguage = primaryLanguage || ''

        this.portion_id = portion_id
        if (typeof this.portion_id !== 'string' || this.portion_id.trim() === '') {
            throw new TRLMalformedError(`Malformed portion_id: ${this.portion_id}`)
        }

        this.rank = rank
        if (typeof this.rank !== 'string' || this.rank.trim() === '' || this.rank === DEFAULT_RANK || this.rank.length !== DEFAULT_RANK.length) {
            throw new TRLMalformedError(`Malformed rank: ${this.rank}`)
        }

        this.titles = titles
        validateTitles(this.titles, primaryLanguage)

        if (!Array.isArray(passages)) {
            throw new TRLMalformedError('passages is not an arry')
        }
        this.thumbnail = thumbnail
        if (!skipThumbnailValidation) validateThumbnail(this.thumbnail)
        // skip the first passage if firstPassageIsThumbnail
        this.passages = passages
            .slice(firstPassageIsThumbnail ? 1 : 0)
            .map(passage => !skipPassageValidation ?
                new TRLPassage(passage, primaryLanguage, skipThumbnailValidation) : passage
            )
    }

    // Build a TRLPortion from a model Portion.
    // Will throw TRL* if any required information issing, e.g. titles
    static create(portion: Portion, primaryLanguage: string): TRLPortion {

        if (portion.passages.length === 0) {
            throw new TRLMissingPassageError('Passages are missing for portion: ' + portion.name)
        }
        const portionThumbnailUrl = portion.getThumbnailVideoUrl('')
        if (portionThumbnailUrl === '') {
            throw new TRLMissingThumbnailVideoError('First passage is missing thumbnail video for portion: ' + portion.name)
        }
        const thumbnail = createTRLThumbnailVideo(portionThumbnailUrl)
        // console.log('create portion', { portionThumbnailUrl, thumbnail })

        let { _id: portion_id, rank, titles, finalPassages: passages, firstPassageIsThumbnail } = portion

        function buildTRLPassage(passage: Passage) {
            validateTitles(passage.titles, primaryLanguage)
            
            const videoUrl = getPassageVideoUrl(passage)
            const passageThumbnailUrl = passage.getThumbnailVideoUrl('')
            if (passageThumbnailUrl === '') {
                throw new TRLMissingThumbnailVideoError('missing thumbnail video for passage: ' + passage.name)
            }
            const thumbnail = createTRLThumbnailVideo(passageThumbnailUrl)
            return new TRLPassage({ titles: passage.titles, videoUrl, thumbnail }, primaryLanguage)
        }

        let trlPassages = passages.map(buildTRLPassage)

        validateTitles(portion.titles, primaryLanguage)

        let trlPortion = new TRLPortion({
            portion_id,
            rank,
            titles,
            passages: trlPassages,
            thumbnail,
        }, primaryLanguage, firstPassageIsThumbnail)

        return trlPortion
    }
}

/**
 * The backend 'get signed url' (s3Urls) function will allow read access to any of the videos listed here
 * by any member of any project.
 */
export interface ITRLPassage {
    titles: IMultilingualString[]
    videoUrl: string
    thumbnail: ITRLThumbnail
}


export class TRLPassage implements ITRLPassage {
    titles: IMultilingualString[] = []
    videoUrl: string = ''
    thumbnail: ITRLThumbnail
    // anchors:   /* future */
    // links:     /* future */

    constructor({ titles, videoUrl, thumbnail }: ITRLPassage, primaryLanguage: string, skipThumbnailValidation = false) {

        this.titles = titles
        validateTitles(this.titles, primaryLanguage)

        this.videoUrl = videoUrl
        if (typeof this.videoUrl !== 'string' || this.videoUrl.trim() === '') {
            throw new TRLMissingMetadataError('Missing videoUrl in passage ' + this.titles[0].text)
        }

        this.thumbnail = thumbnail
        if (!skipThumbnailValidation) validateThumbnail(this.thumbnail)
    }
}

function createTRLThumbnailVideo(thumbnailUrl: string = '') {
    return {
        srcUrl: thumbnailUrl,
        type: ThumbnailType.video,
    }
}

function getPassageVideoUrl(passage: Passage) {
    if (passage.publishedUrl) {
        return passage.publishedUrl
    }

    let passageVideo = passage.getDefaultVideo(null)
    if (!passageVideo) {
        throw new TRLMissingVideoError('Missing video for passage: ' + passage.name)
    }
    return passageVideo.url
}

function validateThumbnail(thumbnail: any) {
    if (thumbnail === undefined) {
        return
    }
    if (typeof thumbnail !== 'object') {
        throw new TRLMalformedError('thumbnail is not an object')
    }
    if (typeof thumbnail.srcUrl !== 'string' || thumbnail.srcUrl.trim() === '') {
        throw new TRLMalformedError('thumbnail.srcUrl is not a string or is empty')
    }
    if (typeof thumbnail.type !== 'string' || thumbnail.type.trim() === '') {
        throw new TRLMalformedError('thumbnail.type is not a string or is empty')
    }
    if (!Object.values(ThumbnailType).includes(thumbnail.type)) {
        throw new TRLMalformedError('thumbnail.type is not a valid value')
    }
}

// This will throw TRL* if required information (e.g. titles) is missing from the resource
function shallowValidateTRLResource(json: ITRLResource) {
    const { resourceName, license, copyright, logoUrl, portions, primaryLanguage } = json
    if (typeof resourceName !== 'string' || resourceName.trim() === '') {
        throw new TRLMalformedError(`Malformed resourceName: ${resourceName}`)
    }

    if (!Object.values(TRLLicense).includes(license)) {
        throw new TRLMissingMetadataError('license')
    }

    if (typeof copyright !== 'string' || copyright.trim() === '') {
        throw new TRLMissingMetadataError('copyright')
    }

    if (typeof logoUrl !== 'string' || logoUrl.trim() === '') {
        throw new TRLMissingMetadataError('logoUrl')
    }

    validatePrimaryLanguage(primaryLanguage)

    if (!Array.isArray(portions)) {
        throw new TRLMalformedError('portions is not an array')
    }
}

function validatePrimaryLanguage(primaryLanguage: string) {
    if (typeof primaryLanguage !== 'string') {
        throw new TRLMalformedError(`Malformed primaryLanguage: ${primaryLanguage}`)
    }
    try {
        validateIso639dash1LanguageCode(primaryLanguage)
    } catch (e) {
        const err = e as Error
        const message = err && err.message || JSON.stringify(e)
        throw new TRLMalformedError(message)
    }
}

// Must have title for at least the primary language
// OR if primary language not specified ('') must have at least one title
function validateTitles(titles: IMultilingualString[], primaryLanguage: string) {
    validatePrimaryLanguage(primaryLanguage)

    if (!Array.isArray(titles)) {
        throw new TRLMalformedError('titles is not an array')
    } 

    if (primaryLanguage === '') {
        if (titles.length === 0) {
            throw new TRLMissingTitlesError()
        }
        return
    }

    if (!titles.find(title => title.language === primaryLanguage)) {
        throw new TRLMissingTitlesError()
    }
}

// Allow direct access to static class methods from console
const _window = window as any
_window.TRLResource = TRLResource
_window.TRLResources = TRLResources
