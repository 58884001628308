// Allow user to select project plan task for a video

import React, { FC } from 'react'
import { t } from 'ttag'

import { Dropdown, MenuItem } from 'react-bootstrap'
import { Passage, PassageVideo, ProjectPlan, ProjectTask } from '../../models3/ProjectModels'
import { displayError } from '../utils/Errors'
import { useFlags } from 'launchdarkly-react-client-sdk'

const log = require('debug')('sltt:TaskSelectorDropdown')

// Eyes 👀 - Unicode: U+1F440
// People Holding Hands 👫 - Unicode: U+1F46B
// Group of People 👥 - Unicode: U+1F465
// Cityscape 🏙️ - Unicode: U+1F3D9
// Speech Balloon 💬 - Unicode: U+1F4AC
// Magnifying Glass Tilted Left 🔍 - Unicode: U+1F50D
// Microscope 🔬 - Unicode: U+1F52C

// update long and short name
// add short name to passage display
// make video selector use short name (and long name?)
// ask about what to display for "Not started"

interface IDecoratedTask {
    index: number // 0 to tasks.length - 1
    task: ProjectTask
    color: string
    completed: number // 0 to 100
}

export function decoratedTasks(plan: ProjectPlan, useRedGreen: boolean): IDecoratedTask[] {
    function toHex(n: number) {
        const value = Math.min(255, Math.max(0, n))
        return Math.floor(value).toString(16).padStart(2, '0')
    }

    function colorByStage(stagesLength: number, stagePosition: number, lastTask: boolean) {
        const _colorByStage = [
            ['transparent', 'lime'],
            ['transparent', 'red', 'lime'],
            ['transparent', 'red', 'yellow', 'lime'],
            ['transparent', 'red', 'orange', 'yellow', 'lime'],
            ['transparent', 'red', 'orange', 'yellow', 'green', 'lime'],
            // plans with more than 5 stages will repeat the last color of the 5 stage list
        ]

        const index = stagesLength - 3 // Pick color based on number of stages not including 'Not started' and 'Finished'
        const colors = _colorByStage[Math.max(0, Math.min(index, _colorByStage.length-1))]

        // Added a special case for the task associated with the last stage because it has stagePosition set to 0.
        if (lastTask) return colors[colors.length-1]

        return colors[Math.min(stagePosition, colors.length-1)]
    }

    const { tasks } = plan
    return tasks.map((task, index) => {
        const completed = 100 * index / (tasks.length - 1)
        
        let color: string
        if (useRedGreen) {
            color = `#${toHex(255 - 255 * completed / 100)}${toHex(255 * completed / 100)}00`
        } else {
            color = colorByStage(plan.stages.length, task.stagePosition, task.lastTask)
        }

        return { index, task, color, completed }
    })
}

interface ICompletionIcon {
    completed: number, // 0 to 100
    color: string, // e.g. 'green
    width: number, // in pixels
}

const CompletionIcon: FC<ICompletionIcon> = ({ completed, color, width}) => {
    const circleStyle = {
        width: `${width}px`,
        background: "lightgrey",
        borderRadius: "50%",
    }

    const segmentStyle = {
        strokeDasharray: `${completed} 100`,
        fill: "none",
        strokeWidth: "32px",
        stroke: color,
    }

    return (
        <span className="completion-icon">
            <svg viewBox="0 0 64 64" style={circleStyle}>
                <circle r="25%" cx="50%" cy="50%" style={segmentStyle} />
            </svg>
        </span>
    )
}

interface IDisplayStatusPieChartLocal {
    decoratedTask: IDecoratedTask,
    includeName: boolean,
}

const DisplayStatusPieChartLocal: FC<IDisplayStatusPieChartLocal> = ({ decoratedTask, includeName }) => 
{
    const { task, completed, color } = decoratedTask
    const name = includeName ? ` ${task.displayedName}` : ''
    const width = 13
    return (
        <span data-toggle="tooltip" title={task.displayedName}>
            <CompletionIcon {...{ completed, color, width }} />{name}
        </span>
    )
}

interface IDisplayStatusPieChart {
    status: string,
    plan: ProjectPlan,
}

export const DisplayStatusPieChart: FC<IDisplayStatusPieChart> = ({ status, plan }) => {
    const flags = useFlags()
    if (!status) { return null }

    const tasks = decoratedTasks(plan, flags.redGreen)
    const task = tasks.find(task => task.task.id === status) ?? tasks[0]

    if (!task) { return null }

    return (<DisplayStatusPieChartLocal decoratedTask={task} includeName={false} />)
}


interface ITaskSelectorDropdown {
    passage: Passage,
    video: PassageVideo | null,
    plan: ProjectPlan,
    enabled: boolean,
}

export const TaskSelectorDropdown: FC<ITaskSelectorDropdown> = ( { passage, video, plan, enabled }) => 
{
    const className = "task-selector-dropdown sl-task-selector-in-timeline-button"

    const tooltip = /* translator: important */ t`Select current project task for this passage`

    if (video === null) return null
    const status = video.getStatus(passage)
    const flags = useFlags()

    const tasks = decoratedTasks(plan, flags.redGreen)
    const task = tasks.find(task => task.task.id === status) ?? tasks[0]

    // Don't show dropdown if ther are no tasks (should not happen, but don't want to crash)
    if (!task) { return null }

    // data-tip data-place="right" 
    return (
        <div
            className={className}
            data-toggle="tooltip" title={tooltip} style={{ zIndex: 1001 }}
        >
            <Dropdown id="task-selector-dropdown" disabled={!enabled}>
                <Dropdown.Toggle className="task-selector-dropdown-item">
                    <DisplayStatusPieChartLocal {...{ decoratedTask: task, includeName: false }} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {tasks.map((_task, i) => {
                        return (
                            <MenuItem
                                key={i}
                                className='task-selector-dropdown-item'
                                onClick={() => video.setStatus(_task.task.id).catch(displayError)}
                            >
                                <DisplayStatusPieChartLocal {...{ decoratedTask: _task, includeName: true }} />
                            </MenuItem>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

