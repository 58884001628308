import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import TextInput from '../../utils/TextInput'
import { RootContext } from '../../app/RootContext'
import { Root } from '../../../models3/Root'
import { ProjectStage } from '../../../models3/ProjectModels'

interface IProjectTaskAdder {
    buttonClassName: string,
    addTask: (name: string) => void,
    stage: ProjectStage,
}

class ProjectTaskAdder extends Component<IProjectTaskAdder> {
    @observable adding = false

    static contextType = RootContext

    render() {
        let rt: Root = this.context
        if (!rt) return null

        let { validate } = this

        let _addTask = t`Add task.`

        let { buttonClassName } = this.props
        if (this.adding) {
            return (
                <div className="project-task-adder">
                    <TextInput
                        placeholder={_addTask}
                        message={t`Type Enter to add new task or Esc to cancel.`}
                        validate={validate}
                        _onEscape={this.onCancel}
                        _onEnter={this.onEnter} />
                </div>
            )
        }

        return (
            <div onClick={ () => this.adding = true }
                className={buttonClassName}    
                data-toggle="tooltip" 
                title={_addTask}>
                    <i className="far fa-fw fa-plus-square"></i>
            </div>
        )
    }

    validate = (value: string) => {
        value = value.trim()
        let { stage } = this.props
        if (stage.tasks.find(task => task.name === value)) {
            return t`Duplicate name`
        }
        if (value === '') {
            return t`Empty name`
        }
        return ''
    }

    onCancel = () => this.adding = false

    onEnter = (newTaskName: string) => {
        if (!this.validate(newTaskName)) {
            this.props.addTask(newTaskName)
        }
        this.adding = false
    }
}

export default observer(ProjectTaskAdder)