import React, { FC, useRef, useState } from 'react'
import { observer, /* inject */ } from 'mobx-react'

import { displayError } from '../utils/Errors'
import { OkEditSegmentButton, CancelEditSegmentButton } from '../utils/Buttons'
import { PassageSegment } from '../../models3/ProjectModels'
import { t } from 'ttag'
import { useOnClickOutside } from '../utils/Hooks'

const log = require('debug')('sltt:SegmentGlossEditor')

interface ISegmentGlossEditor {
    segment: PassageSegment,
    onClose: () => void,
    identity: string,
}

const SegmentGlossEditor: FC<ISegmentGlossEditor> = observer(({ segment, onClose, identity }) => {
    const [value, setValue] = useState(() => {
        let sg = segment.glosses.find(g => g.identity === identity)
        return sg ? sg.gloss : ''
    })

    const segmentGlossEditorRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(segmentGlossEditorRef, () => {
        // Delay the save so the clicked button will still be disabled when the click event finishes propagating
        // during that delay (otherwise the clicked button may become stealthily active shortly after the ref container loses focus
        // so when the click event finishes propagating, it will execute the clicked button's action.
        setTimeout(() => save(), 250)
    })

    function onChange(e: any) {
        setValue(e.target.value)
    }

    function save() {
        segment.setGloss(identity, value)
            .catch(displayError)

        onClose()
    }

    return (
        <div className="segment-captions-editor" ref={segmentGlossEditorRef}>
            <div className="segment-captions-identity">{identity}</div>
            <div className="segment-captions-editor-editor">
                <textarea
                    name="story"
                    autoFocus
                    placeholder={t`Gloss for segment...`}
                    rows={10}
                    cols={60}
                    value={value}
                    onChange={onChange} />
            </div>
            <div>
                <OkEditSegmentButton
                    enabled={true}
                    onClick={save} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={onClose} />
            </div>
        </div>
    )
})

export default SegmentGlossEditor
