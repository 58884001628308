// Display a item of a note so that the user can play
// or delete[last item only]

import React, { FC, useEffect, useRef, useState } from 'react'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import { Member, PassageNoteItem } from '../../models3/ProjectModels'
import { TextNotification } from '../notifications/Notifications'
import { PencilButton, DeleteButton, CloneButton } from '../utils/Buttons'
import MediaThumbnail from '../utils/MediaThumbnail'
import { systemError, displayError, displayInfo } from '../utils/Errors'
import Player from '../utils/Player'
import { Switch } from "../utils/Switch"
import { ConsultantIcon } from '../utils/Icons'
import { MemberDisplay } from '../utils/MemberDisplay'
import { VideoCache } from '../../models3/VideoCache'
import { EditableRichText } from '../utils/RichTextEditor'
import { NoteTextEditor } from './NoteTextEditor'
import { fmt } from '../utils/Fmt'
import { NoteSearchTarget } from './NoteMain'
import { IRoot } from '../../models3/Root'
import { confirmAlert } from 'react-confirm-alert'
import { prepNotification } from '../utils/PrepNotification'
import './Note.css'
import { useHoverDelay } from '../utils/Hooks'

const log = require('debug')('sltt:NoteItem')

interface INoteItem {
    item: PassageNoteItem,
    noteRoot: IRoot,
    closeNoteDialog?: () => void,
    setEditing: (value: boolean) => void,
    allowEditing: boolean,
    searchResult: string,
    searchText: string,
}
export const NoteItem: FC<INoteItem> = observer(({ 
        noteRoot, item, closeNoteDialog, setEditing, allowEditing, searchResult, searchText }) => {
    const { dateFormatter, project, note, iAmAdmin, username } = noteRoot

    const [mouseOver, setMouseOver] = useState(false)
    const showViewers = useHoverDelay(mouseOver)

    let member = project.findMemberWithDefault(item.creator)
    const creationDate = item.displayedCreationDate(dateFormatter)
    const viewers = item.viewedBy.map(email => project.creatorName(email)).join(', ')

    return (
        <div className="note-item">
            <div className='note-item-header'
                    onMouseEnter={() => setMouseOver(true)}
                    onMouseLeave={() => setMouseOver(false)}>
                <div className='note-item-creation-date'>
                    {creationDate}
                </div>
                <i>
                    <MemberDisplay member={member} imageSize='large' />
                </i>
            </div>
            {showViewers && <div className='note-viewers'>
                <span className='note-viewers-heading'>
                    <span className={`fas fa-fw fa-glasses`} />&nbsp;
                    {t`Viewed by:`}
                </span>
                {viewers}
            </div>}
            <NoteItemBody {...{ 
                noteRoot, item, closeNoteDialog, setEditing, allowEditing, searchResult, searchText,
            }} />
        </div>
    )
})


interface IConsultantOnlySwitch {
    noteRoot: IRoot,
    item: PassageNoteItem,
}

const ConsultantOnlySwitch: FC<IConsultantOnlySwitch> = observer(({ noteRoot, item }) => {
    let { consultantOnly } = item
    let { canViewConsultantOnlyFeatures } = noteRoot

    if (!canViewConsultantOnlyFeatures) return null

    let tooltip = t`Visible only to consultants.`

    // If the item is (not yet) consultant only, we make it transparent until hovered over
    let iconClassName = consultantOnly ? '' : 'not-consultant-only-item '
    iconClassName += 'consultant-only-item-icon'

    return (
        <span className={`consultant-only-switch note-item-button`}>
            <ConsultantIcon className={iconClassName} tooltip={tooltip} />
            <Switch
                className="consultant-only-item-switch"
                enabled={!noteRoot.note!.resolved}
                value={consultantOnly}
                setValue={ async (value: boolean) => {
                    try {
                        log('ConsultantOnlySwitch', !consultantOnly)
                        await item.setConsultantOnly(!consultantOnly)
                    }
                    catch (error) {
                        displayError(error)
                    }
                }} 
                tooltip={tooltip} />
        </span>)
})

const NoteItemBody: FC<INoteItem> = observer(({ item, noteRoot, closeNoteDialog, setEditing, allowEditing, searchResult, searchText }) => {
    if (item.resolved) return (<span>{/* translator: important */ t`RESOLVED!`}</span>)

    if (item.unresolved) return (<span>{/* translator: important */ t`UNRESOLVED!`}</span>)
    
    if (item.isTextItem()) return (<NoteTextItem {...{ noteRoot, item, setEditing, allowEditing,
        searchResult, searchText }} />)
    
    if (item.isImageItem()) return (<NoteImageItem {...{ noteRoot, item, setEditing, allowEditing,
        searchResult, searchText }} />)
    
    return (<NoteVideoItem {...{ noteRoot, item, closeNoteDialog, setEditing, allowEditing,
        searchResult, searchText }} />)
})


const NoteImageItem: FC<INoteItem> = ({ noteRoot, item }) => {
    let [open, setOpen] = useState(false)

    let imageClassName = open ? 'note-image-thumbnail-3x' : 'note-image-thumbnail'
    return (
        <>
            <div className={`note-item-main ${imageClassName}`}>
                <MediaThumbnail
                    url={item.url}
                    creator={item.creator}
                    isImage={true}
                    currentTime={0}
                    onClickVideo={() => {}}
                    onClickImage={() => setOpen(!open)}
                />
            </div>
            <NoteItemControls {...{ noteRoot, item }} />
        </>
    )
}

interface INoteItemControls {
    item: PassageNoteItem,
    noteRoot: IRoot,
    setEditing?: (editing: boolean) => void,
        // we assume setEditing passed only if the user has permission to edit
    url?: string,
}

const NoteItemControls: FC<INoteItemControls> = ({ noteRoot, item, setEditing, url }) => {
    let { iAmAdmin, note, createNoteIfNonexistent, hardNotificationCutoff, 
            username, useMobileLayout } = noteRoot
    let iCreatedThisItem = (item.creator === username)
    let deleteable = !(note?.resolved) && (iCreatedThisItem || iAmAdmin)

    const removeItem = async () => {
        try {
            await createNoteIfNonexistent() // ???
            await note!.removeItem(item._id)
        } catch (err) {
            systemError(err)
        }
    }

    function removeItemWithConfirmation() {
        confirmAlert({
            title: /* translator: important */ t`Delete note item?!`,
            confirmLabel: /* translator: important */ t`Delete item!`,
            cancelLabel: /* translator: important */ t`Keep item.`,
            message: '',
            onConfirm: () => {
                removeItem().catch(displayError)
            },
            onCancel: () => { 
            },
        })
    }

    let cutoff = hardNotificationCutoff()
    let shouldDisplay = item.isUnviewedAfterDate(username, cutoff)

    return (
        <div className='note-item-controls'>
            {shouldDisplay && 
                <TextNotification 
                    className='note-item-button note-item-notification' 
                    tooltip={t`This item is unviewed`} />}
            <ConsultantOnlySwitch {...{ noteRoot, note, item }} />
            {setEditing && !useMobileLayout &&
                <span className='note-edit-button note-item-button'>
                    <PencilButton
                        enabled='true'
                        className='note-item-edit-button'
                        onClick={() => setEditing(true)}
                        tooltip={/* translator: important */ t`Edit note item`}
                    />
                </span>}
            {(!useMobileLayout && url) &&
                <span className="note-item-download-button"
                      onClick={() => downloadUrl(url)}>
                    <i className="fas fa-fw fa-download"></i>
                </span>
            }
            {(!useMobileLayout && url) &&
            <span className='note-item-button'>
                <CloneButton
                    className='note-clone-button'
                    tooltip={t`Use this note video as a patch.`}
                    enabled={true}
                    onClick={() => {
                        noteRoot.clonedNoteItem = item
                        displayInfo(t`Go to the video segment you want to patch. Shift click the record patch button to use this video as a patch.`)
                    }} />
            </span>            }
            {deleteable && !useMobileLayout &&
                <span className='note-item-button'>
                    <DeleteButton 
                        className='note-item-delete-button' 
                        tooltip={/* translator: important */ t`Delete.`} 
                        enabled={true} 
                        onClick={removeItemWithConfirmation} />
                </span>
            }
        </div>
    )
}

export async function downloadUrl(url: string, filename?: string) {
    let blob = await VideoCache.getVideoBlob(url)
    if (!blob) return
    let href = window.URL.createObjectURL(blob)

    let link = document.createElement('a')
    let tag = url.split('/').slice(-1)[0]

    if (!filename) {
        filename = 'note_' + tag.split('-')[0] + '.webm'
    }
    link.download = filename
    link.href = href
    
    link.click()
}

interface INoteTextItemParagrah {
    para: string,  
    searchText: string, // string we are searching for, empty if none
}

const NoteTextItemParagrah: FC<INoteTextItemParagrah> = ({ para, searchText}) => 
{
    let start = -1
    if (searchText) {
        start = para.search(searchText)
    }
    if (start >= 0) {
        let before = para.slice(0, start)
        let selection = para.slice(start, start+searchText.length)
        let after = para.slice(start+searchText.length)

        return(
            <div className="note-text-paragraph">
                {before}
                <span className="note-text-paragraph-selection">{selection}</span>
                {after}
            </div>
        )
    }

    return (
        <div className="note-text-paragraph">
            {para}
        </div>
    )
}

const NoteTextItem: FC<INoteItem> = ({ noteRoot, item, setEditing, allowEditing, searchResult, searchText }) => {
    let [editing, setLocalEditing] = useState(false)

    let { iAmAdmin, username, note, project, iAmInterpreter } = noteRoot

    let editable = allowEditing && !note!.resolved && (iAmAdmin || item.creator === username)

    let prefix = 'note-text-editor'

    function _setEditing(value: boolean) {
        setLocalEditing(value)
        setEditing(value)
    }

    // For now, use the old text editor. We don't do this for text that has
    // already been edited in the new editor. If we did, the old text editor
    // would display HTML strings, which would be surprising for users.
    let useNextTextEditor = item.text.trim().startsWith('<') && item.text.trim().endsWith('>')
    if (useNextTextEditor) {
        return (
            <>
                <EditableRichText
                    savedText={item.text}
                    save={text => item.updateText(text)}
                    cancel={() => {}}
                    editorOpen={editing}
                    setEditorOpen={_setEditing}
                    prefix={prefix}
                />
                {!editing && (
                    <NoteItemControls {...{ noteRoot, item }} setEditing={editable ? _setEditing : undefined} />
                )}
            </>
        )
    }

    if (!editing) {
        return (
            <NoteSearchTarget className="note-text-item" {...{ searchResult, _id: item._id }} >
                <>
                    <div className="note-item-main note-text">
                        {item.text.split('\n').map((para, i) => {
                            return (
                                <NoteTextItemParagrah {...{para, searchText, key: i }} />
                            )
                        })}
                    </div>
                    <NoteItemControls {...{ noteRoot, item }} setEditing={editable ? _setEditing : undefined} />
                </>
            </NoteSearchTarget>   
        )
    }

    return (
        <NoteTextEditor
            initialText={item.text}
            onSave={async (text: string) => {
                await item.updateText(text)
                prepNotification({ text, noteRoot })
                _setEditing(false)
            }}
            onCancel={() => _setEditing(false)}
        />
    )
}

const NoteVideoItem: FC<INoteItem> = ({ noteRoot, item }) => {
    let [playing, setPlaying] = useState(false)


    let { username } = noteRoot
    if (!playing) {
        return (
            <>
                <div className="note-item-main media-thumbnail">
                    <MediaThumbnail
                        url={item.url}
                        creator={item.creator}
                        isImage={false}
                        currentTime={0.2}
                        onClickVideo={() => {
                            setPlaying(true)
                        }}
                        onClickImage={() => {}}
                    />
                </div>
                <NoteItemControls {...{ item, noteRoot, url: item.url }} />
            </>
        )
    }

    item.addViewedBy(username)
        .catch(displayError)

    return (
        <div>
            <Player
                className="note-item-player"
                videoUrl={item.url}
                onEnded={() => setPlaying(false)} />
        </div>
    )
}
