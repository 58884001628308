import React, { FC, useEffect, useRef, useState } from 'react'
import {observer} from 'mobx-react'
import _ from 'underscore'
import { t } from 'ttag'
import Vimeo from '@u-wave/react-vimeo'

import './DbsPlayer.css'
import { RefRange } from '../../scrRefs/RefRange'
import { getSLBiblePassages, SLBiblePassage } from '../../resources/albums'
import { Root } from '../../models3/Root'
import { ReferenceInput } from '../utils/ReferenceInput'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { Project } from '../../models3/ProjectModels'
import useResizeObserver from "use-resize-observer"


// eslint-disable-next-line
const log = require('debug')('sltt:DbsPlayer') 

function defaultPassage(currentPassage: SLBiblePassage | undefined, passages: SLBiblePassage[]) {
    // If previously selected project and it's in new list, use first item from this previous project
    // If previously selected project not in list, use first item from American project
    // If previously selected project not in list and American not in list, select first item in list
    // If no previous project, use first from American
    // If no previous project and American not in list, select first item in list
    let americanPassages = passages.filter(p => p.languageName === 'American')
    let currentProjectPassages = passages.filter(p => p.languageName === currentPassage?.languageName)
    let nextPassage = currentProjectPassages[0] || americanPassages[0] || passages[0]
    return nextPassage
}

interface IDbsPlayer {
    rt: Root,
}

const DbsPlayer: FC<IDbsPlayer> = observer(({ rt }) => {
    const [dbsRefs, setDbsRefs] = useState<RefRange[]>([])
    const [passages, setPassages] = useState<SLBiblePassage[]>([])
    const [passage, setPassage] = useState<SLBiblePassage | undefined>(undefined)
    const [signedUrl, setSignedUrl] = useState('')

    let defaultReferenceId = 'DBSReference'

    const { ref, width = 640 } = useResizeObserver<HTMLDivElement>()

    // Whenever dbsRefs changes, fetch a list of associated passages and select a default passage
    useEffect(() => {
        (async () => {
            let passages = await getSLBiblePassages(dbsRefs)
            setPassages(passages)
            setPassage(defaultPassage(passage, passages))
        })()
    }, [dbsRefs])

    // If we don't have any refs yet get them from localStorage default or rt.dbsRefs
    useEffect(() => {
        if (dbsRefs.length > 0) return

        // No references passed try to get a default reference from localStorage
        let defaultRefernce = rt.getDefault(defaultReferenceId) ?? ''
        log('defaultRerence', defaultRefernce)

        if (defaultRefernce) {
            let _refs = JSON.parse(defaultRefernce).map((r: any) => new RefRange(r.startRef, r.endRef))
            log('parseDefault', _refs)
            if (_refs.length) {
                setDbsRefs(_refs)
                return
            }      
        }
        
        // Set refs to current rt.dbsRefs
        if (rt.dbsRefs.length) {
            setDbsRefs(rt.dbsRefs)
        }
    }, [dbsRefs])

    function onClick(passage: SLBiblePassage) {
        log('onClick', JSON.stringify(passage, null, 4))
        setPassage(passage)
    }

    return (
        <div className="dbs-player-top-container" ref={ref}>
            <ResourcePlayerToolbar {...{ rt, setDbsRefs, defaultReferenceId, passages, passage, onClick }} />
            <div className='dbs-player-video-area'>
                { passage ? (
                    <DbsVideoMain passage={passage} width={width} /> 
                ) : (
                    <div>{t`No video selected.`}</div>
                )}
            </div>
        </div>
    )
})

type ResourcePlayerToolbarProps = {
    rt: Root,
    setDbsRefs: (refs: RefRange[]) => void,
    defaultReferenceId: string,
    passages: SLBiblePassage[],
    passage?: SLBiblePassage,
    onClick: (passage: SLBiblePassage) => void,
}

const ResourcePlayerToolbar: FC<ResourcePlayerToolbarProps> = observer(({ rt, setDbsRefs, defaultReferenceId, passages, passage, onClick }) => {
    const [errored, setErrored] = useState(false)
    let { dbsRefs } = rt // force re-render on change

    return (
        <div className='video-toolbar dbs-player-toolbar'>
            <div className='dbs-reference-search'>
                <ReferenceInput
                    refInput={rt}
                    refs={[]}
                    setRefs={setDbsRefs}
                    errored={errored}
                    setErrored={setErrored}
                    allowBookNameOnly={true}
                    includeGotoReferenceButton={true}
                    defaultReferenceId={defaultReferenceId} />
            </div>
            <div className="dbs-video-selector">
                { passage && 
                    <VideoSelector {...{ project: rt.project, passages, passage, onClick }} /> }
            </div>
        </div>
    )
})

function displayedVideoName(passage: SLBiblePassage, project: Project) {
    let { name } = passage
    if (name.length > 80) {
        name = name.slice(0, 80) + '...'
    }
    if (name) { name = '(' + name + ')' }

    return `${passage.languageName} - ${RefRange.refRangesToDisplay(passage.refs, project)} ${name}`
}

type VideoSelectorProps = {
    project: Project,
    passages: SLBiblePassage[],
    passage: SLBiblePassage,
    onClick: (passage: SLBiblePassage) => void,
}

const VideoSelector: FC<VideoSelectorProps> = observer(({ project, passages, passage, onClick }) => {
    return (
        <Dropdown id='dbs-video-selector' pullRight>
            <Dropdown.Toggle className='sl-dropdown dbs-video-dropdown'>
                {displayedVideoName(passage, project)}
            </Dropdown.Toggle>
            <Dropdown.Menu className='scrollable-dropdown'>
                {passages.map(p => (
                    <MenuItem key={`${p.url}`} onSelect={() => onClick(p)} >
                        {displayedVideoName(p, project)}
                    </MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
})


// <Vimeo videoId={ videoId } />,

interface IDbsVideoMain {
    passage: SLBiblePassage,
    width: number,
}

const DbsVideoMain: FC<IDbsVideoMain> = observer(({ passage, width }) => {
    let isVimeo = passage.url.includes('vimeo')

    let width2 = 20 * Math.round(width / 20)

    let hostedBy = ''
    if (passage.hostedBy !== 'United Bible Societies') {
        hostedBy = '/ ' + /* translator: important */ t`Hosted by: ` + passage.hostedBy
    }

    return (
        <div className="dbs-video-main">
            <div className='dbs-video-container'>
                {isVimeo ? 
                    <VimeoPlayer {...{passage, width}}/>
                    :
                    <video className="dbs-player-video" controls src={passage.url} />
                }
            </div>
            <p> {passage.copyright} {hostedBy} </p>
        </div>
    )
})

const VimeoPlayer: FC<IDbsVideoMain> = ({ passage, width }) => {
    function ss(u:string) { return u.split('/').slice(-1)[0] }

    let [url, setUrl] = useState(passage.url)
    log(`VimeoPlayer ${ss(passage.url)} / ${ss(url)}`)

    /**
     * There is a bug in <Vimeo> that causes it to fail to load private videos
     * when passage.url changes to a new video.
     * To work around this we remove the Vimeo control for 100ms
     * and then create a new control with the new url.
     */
    if (url && passage.url !== url) {
        setTimeout(() => { setUrl(passage.url) }, 100)
        return (<div></div>)
    }

    let width2 = 20 * Math.round(width / 20)

    return (
        <Vimeo className="dbs-vimeo" 
            video={url} 
            showTitle={false} 
            showByline={false} 
            showPortrait={false}
            width={width2 - 50} 
            height={(width2 - 50) * 480 / 640} />
    )
}


export default DbsPlayer
