import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { t } from 'ttag'
import { Member, Passage, Project, validateUsername } from '../../models3/ProjectModels'
import { MembersIcon } from './Icons'
import './MemberDisplay.css'
import { TextInputWithEditButton } from './TextInput'
import { systemError } from './DynamicErrors'
import { normalizeUsername } from '../../models3/DBAcceptor'
import { MemberImagePicker } from '../projectSettings/members/MemberView'

const log = require('debug')('sltt:MemberDisplay')

type IEditableMemberDisplay = {
    project: Project,
    member: Member,
}

// This view allows editing the members email address and is only shown to admins
export const EditableMemberDisplay: FC<IEditableMemberDisplay> = observer(({ project, member }) => {
    const { imageUrl, email, previousEmails } = member

    const [pickImage, setPickImage] = React.useState(false)

    if (pickImage) {
        return (<MemberImagePicker {...{project, member, setPickImage}} />)
    }

    return (
        <span className='member-display-full inline-flex-centered'>
            <span className='member-image-container-extra-space'>
                <MemberAvatar imageUrl={imageUrl} variation={'large'} 
                    onClick={() => setPickImage(true)} />
            </span>
            <div>
                <TextInputWithEditButton
                    className="member-display-email-editor"
                    allowEdit={true}
                    value={email}
                    validate={username => validateUsername(normalizeUsername(username))}
                    setValue={_email => project.setMemberEmail(email, _email).catch(systemError)} />
                {!!previousEmails.length && 
                    <div data-toggle="tooltip" title={t`Previous email addresses.`}>
                        ({previousEmails.join(', ')})
                    </div> }
            </div>
        </span>
    )
})

type MemberDisplayProps = {
    member: Member,
    imageSize: ImageSize,
    showEmail?: boolean, // show email address instead of member.name
    hideUsername?: boolean, // Don't show user name or email address
    hideImage?: boolean,
}

export const MemberDisplay: FC<MemberDisplayProps> = observer(({ member, imageSize, showEmail, hideUsername, hideImage }) => {
    const { imageUrl, email, name } = member

    let displayName = ''
    let title = ''
    if (showEmail) {
        displayName = email
    }
    else if (!hideUsername) {
        displayName = name ? name : email.split('@')[0]
        title = email
    }

    return (
        <span className='member-display-full inline-flex-centered'>
            {!hideImage && <span className='member-image-container-extra-space'>
                <MemberAvatar imageUrl={imageUrl} variation={imageSize} />
            </span>}
            <span {...{ title }}>{displayName}</span>
        </span>
    )
})

type ImageSize = 'small' | 'large'
type MemberAvatarProps = {
    imageUrl: string,
    variation: ImageSize,
    onClick?: () => void,
}

const MemberAvatar: FC<MemberAvatarProps> = observer(({ imageUrl, variation, onClick }) => {
    const className = variation === 'large' ? 'member-image-container-lg' : 'member-image-container-sm'
    const defaultImageClassName = variation === 'large' ? 'default-member-image-lg' : 'default-member-image-sm'

    return (
        <div className="member-avatar">
            {imageUrl && 
                <img className={className} src={imageUrl} />}
            {!imageUrl &&
                <MembersIcon className={`${className} ${defaultImageClassName}`} tooltip='' />}
            {onClick && 
                <div className="member-avatar-image-adder" 
                        onClick={onClick}
                        data-toggle="tooltip"
                        title={t`Add image for user.`}>
                    <i className="far fa-fw fa-plus-square"></i>
                </div>}
        </div>
    )
})

type MemberSelectorProps = {
    project: Project,
    passage: Passage,
    allowChange: boolean,
}

export const MemberSelector: FC<MemberSelectorProps> = ({ project, passage, allowChange }) => {
    let { members } = project
    let { assignee } = passage
    // Here we need an actual current member so we don't use findMember (which includes former email address in the search)
    let currentMember = members.find(mem => mem.email === assignee)

    return (
        <span className='member-selector-current-status'>
            {currentMember ? (
                <MemberDisplay member={currentMember} imageSize='small' hideUsername={!!currentMember.imageUrl} />
            ) : (
                <div className='member-display'>
                    {t`Unassigned`}
                </div>
            )}
            <DropdownButton
                bsStyle='default'
                id='member-selector'
                title=''
                className='sl-dropdown member-selector-dropdown'
                disabled={!allowChange}
            >
                <MenuItem onSelect={() => passage.setAssignee('')}>
                    <div className='member-display'>
                        {t`Unassigned`}
                    </div>
                </MenuItem>
                {members.map((member, i) => (
                    <MenuItem
                        key={i}
                        onSelect={() => passage.setAssignee(member.email)}
                    >
                        <MemberDisplay member={member} imageSize='small' />
                    </MenuItem>
                ))}
            </DropdownButton>
        </span>
    )
}