import React, { FC, useEffect, useState } from 'react'
import { t } from 'ttag'

import 'react-checkbox-tree/lib/react-checkbox-tree.css'

const log = require('debug')('sltt:SearchableItemsSelector') 

import CheckboxTree, { Node } from 'react-checkbox-tree'
import { Passage, PassageNote, PassageVideo, Portion } from '../../models3/ProjectModels'
import { observer } from 'mobx-react'
import { IDateFormatter } from '../../models3/DateUtilities'
import _ from 'underscore'
import { s } from './Fmt'
import { CheckedItem } from '../../models3/RootBase'
import { IRoot } from '../../models3/Root'

// NOTE: in this module "checked" means the item has been selected by the user
// in the checkbox tree control.

/** <SearchableItemsSelector>
 * 
 * Show a checkbox tree allowing the user to select
 * 
 *    - all portions/passages
 *    - selected portions
 *    - selected passages in selected portions
 *    - specific drafts in the currently selected passage
 * 
 * By default the latest draft of the currently selected portion/passage is selected.
 */



export function getCheckedVideos(rt: IRoot, checked: CheckedItem[]) {
    let videos = checked.map(item => getCheckedItemVideo(rt, item, checked))
    return _.compact(videos)
}

/** 
 * Return the PassageVideo corresponding to this item or null if none.
 */
export function getCheckedItemVideo(
        rt: IRoot,
        item: CheckedItem,
        checked: CheckedItem[]
    ): PassageVideo | null 
{
    let { project } = rt
    let passage = project.findPassage(item)
    if (!passage) return null

    let video = passage.findVideo(item)
    if (video) return video

    let specificVideosChecked = checked.filter(_item => _item.startsWith(item)).length > 1
    if (!specificVideosChecked) {
        return passage.getLatestVideo()
    }

    return null
}

export function checkedItemName(
    rt: IRoot,
    item: string /* _id of item */,
    checked: string[]) {
    let video = getCheckedItemVideo(rt, item, checked)
    if (!video) return ''
    return video.displayName(rt, true)
}

// Node { value, label, children }

function getDraftNodes(passage: Passage, _passage: Passage,
    dateFormatter: IDateFormatter, includeOldDrafts: boolean): Node[] | undefined 
{
    // If we have not been specifically requested to include older draft versions
    // of all passages and this is not the users current passage, ignore the older drafts.
    if (!includeOldDrafts && passage._id !== _passage._id) {
        return undefined
    }

    let videos = _passage.videosNotDeleted
    videos.reverse()

    let nodes = videos.map(video => ({
        label: video.displayedCreationDate(dateFormatter), 
        value: video._id
    }))
    return nodes
}

function addPortionNode(
        nodes: Node[], 
        portion: Portion, 
        passage: Passage,
        dateFormatter: IDateFormatter,
        includeOldDrafts: boolean,
    )
{
    let children: Node[] = []

    for (let _passage of portion.passages) {
        let label = _passage.name
        let value = _passage._id
        children.push({ 
            label, 
            value, 
            children: getDraftNodes(passage, _passage, dateFormatter, includeOldDrafts) 
        })
    }

    nodes.push({
        label: portion.name,
        value: portion._id,
        children
    })
}

export function getSearchableItemNodes(rt: IRoot, includeOldDrafts: boolean)
{
    let { portion, passage } = rt
    if (!portion || !passage) return []
    
    let nodes: Node[] = []

    addPortionNode(nodes, portion, passage, rt.dateFormatter, includeOldDrafts)

    for (let _portion of rt.project.portions) {
        if (_portion._id !== portion._id) {
            addPortionNode(nodes, _portion, passage, rt.dateFormatter, includeOldDrafts)
        }
    }

    let topNode: Node = {
        label: '',
        value: '*all*',
        children: nodes
    }

    return [topNode]
}

// Walk the tree of nodes and return just the _ids
export function getSearchableItemIds(rt: IRoot) {
    let ids: string[] = []
    
    function addNode(node: Node) {
        ids.push(node.value)
        for (let child of node.children ?? []) {
            addNode(child)
        }
    }

    addNode(getSearchableItemNodes(rt, true)[0])

    return ids
}


type ISearchableItemsSelector = {
    rt: IRoot,
    checked: CheckedItem[],
        // checked array can contain: portion ids, passage ids, video ids
    setChecked: (CheckedItem:string[]) => void, 
}

export const SearchableItemsSelector: FC<ISearchableItemsSelector> = observer(({rt, checked, setChecked}) => {
    function initiallyExpanded() {
        let _expanded = ['*all*']
        let video = rt.passageVideo
        if (video) _expanded.push(video._id.split('/')[0])
        return _expanded
    }

    let [expanded, setExpanded] = useState<string[]>(initiallyExpanded())
    let [nodes] = useState(getSearchableItemNodes(rt, false))

    return (
        <CheckboxTree
            iconsClass="fa5"
            icons={{ leaf: <span className="sltt-icon-video" /> }}
            nodes={nodes}
            checked={checked}
            expanded={expanded}
            onCheck={setChecked}
            onExpand={setExpanded} />
    )
})


